import Base = require("Everlaw/Base");
import Button = require("Everlaw/UI/Button");
import { ColorTokens, EverColor } from "design-system";
import { Compare as Cmp, Is } from "core";
import Dialog = require("Everlaw/UI/Dialog");
import { Color } from "Everlaw/ColorUtil";
import { elevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";
import Dom = require("Everlaw/Dom");
import Rest = require("Everlaw/Rest");
import Task = require("Everlaw/Task");
import User = require("Everlaw/User");
import { UserObject } from "Everlaw/UserObject";

/* Must match SearchTerm.java toJsonTreeWithDescription().
 *  0 eql
 *  1 docs
 *  2 uniques
 *  3 docsDedupe
 *  4 uniquesDedupe
 *  5 docsAttach
 *  6 docsAttachDedupe
 *  7 description
 */

enum TermIndex {
    eql = 0,
    docs = 1,
    uniques = 2,
    docsDedupe = 3,
    uniquesDedupe = 4,
    docsAttach = 5,
    docsAttachDedupe = 6,
    description = 7,
}

/* numDocs and searchableSetSize must match NumDocs.java toJsonTree()
 *  0 docs
 *  1 docsDedupe
 *  2 docsAttach
 *  3 docsAttachDedupe
 */

enum STRIndex {
    docs = 0,
    docsDedupe = 1,
    docsAttach = 2,
    docsAttachDedupe = 3,
}

class SearchTermReport extends UserObject implements Base.Colored {
    get className() {
        return "SearchTermReport";
    }
    override id: SearchTermReport.Id;
    name: string;
    numSearches: number;
    /* numDocs and searchableSetSize must match NumDocs.java toJsonTree()
     *  0 docs
     *  1 docsDedupe
     *  2 docsAttach
     *  3 docsAttachDedupe
     */
    numDocs: number[];
    searchableSetSize: number[];
    user: User;
    autoRefresh: boolean;
    dedupe: boolean;
    lastUpdated: number;
    // termsData is not populated in the constructor for space considerations.
    termsData: { [id: string]: SearchTermReport.TermData };
    constructor(params: any) {
        super(params);
        this.termsData = {};
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        if (Is.number(params.user)) {
            this.user = Base.get(User, params.user);
        }
    }
    override compare(other: SearchTermReport) {
        const key = (str: SearchTermReport) => [
            str !== SearchTermReport.dummyAnySearchTermReport,
            !str.favorite,
            -str.getLastActivity(),
            str.name,
            str.id,
        ];
        return Cmp.full(key(this), key(other));
    }
    setTermsData(data: { [id: string]: SearchTermReport.TermData }) {
        this.termsData = data;
    }
    override display() {
        return this.name;
    }
    getColor() {
        return SearchTermReport.COLOR;
    }
    getNumDocsWithoutAttachments() {
        if (!this.numDocs) {
            return null;
        }
        return this.numDocs[getNumDocsWithoutAttachmentsIndex(this)];
    }
    getNumAttachments() {
        const numDocsWithAttachments = this.getNumDocs();
        const numDocsWithoutAttachments = this.getNumDocsWithoutAttachments();
        // If either numDocsWithAttachments or numDocsWithoutAttachments is null, we do not have
        // enough data to determine the number of attachments, so bail and just return null.
        return numDocsWithAttachments === null || numDocsWithoutAttachments === null
            ? null
            : numDocsWithAttachments - numDocsWithoutAttachments;
    }
    getNumDocs() {
        if (!this.numDocs) {
            return null;
        }
        return this.numDocs[getNumDocsWithAttachmentsIndex(this)];
    }
    getSearchableSetSize() {
        if (!this.searchableSetSize) {
            return null;
        }
        // NOTE: Currently only indexes returned by getNumDocsWithAttachmentsIndex are used.
        // However, to support legacy data that may still use the old index, we fall back to
        // getNumDocsWithoutAttachmentsIndex if necessary.
        const withAttach = this.searchableSetSize[getNumDocsWithAttachmentsIndex(this)];
        if (withAttach !== null) {
            return withAttach;
        }
        return this.searchableSetSize[getNumDocsWithoutAttachmentsIndex(this)];
    }
    getDocsForSearch(searchId: string) {
        return this.termsData[searchId][getDocsIndex(this)];
    }
    getUniquesForSearch(searchId: string) {
        return this.termsData[searchId][getUniquesIndex(this)];
    }
    getAttachedForSearch(searchId: string) {
        return this.termsData[searchId][getAttachedIndex(this)];
    }
    getDescriptionForSearch(searchId: string): string {
        // TS complains without the "as unknown".
        return this.termsData[searchId][TermIndex.description] as unknown as string;
    }
    getEqlForSearch(searchId: string) {
        return this.termsData[searchId][TermIndex.eql];
    }
    initTermsDataForSearch(searchId: string, desc: SearchTermReport.TermData) {
        this.termsData[searchId] = desc;
    }
    // null out the unique counts.
    clearUniques() {
        Object.values(this.termsData).forEach((term) => {
            term[TermIndex.uniques] = null;
            term[TermIndex.uniquesDedupe] = null;
        });
    }
    // null out the doc counts and unique counts.
    clearTermsData() {
        Object.values(this.termsData).forEach((term) => {
            // Don't null out index 0 (eql) or index 7 (description)
            for (let i = 1; i <= 6; i++) {
                term[i] = null;
            }
        });
    }
    @elevatedRoleConfirm("refreshing a search term report")
    refresh(callback?: Rest.Callback, error?: (err: Rest.Failed) => void) {
        Rest.post("refreshSearchTermReport.rest", { searchTermReportId: this.id }).then((data) => {
            if (data) {
                Task.taskCreationNotification(data);
            }
        });
    }
    @elevatedRoleConfirm("renaming a search term report")
    rename(newname: string): Promise<string> {
        ga_event("Search Term Report", "rename");
        return Rest.post("renameSearchTermReport.rest", {
            name: newname,
            searchTermReportId: this.id,
        }).then((data) => {
            this._mixin(data);
            Base.publish(this);
            return this.name;
        });
    }
    @elevatedRoleConfirm("deleting a search term report")
    delete(onRemove?: () => void) {
        const contentContainer = Dom.div();
        const dialog = new Dialog({
            title: "Delete search term report",
            content: contentContainer,
        });
        const warningDiv = Dom.div(
            { style: { paddingBottom: "24px" } },
            Dom.div('Are you sure you want to delete search term report "' + this.display() + '"?'),
            Dom.br(),
            Dom.div(Dom.b("This action cannot be undone.")),
        );
        const buttonContainer = Dom.div({ class: "dialog-buttons" });
        new Button({
            label: "Cancel",
            width: "one",
            parent: buttonContainer,
            onClick: () => {
                dialog.destroy();
            },
            makeFocusable: true,
        });
        new Button({
            label: "Delete",
            class: "important unsafe",
            width: "one",
            parent: buttonContainer,
            onClick: () => {
                Rest.post("deleteSearchTermReport.rest", { searchTermReportId: this.id }).then(
                    () => {
                        Base.remove(this);
                        dialog.destroy();
                        onRemove && onRemove();
                    },
                );
                ga_event("Search Term Report", "delete");
            },
            makeFocusable: true,
        });
        Dom.place([warningDiv, buttonContainer], contentContainer);
        dialog.show();
    }
}

module SearchTermReport {
    export declare enum Id {}

    /* Must match SearchTerm.java toJsonTreeWithDescription().
     *  0 eql
     *  1 docs
     *  2 uniques
     *  3 docsDedupe
     *  4 uniquesDedupe
     *  5 docsAttach
     *  6 uniquesAttach
     *  7 docsAttachDedupe
     *  8 uniquesAttachDedupe
     *  9 description
     */
    export type TermData = [
        any,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        string,
    ];

    export const COLOR = Color.fromEverColor(ColorTokens.OBJECT_SEARCH_TERM_REPORT);

    export class SearchTermReportContent extends Base.Primitive<string> {
        /*
         * This set mirrors enum SearchTermReportContent in SearchTermReportProperty.java.
         */
        static readonly AllDocs = new SearchTermReportContent(
            "AllDocs",
            "(All documents and families with hits)",
        );
        static readonly DocsHits = new SearchTermReportContent(
            "DocHits",
            "Only documents with hits",
        );
        static readonly Attachments = new SearchTermReportContent(
            "Attachments",
            "Only family members of documents in search term report",
        );

        override get className() {
            return "SearchTermReportContent";
        }

        private constructor(
            id: string,
            public override name: string,
        ) {
            super(id, name);
            Base.add(this);
        }

        override display() {
            return this.name;
        }

        getId() {
            return this.id;
        }

        static allSearchTermReportContent(): SearchTermReportContent[] {
            return [
                SearchTermReportContent.AllDocs,
                SearchTermReportContent.DocsHits,
                SearchTermReportContent.Attachments,
            ];
        }
    }

    // Dummy search term report entry for including all search term report in search
    export const dummyAnySearchTermReport = new SearchTermReport({
        name: "Any Search Term Report",
        getColor: () => {
            return Color.fromEverColor(EverColor.EVERBLUE_40);
        },
        display: function () {
            return "(" + this.name + ")";
        },
    });
}

function getDocsIndex(report: SearchTermReport) {
    // See getNumDocsForSearch in SearchTermReport.java
    return report.dedupe ? TermIndex.docsDedupe : TermIndex.docs;
}

function getUniquesIndex(report: SearchTermReport) {
    // See getNumUniquesForSearch in SearchTermReport.java
    return report.dedupe ? TermIndex.uniquesDedupe : TermIndex.uniques;
}

function getAttachedIndex(report: SearchTermReport) {
    return report.dedupe ? TermIndex.docsAttachDedupe : TermIndex.docsAttach;
}

function getNumDocsWithoutAttachmentsIndex(report: SearchTermReport) {
    return report.dedupe ? STRIndex.docsDedupe : STRIndex.docs;
}

function getNumDocsWithAttachmentsIndex(report: SearchTermReport) {
    return report.dedupe ? STRIndex.docsAttachDedupe : STRIndex.docsAttach;
}

export = SearchTermReport;
