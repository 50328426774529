import React, { ReactNode } from "react";
import { Link, Span } from "components/Text";

// TODO: consider moving into design-system

/** Very simple container for DetailCardBreakdownItems */
export function DetailCardBreakdown({ children }: { children: ReactNode }) {
    return <div className={"bb-detail-card__breakdown"}>{children}</div>;
}

export interface DetailCardBreakdownItemProps {
    /** e.g. "Examine", "PDF", "Endorsed by code" */
    category: string;
    /** Link to affected documents */
    href?: string;
    /** value to display. e.g. "24", "1 issue", "–" (en-dash, not hyphen) */
    value?: string | number;
}

/** An item covering one category of issues/errors inside a DetailCardBreakdown */
export function DetailCardBreakdownItem({
    category,
    href,
    value,
}: DetailCardBreakdownItemProps): ReactNode {
    return (
        <div className={"bb-detail-card__breakdown-row"}>
            <Span.Overline className={"bb-detail-card__breakdown-category"}>
                {category}
            </Span.Overline>
            {href ? (
                <Link href={href}>
                    <Span.Small>{value}</Span.Small>
                </Link>
            ) : (
                <Span.Small className={"bb-text-color--secondary"}>{value ?? "-"}</Span.Small>
            )}
        </div>
    );
}
