import {
    AdvancedTable,
    ColumnProps,
    DialogSize,
    MarkedText,
    Memo,
    Modal,
    PaginationBar,
    RowKey,
    RowObject,
    Table,
    TextFilter,
    useBrandedMemo,
} from "design-system";
import { containsIgnoreCase } from "core";
import {
    onRecommendationClick,
    recommendationSort,
    TableRecommendationDisplay,
} from "Everlaw/SmartOnboarding/Components/RecommendationDisplay";
import type { Recommendation } from "Everlaw/SmartOnboarding/Recommendation";
import {
    getRecommendationsWithPermissions,
    Recommendations,
} from "Everlaw/SmartOnboarding/RecommendationConstants";
import * as React from "react";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";

interface RecommendationTableRow extends RowObject {
    recommendation: ReactNode;
    page: ReactNode;
    /**
     * Used as a row key, but this shouldn't be displayed in the table.
     */
    recommendationKey: keyof typeof Recommendations;
}

const TABLE_HEADERS = [
    {
        id: "recommendation",
        title: "Recommendation",
        width: "100%",
    },
    {
        id: "page",
        title: "Page",
        width: 240,
    },
] as Memo<ColumnProps<RecommendationTableRow>[]>;

/**
 * {@link Recommendation}s should be listed in decreasing order of most recently shown. We should
 * only display recommendations that have been {@link Recommendation#triggered triggered}.
 */
const getTriggeredRecommendationsInOrder = () => {
    return getRecommendationsWithPermissions()
        .filter((rec) => rec.isTriggeredUnsafe())
        .sort(recommendationSort);
};

interface AllRecommendationsProps {
    show: boolean;
    onMenuClose: () => void;
}

const pageSize = 10;
export const AllRecommendationsTable: FC<AllRecommendationsProps> = ({ show, onMenuClose }) => {
    const recommendations = getTriggeredRecommendationsInOrder();
    const [currentPage, setCurrentPage] = useState(1);
    const [activeRow, setActiveRow] = useState<RowKey>();
    const { inputValue, setInputValue, filterValue, setFilterValue, showInput, setShowInput } =
        TextFilter.use({});
    const textFilter = (
        <TextFilter
            inputValue={inputValue}
            setInputValue={setInputValue}
            showInput={showInput}
            setShowInput={setShowInput}
            setFilterValue={setFilterValue}
            placeholder={"Enter recommendation or page"}
            aria-label={"Search recommendation or page"}
            width={"280px"}
        />
    );
    const filteredRecommendations = useMemo<Recommendation[]>(() => {
        return recommendations.filter((recommendation) => {
            if (!filterValue) {
                return true;
            }
            return (
                containsIgnoreCase(recommendation.displayName, filterValue)
                || containsIgnoreCase(recommendation.pageDisplayName(), filterValue)
            );
        });
    }, [filterValue, recommendations]);
    const tableObjects = useBrandedMemo<RecommendationTableRow[]>(() => {
        const start = (currentPage - 1) * pageSize;
        const end = start + pageSize;
        return filteredRecommendations.slice(start, end).map((recommendation) => {
            return {
                recommendation: (
                    <TableRecommendationDisplay
                        recommendation={recommendation}
                        filterValue={filterValue}
                    />
                ),
                page: (
                    <MarkedText textToMark={filterValue}>
                        {recommendation.pageDisplayName()}
                    </MarkedText>
                ),
                recommendationKey: recommendation.getRecommendationKey(),
                hasActiveState: true,
            };
        });
    }, [currentPage, filterValue, filteredRecommendations]);
    // Updating the filter should reset to the first page.
    useEffect(() => {
        setCurrentPage(1);
    }, [filterValue]);
    const actionBar = (
        <Table.ActionBar
            rowCountSummary={
                <Table.RowCountSummary
                    numTotalRows={recommendations.length}
                    numFilterResults={filteredRecommendations.length}
                    counter={"recommendation"}
                    counterPlural={"recommendations"}
                />
            }
            textFilter={textFilter}
        />
    );
    const paginationBar = (
        <PaginationBar
            numTotalRows={filteredRecommendations.length}
            currentPage={currentPage}
            pageSize={Math.min(pageSize, filteredRecommendations.length)}
            setCurrentPage={setCurrentPage}
        />
    );
    return (
        <Modal
            size={DialogSize.LARGE}
            visible={show}
            onHide={() => {
                onMenuClose();
                setShowInput(true);
            }}
            onComplete={() => {
                if (activeRow) {
                    onRecommendationClick(activeRow as keyof typeof Recommendations);
                }
                onMenuClose();
            }}
            primaryButton={activeRow ? "Go to page" : "OK"}
            secondaryButton={activeRow ? "Cancel" : null}
            onCancel={() => setActiveRow(undefined)}
            title={"Recommendations"}
            className={"all-recommendations-table-dialog"}
        >
            <div className={"all-recommendations-table__info-text"}>
                Select a recommendation to view
            </div>
            <AdvancedTable
                aria-label={"All recommendations table"}
                className={"all-recommendations-table"}
                headers={TABLE_HEADERS}
                getKey={(obj) => obj.recommendationKey}
                objects={tableObjects}
                activeRow={activeRow}
                setActiveRow={setActiveRow}
                placeholder={
                    filterValue
                        ? "No results"
                        : "Continue working in Everlaw to see smart recommendations"
                }
                actionBar={actionBar}
                paginationBar={paginationBar}
            />
        </Modal>
    );
};
