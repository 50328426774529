import { Arr, Compare as Cmp, Str } from "core";
import Base = require("Everlaw/Base");
import { EverColor } from "design-system";
import { PartialSelectionType } from "Everlaw/PartialSelectionType";

// color-picker colors must all be unique
// This should correspond to the palette in PersistentHighlightCategoryService.java
export const highlightPalette = [
    EverColor.YELLOW_30,
    EverColor.TANGERINE,
    EverColor.EVERBLUE_20,
    EverColor.SAKURA,
    EverColor.RED_20,
    EverColor.GREEN_40,
    EverColor.YELLOW_20,
    EverColor.TAN,
    EverColor.EVERBLUE_30,
    EverColor.AQUAMARINE,
    EverColor.EARTH,
    EverColor.GREEN_10,
];

export function randomColorFromPalette(): number {
    return Math.floor(Math.random() * highlightPalette.length);
}

export interface PatternSpec {
    name: string;
    regex: string;
    placement: string;

    // Currently only used for displaying partial PII redaction options (previewing the redaction
    // with stars ex: ******1234). Product wanted all the numbers to end in 1234 but this results in
    // some silly full-text examples. Feel free to change them to something more reasonable if we're
    // displaying the whole string
    example: string;

    // The selection type used for partial PII redactions
    partialSelectionOptions: PartialSelectionType[];

    // whether this is a regex replaced by a well-known pattern token such as <ssn>
    legacy?: boolean;
}

/**
 * A list of possible regex formulas to create persistent highlights with. If placement is set to
 * main then it will be added to the list of persistent highlight badges in the unused section
 * of the persistent highlight settings page. Any other value for placement will place the formula
 * in the singleselect dropdown in the unused section.
 */
export const NAMED_PATTERNS: PatternSpec[] = [
    {
        name: "[National Insurance (UK)]",
        regex: '/[A-Za-z]{2}[0-9]{6}[A-z]/ OR "/[A-Za-z]{2}/ /[0-9]{2}/ /[0-9]{2}/ /[0-9]{2}/ /[A-Za-z]/"',
        placement: "Europe",
        example: "AB123123A",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_CHARS],
    },
    {
        name: "[Bank Account Numbers (UK)]",
        regex: "/[0-9]{7,8}/",
        placement: "Europe",
        example: "12341234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Passport Numbers (UK)]",
        regex: "/[0-9]{7}[0-9]{2}/",
        placement: "Europe",
        example: "123451234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Tax File Numbers (AU)]",
        regex: '/[0-9]{8,9}/ OR "/[0-9]{3}/ /[0-9]{3}/ /[0-9]{2,3}/"',
        placement: "Australia",
        example: "123-451-234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Medical Numbers (AU)]",
        regex: "/[2-6][0-9]{9,10}/",
        placement: "Australia",
        example: "12345671234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Passport Numbers (AU)]",
        regex: "/[A-Za-z][0-9]{7}/",
        placement: "Australia",
        example: "AB121234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Social Insurance Numbers (CA)]",
        regex: '"/[0-9]{3}/ /[0-9]{3}/ /[0-9]{3}/" OR /[0-9]{9}/',
        placement: "Canada",
        example: "123-451-234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Bank Account Numbers (CA)]",
        regex: '"/[0-9]{5}/ /[0-9]{3}/" OR /0[0-9]{8}/',
        placement: "Canada",
        example: "00123451234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Health Service Numbers (CA)]",
        regex: "/[0-9]{10}/",
        placement: "Canada",
        example: "1234561234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Passport Numbers (CA)]",
        regex: "/[A-Z]{2}[0-9]{6}/",
        placement: "Canada",
        example: "AB121234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Personal Health Information Numbers (CA)]",
        regex: "/[0-9]{9}/",
        placement: "Canada",
        example: "12341234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Bank Account Numbers (US)]",
        regex: "/[0-9]{6,17}/",
        placement: "US",
        example: "123456781234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Passport Numbers (US)]",
        regex: "/[0-9]{8}[0-9]/",
        placement: "US",
        example: "012341234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Employer Identification Numbers (US)]",
        regex: "<ein>",
        placement: "US",
        example: "12-3451234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Employer Identification Numbers (US)] (Legacy)",
        legacy: true,
        regex: '"/[0-9]{2}/ /[0-9]{7}/"',
        placement: "US",
        example: "12-3451234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[ITINs (US)]",
        regex: '"/[9][0-9]/ /[7-8][0-9]/ /[0-9]{4}/" OR "/[9][0-9]/-/[7-8][0-9]/-/[0-9]{4}/" OR /[9][0-9][7-8][0-9]{5}/',
        placement: "US",
        example: "123-45-1234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Phone Numbers (US)]",
        regex: "<phone>",
        placement: "main",
        example: "123-456-1234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Phone Numbers (US)] (Legacy)",
        legacy: true,
        regex: '"/[0-9]{3}/ /[0-9]{3}/ /[0-9]{4}/"',
        placement: "main",
        example: "123-456-1234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[SSNs (US)]",
        regex: "<ssn>",
        placement: "main",
        example: "123-45-1234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[SSNs (US)] (Legacy)",
        legacy: true,
        regex: '"/[0-9]{3}/ /[0-9]{2}/ /[0-9]{4}/" OR "xxx xx /[0-9]{4}/"',
        placement: "main",
        example: "123-45-1234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Email Addresses]",
        regex: "<email>",
        placement: "main",
        example: "janedoe@company.com",
        partialSelectionOptions: [
            PartialSelectionType.USERNAME,
            PartialSelectionType.USERNAME_NOT_FIRST_CHAR,
            PartialSelectionType.DOMAIN,
        ],
    },
    {
        name: "[Email Addresses] (Legacy)",
        legacy: true,
        regex: "/[']?[-A-Za-z0-9._%+]+[@][-A-Za-z0-9.]+[.][A-Za-z]{2,4}/",
        placement: "main",
        example: "janedoe@company.com",
        partialSelectionOptions: [
            PartialSelectionType.USERNAME,
            PartialSelectionType.USERNAME_NOT_FIRST_CHAR,
            PartialSelectionType.DOMAIN,
        ],
    },
    {
        name: "[Credit Card Numbers]",
        regex: "<credit-card>",
        placement: "main",
        example: "1234 5678 1234 1234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[Credit Card Numbers] (Legacy)",
        legacy: true,
        regex: '"/[0-9]{4}/ /[0-9]{4}/ /[0-9]{4}/ /[0-9]{2,4}/"',
        placement: "main",
        example: "1234 5678 1234 1234",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_DIGITS],
    },
    {
        name: "[IBAN numbers]",
        regex: "<iban>",
        placement: "main",
        example: "AB12ABCD123456123456AB",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_CHARS],
    },
    {
        name: "[IBAN numbers] (Legacy)",
        legacy: true,
        regex: ibanNumberRegex(),
        placement: "main",
        example: "AB12ABCD123456123456AB",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_FOUR_CHARS],
    },
    {
        name: "[IP Addresses]",
        regex: "/[0-9]{1,3}[.][0-9]{1,3}[.][0-9]{1,3}[.][0-9]{1,3}/",
        placement: "main",
        example: "255.255.255.255",
        partialSelectionOptions: [PartialSelectionType.NOT_LAST_BYTE],
    },
];

/**
 * TODO: Around a year from now (so around Sep/Oct 2024) we can (and should) remove this,
 *  replace the usages with {@link NAMED_PATTERNS}, remove the {@link PatternSpec#legacy} field,
 *  and the items in {@link NAMED_PATTERNS} with legacy: true.
 */
export const NAMED_PATTERNS_NON_LEGACY: PatternSpec[] = NAMED_PATTERNS.filter((p) => !p.legacy);

export const persistentHighlightClassString = "PersistentHighlight";

// Used to build a long, mostly repeating regex formula for identifying IBAN numbers
function ibanNumberRegex() {
    const max = 7;
    const ret = Arr.repeat(null, max)
        .map(
            (v, index) =>
                '"/[A-Za-z]{2}[0-9]{2}/ '
                + Str.repeat("/([A-Za-z]|[0-9]){4}/ ", index + 1)
                + '/([A-Za-z]|[0-9]){1,3}/"',
        )
        .join(" OR ");
    return ret + ' OR "/[A-Za-z]{2}[0-9]{2}([A-Za-z]|[0-9]){1,30}/"';
}

export function display(regex: string) {
    let display = regex;
    NAMED_PATTERNS.forEach((pattern) => {
        if (pattern.regex === regex) {
            display = pattern.name;
            return display;
        }
    });
    return display;
}

export class PersistentHighlight extends Base.Object {
    get className() {
        return persistentHighlightClassString;
    }
    /**
     * ids are usually null until assigned by the server.  However,
     * negative ids are used for pre-defined regex badges that are
     * available in the UI but are not actually part of the project.
     * A proper id is created by the server when a badge is dragged into a
     * real category.
     */
    override id: number;
    name: string;
    /**
     * Undefined for "Uncategorized" highlights
     */
    categoryId?: number;
    color: string;
    constructor(params: { id?: number; name: string; categoryId?: number; color?: string }) {
        super(params);
        params.color = params.color || highlightPalette[0];
        this._mixin(params);
    }
    override _mixin(params) {
        Object.assign(this, params);
    }
    override compare(other: PersistentHighlight) {
        return Cmp.strQuotes(this.name, other.name);
    }
    namedOrDisplay() {
        const possibleNamedPattern = NAMED_PATTERNS.find((p) => p.regex === this.name);
        return possibleNamedPattern?.name || this.name;
    }
    override display() {
        return this.name;
    }
}

export function getUnusedNamedPatterns(): PersistentHighlight[] {
    const usedNames = new Set(Base.get(PersistentHighlight).map((highlight) => highlight.name));
    let idCounter = 0;
    const unused: PersistentHighlight[] = [];
    NAMED_PATTERNS_NON_LEGACY.forEach((pattern) => {
        if (pattern.placement === "main" && !usedNames.has(pattern.regex)) {
            --idCounter;
            unused.push(
                new PersistentHighlight({
                    id: idCounter,
                    name: pattern.regex,
                    color: EverColor.EVERBLUE_40,
                }),
            );
        }
    });
    return unused;
}

export const persistentCategoryClassString = "PersistentCategory";
export const persistentCategoryPlural = "Persistent Categories";

export class PersistentCategory extends Base.Object {
    get className() {
        return persistentCategoryClassString;
    }
    /**
     * ids are usually null until assigned by the server.  However,
     * there are some special cases that have to be distinguished
     * before the server defines the id.
     * undefined: the uncategorized category
     * -1: the "Unused" category, where common regex expressions are listed
     */
    override id: number;
    name: string;
    color: string;
    override compare(other: PersistentCategory) {
        return Cmp.strQuotes(this.name, other.name);
    }
    constructor(params: { id?: number; name: string; color: string }) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params) {
        Object.assign(this, params);
    }
    override display() {
        return this.name || "(Uncategorized)";
    }
    getColor() {
        return this.color || EverColor.WHITE;
    }
}

export module PersistentCategory {
    export type Id = number & Base.Id<"PersistentCategory">;
}
