import Base = require("Everlaw/Base");
import { ColorTokens } from "design-system";
import { Arr, Compare as Cmp } from "core";
import DocumentMutator = require("Everlaw/DocumentMutator");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import Task = require("Everlaw/Task");
import User = require("Everlaw/User");
import { UserObject } from "Everlaw/UserObject";
import {
    ChatSegmentationOption,
    Deduplication,
    ImageInlining,
    PdfOption,
    SpeakerNotesOption,
} from "Everlaw/Model/Processing/ProcessingDefs";

import { Color } from "Everlaw/ColorUtil";

class Dataset extends UserObject implements Base.Colored {
    get className(): string {
        return "Dataset";
    }
    override id: number;
    userId: User.Id;
    name: string;
    databaseId: number;
    config: Dataset.ProcessingConfig;
    projects: number[];
    deNISTing: boolean;
    total: number;
    examineErrors: number;
    pdfErrors: number;
    textErrors: number;
    created: number;
    ocrPages: number;
    description: string;
    deduplication: Deduplication;
    defaultCustodian: string;
    imageInlining: ImageInlining;
    speakerNotesOption: SpeakerNotesOption;
    filetypes: { [filetype: string]: number };
    custodians: string[];
    promotions: string;
    mutations: string;
    mutator: DocumentMutator;
    originatingProjectId: Project.Id;

    constructor(params: any) {
        super(params);
        this._mixin(params);
        this.mutator = this.mutations
            ? new DocumentMutator(JSON.parse(this.mutations))
            : new DocumentMutator();
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        this.deduplication = Deduplication[<keyof typeof Deduplication>params.deduplication];
        this.imageInlining = ImageInlining[<keyof typeof ImageInlining>params.imageInlining];
    }
    override defaultLastActivity(): number {
        return this.created;
    }
    override compare(other: Dataset): number {
        // sort by created, breaking ties by id
        return Cmp.num(other.created, this.created) || Cmp.num(this.id, other.id);
    }
    getUserId(): User.Id {
        return this.userId;
    }
    getUser(): User {
        if (this.userId) {
            return Base.get(User, this.userId);
        }
    }
    getCreated(): number {
        return this.created;
    }
    override display(): string {
        return this.name;
    }
    /**
     * Appears in Processing.Dataset, Upload.Homepage, and Production. Used to display a document
     * set object with its type in search, results, review, and datavis.
     */
    displayWithType(): string {
        return "Native: " + this.display();
    }
    rename(name: string): Promise<string> {
        return this.updateInfo(name, this.description).then((dataset) => dataset.name);
    }
    inProject(p: Project): boolean {
        return !p.partial || Arr.contains(this.projects, p.id);
    }
    private updateInfo(name: string, message: string): Promise<Dataset> {
        if (name !== this.name || message !== this.description) {
            return Rest.post("processing/updateDatasetInfo.rest", {
                id: this.id,
                name,
                message,
            }).then((data) => {
                this._mixin(data);
                Base.publish(this);
                return this;
            });
        } else {
            return Promise.resolve(this);
        }
    }

    getMessage(): string {
        return this.description;
    }

    setMessage(newMessage: string): void {
        this.updateInfo(this.name, newMessage);
    }
    remove(reason: string, total: number, callback?: Task.Callback): boolean {
        Task.createTask(
            "processing/deleteDataset.rest",
            {
                id: this.id,
                reason: reason,
                totalDocs: total,
            },
            {
                success: (data, msg) => {
                    Base.remove(this);
                    if (callback) {
                        callback(data, msg);
                    }
                },
            },
        );
        return true;
    }

    getColor(): Color {
        return Dataset.COLOR;
    }
}

module Dataset {
    export const COLOR = Color.fromEverColor(ColorTokens.OBJECT_UPLOAD_NATIVE);

    export interface ProcessingConfig {
        timezone: string;
        pdfs: PdfOption;
        ocr_language: string;
        page_size: string;
        use_image_proxy: boolean;
        fetch_slack_attachments: boolean;
        speaker_notes_option: SpeakerNotesOption;
        chat_segmentation_option: ChatSegmentationOption;
    }

    export type UserMapping = { [id: string]: string };
    export type ProjectMapping = { [key: string]: string };
    export type CustodianMapping = { [id: string]: string };
}

export = Dataset;
