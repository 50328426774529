import { useResizeObserver } from "hooks/useResizeObserver";
import { Dispatch } from "react";
import { Memo } from "hooks/useBranded";

export enum OverflowDirection {
    HORIZONTAL,
    VERTICAL,
}

function isOverflowing<E extends Element>(element: E, direction: OverflowDirection): boolean {
    switch (direction) {
        case OverflowDirection.HORIZONTAL:
            return element.scrollWidth > element.clientWidth;
        case OverflowDirection.VERTICAL:
            return element.scrollHeight > element.clientHeight;
    }
}

export type UseDetectOverflowResult<T extends Element = Element> = [
    boolean,
    Memo<Dispatch<T | null>>,
];

export function useDetectOverflow<T extends Element = Element>(
    direction: OverflowDirection = OverflowDirection.HORIZONTAL,
): UseDetectOverflowResult<T> {
    // Use a ResizeObserver to detect when the target element is resized outside of React's systems.
    const [setObservedNode, observerEntry] = useResizeObserver<T>();
    const targetElement = observerEntry.target;
    const result = !!targetElement && isOverflowing(targetElement, direction);

    return [result, setObservedNode];
}
