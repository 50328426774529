// Dom - DOM manipulation functions
//
// Consolidates some of the most commonly-used dojo/dom* functionality, and changes the paradigm of
// node-like arguments to accept not only Node and ID arguments, but also Widget-like arguments,
// trying things like n.getNode(). See Dom.node for the basis of the functionality, upon which the
// remaining items are built.
//
// Where sensible, the functions here support an Array of nodes as the first argument, indicating
// that behavior by the parameter name "nodes", meaning "node or node ID or widget or arrays of such
// items". Where we depart from that convention, we must clearly indicate it.
import { Arr } from "core";
import Bugsnag = require("Everlaw/Bugsnag");
import { Is } from "core";
import {
    createElement,
    DependencyList,
    HTMLAttributes,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
} from "react";

/**
 * Returns the element with the given ID. This function assumes that if the requested element does
 * not exist, it is a catastrophic failure and a bugsnag error is logged. You should only use it
 * when you are certain the element should exist.This allows callers to not have to check the return
 * value, knowing that an error has already been logged if there was an issue. If you want to fetch
 * an element that may or may not exist (or test for an element's existence), call
 * document.getElementById() directly.
 *
 * For now, on failure we're still returning null here (cast to an HTMLElement, to pass strict null
 * checks) in order to avoid breaking any legacy code that we haven't tracked down yet. We could
 * continue to do this into the future, or we could choose to throw an exception on failure once
 * we've caught all these cases in the existing codebase. That is still TBD.
 */
export function byId(id: string): HTMLElement {
    const elem = document.getElementById(id);
    if (!elem) {
        // This function should not be called in cases where the node does not exist. If you are
        // assigned a bug related to this issue, please fix the calling code to use
        // document.getElementById() directly.
        Bugsnag.notify(
            Error(
                `Dom.byId() found no element with ID=${id}. NOTE: This log is informational and has not affected the functionality of the site (though the offending call should be fixed).`,
            ),
        );
        return null as unknown as HTMLElement;
    }
    return elem;
}

/**
 * Convenience function that checks if the node with the given ID exists, and conditionally executes
 * the specified callback on the node if it does.
 */
export function ifNodeExists(id: string, callback: (n: HTMLElement) => void): void {
    const n = document.getElementById(id);
    n && callback(n);
}

/**
 * Returns the nodes with the given (String) class as an array.
 */
export const byClass: (className: string) => HTMLElement[] = (className: string) => {
    return [].slice.call(document.getElementsByClassName(className));
};

/**
 * This should probably be called HTMLElementable, but that's a mouthful. (An HTMLElement is a Node,
 * but not every Node is an HTMLElement.)
 *
 * You might be tempted to add Node, but you probably don't want to do that (for the above reason).
 */
export type Nodeable =
    | string
    | HTMLElement
    | { getNode(): HTMLElement | null }
    | { domNode: HTMLElement | null }
    | { node: HTMLElement | null }
    | { _node: HTMLElement | null };

/**
 * Converts the specified Nodeable to an HTMLElement using multiple heuristics that are meant to
 * work for not only nodes and IDs, but also various widget types. If this function is called with a
 * nullish value, or with a Nodeable whose node is null (for example, a widget that has already been
 * destroyed), it is considered a catastrophic failure and a bugsnag error is logged. This allows
 * callers to not have to check the return value, knowing that an error has already been logged if
 * there was an issue.
 *
 * For now, on failure we're still returning null here (cast to an HTMLElement, to pass strict null
 * checks) in order to avoid breaking any legacy code that we haven't tracked down yet. We could
 * continue to do this into the future, or we could choose to throw an exception on failure once
 * we've caught all these cases in the existing codebase. That is still TBD.
 */
export function node(n: Nodeable): HTMLElement;
export function node<T extends Node>(n: T | Nodeable): T | HTMLElement {
    let retNode: T | HTMLElement | null = null;
    if (n !== null && n !== undefined) {
        if (Is.string(n)) {
            retNode = document.getElementById(n);
        } else if (n instanceof Node) {
            retNode = n;
        } else if ("getNode" in n && Is.func(n.getNode)) {
            retNode = n.getNode();
        } else if ("domNode" in n && n.domNode instanceof Node) {
            retNode = n.domNode;
        } else if ("node" in n && n.node instanceof Node) {
            retNode = n.node;
        } else if ("_node" in n && n._node instanceof Node) {
            retNode = n._node;
        }
    }
    if (!retNode) {
        // This function should not be called with nullish values or in cases where the Nodeable's
        // node is null (e.g., after a widget has been destroyed). If you are assigned a bug
        // related to this issue, please fix the calling code to do a proper check before calling
        // this function.
        Bugsnag.notify(
            Error(
                "Dom.node() couldn't convert the passed Nodeable to a node. NOTE: This log is "
                    + "informational and has not affected the functionality of the site.",
            ),
            { metaData: { nodeable: n } },
        );
        return null as unknown as HTMLElement;
    }
    return retNode;
}

/**
 * Destroys the given node(s). Works for IDs but not for widgets. Accepts multiple arguments and
 * array arguments; destroys them all.
 */
export function destroy(ids: string | Node | (string | Node)[]): void {
    Arr.wrap(ids).forEach((id) => {
        const node = Is.string(id) ? document.getElementById(id) : id;
        node?.parentNode?.removeChild(node);
    });
}

export const remove = destroy;

/**
 * Removes all children of the given node(s). Works for IDs but not for widgets. Accepts multiple
 * arguments and array arguments; empties them all.
 */
export function empty(ids: string | Node | (string | Node)[]): void {
    Arr.wrap(ids).forEach((id) => {
        const node = Is.string(id) ? document.getElementById(id) : id;
        if (node) {
            node.textContent = "";
        }
    });
}

// This group of functions is like their dojo/dom counterparts except that the first argument can be
// not only a DOM node or ID, but also a widget-like object, or even an Array of such items. When an
// array is the first argument, the return value is an array of values.

/**
 * Returns true if the given node has the given attribute, false otherwise.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function hasAttr(nodeable: Nodeable, attr: string): boolean {
    return nodeable ? node(nodeable).hasAttribute(attr) : false;
}

/**
 * Returns the value of the given attribute for the given node, or null if the node does not have
 * that attribute.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function getAttr(nodeable: Nodeable, attr: string): string | null {
    return nodeable ? node(nodeable).getAttribute(attr) : null;
}

/**
 * Sets the given attribute to the given value for the given node(s).
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function setAttr(nodeables: Nodeable | Nodeable[], attr: string, value: string): void {
    Arr.wrap(nodeables).forEach((nodeable) => {
        nodeable && node(nodeable).setAttribute(attr, value);
    });
}

/**
 * Removes the given attribute from the given node(s).
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function removeAttr(nodeables: Nodeable | Nodeable[], attr: string): void {
    Arr.wrap(nodeables).forEach((nodeable) => {
        nodeable && node(nodeable).removeAttribute(attr);
    });
}

/**
 * Sets the aria-label attribute to the specified value for the specified node.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function setAriaLabel(nodeable: Nodeable, ariaLabel: string): void {
    setAttr(nodeable, "aria-label", ariaLabel);
}

/**
 * Sets the aria-live attribute for the given node(s). Defaults to assertive. This causes any change
 * in the node to be immediately read out by a screen reader which is useful for accessibility.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function setAriaLive(nodeables: Nodeable | Nodeable[], value?: string): void {
    setAttr(nodeables, "aria-live", value || "assertive");
}

/**
 * Return true if the given node has the given class, false otherwise.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function hasClass(nodeable: Nodeable, clazz: string): boolean {
    if (!nodeable) {
        return false;
    }
    return node(nodeable)?.classList?.contains(clazz) || false;
}

/**
 * We allow for space-separated css class strings in several of our utility functions below, but you
 * cannot pass them to any of the classList methods so we have to first split any incoming strings
 * into individual classes. Gracefully handles passing nullish values in an array.
 */
function getClassArray(classes: string | string[]): string[] {
    if (!classes) {
        return [];
    }
    return (Is.array(classes) ? classes.filter((c) => !!c).join(" ") : classes).trim().split(/\s+/);
}

/**
 * Adds the given class(es) to the given node(s). Accepts either a space-separated string of class
 * names, or an array of (space-free) class names.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function addClass(nodeables: Nodeable | Nodeable[], classes: string | string[]): void {
    const wrappedClasses = getClassArray(classes);
    Arr.wrap(nodeables).forEach((nodeable) => {
        nodeable && node(nodeable).classList.add(...wrappedClasses);
    });
}

/**
 * Removes the given class(es) from the given node(s). Accepts either a space-separated string of
 * class names, or an array of (space-free) class names.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function removeClass(nodeables: Nodeable | Nodeable[], classes: string | string[]): void {
    const wrappedClasses = getClassArray(classes);
    Arr.wrap(nodeables).forEach((nodeable) => {
        nodeable && node(nodeable).classList.remove(...wrappedClasses);
    });
}

/**
 * Adds the specified class(es) and removes the specified class(es) from the specified node(s).
 * A more convenient, efficient version of calling:
 *   Dom.removeClass(nodes, removeClasses)
 *   Dom.addClass(nodes, addClasses)
 *
 * Note the order of addClasses and removeClasses arguments!
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function replaceClass(
    nodeables: Nodeable | Nodeable[],
    addClasses: string | string[],
    removeClasses: string | string[],
): void {
    const wrappedAdds = getClassArray(addClasses);
    const wrappedRemoveds = getClassArray(removeClasses);
    Arr.wrap(nodeables).forEach((nodeable) => {
        if (nodeable) {
            const n = node(nodeable);
            n.classList.remove(...wrappedRemoveds);
            n.classList.add(...wrappedAdds);
        }
    });
}

/**
 * If condition is not specified, removes the given class(es) from the given node(s) if the class
 * is present, and added them if the class is not present. If condition is specified, then this
 * functions identically to Dom.addClass() when condition=true, and Dom.removeClass() when
 * condition=false.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function toggleClass(
    nodeables: Nodeable | Nodeable[],
    classes: string | string[],
    condition?: boolean,
): void {
    if (condition === true) {
        addClass(nodeables, classes);
    } else if (condition === false) {
        removeClass(nodeables, classes);
    } else {
        const wrappedClasses = getClassArray(classes);
        Arr.wrap(nodeables).forEach((nodeable) => {
            if (nodeable) {
                const n = node(nodeable);
                wrappedClasses.forEach((c) => {
                    n.classList.toggle(c);
                });
            }
        });
    }
}

export type StyleProps = Partial<Record<CssAttribute, string | null>>;

/**
 * Valid CSS attributes to get/set below.
 */
export type CssAttribute = Exclude<
    keyof CSSStyleDeclaration,
    | "length"
    | "parentRule"
    | "getPropertyPriority"
    | "getPropertyValue"
    | "item"
    | "removeProperty"
    | "setProperty"
    | number
    | symbol
>;

// Cache of which attributes need to be returned as floats, for performance.
const pixelNamesCache: { [attr: string]: boolean } = { left: true, top: true };
const pixelRegExp = /margin|padding|width|height|max|min|offset/;

/**
 * Applies the given style(s) to the given node(s). The styles parameter can either be a
 * CssAttribute Object mapping attributes to values, or a string indicating the attribute to set.
 * In the case of a string, value contains the attribute value. For example:
 *
 *      Dom.style(myNode, { backgroundColor: 'white' });
 *      // is equivalent to
 *      Dom.style(myNode, 'backgroundColor', 'white');
 *
 * Note that the CSS property names use camel case; using 'background-color' in either example
 * above would be an error.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function style(nodeables: Nodeable | Nodeable[], styles: StyleProps): void;
export function style(
    nodeables: Nodeable | Nodeable[],
    styles: CssAttribute,
    value: string | null,
): void;
export function style(
    nodeables: Nodeable | Nodeable[],
    styles: StyleProps | CssAttribute,
    value?: string | null,
): void {
    if (!styles) {
        // nullish styles shouldn't be passed to this function. If you are assigned a bug for this
        // issue, please clean up the calling code to test the styles before calling this function.
        Bugsnag.notify(
            Error(
                "Dom.style() was passed a nullish styles parameter. NOTE: This log is "
                    + "informational and has not affected the functionality of the site.",
            ),
        );
        return;
    }
    Arr.wrap(nodeables).forEach((nodeable) => {
        if (!nodeable) {
            return;
        }
        if (Is.string(styles)) {
            node(nodeable).style[styles] = value ?? "";
        } else {
            Object.entries(styles).forEach(([prop, val]) => {
                style(nodeable, prop as CssAttribute, val);
            });
        }
    });
}

/**
 * Returns the computed style value of the given attribute for the given node.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function getStyle(nodeable: Nodeable, attr: CssAttribute): string | number {
    if (!nodeable) {
        return "";
    }
    const style = window.getComputedStyle(node(nodeable));
    const value = style[attr];
    const attrLc = attr.toLowerCase();
    if (!(attrLc in pixelNamesCache)) {
        pixelNamesCache[attrLc] = pixelRegExp.test(attrLc);
    }
    return pixelNamesCache[attrLc] ? parseFloat(value) || 0 : value;
}

/**
 * Helper function used by place() below.
 */
function _place(node: Node, refNode: Node, pos?: string | number): void {
    const insertBefore = (node: Node, refNode: Node) => {
        const parent = refNode.parentNode;
        if (parent) {
            parent.insertBefore(node, refNode);
        }
    };

    const insertAfter = (node: Node, refNode: Node) => {
        // Try to insert node after ref
        const parent = refNode.parentNode;
        if (parent) {
            if (parent.lastChild === refNode) {
                parent.appendChild(node);
            } else {
                parent.insertBefore(node, refNode.nextSibling);
            }
        }
    };

    if (Is.num(pos)) {
        const cn = refNode.childNodes;
        if (!cn.length || cn.length <= pos) {
            refNode.appendChild(node);
        } else {
            insertBefore(node, cn[pos < 0 ? 0 : pos]);
        }
    } else {
        switch (pos) {
            case "before":
                insertBefore(node, refNode);
                break;
            case "after":
                insertAfter(node, refNode);
                break;
            case "replace":
                refNode.parentNode?.replaceChild(node, refNode);
                break;
            case "only":
                empty(refNode);
                refNode.appendChild(node);
                break;
            case "first":
                if (refNode.firstChild) {
                    insertBefore(node, refNode.firstChild);
                    break;
                }
            // else fallthrough...
            default: // aka: last
                refNode.appendChild(node);
        }
    }
}

/**
 * (nodes, refNode, pos): Places the node(s) (in order, if it is an Array) in the indicated pos in
 *  relation to refNode. As with nodes, the refNode is passed to Dom.node, but it cannot be an
 *  Array. Returns the first argument unchanged.
 *
 *  The value of pos indicates where the node(s) should be placed in relation to the refNode. It
 *  defaults to "last". The following values of pos indicate that the node(s) will be placed:
 *
 *      before*:    as siblings preceding refNode
 *      after:      as siblings coming after refNode
 *      replace**:  in place of refNode
 *      only**:     as the only child of refNode
 *      first*:     as the first child of refNode
 *      last:       as the last child of refNode
 *
 *          *   an Array of nodes will appear in the DOM in reverse order for these pos values
 *          **  an Array of nodes is undefined or not sensible for these pos values
 */
export function place<T extends Node | Nodeable | (Node | Nodeable)[]>(
    nodeables: T,
    refNodeable: Node | Nodeable,
    pos?: string | number,
): T {
    const ref = refNodeable instanceof Node ? refNodeable : node(refNodeable);
    if (ref) {
        Arr.wrap(nodeables).forEach((nodeable) => {
            const n = nodeable instanceof Node ? nodeable : node(nodeable);
            _place(n, ref, pos);
        });
    }
    return nodeables;
}

/**
 * Returns the geometry of the given node calculated by combining offset*values appropriately.
 * The geometry is an Object with the following properties, which are often non-integers:
 *
 *      w: the width in pixels
 *      h: the height in pixels
 *      x: the x offset of the top left corner in pixels
 *      y: the y offset of the top left corner in pixels
 *
 * When fromRoot is true, the x and y offsets will be from the top-left corner of the document.
 * Otherwise (the default), they will be the offsets from the top-left corner of the viewport.
 */
export function position(
    nodeable: Nodeable,
    fromRoot = false,
): { w: number; h: number; x: number; y: number } {
    const n = node(nodeable);
    const rect = n.getBoundingClientRect();
    const ret = { x: rect.left, y: rect.top, w: rect.right - rect.left, h: rect.bottom - rect.top };
    if (fromRoot) {
        const defaultView = window.document.defaultView;
        if (defaultView) {
            if ("scrollX" in defaultView) {
                ret.x += defaultView.scrollX;
            }
            if ("scrollY" in defaultView) {
                ret.y += defaultView.scrollY;
            }
        }
    }
    return ret;
}

// Some helper routines used by the geometry functions. This code is based on the code in the
// dojo/dom-geometry module.

function toPixelValue(value: string) {
    return parseFloat(value) || 0;
}

function getPadExtents(s: CSSStyleDeclaration) {
    const l = toPixelValue(s.paddingLeft);
    const t = toPixelValue(s.paddingTop);
    const r = toPixelValue(s.paddingRight);
    const b = toPixelValue(s.paddingBottom);
    return { l: l, t: t, r: r, b: b, w: l + r, h: t + b };
}

function getBorderExtents(s: CSSStyleDeclaration) {
    const l = s.borderLeftStyle !== "none" ? toPixelValue(s.borderLeftWidth) : 0;
    const t = s.borderTopStyle !== "none" ? toPixelValue(s.borderTopWidth) : 0;
    const r = s.borderRightStyle !== "none" ? toPixelValue(s.borderRightWidth) : 0;
    const b = s.borderBottomStyle !== "none" ? toPixelValue(s.borderBottomWidth) : 0;
    return { l: l, t: t, r: r, b: b, w: l + r, h: t + b };
}

function getMarginExtents(s: CSSStyleDeclaration) {
    const l = toPixelValue(s.marginLeft);
    const t = toPixelValue(s.marginTop);
    const r = toPixelValue(s.marginRight);
    const b = toPixelValue(s.marginBottom);
    return { l: l, t: t, r: r, b: b, w: l + r, h: t + b };
}

/**
 * Returns the geometry of the given node calculated from the computed style. The width and height
 * may differ slightly from that returned from Dom.position; it's unclear whether this is due solely
 * to rounding factors or whether some other factor is at play. The geometry is an Object with the
 * following properties:
 *
 *      w: the width in pixels
 *      h: the height in pixels
 *      l: the position in relation to the left of the parent node, in pixels
 *      t: the position in relation to the top of the parent node, in pixels
 *
 * The area described is for full area of the node, including the margins, borders, padding, and
 * content.
 *
 * Note that calculating this information requires an expensive getComputedStyle() call, so if you
 * may use it repeatedly then you should cache the result.
 *
 * This code is derived from getMarginBox() in dojo/dom-geometry.
 */
export function geometry(nodeable: Nodeable): { w: number; h: number; l: number; t: number } {
    const n = node(nodeable);
    const style = window.getComputedStyle(n);
    const me = getMarginExtents(style);
    const l = n.offsetLeft - me.l;
    const t = n.offsetTop - me.t;
    return { l: l, t: t, w: n.offsetWidth + me.w, h: n.offsetHeight + me.h };
}

/**
 * Returns an Object describing the width ('w' property) and height ('h' property) in pixels of the
 * content box(es) of the given node(s). The content box is the area inside the padding that
 * contains the node's content.
 *
 * Note that calculating this information requires an expensive getComputedStyle() call, so if you
 * may use it repeatedly then you should cache the result.
 *
 * This code is derived from getContentBox() in dojo/dom-geometry.
 */
export function contentSize(nodeable: Nodeable): { w: number; h: number } {
    const n = node(nodeable);
    const style = window.getComputedStyle(n);
    let w = n.clientWidth;
    let h: number;
    const pe = getPadExtents(style);
    const be = getBorderExtents(style);
    if (!w) {
        w = n.offsetWidth - be.w;
        h = n.offsetHeight - be.h;
    } else {
        h = n.clientHeight;
    }
    return { w: w - pe.w, h: h - pe.h };
}

/**
 * Returns true if n is entirely positioned within the viewport. If any portion of n is scrolled out
 * of view, or if there is no node n, then this returns false.
 */
export function fullyVisible(n: Nodeable): boolean {
    const p = position(node(n));
    return p ? p.y >= 0 && p.y + p.h <= window.innerHeight : false;
}

// HTMLElementTagNameMap has all the HTMLElement subclasses, but it doesn't have a fallback for
// elements like "b" that are just plain HTMLElements.
interface TagNameMap extends HTMLElementTagNameMap {
    [key: string]: HTMLElement;
}

/**
 * When creating DOM nodes using Dom.create() or Dom.<tag-name>(), we allow some special attribute
 * assignment syntax for things like styles, innerHTML, textContent, and non-string values. This
 * small utility function handles these cases.
 */
function setNodeAttr(n: HTMLElement, attr: string, val: unknown): void {
    if (attr === "style") {
        // We allow styles to be specified either as a string (in which case we just set it
        // directly) or as an object with style props (in which case we use our style() function).
        if (Is.string(val)) {
            n.setAttribute("style", val);
        } else if (Is.object(val) && Is.plainObject(val)) {
            style(n, val);
        }
    } else if (attr === "textContent" || attr === "innerHTML") {
        if (Is.string(val)) {
            n[attr] = val;
        }
    } else if (Is.func(val) || Is.boolean(val)) {
        // on* event handler or boolean attribute like `required`.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (n as any)[attr] = val;
    } else {
        // Set the attribute normally. Non-string values will be automatically converted to strings.
        n.setAttribute(attr, val as string);
    }
}

/**
 * Creates node(s) with the given tag name(s) and attrs, then places the nodes in the same manner as
 * Dom.place(nodes, refNode, pos). The attrs argument is quite flexible:
 *
 *  1.  It may be omitted altogether; if the 2nd argument is a String or a Node, it will be treated
 *      as the refNode, with the 3rd argument treated as the pos, and attrs as null. This avoids the
 *      anti-pattern of Dom.create('td', null, tr) or Dom.create('td', {}, tr), replacing it with
 *      Dom.create('td', tr).
 *  2.  When present, it must be an Object, with the keys being DOM node attribute names, and the
 *      values being their values. Some attributes (e.g., "style" and "content") will be treated
 *      specially.
 *  3.  A "content" attribute may contain text and/or other nodes; they will be appended using
 *      Dom.addContent.
 *  4.  A "style" attribute may either be an Object (see Dom.style), or a String. When it's a
 *      String, note that the syntax is like normal HTML: the CSS properties contain hyphens instead
 *      of using camelCase.
 *
 * Style note: For the style attribute, when there is just one attribute, we generally prefer String
 * notation (e.g., "text-align: center"), but when there are multiple attributes, we prefer Object
 * notation (e.g., { textAlign: "center", fontWeight: "bold" }). A good rule of thumb is that a
 * String style should not generally contain semicolons. As with our standard style, any Object
 * containing more than one or maybe two items should be wrapped:
 *
 *  Dom.create('div', {
 *      style: {
 *          textAlign: "center",
 *          fontWeight: "bold",
 *          fontStyle: "italic"
 *      }
 *  }, parentNode);
 *
 * As with Dom.place, the refNode is passed through Dom.node, so it can accept widgets and such. Be
 * aware, however, that this property does not combine well with omitting attrs (since the 2nd
 * argument is neither a String nor a Node). In such cases, attrs must be an explicit null.
 *
 * The refNode and pos arguments are also optional, but in such cases, you should consider using the
 * shorthand Dom[tagName] functions, like Dom.div, Dom.span, etc. Add new ones when we use a given
 * tag more than once across our codebase.
 */
export function create<Tag extends string>(
    tagName: Tag,
    attrs: any,
    refNode?: Node | Nodeable,
    pos?: string | number,
): TagNameMap[Tag];
export function create<Tag extends string>(
    tagName: Tag,
    refNode?: Node | Nodeable,
    pos?: string | number,
): TagNameMap[Tag];
export function create<Tag extends string>(
    tagName: Tag,
    attrs: any,
    refNode?: any,
    pos?: any,
): TagNameMap[Tag] {
    if (Is.string(attrs) || attrs instanceof Node) {
        pos = refNode;
        refNode = attrs;
        attrs = null;
    }
    const n = document.createElement(tagName);
    for (const x in attrs) {
        if (x === "content") {
            addContentInternal(n, attrs[x]);
        } else {
            setNodeAttr(n, x, attrs[x]);
        }
    }
    if (refNode) {
        _place(n, node(refNode), pos);
    }
    return n;
}

/**
 * Shows the given node(s) when show is omitted or is true. Otherwise, acts like Dom.hide.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function show(nodes: Nodeable | Nodeable[], show?: boolean): void {
    if (show || !Is.defined(show)) {
        removeClass(nodes, "hidden");
    } else {
        hide(nodes);
    }
}

/**
 * Hides the given node(s) when hide is omitted or is true. Otherwise, acts like Dom.show.
 *
 * For backwards compatibility, this function gracefully handles nullish nodeables for now even
 * though it is not part of the function signature. Once strict null checks are being adhered to
 * by callers, we can disable this support.
 */
export function hide(nodes: Nodeable | Nodeable[], hide?: boolean): void {
    if (hide || !Is.defined(hide)) {
        addClass(nodes, "hidden");
    } else {
        show(nodes);
    }
}

/**
 * Returns boolean(s) indicating if the given node(s) are currently hidden.
 */
export function isHidden(nodeable: Nodeable): boolean {
    return hasClass(nodeable, "hidden");
}

export function visible(nodes: Nodeable | Nodeable[], show?: boolean): void {
    if (show || !Is.defined(show)) {
        removeClass(nodes, "invisible");
    } else {
        invisible(nodes);
    }
}

export function invisible(nodes: Nodeable | Nodeable[], hide?: boolean): void {
    if (hide || !Is.defined(hide)) {
        addClass(nodes, "invisible");
    } else {
        visible(nodes);
    }
}

export function isInvisible(node: Nodeable): boolean {
    return hasClass(node, "invisible");
}

/**
 * Adds content to a DOM node consisting of text strings, other DOM nodes, or a mixture of both.
 * More precisely, appends any number of content items, where a content item may be one of:
 * <ul>
 *  <li>a primitive value other than null/undefined, which is stringified and appended as text
 *  <li>null/undefined, which is ignored (a convenience for optional portions of content)
 *  <li>a DOM node
 *  <li>an array, which in turn contains other content items (another convenience)
 * </ul>
 * Note that entities like "&mdash;" cannot be used in escaped-entity form. Refer to Entities.js,
 * which provides some commonly-used HTML entities.
 */
export function addContent(nodeable: Nodeable | Node, ...items: Content[]): void {
    const n = nodeable instanceof Node ? nodeable : node(nodeable);
    n && addContentInternal(n, items);
}

/**
 * Like Dom.addContent, but replaces any existing children of the node rather than appending.
 */
export function setContent(nodeable: Nodeable | Node, ...items: Content[]): void {
    const n = nodeable instanceof Node ? nodeable : node(nodeable);
    if (n) {
        n.textContent = ""; // Remove all existing child nodes
        addContentInternal(n, items);
    }
}

export interface TagFunction<E extends HTMLElement> {
    (attrs?: any, ...items: Content[]): E;
}

/**
 * Functions to concisely create unplaced elements of various common types.
 *
 * Each function accepts an optional {attribute: value} map as the first parameter, followed by
 * any number of content items which are added to the element.
 *
 * Example: Dom.a({href: "home.do"}, "Home")
 */
function makeTagFunc<Tag extends string>(
    tagName: Tag,
): (attrs?: any, ...items: Content[]) => TagNameMap[Tag] {
    return function (attrs?: any, ...items: Content[]): TagNameMap[Tag] {
        const node = <TagNameMap[Tag]>document.createElement(tagName);
        let i = 0;
        // Check for optional {attribute: value} map as first parameter - must be a plain Object.
        if (Is.plainObject(attrs)) {
            for (const x in attrs) {
                setNodeAttr(node, x, attrs[x]);
            }
            i = 1;
        }
        for (; i < arguments.length; i++) {
            addContentInternal(node, arguments[i]);
        }
        return node;
    };
}
/**
 * Note that when creating an external link with `target: "_blank"`, the destination window has
 * a reference to our window. This is a security hole, because a compromised site can then use that
 * reference to redirect our window to a new page. To avoid this, whenever creating an *external*
 * link with `target: "_blank"`, we also make sure to include `rel: "noopener noreferrer"`. See
 * https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c
 * for more information.
 */
export const a = (attrs?: any, ...items: Content[]) => {
    const node = makeTagFunc("a")(attrs, ...items);
    if (node.target === "_blank") {
        node.rel = "noopener noreferrer";
    }
    return node;
};
export const b = makeTagFunc("b");
export const br = makeTagFunc("br");
export const col = makeTagFunc("col");
export const colGroup = makeTagFunc("colGroup");
export const div = makeTagFunc("div");
export const form = makeTagFunc("form");
export const h1 = makeTagFunc("h1");
export const h2 = makeTagFunc("h2");
export const h3 = makeTagFunc("h3");
export const h4 = makeTagFunc("h4");
export const h5 = makeTagFunc("h5");
export const h6 = makeTagFunc("h6");
export const i = makeTagFunc("i");
export const iframe = makeTagFunc("iframe");
export const img = makeTagFunc("img");
export const input = makeTagFunc("input");
export const label = makeTagFunc("label");
export const li = makeTagFunc("li");
export const mark = makeTagFunc("mark");
export const p = makeTagFunc("p");
export const pre = makeTagFunc("pre");
export const span = makeTagFunc("span");
export const table = makeTagFunc("table");
export const tbody = makeTagFunc("tbody");
export const tfoot = makeTagFunc("tfoot");
export const thead = makeTagFunc("thead");
export const td = makeTagFunc("td");
export const th = makeTagFunc("th");
export const tr = makeTagFunc("tr");
export const ul = makeTagFunc("ul");
export const ol = makeTagFunc("ol");
export const hr = makeTagFunc("hr");
export const textarea = makeTagFunc("textarea");
export const option = makeTagFunc("option");
export const header = makeTagFunc("header");
export const footer = makeTagFunc("footer");
export const nav = makeTagFunc("nav");
export const main = makeTagFunc("main");
export const section = makeTagFunc("section");
export const summary = makeTagFunc("summary");
export const caption = makeTagFunc("caption");
export const blockquote = makeTagFunc("blockquote");
export const article = makeTagFunc("article");
export const aside = makeTagFunc("aside");
export const details = makeTagFunc("details");
export const fieldset = makeTagFunc("fieldset");
export const legend = makeTagFunc("legend");
export const figure = makeTagFunc("figure");
export const figcaption = makeTagFunc("figcaption");
export const progress = makeTagFunc("progress");
export const abbr = makeTagFunc("abbr");
export const address = makeTagFunc("address");
export const time = makeTagFunc("time");
export const dialog = makeTagFunc("dialog");
export const output = makeTagFunc("output");

export function svg(): SVGSVGElement {
    return document.createElementNS("http://www.w3.org/2000/svg", "svg");
}

/**
 * Creates a document fragment and adds content to it. A fragment is a kind of container node that
 * is never actually inserted into a document; a DOM call to insert a fragment will automatically
 * insert its children instead. In this way, a sequence of multiple nodes can be inserted as a
 * single unit, without having to create an extra element to hold them.
 */
export function fragment(...items: Content[]): DocumentFragment {
    const node = document.createDocumentFragment();
    addContentInternal(node, items);
    return node;
}

/**
 * Takes an array of content items and returns an array that intersperses them with a separator.
 * The separator may be either a string or a function that returns a content item (a function is
 * required for any separator that is not a plain string, because a DOM node cannot be placed in
 * the document in multiple locations).
 *
 * Examples: Dom.join(";", [x, y, z]) returns [x, ";", y, ";", z]
 *           Dom.join(Dom.br, [x, y, z]) returns [x, Dom.br(), y, Dom.br(), z]
 */
export function join(separator: string | { (): Content }, items: Content[]): Content[] {
    const joined: Content[] = [];
    items.forEach((item, i) => {
        if (i !== 0) {
            joined.push(typeof separator === "string" ? separator : separator());
        }
        joined.push(item);
    });
    return joined;
}

/**
 * Returns the first ancestor of the given node (including itself) that has one of the given
 * classes, or null if there is no such ancestor.
 */
export function firstAncestor(e: Element | null, ...classes: string[]): Element | null {
    let elem = e;
    while (elem) {
        if (classes.some((cls) => elem?.classList?.contains(cls))) {
            return elem;
        }
        elem = elem.parentElement;
    }
    return null;
}

export function firstAncestorOfType(
    node: Node,
    ...nodeTypes: Array<typeof HTMLElement>
): HTMLElement | null {
    let n: Node | null = node;
    while (n) {
        if (nodeTypes.some((nodeType) => n instanceof nodeType)) {
            return <HTMLElement>n;
        }
        n = n.parentNode;
    }
    return null;
}

/**
 * Returns the first child of an element that has one of the given classes.
 */
export function firstChild(n: HTMLElement, ...classes: string[]): HTMLElement | null {
    for (const child of [].slice.call(n.children)) {
        if (classes.some((clazz) => hasClass(child, clazz)) || classes.length <= 0) {
            return child;
        }
    }
    return null;
}

export type Content = string | number | Node | ContentArray | null | undefined;
interface ContentArray extends Array<Content> {}

function addContentInternal(node: Node, item: Content): void {
    if (item === null || item === undefined) {
        // null or undefined - do nothing
    } else if (item instanceof Node) {
        node.appendChild(item);
    } else if (item instanceof Array) {
        for (let i = 0; i < item.length; i++) {
            addContentInternal(node, item[i]);
        }
    } else {
        // Primitive value (string, number, boolean) - add as text
        if (node.lastChild instanceof Text && node.lastChild.nodeValue) {
            node.lastChild.nodeValue += item;
        } else {
            node.appendChild(document.createTextNode(String(item)));
        }
    }
}

export function blur(): void {
    const el = document.activeElement;
    if (el instanceof HTMLElement) {
        el.blur();
    }
}

export function indexInParent(
    elem: HTMLElement,
    filter: (e: HTMLElement) => boolean = () => true,
): number {
    let i = 0;
    while (elem.previousElementSibling) {
        filter(elem.previousElementSibling as HTMLElement) && i++;
        elem = elem.previousElementSibling as HTMLElement;
    }
    return i;
}

/**
 * Temporarily show a hidden element offscreen to measure/mutate it until you like its dimensions.
 * This gets around the fact we can't calculate dimensions of hidden elements.
 * Assumes `elem` has been hidden with Dom.hide() and that Dom.show() will make it visible.
 */
export class Fit {
    private origPos: { w: number; h: number; x: number; y: number };
    private posType: string;
    constructor(private elem: HTMLElement) {
        this.origPos = position(elem);
        this.posType = getStyle(elem, "position").toString();
    }
    render(): Fit {
        style(this.elem, {
            position: this.posType === "absolute" ? "absolute" : "relative",
            top: "-10000px",
        });
        show(this.elem);
        return this;
    }
    measure(): { w: number; h: number } {
        const newPos = position(this.elem);
        return { w: newPos.w, h: newPos.h };
    }
    restore(): void {
        hide(this.elem);
        style(this.elem, { position: this.posType, top: this.origPos.y + "px" });
    }
}

const interactiveTagList = new Set();
[
    "details",
    "summary",
    "menu",
    "menuitem",
    "a",
    "button",
    "input",
    "option",
    "dialog",
    "select",
].forEach((value) => interactiveTagList.add(value));

/**
 * Returns true if the element passed in to the function is considered interactive, that is if it
 * can be focused even without a tabindex attribute.
 */
export function isInteractive(elem: HTMLElement): boolean {
    const tagName = elem.tagName.toLowerCase();
    return interactiveTagList.has(tagName);
}

/**
 * Given an element inside a vertically scrolling container, return whether element is in view.
 */
export function isInView(elem: HTMLElement, container: HTMLElement): boolean {
    const elemTop = elem.offsetTop;
    const elemBot = elemTop + elem.scrollHeight;
    const viewTop = container.scrollTop;
    const viewBot = viewTop + container.clientHeight;
    return elemTop > viewTop && elemBot < viewBot;
}

export interface WrapperProps<E extends HTMLElement> extends HTMLAttributes<E> {
    childContent: () => Content;
    tag?: string;
    dependencies?: DependencyList;
}

/**
 * Wraps non-React code in a React Element. This function should only be used when trying to mix
 * React code with imperative code and should likely be avoided if possible.
 */
export function Wrapper<E extends HTMLElement>({
    childContent,
    tag = "div",
    dependencies = [],
    ...props
}: WrapperProps<E>): ReactElement {
    const element = useMemo(childContent, [childContent, ...dependencies]);
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        ref.current && addContentInternal(ref.current, element);
    }, [element]);
    return createElement(tag, {
        ...props,
        ref,
    });
}
