import Base = require("Everlaw/Base");
import Database = require("Everlaw/Database");
import { Is } from "core";
import Project = require("Everlaw/Project");
import { SystemPermission } from "Everlaw/SystemPermission";
import User = require("Everlaw/User");
import { RowData } from "Everlaw/Table";
import * as React from "react";
import { FC, ReactNode } from "react";
import BaseSideBar = require("Everlaw/UI/SideBar");
import BaseTable = require("Everlaw/Table");

import { Element as ContextElement } from "Everlaw/Context/UI/Element";
import ContextSideBar = require("Everlaw/Context/UI/SideBar");
import ContextSingleSelect = require("Everlaw/Context/UI/SingleSelect");
import ContextTable = require("Everlaw/Context/UI/Table");
import ContextTableWidget = require("Everlaw/Context/UI/TableWidget");
import ContextPopoverMenu = require("Everlaw/Context/UI/PopoverMenu");

abstract class Context {
    constructor(readonly context: Database.Context | Context.AltContext) {
        Context.CONTEXTS[context] = this;
    }
    sideBar(params: ContextSideBar.Params) {
        return this._mixinElement(ContextSideBar, params);
    }
    singleSelect<T extends Base.Object>(params: ContextSingleSelect.Params<T>) {
        return this._mixinElement(ContextSingleSelect, params);
    }
    table<OBJ extends Base.Object, DATA extends RowData>(params: ContextTable.Params<OBJ, DATA>) {
        return this._mixinElement(ContextTable, params);
    }
    tableWidget<OBJ extends Base.Object, DATA extends RowData>(
        params: ContextTableWidget.Params<OBJ, DATA>,
    ) {
        return this._mixinElement(ContextTableWidget, params);
    }
    popoverMenu(params: ContextPopoverMenu.Params) {
        return this._mixinElement(ContextPopoverMenu, params);
    }
    _mixinElement<T extends Context.Constructor, P extends {}>(
        element: ContextElement<T, P>,
        params: P,
    ) {
        return this.mixin(element.getBase(), element.getContextParams(params));
    }
    mixin<T extends Context.Constructor>(Base: T, params: Context.Params<T>) {
        return this.inContext() ? this._doMixin(Base, params) : Base;
    }
    _doMixin<T extends Context.Constructor>(Base: T, params: Context.Params<T>) {
        return class extends (params.Base || Base) {
            constructor(...args: any[]) {
                super(...(params.updateArgs ? params.updateArgs(args) : args));
                params.updateObj && params.updateObj(this);
            }
        };
    }
    protected display(displayInContext: string, displayNotInContext: string): string {
        return this.inContext() ? displayInContext : displayNotInContext;
    }
    inContext(project: Project = Project.CURRENT, user = User.me, override?: SystemPermission) {
        return project
            ? this.inProjectContext(project)
                  // We have to test `Is.defined(override) &&` instead of `override &&` because an
                  // enum with ordinal value 0 would also test false.
                  && !(
                      Is.defined(override)
                      && this.canElevatedRolesOverride()
                      // TODO: The permission check will always fail for user != User.me, but it looks
                      // like this method is always called with the default User.me parameter.
                      && user.has(override)
                  )
            : this.inServerContext();
    }
    inProjectContext(project: Project) {
        return this.inDatabaseContext(project);
    }
    inDatabaseContext(object: Project | Database) {
        return object.context === this.context;
    }
    abstract inServerContext(): boolean;
    canElevatedRolesOverride() {
        return true;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Context {
    export type Constructor<T = {}> = new (...args: any[]) => T;

    export interface Params<T extends Constructor> {
        /** An optional subclass of T to override class methods. */
        Base?: T;
        /** An optional function to modify constructor arguments before a new object is instantiated */
        updateArgs?: (args: any[]) => any[];
        /** An optional function to be executed after a new object is instantiated */
        updateObj?: (obj: any) => void;
    }

    export enum AltContext {
        ENGADMIN = "ENGADMIN",
        CXADMIN = "CXADMIN",
        PRODADMIN = "PRODADMIN",
        FINADMIN = "FINADMIN",
    }

    export type ContextMap<T> = Partial<{ [context in Database.Context | AltContext]: T }>;

    export const CONTEXTS: ContextMap<Context> = {};

    export function mixin<T extends Constructor>(Base: T, contextParams: ContextMap<Params<T>>) {
        for (const context in contextParams) {
            if (CONTEXTS[context].inContext()) {
                return CONTEXTS[context]._doMixin(Base, contextParams[context]);
            }
        }
        return Base;
    }

    export function sideBar(contextParams: ContextMap<ContextSideBar.Params>) {
        for (const context in contextParams) {
            if (CONTEXTS[context].inContext()) {
                const params = contextParams[context];
                return CONTEXTS[context].sideBar(params);
            }
        }
        return BaseSideBar.SideBar;
    }

    export function table<OBJ extends Base.Object, DATA extends RowData>(
        contextParams: ContextMap<ContextTable.Params<OBJ, DATA>>,
    ) {
        for (const context in contextParams) {
            if (CONTEXTS[context].inContext()) {
                return CONTEXTS[context].table(contextParams[context]);
            }
        }
        return BaseTable;
    }

    export function tableWidget<OBJ extends Base.Object, DATA extends RowData>(
        contextParams: ContextMap<ContextTableWidget.Params<OBJ, DATA>>,
    ) {
        for (const context in contextParams) {
            if (CONTEXTS[context].inContext()) {
                return CONTEXTS[context].tableWidget(contextParams[context]);
            }
        }
        return BaseTable.Widget;
    }

    interface InLevelContextProps {
        context: Database.Context | AltContext;
        user?: User;
        override?: SystemPermission;
    }

    interface InProjectLevelContextProps extends InLevelContextProps {
        project?: Project;
    }

    interface InDatabaseLevelContextProps extends InLevelContextProps {
        database?: Database;
    }

    type InServerLevelContextProps = InLevelContextProps;

    type InLevelContextInternalProps<P extends InLevelContextProps> = Omit<P, "context"> & {
        context: Context;
    };

    export abstract class Level<P extends InLevelContextProps> {
        static DEFAULT = new (class extends Level<InProjectLevelContextProps> {
            protected override inContextInternal({
                user,
                override,
                context,
                project = Project.CURRENT,
            }: InLevelContextInternalProps<InProjectLevelContextProps>) {
                return context.inContext(project, user, override);
            }
        })();
        static PROJECT = new (class extends Level<InProjectLevelContextProps> {
            protected override inContextInternal({
                user,
                override,
                context,
                project = Project.CURRENT,
            }: InLevelContextInternalProps<InProjectLevelContextProps>) {
                return (
                    !this.isOverride(override, user, context)
                    && project
                    && context.inProjectContext(project)
                );
            }
        })();
        static DATABASE = new (class extends Level<InDatabaseLevelContextProps> {
            protected override inContextInternal({
                user,
                override,
                context,
                database = Project.CURRENT?.getDatabase(),
            }: InLevelContextInternalProps<InDatabaseLevelContextProps>) {
                return (
                    !this.isOverride(override, user, context)
                    && database
                    && context.inDatabaseContext(database)
                );
            }
        })();
        static SERVER = new (class extends Level<InServerLevelContextProps> {
            protected override inContextInternal({
                user,
                override,
                context,
            }: InLevelContextInternalProps<InServerLevelContextProps>) {
                return !this.isOverride(override, user, context) && context.inServerContext();
            }
        })();

        inContext({ user = User.me, context, ...props }: P): boolean {
            const contextValue = CONTEXTS[context];
            return this.inContextInternal({
                user,
                context: contextValue,
                ...props,
            } as Omit<P, "context"> & { context: Context });
        }

        protected abstract inContextInternal(props: InLevelContextInternalProps<P>): boolean;

        protected isOverride(override: SystemPermission | undefined, user: User, context: Context) {
            // We have to test `Is.defined(override) &&` instead of `override &&` because an enum with
            // ordinal value 0 would also test false.
            return Is.defined(override) && context.canElevatedRolesOverride() && user.has(override);
        }
    }

    interface ContextComponentProps {
        /**
         * The level at which to check the current context.
         *
         * If DEFAULT, uses {@link Context#inContext} to determine the current context.
         *
         * If PROJECT and the given {@code project} is defined, uses {@link
         * Context#inProjectContext} to determine the current context, allowing the user to
         * override if they have the given
         * {@code override} permission.
         *
         * If DATABASE and the given {@code database} is defined, uses
         * {@link Context#inDatabaseContext} to determine the current context, allowing the user to
         * override if they have the given
         * {@code override} permission.
         *
         * If SERVER, uses {@link Context#inServerContext} to determine the current context,
         * allowing the user to override if they have the given {@code override} permission.
         */
        level?: Level<any>;
        /**
         * The project to check the context of, if level is {@link Level.DEFAULT} or
         * {@link Level.PROJECT}. Defaults to {@link Project.CURRENT}.
         */
        project?: Project;
        /**
         * The database to check the context of, if level is {@link Level.DATABASE}.
         */
        database?: Database;
        /**
         * When provided, indicates which elevated permission the user must have in order to
         * override the context.
         */
        override?: SystemPermission;
    }

    export interface OnlyProps extends ContextComponentProps {
        /**
         * The context to check.
         */
        context: Database.Context | AltContext;
        /**
         * The element to place in the DOM, only if in the given {@code context} at the given
         * {@code level}.
         */
        children: ReactNode;
    }

    /**
     * A component that only renders if currently in the given context at the given level.
     */
    export const Only: FC<OnlyProps> = ({
        level = Level.DEFAULT,
        context,
        project = Project.CURRENT,
        database = project?.getDatabase(),
        override,
        children,
    }) => {
        return level.inContext({
            context,
            project,
            database,
            override,
            user: User.useMe(),
        } as InLevelContextProps) ? (
            <>{children}</>
        ) : undefined;
    };

    export interface DependentProps extends ContextComponentProps, ContextMap<ReactNode> {
        /**
         * The element to place in the DOM, only if not in any of the provided contexts.
         */
        children?: ReactNode;
    }

    /**
     * A component that renders differently depending on the current context at the given level.
     *
     * E.g. given the following:
     * {@code <Context.Dependent ECA="ECA" SBFREE="SBFREE">Neither</Context.Dependent>}
     *
     * The component will render "ECA" if currently in the ECA context, "SBFREE" if currently in the
     * SBFREE context, and "Neither" if in neither.
     */
    export const Dependent: FC<DependentProps> = ({
        level = Level.DEFAULT,
        project = Project.CURRENT,
        database = project?.getDatabase(),
        override = undefined,
        children,
        ...contextChildren
    }) => {
        const user = User.useMe();
        for (const contextName in contextChildren) {
            const context = contextName as AltContext | Database.Context;
            const properties: InProjectLevelContextProps &
                InDatabaseLevelContextProps &
                InServerLevelContextProps = {
                user,
                project,
                database,
                override,
                context,
            };
            if (level.inContext(properties)) {
                return <>{contextChildren[contextName]}</>;
            }
        }
        return children && <>{children}</>;
    };
}

export = Context;
