import { Arr } from "core";
import { NameAnalysis } from "Everlaw/Model/Upload/Metadata/NameAnalysis";
import { TypeFormat } from "Everlaw/UI/Upload/Metadata/TypeFormat";

const GUESS_SIMILARITY = 0.99; // see MetadataSynonymService.java

/**
 * Corresponds to ColumnAnalysis.java.
 */
class ColumnAnalysis {
    private static EXPECTED_TYPE_SCORE = 0.5;

    private headerClass: string;
    private nameAnalyses: { [typeName: string]: NameAnalysis[] };
    private headerOccurrences = 0;
    private typeFormats: { [typeFormatId: string]: TypeFormat } = {};
    private typeScores: { [type: string]: number } = {};

    constructor(
        params: any,
        numDocs: number,
        allNameAnalyses: { [headerClass: string]: { [typeName: string]: NameAnalysis[] } },
    ) {
        this.headerClass = params.headerClass;
        this.nameAnalyses = allNameAnalyses[this.headerClass];
        // Calculate occurrences first so they're already available for TypeFormat scores.
        const typeOccurrences: { [type: string]: number } = {};
        Object.entries(this.nameAnalyses || {}).forEach(([type, analyses]) => {
            typeOccurrences[type] = 0;
            analyses
                .filter((analysis) => analysis.similarity !== GUESS_SIMILARITY) // filter out guesses
                .forEach((analysis) => {
                    const effectiveCount = analysis.effectiveCount();
                    this.headerOccurrences += effectiveCount;
                    typeOccurrences[type] += effectiveCount;
                });
        });
        Object.entries(typeOccurrences).forEach(([type, occurrences]) => {
            this.typeScores[type] =
                this.headerOccurrences <= 0 ? 0 : occurrences / this.headerOccurrences;
        });
        params.typeAnalyses.forEach((analysis: any) => this.addTypeAnalysis(analysis, numDocs));
    }
    getTypeFormat(typeFormatId: string) {
        return this.typeFormats[typeFormatId];
    }
    getNameAnalyses(typeFormatId: string) {
        return this.nameAnalyses[this.typeFormats[typeFormatId].type.name];
    }

    isExpectedType(typeFormat: TypeFormat) {
        return this.typeScore(typeFormat.type.name) >= ColumnAnalysis.EXPECTED_TYPE_SCORE;
    }
    /**
     * How likely is a column with this (or a similar) name to have the given type, historically?
     */
    typeScore(type: string) {
        return this.typeScores[type] || 0;
    }
    /**
     * How likely is a column with this name and the given type to be a canonical field, historically?
     */
    canonicalScore(type: string) {
        if (this.headerOccurrences <= 0) {
            return 0;
        }
        let canonical = 0;
        const analyses = this.nameAnalyses[type];
        if (analyses) {
            analyses.forEach((na) => {
                if (na.nameStatus().canonical) {
                    canonical += na.effectiveCount();
                }
            });
        }
        return canonical / this.headerOccurrences;
    }

    private addTypeAnalysis(typeAnalysis: any, numDocs: number) {
        const typeFormat = new TypeFormat(typeAnalysis, this, numDocs);
        return (this.typeFormats[typeFormat.id] = typeFormat);
    }
    deleteTypeFormat(typeFormat: TypeFormat) {
        delete this.typeFormats[typeFormat.id];
    }
    sortedTypeFormatIds() {
        return Arr.sort(Object.values(this.typeFormats), { key: (tf) => tf.score }).map(
            (tf) => tf.id,
        );
    }
    getBestTypeFormatId() {
        return this.sortedTypeFormatIds()[0];
    }
    numTypeFormats() {
        return Object.keys(this.typeFormats).length;
    }
    fetchSamples() {
        // The samples for all formats are based on exactly the same set of original values. Since
        // all values are valid for text, we just use the sample values of the Text format.
        const textTypeFormat = Arr.firstElement(Object.values(this.typeFormats), (tf) => tf.isText);
        return Arr.sorted(Object.keys(textTypeFormat.sampleValues || {}));
    }
    someTypeFormat(f: (typeFormat: TypeFormat) => boolean) {
        return Object.values(this.typeFormats).some(f);
    }
    forEachTypeFormat(f: (typeFormat: TypeFormat, typeFormatId: string) => void) {
        Object.entries(this.typeFormats).forEach(([t, tf]) => f(tf, t));
    }
    /**
     * Calls f for each of this.formats in sorted order except that it iterates the Text format first.
     */
    forEachTypeFormatSortedTextFirst(f: (typeFormat: TypeFormat) => void) {
        this.someTypeFormat((typeFormat) => {
            if (typeFormat.isText) {
                f(typeFormat);
                return true;
            }
        });
        this.sortedTypeFormatIds().forEach((typeFormatId) => {
            const typeFormat = this.typeFormats[typeFormatId];
            if (!typeFormat.isText) {
                f(typeFormat);
            }
        });
    }

    getMaxNumFormats() {
        return Math.max(...Object.values(this.typeFormats).map((tf) => tf.getNumFormats()));
    }
}

export { ColumnAnalysis };
