import Dom = require("Everlaw/Dom");
import { IconButton, IconButtonParams } from "Everlaw/UI/Button";
import { Is } from "core";
import Toggle = require("Everlaw/UI/Toggle");

export interface SwitchingIconButtonParams
    extends Toggle.Params,
        Pick<IconButtonParams, "suppressDojoEvent"> {
    /**
     * The class to apply to the button element.
     */
    className?: string;
    /**
     * The icon class to use when the button is switched on.
     */
    onClass: string;
    /**
     * The icon class to use when the button is switched off.
     */
    offClass: string;
    /**
     * If onAriaLabel is not provided but onTooltip is, then onTooltip will be used instead.
     * Likewise, if offAriaLabel is not provided but offTooltip is, then offTooltip will
     * be used instead.
     *
     * If only onAriaLabel/onTooltip is specified, then only onAriaLabel/onTooltip is applied
     * as the aria-label, and state is indicated by toggling aria-pressed. In such cases, make
     * sure that onAriaLabel/onTooltip describes what happens when the icon switches on.
     *
     * If both onAriaLabel/onTooltip and offAriaLabel/offTooltip are specified,
     * then onAriaLabel/onTooltip is used when the button is switched on, and
     * offAriaLabel/offTooltip is used otherwise.
     */
    onAriaLabel?: string;
    offAriaLabel?: string;
}

/**
 * An icon button that can be toggled between two different icons on click
 * (e.g. the favorites star).
 *
 * In most cases, this widget should be used over SwitchingIcon.
 */
export class SwitchingIconButton extends Toggle {
    onClass: string;
    offClass: string;
    readonly onAriaLabel: string;
    readonly offAriaLabel: string | undefined;
    button: IconButton;
    constructor(params: SwitchingIconButtonParams) {
        super();
        Object.assign(this, params);
        if (!this.onAriaLabel && this.onTooltip && Is.string(this.onTooltip)) {
            this.onAriaLabel = this.onTooltip;
        }
        if (!this.offAriaLabel && this.offTooltip && Is.string(this.offTooltip)) {
            this.offAriaLabel = this.offTooltip;
        }
        this.button = new IconButton({
            className: params.className,
            iconClass: this.onClass,
            ariaLabel: this.onAriaLabel,
            showTooltipOnDisable: !!params.disabledReason,
            tooltip: params.state ? params.onTooltip : params.offTooltip,
            onClick: (e) => {
                this.tooltip?.close();
                this.onClick(e);
            },
            suppressDojoEvent: params.suppressDojoEvent,
        });
        this.node = this.button.node;
        this.registerDestroyable(this.button);
        this.initToggle(params.state, params.disabled);
    }
    protected override _set(state: boolean) {
        this.button.setIconClass(state ? this.onClass : this.offClass);
        if (Is.defined(this.offAriaLabel)) {
            Dom.setAttr(this.node, "aria-label", state ? this.onAriaLabel : this.offAriaLabel);
        } else {
            Dom.setAttr(this.node, "aria-pressed", String(state));
        }
    }
    setOnIconClass(onClass: string) {
        if (this.isSet() && this.onClass !== onClass) {
            this.button.setIconClass(onClass);
        }
        this.onClass = onClass;
    }
    override isSet() {
        return Dom.hasClass(this.button.icon, "icon_" + this.onClass);
    }
    override isDisabled() {
        return (
            Dom.hasAttr(this.node, "disabled") || Dom.getAttr(this.node, "aria-disabled") === "true"
        );
    }
    override setDisabled(disabled = true, onlyShowDisabledText = false) {
        this.button.setDisabled(disabled);
        this.updateTooltip(this.isSet(), disabled, onlyShowDisabledText);
    }
}
