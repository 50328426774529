import {
    DEFAULT_TASK_CONFIG,
    DraftingType,
    WritingAssistantTaskParams,
    WritingAssistantTaskSelectionOptions,
    WritingAssistantTaskType,
} from "Everlaw/Argument/View/WritingAssistantDialog/util/WritingAssistant";
import * as Base from "Everlaw/Base";
import { PermList } from "Everlaw/Base";
import { Compare as Cmp } from "core";
import { Is } from "core";
import * as Perm from "Everlaw/PermissionStrings";
import * as Preference from "Everlaw/Preference";
import * as Project from "Everlaw/Project";
import * as User from "Everlaw/User";

export type WritingAssistantTemplateId = (number | string) & Base.Id<"WritingAssistantTemplate">;

interface WritingAssistantTemplateParams {
    id: WritingAssistantTemplateId;
    name: string;
    owningUserId: User.Id | undefined;
    projectId: number;
    task: WritingAssistantTaskSelectionOptions;
    taskParams: WritingAssistantTaskParams;
    pinned: boolean;
    security?: PermList;
}

/** See WritingAssistantTemplate.java */
export class WritingAssistantTemplate extends Base.SecuredObject {
    override id: WritingAssistantTemplateId;
    name: string;
    owningUserId: User.Id | undefined;
    projectId: number;
    task: WritingAssistantTaskSelectionOptions;
    taskParams: WritingAssistantTaskParams;
    pinned: boolean;

    override get className(): string {
        return "WritingAssistantTemplate";
    }

    constructor(params: WritingAssistantTemplateParams) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: WritingAssistantTemplateParams): void {
        Object.assign(this, params);
    }

    override display() {
        return this.name;
    }

    override compare(other: WritingAssistantTemplate): number {
        if (Is.number(this.id) && Is.number(other.id)) {
            return Cmp.num(this.id as number, other.id as number);
        } else if (Is.string(this.id) && Is.string(other.id)) {
            return Cmp.str(this.id as string, other.id as string);
        }
        // Other the string keys before number keys
        return Is.string(this.id) ? -1 : 1;
    }

    clone(): WritingAssistantTemplate {
        return new WritingAssistantTemplate({
            id: this.id,
            name: this.name,
            owningUserId: this.owningUserId,
            pinned: this.pinned,
            projectId: this.projectId,
            security: this.security,
            task: this.task,
            taskParams: { ...this.taskParams },
        });
    }

    // Comparison of the user task param selection (where the task param selection keys are required),
    // and the task params of this template. The user is considered to have modified the template if
    // (1) they changed any values of the template (key is present in both and values unequal), or
    // (2) they changed any values not present in the template (key not present does not equal default)
    equalsUserTaskSelection(
        taskSelection: WritingAssistantTaskSelectionOptions,
        paramSelection: Required<WritingAssistantTaskParams>,
    ): boolean {
        if (this.task !== taskSelection) {
            return false;
        }
        const taskParamKeys = Object.keys(paramSelection) as Array<keyof typeof paramSelection>;
        return taskParamKeys.every((key) =>
            key in this.taskParams
                ? this.taskParams[key] === paramSelection[key]
                : paramSelection[key] === DEFAULT_TASK_CONFIG[key],
        );
    }
}

export let DEFAULT_WRITING_ASSISTANT_TEMPLATES: readonly WritingAssistantTemplate[] = [];

export let setDefaultTemplatePinned: (
    id: WritingAssistantTemplateId,
    pinned: boolean,
) => readonly WritingAssistantTemplate[] = () => {
    return [];
};

/** Because of weird circular dependencies this can be loaded outside of Project Contexts. */
if (Project.CURRENT) {
    // This file gets called in more places in Storybuilder, but we shouldn't need to load the
    // preference in every screen. Instead, just check if it's loaded before calling.
    const pinnedDefaultTemplateIds = new Set(
        Preference.CHRONOLOGY.pinnedDefaultWritingAssistantTemplates.prefs
            ? Preference.CHRONOLOGY.pinnedDefaultWritingAssistantTemplates.getProjectDefault().val
            : Preference.CHRONOLOGY.pinnedDefaultWritingAssistantTemplates.defaultVal,
    );
    /** Default Templates. Give increasingly negative ids so they don't overlap */
    DEFAULT_WRITING_ASSISTANT_TEMPLATES = Object.freeze([
        new WritingAssistantTemplate({
            id: "factualBackground" as WritingAssistantTemplateId,
            name: "Factual Background",
            owningUserId: undefined,
            projectId: Project.CURRENT.id,
            task: WritingAssistantTaskType.WRITING_ASSISTANT_MEMO,
            taskParams: {
                memoType: DraftingType.ANALYZE,
                userInstructions:
                    "the factual basis and background of the case. Discuss with "
                    + "specificity the history and context, key entities, and important events "
                    + "and facts.",
            },
            pinned: pinnedDefaultTemplateIds.has("factualBackground" as WritingAssistantTemplateId),
        }),
        new WritingAssistantTemplate({
            id: "misconductAnalysis" as WritingAssistantTemplateId,
            name: "Misconduct Analysis",
            owningUserId: undefined,
            projectId: Project.CURRENT.id,
            task: WritingAssistantTaskType.WRITING_ASSISTANT_OUTLINE,
            taskParams: {
                outlineType: DraftingType.ANALYZE,
                userInstructions:
                    "misconduct (illegal, unethical, or potentially problematic behavior)"
                    + " by the major parties involved. Name the major parties and provide additional "
                    + "detail about how their misconduct was problematic in the context of this case.",
            },
            pinned: pinnedDefaultTemplateIds.has(
                "misconductAnalysis" as WritingAssistantTemplateId,
            ),
        }),
        new WritingAssistantTemplate({
            id: "themeList" as WritingAssistantTemplateId,
            name: "Theme List",
            owningUserId: undefined,
            projectId: Project.CURRENT.id,
            task: WritingAssistantTaskType.WRITING_ASSISTANT_LIST,
            taskParams: {
                itemType: "topic, subject, or theme",
                outlineType: DraftingType.ANALYZE,
                itemInfo:
                    "Name (Clearly and concisely name each topic, subject, or theme), Description "
                    + "(Define the stated topic, subject, or theme, explaining its importance), "
                    + "Evidence (Provide 3-5 pertinent evidence sources, explaining how each source "
                    + "relates to the topic, subject, or theme).",
            },
            pinned: pinnedDefaultTemplateIds.has("themeList" as WritingAssistantTemplateId),
        }),
        new WritingAssistantTemplate({
            id: "castOfCharacters" as WritingAssistantTemplateId,
            name: "Cast of Characters",
            owningUserId: undefined,
            projectId: Project.CURRENT.id,
            task: WritingAssistantTaskType.WRITING_ASSISTANT_TABLE,
            taskParams: {
                itemType: "entity. Identify as many entities as possible given the sources.",
                columns: [
                    "Name (inclusive of aliases or acronyms)",
                    "Entity Type",
                    "Facts about the entity from the sources",
                    "Relevant sources",
                ],
            },
            pinned: pinnedDefaultTemplateIds.has("castOfCharacters" as WritingAssistantTemplateId),
        }),
    ]);

    /**
     * Sets the project preference for which default templates should be pinned. The user
     * must be a project admin or have elevated access to perform this action. Returns the
     * resulting Default Templates.
     */
    setDefaultTemplatePinned = (id, pinned) => {
        if (
            !User.me.can(Perm.ADMIN, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN)
            || !DEFAULT_WRITING_ASSISTANT_TEMPLATES.some((t) => t.id === id)
        ) {
            return DEFAULT_WRITING_ASSISTANT_TEMPLATES;
        }
        const pinnedSet = new Set(
            Preference.CHRONOLOGY.pinnedDefaultWritingAssistantTemplates.getProjectDefault().val,
        );
        pinned ? pinnedSet.add(id) : pinnedSet.delete(id);
        Preference.CHRONOLOGY.pinnedDefaultWritingAssistantTemplates.setProjectDefault(
            Array.from(pinnedSet),
        );
        DEFAULT_WRITING_ASSISTANT_TEMPLATES = Object.freeze(
            DEFAULT_WRITING_ASSISTANT_TEMPLATES.map((template) => {
                if (template.id === id) {
                    template.pinned = pinned;
                }
                return template;
            }),
        );
        return DEFAULT_WRITING_ASSISTANT_TEMPLATES;
    };
}
