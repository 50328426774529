import ActionNode = require("Everlaw/UI/ActionNode");
import { Arr, Str } from "core";
import Base = require("Everlaw/Base");
import Checkbox = require("Everlaw/UI/Checkbox");
import dojo_keys = require("dojo/keys");
import dojo_on = require("dojo/on");
import Dom = require("Everlaw/Dom");
import E = require("Everlaw/Entities");
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import { IntervalSelector, PageTable, PageTableDispRange } from "Everlaw/PaginatedTable";
import { MAX_FILES } from "Everlaw/FilePicker";
import Project = require("Everlaw/Project");
import ProjectDateUtil = require("Everlaw/ProjectDateUtil");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import { RowData } from "Everlaw/Table";
import Table = require("Everlaw/Table");
import TextBox = require("Everlaw/UI/TextBox");
import Tooltip = require("Everlaw/UI/Tooltip");
import UI = require("Everlaw/UI");
import Util = require("Everlaw/Util");

/**
 * Dialog for selecting ShareFile files for upload and supporting objects
 *
 * @author kylechatman
 */

/**
 * A ShareFile item. May be file, folder, or search result
 * see SharefileObject.java for backend equivalent
 */
export class Item extends Base.Object {
    className: "SharefileItem";

    override id: string;
    name: string;
    fileType: string;
    size: number;
    fSize: string;
    created?: number;
    fCreated: string;
    owner: string;
    rank: number;

    constructor(params: any) {
        super(params);
        this._mixin(params);
        this.fCreated = params.created
            ? ProjectDateUtil.displayShortDateTime(parseInt(params.created))
            : "";
    }

    override _mixin(params: any) {
        Object.assign(this, params);
    }

    override compare(other: Item) {
        if (!(this.name && other.name)) {
            return 0;
        }
        if (this.isFolder() !== other.isFolder()) {
            // xor items are folders
            return this.isFolder() ? -1 : 1;
        }
        return this.name.localeCompare(other.name);
    }

    override display() {
        return this.name;
    }

    icon() {
        return new Icon(Str.capitalize(this.fileType));
    }

    isFolder() {
        return this.fileType === "folder";
    }

    static compareName(o1: Item, o2: Item) {
        return o1.compare(o2);
    }

    static compareSize(o1: Item, o2: Item) {
        if (!(o1.size && o2.size)) {
            return 0;
        }
        if (o1.isFolder() !== o2.isFolder()) {
            // xor items are folders
            return o1.fileType === "folder" ? -1 : 1;
        }
        return o1.size - o2.size;
    }

    static compareDate(o1: Item, o2: Item) {
        if (!(o1.created && o2.created)) {
            return 0;
        }
        if (o1.isFolder() !== o2.isFolder()) {
            // xor items are folders
            return o1.fileType === "folder" ? -1 : 1;
        }
        return o1.created - o2.created;
    }

    static compareOwner(o1: Item, o2: Item) {
        if (!(o1.owner && o2.owner)) {
            return 0;
        }
        if (o1.isFolder() !== o2.isFolder()) {
            // xor items are folders
            return o1.isFolder() ? -1 : 1;
        }
        return o1.owner.localeCompare(o2.owner);
    }

    static compareRank(o1: Item, o2: Item) {
        if (!(o1.rank && o2.rank)) {
            return 0;
        }
        return o1.rank - o2.rank;
    }
}

interface ItemData extends Table.RowData {
    item: Item;
    checkbox: Checkbox;
}

/**
 * Display file tree breadcrumbs
 */
class Breadcrumbs {
    private breadcrumbNode: HTMLSpanElement;
    private static readonly CHAR_LIMIT = 100;
    private static readonly SEP = " > ";
    currentFolder: Item;

    constructor(
        parent: HTMLElement,
        private callback: (Item) => void,
    ) {
        const box = Dom.create("div", { class: "sf-breadcrumb-box" }, parent);
        this.breadcrumbNode = Dom.create(
            "span",
            {
                innerHTML: Picker.titles.ROOT,
                style: { fontSize: "12px" },
            },
            box,
        );
    }

    /**
     * Given breadcrumb request result as a list of Item, display as
     * folder > folder > folder
     * From looking at the breadcrumbs we can tell if this is a file in Personal Folders or
     * Shared Folders. This returns that information so the caller can update the UI accordingly.
     */
    setBreadcrumbsFromResult(items: Item[]): string {
        this.breadcrumbNode.innerHTML = "";
        if (!items) {
            return Picker.titles.ROOT;
        }
        if (items.length === 2 && items[1].id === this.currentFolder.id) {
            // special case if clicked on root folder from breadcrumbs.
            // otherwise you would get Personal Folders > Personal Folders
            this.altCrumbs(Picker.titles.ROOT);
            return Picker.titles.ROOT;
        }
        // how much of path can we show in our horizontal space?
        // skip first item, always name: Folders, id: top
        // index 1 should be first we want to report
        let startIndex = 1;
        while (startIndex < items.length) {
            const limit =
                Breadcrumbs.CHAR_LIMIT
                - (startIndex === 1 ? 0 : Breadcrumbs.SEP.length + E.ELIP.length)
                - this.currentFolder.name.length;
            if (Breadcrumbs.calcLength(startIndex, items) > limit) {
                startIndex++;
            } else {
                break;
            }
        }
        // ellipses if omitted ancestors
        if (startIndex > 1) {
            Dom.place(this.getCrumb(items[startIndex - 1], E.ELIP), this.breadcrumbNode);
            Dom.create("span", { content: Breadcrumbs.SEP }, this.breadcrumbNode);
        }
        // add ancestors as links if folder
        for (let i = startIndex; i < items.length; i++) {
            Dom.place(this.getCrumb(items[i]), this.breadcrumbNode);

            Dom.create(
                "span",
                {
                    textContent: Breadcrumbs.SEP,
                },
                this.breadcrumbNode,
            );
        }
        Dom.create(
            "span",
            {
                textContent: Str.ellipsify(
                    this.currentFolder.name,
                    Breadcrumbs.CHAR_LIMIT - E.ELIP.length - Breadcrumbs.SEP.length,
                ),
            },
            this.breadcrumbNode,
        );
        // If user clicked on this folder from search results, caller won't know from context if
        // it is in Personal or Shared Folders, but we can tell from breadcrumbs
        return items.length > 1 && items[1].name === "Shared Folders"
            ? Picker.titles.SHARED
            : Picker.titles.ROOT;
    }

    // show a message instead of file tree
    altCrumbs(text?: string) {
        this.breadcrumbNode.innerHTML = text ? Str.capitalize(text) : "";
    }

    private getCrumb(item: Item, text = item.name) {
        if (item.fileType === "folder") {
            return Dom.a(
                {
                    onclick: () => {
                        this.callback(item);
                    },
                },
                text,
            );
        } else {
            return Dom.span({}, text);
        }
    }

    private static calcLength(fromIndex, fullCrumbs: Item[]) {
        if (fromIndex >= fullCrumbs.length) {
            return Breadcrumbs.CHAR_LIMIT + 1;
        }
        let count = 0;
        for (let i = fromIndex; i < fullCrumbs.length - 1; i++) {
            count += fullCrumbs[i].name.length + Breadcrumbs.SEP.length;
        }
        return count + fullCrumbs[fullCrumbs.length - 1].name.length;
    }
}

/**
 * A table which supports showing a portion of total results for paging behavior
 */
export class SfPageTable<OBJ extends Base.Object, DATA extends RowData>
    extends Table<OBJ, DATA>
    implements PageTable
{
    fullData: OBJ[]; // full results, may not all be shown at once
    static readonly INTERVALS = [50, 100, 200, 500]; // options for how many results per page
    interval = SfPageTable.INTERVALS[0]; // current results per page
    startIndex: number = 0;
    endIndex: number = this.interval;
    override store: Base.JsonStore<OBJ>; // the data shown in the table
    reverseSort = false;

    hasNext() {
        return this.endIndex > 0 && this.endIndex < this.fullData.length;
    }

    setInterval(interval: number): void {
        this.interval = interval;
    }

    getInterval(): number {
        return this.interval;
    }

    totalSize(): number {
        return this.fullData ? this.fullData.length : 0;
    }

    getDisplayedRange(): PageTableDispRange {
        return {
            start: this.startIndex,
            end: this.endIndex,
        };
    }

    hasPrevious() {
        return this.startIndex > 0;
    }

    setAndShow(data: OBJ[]) {
        this.fullData = data;
        Arr.sort(this.fullData, {
            cmp: (o1, o2) => this._compareWithFallback(o1, o2),
        });
        this.showRange(0);
    }

    showRange(start: number, end?: number) {
        this.startIndex = start > -1 && start < this.fullData.length ? start : 0;
        if (end && end <= this.fullData.length && end > start) {
            this.endIndex = end;
        } else {
            this.endIndex = Math.min(this.fullData.length, this.startIndex + this.interval);
        }
        this.store.clear();
        this.store.setAll(this.fullData.slice(this.startIndex, this.endIndex));
    }

    showNext() {
        this.showRange(this.endIndex);
    }

    showPrevious() {
        this.showRange(Math.max(this.startIndex - this.interval, 0));
    }

    clearData() {
        this.store.clear();
        this.fullData = [];
    }

    // Set a new compare function and refresh table contents accordingly, restarting at beginning
    setAndSortBy(compare: (o1: OBJ, o2: OBJ) => number, reverse = false) {
        this.compare = compare;
        this.reverseSort = reverse;
        Arr.sort(this.fullData, {
            cmp: (o1, o2) => this._compareWithFallback(o1, o2),
        });
        this.showRange(0);
    }

    // Base table doesn't support reverse sort, override
    override _compareWithFallback(o1: OBJ, o2: OBJ): number {
        return (this.reverseSort ? -1 : 1) * super._compareWithFallback(o1, o2);
    }
}

interface ThreeSortHeaderParams {
    name: string;
    negativeIconClass?: string;
    positiveIconClass?: string;
    neutralIconClass?: string;
    onClick?: () => void;
    nameTooltip?: string;
}

enum ThreeState {
    NEGATIVE,
    NEUTRAL,
    POSITIVE,
}

/**
 * Table header element with title and three states that each have an icon
 * Intended for clicking on header to sort ascending or descending, or hide if sorting by other
 */
class ThreeSortHeader {
    icons: { [i: number]: Icon } = {};
    state: ThreeState;
    activeIcon: Icon;
    node: HTMLElement;
    destroyables: Util.Destroyable[] = [];

    constructor(params: ThreeSortHeaderParams) {
        const tooltip = "Sort by " + params.name;

        this.icons[ThreeState.NEGATIVE] = new Icon(params.positiveIconClass || "caret-down-20", {
            tooltip: tooltip,
        });
        if (params.neutralIconClass) {
            // If a neutral icon is specified, use it and don't hide
            this.icons[ThreeState.NEUTRAL] = new Icon(params.neutralIconClass, {
                tooltip: tooltip,
            });
        } else {
            // By default, neutral is blank
            this.icons[ThreeState.NEUTRAL] = new Icon("caret-right-20", { tooltip: tooltip });
            Dom.invisible(this.icons[ThreeState.NEUTRAL]);
        }
        this.icons[ThreeState.POSITIVE] = new Icon(params.positiveIconClass || "caret-up-20", {
            tooltip: tooltip,
        });

        this.activeIcon = this.icons[ThreeState.NEUTRAL];
        this.state = ThreeState.NEUTRAL;

        const nameDiv = Dom.div({ style: { display: "inline-block" } }, params.name);
        let nameContent: Node;
        if (params.nameTooltip) {
            const actionNode = new ActionNode(nameDiv, {
                tooltip: params.nameTooltip,
                tooltipPosition: ["above"],
            });
            this.destroyables.push(actionNode);
            nameContent = actionNode.node;
        } else {
            nameContent = nameDiv;
        }

        this.node = Dom.div({ class: "h-spaced-8" }, nameContent, this.activeIcon.node);
        const act = new ActionNode(this.node, { onClick: params.onClick || (() => {}) });
        this.destroyables.push.apply(this.destroyables, this.icons);
        this.destroyables.push(this.node, act);
    }

    setState(state: ThreeState = ThreeState.NEUTRAL) {
        if (this.state === state) {
            return;
        }
        this.state = state;
        this.node.replaceChild(this.icons[state].node, this.activeIcon.node);
        this.activeIcon = this.icons[state];
        Dom.invisible(this.activeIcon, state === ThreeState.NEUTRAL);
    }

    toggle() {
        switch (this.state) {
            case ThreeState.NEUTRAL:
            case ThreeState.NEGATIVE:
                this.setState(ThreeState.POSITIVE);
                break;
            case ThreeState.POSITIVE:
                this.setState(ThreeState.NEGATIVE);
                break;
            default:
                this.setState(ThreeState.NEUTRAL);
                break;
        }
    }

    destroy() {
        Util.destroy(this.destroyables);
    }
}

interface LocationParams {
    name: string;
    onClick: () => void;
}

/**
 * Object representing a common location in picker navigation (e.g. Personal Folders)
 */
class Location {
    name: string;
    node: HTMLElement; // a button-like element
    tooltip: Tooltip.MirrorTooltip;
    items: Item[] = null; // a cache of items for this location
    destroyables: Util.Destroyable[] = [];

    constructor(params: LocationParams) {
        this.name = params.name;
        const nameDiv = Dom.span({ class: "sf-folder-div-name h6" }, this.name);
        this.tooltip = new Tooltip.MirrorTooltip(nameDiv);
        this.node = Dom.div(
            {
                class: "sf-folder-div action lighten-on-hover",
                tabIndex: "0",
            },
            nameDiv,
        );
        const tap = dojo_on(this.node, Input.tap, () => {
            params.onClick();
        });
        const enter = dojo_on(this.node, "keypress", (e: KeyboardEvent) => {
            if (e.keyCode === dojo_keys.ENTER || e.keyCode === dojo_keys.SPACE) {
                params.onClick();
            }
        });
        this.destroyables.push(this.tooltip, tap, enter);
    }
    toggleSelected(state: boolean) {
        Dom.toggleClass(this, "selected", state);
        Dom.toggleClass(this, "lighten-on-hover", !state);
    }
    destroy() {
        Util.destroy(this.destroyables);
    }
}

/**
 * An html element showing information about how many items are currently selected.
 * Hidden if none
 */
class SelectedCount {
    node: HTMLElement;
    countSpan: HTMLSpanElement;
    overSpan: HTMLSpanElement;
    warning: HTMLDivElement;

    constructor() {
        this.countSpan = Dom.span({ style: { fontWeight: "bold" } }, "0");
        this.overSpan = Dom.span({ style: { marginLeft: "8px" } }, "Message goes here");
        this.warning = Dom.div({}, [new Icon("alert-triangle-red-20").node, this.overSpan]);
        this.node = Dom.div({ style: { textAlign: "right" } }, [
            Dom.br(),
            this.countSpan,
            " Selected",
            Dom.br(),
            this.warning,
        ]);
        Dom.invisible(this.warning, true);
        Dom.show(this.node, false);
    }

    update(thing: Record<string, Item>) {
        const count = Object.keys(thing).length;
        const over = count - MAX_FILES;
        this.countSpan.innerText = count + "";
        this.overSpan.innerText =
            over
            + Str.pluralForm(" file exceeds", over, " files exceed")
            + " the upload limit of "
            + MAX_FILES
            + " files";
        Dom.toggleClass(this.node, "warning", over > 0);
        Dom.invisible(this.warning, over < 1);
        Dom.show(this.node, !!count);
    }
}

export class Picker {
    private leftNav: HTMLDivElement;
    private searchBox: TextBox;
    private locations: { [name: string]: Location } = {};

    private resultsPane: HTMLDivElement;
    private resultsTopDiv: HTMLDivElement;
    private breadcrumbs: Breadcrumbs;
    private headers: ThreeSortHeader[];
    private table: SfPageTable<Item, ItemData>;
    private allCheckbox: Checkbox;
    private selectedItems: { [id: string]: Item } = {};
    private intervalSelect: IntervalSelector;
    private selectedCount: SelectedCount;
    private queryDialog: QueryDialog;

    destroyables: Util.Destroyable[] = [];

    // If a request response comes back with an out of date token it is ignored
    private requestToken: number = 0;

    // ShareFile API search request returns 50 results max by default. If we specify something
    // else in SharefileRequestService.java we will need to update here
    private static readonly maxSearchResults: number = 50;
    private static readonly NO_RESULTS = "No results found";
    private static readonly WAIT_MESSAGE = Dom.span(
        { style: { fontSize: "14px" } },
        "Awaiting response...",
    );
    // In testing on company with ~10k files and ~10gb we observed very long wait times and
    // sometimes got no results with no error message
    private static readonly SEARCH_MESSAGE = Dom.div({}, [
        Dom.span(
            { style: { fontSize: "14px", verticalAlign: "bottom" } },
            "Awaiting response...  ",
        ),
        new Icon("info-circle-20", {
            tooltip: Dom.div(
                "ShareFile searches can take over a minute and may",
                Dom.br(),
                "return no documents when run on large accounts",
            ),
        }).node,
    ]);
    // Should stay synced with CloudRequestController.java
    static readonly queryFlags = {
        ROOT: "root",
        FOLDER: "folder",
        SEARCH: "search",
        SHARED: "shared",
        FAVORITES: "favorites",
        BREADCRUMBS: "breadcrumbs",
    };
    static readonly titles = {
        ROOT: "Personal Folders",
        SEARCH: "Search results",
        SHARED: "Shared Folders",
        FAVORITES: "Favorites",
    };

    constructor(onSubmit: (items: Item[]) => void) {
        // These should be consistent with _processing-page.scss#sharefile-picker
        // it's needed here to pass tableHeight to Table constructor
        const contentHeight = 584;
        const titleHeight = 72;
        const tableHeadHeight = 42;
        const breadcrumbHeight = 20;
        const pageNavHeight = 42;
        const resultsHeight = contentHeight - titleHeight;
        const tableHeight = resultsHeight - breadcrumbHeight - tableHeadHeight - pageNavHeight - 16;

        this.destroyables.push(Picker.SEARCH_MESSAGE, Picker.WAIT_MESSAGE);

        const box = Dom.create("div", {
            id: "sharefile-picker",
        });
        this.destroyables.push(box);

        const titleBox = Dom.create(
            "div",
            {
                class: "sf-title",
            },
            box,
        );
        const titleText = Dom.div({ class: "title-text" }, Dom.h2({}, "Select file(s) to upload"));
        this.selectedCount = new SelectedCount();
        const alignedSelectedCount = UI.alignedContainer({
            content: this.selectedCount.node,
            vertical: true,
            cssClass: "select-count",
        });
        Dom.place([titleText, alignedSelectedCount], titleBox);

        const bodyBox = Dom.create("div", { style: { width: "100%" } }, box);

        this.leftNav = Dom.create("div", { class: "sf-sidebar" }, bodyBox);
        this.searchBox = new TextBox({
            clearMark: true,
            placeholder: "Search by name",
        });
        this.destroyables.push(this.searchBox);
        this.searchBox.onClear = () => {
            if (!this.table.totalSize() && this.table.empty === Picker.SEARCH_MESSAGE) {
                this.fetchRoot();
            }
        };
        this.searchBox.onSubmit = () => {
            this.toggleLocations();
            this.fetchSearch();
        };
        Dom.place(Dom.div({ class: "sf-search" }, this.searchBox.getNode()), this.leftNav);
        this.locations[Picker.titles.ROOT] = new Location({
            name: Picker.titles.ROOT,
            onClick: () => {
                this.toggleLocations(Picker.titles.ROOT);
                this.fetchRoot();
            },
        });
        this.locations[Picker.titles.SHARED] = new Location({
            name: Picker.titles.SHARED,
            onClick: () => {
                this.toggleLocations(Picker.titles.SHARED);
                this.fetchShared();
            },
        });
        this.locations[Picker.titles.FAVORITES] = new Location({
            name: Picker.titles.FAVORITES,
            onClick: () => {
                this.toggleLocations(Picker.titles.FAVORITES);
                this.fetchFavorites();
            },
        });
        Dom.addContent(this.leftNav, [
            this.locations[Picker.titles.ROOT].node,
            this.locations[Picker.titles.SHARED].node,
            this.locations[Picker.titles.FAVORITES].node,
        ]);

        this.resultsPane = Dom.create(
            "div",
            {
                class: "sf-results-pane",
            },
            bodyBox,
        );

        this.resultsTopDiv = Dom.create("div", { class: "sf-results-top" }, this.resultsPane);

        this.breadcrumbs = new Breadcrumbs(this.resultsTopDiv, (item) => this.fetchFolder(item));

        this.allCheckbox = new Checkbox({
            onUserClick: (isChecked, me) => {
                this.setAllCheck(isChecked);
            },
        });
        this.destroyables.push(this.allCheckbox);

        const nameHeader = new ThreeSortHeader({
            name: "Name",
            onClick: () => {
                nameHeader.toggle();
                for (const elem of this.headers) {
                    if (nameHeader !== elem) {
                        elem.setState(ThreeState.NEUTRAL);
                    }
                }
                this.table.setAndSortBy(Item.compareName, nameHeader.state === ThreeState.NEGATIVE);
                this.intervalSelect.update();
            },
        });
        nameHeader.setState(ThreeState.POSITIVE);
        const sizeHeader = new ThreeSortHeader({
            name: "Size",
            onClick: () => {
                sizeHeader.toggle();
                for (const elem of this.headers) {
                    if (sizeHeader !== elem) {
                        elem.setState(ThreeState.NEUTRAL);
                    }
                }
                this.table.setAndSortBy(Item.compareSize, sizeHeader.state === ThreeState.NEGATIVE);
                this.intervalSelect.update();
            },
        });
        const createdHeader = new ThreeSortHeader({
            name: "Date created",
            nameTooltip: (Project.CURRENT && Project.CURRENT.timezoneId) || "UTC",
            onClick: () => {
                createdHeader.toggle();
                for (const elem of this.headers) {
                    if (createdHeader !== elem) {
                        elem.setState(ThreeState.NEUTRAL);
                    }
                }
                this.table.setAndSortBy(
                    Item.compareDate,
                    createdHeader.state === ThreeState.NEGATIVE,
                );
                this.intervalSelect.update();
            },
        });
        const ownerHeader = new ThreeSortHeader({
            name: "Owner",
            onClick: () => {
                ownerHeader.toggle();
                for (const elem of this.headers) {
                    if (ownerHeader !== elem) {
                        elem.setState(ThreeState.NEUTRAL);
                    }
                }
                this.table.setAndSortBy(
                    Item.compareOwner,
                    ownerHeader.state === ThreeState.NEGATIVE,
                );
                this.intervalSelect.update();
            },
        });
        this.headers = [nameHeader, sizeHeader, createdHeader, ownerHeader];
        this.destroyables.push.apply(this.destroyables, this.headers);

        this.table = new SfPageTable({
            store: new Base.JsonStore(Item, false),
            parentNode: this.resultsTopDiv,
            empty: Picker.WAIT_MESSAGE,
            columns: [
                { style: "width: 30px" }, // checkbox
                { style: "width: 48px; text-align: right" }, // icon
                { class: "description" }, // name
                { style: "width: 100px" }, // size
                { style: "width: 175px" }, // created
                { style: "width: 125px" }, // owner
            ],
            cells: [
                // checkbox
                (p) => {
                    if (p.firstTime) {
                        p.data.checkbox = new Checkbox({
                            parent: p.td,
                            onUserClick: (isChecked) => {
                                p.data.checkbox.set(isChecked);
                                if (isChecked) {
                                    this.selectedItems[p.o.id] = p.o;
                                } else {
                                    delete this.selectedItems[p.o.id];
                                }
                                this.selectedCount.update(this.selectedItems);
                                this.toggleAllCheck();
                            },
                        });
                        p.data.destroyables.push(p.data.checkbox);
                        p.data.item = p.o;
                    }
                    const isSelected = p.o.id in this.selectedItems;
                    p.data.checkbox.set(isSelected, true);
                    Dom.toggleClass(p.tr, "selected", isSelected);
                },
                // icon
                (p) => {
                    Dom.setContent(p.td, p.o.icon().node);
                },
                // name
                (p) => {
                    Dom.setContent(p.td, p.o.name);
                },
                // size
                (p) => {
                    Dom.setContent(p.td, p.o.fSize);
                },
                // created
                (p) => {
                    Dom.setContent(p.td, p.o.fCreated);
                },
                // owner
                (p) => {
                    Dom.setContent(p.td, p.o.owner);
                },
            ],
            header: [
                this.allCheckbox.getNode(),
                "",
                nameHeader.node,
                sizeHeader.node,
                createdHeader.node,
                ownerHeader.node,
            ],
            scrollPad: 8,
            maxHeight: tableHeight + "px",
            onClick: (obj, row, col) => {
                if (obj.fileType === "folder" && col !== 0) {
                    // if clicked on folder and not to check checkbox, fetch it
                    this.fetchFolder(obj);
                }
            },
        });
        this.destroyables.push(this.table);

        const tableNav = Dom.create(
            "div",
            {
                class: "paginated-table-nav",
            },
            this.resultsPane,
        );
        this.intervalSelect = new IntervalSelector(this.table, SfPageTable.INTERVALS, () =>
            this.setAllCheck(false),
        );
        Dom.place(this.intervalSelect, tableNav);
        this.destroyables.push(this.intervalSelect);

        this.queryDialog = QueryDialog.create({
            title: "ShareFile upload",
            prompt: box,
            submitIsSafe: true,
            onSubmit: () => {
                onSubmit(Object.values(this.selectedItems));
                return true;
            },
            submitText: "Continue",
            destroyOnClose: true,
            style: {
                maxHeight: "none",
            },
            classes: "sf-dialog",
        });
        this.queryDialog.registerDestroyable(this.destroyables);
        this.queryDialog.disableSubmit(true);

        this.toggleLocations(Picker.titles.ROOT);
        this.fetchRoot(true);
    }

    // Set one, all, or none (default) of the left nav locations to be styled as selected
    private toggleLocations(toSelect?: string, allState = false) {
        for (const locationsKey in this.locations) {
            this.locations[locationsKey].toggleSelected(locationsKey === toSelect || allState);
        }
    }

    private setAllCheck(isChecked: boolean) {
        this.allCheckbox.set(isChecked);
        this.table.entries.forEach((row) => {
            row.data.checkbox.set(isChecked);
            if (isChecked) {
                this.selectedItems[row.data.item.id] = row.data.item;
            } else {
                delete this.selectedItems[row.data.item.id];
            }
        });
        this.selectedCount.update(this.selectedItems);
        this.toggleContinue();
    }

    private toggleAllCheck() {
        // Header checkbox should be checked iff all rows are checked
        let checked = false;
        let i = 0;
        do {
            checked = this.table.entries[i++].data.checkbox.getValue();
        } while (checked && i < this.table.entries.length);
        this.allCheckbox.set(checked);
        // Continue button enabled if items checked but not too many
        this.toggleContinue();
    }

    private toggleContinue() {
        const selected = Object.values(this.selectedItems).length;
        this.queryDialog.disableSubmit(selected === 0 || selected > MAX_FILES);
    }

    private clearTable(forSearch = false) {
        this.setAllCheck(false);
        this.table.clearData();
        this.intervalSelect.update();
        this.table.empty = forSearch ? Picker.SEARCH_MESSAGE : Picker.WAIT_MESSAGE;
        this.resetCompare(forSearch);
        this.table.refresh();
    }

    private resetCompare(forSearch = false) {
        // Reset table search
        this.table.compare = forSearch ? Item.compareRank : Item.compareName;
        this.table.reverseSort = false;
        // Reset sort state in headers
        this.headers[0].setState(forSearch ? ThreeState.NEUTRAL : ThreeState.POSITIVE);
        for (let i = 1; i < this.headers.length; i++) {
            this.headers[i].setState(ThreeState.NEUTRAL);
        }
    }

    private fetchRoot(quiet: boolean = false) {
        this.requestToken++;
        if (!quiet) {
            this.clearTable();
            this.searchBox.clear();
            this.breadcrumbs.altCrumbs(Picker.titles.ROOT);
        }
        // if already have
        if (this.locations[Picker.titles.ROOT].items) {
            this.table.empty = Picker.NO_RESULTS;
            this.table.fullData = this.locations[Picker.titles.ROOT].items;
            this.table.setAndShow(this.locations[Picker.titles.ROOT].items);
            this.intervalSelect.update();
            this.breadcrumbs.altCrumbs(Picker.titles.ROOT);
        } else {
            this.query({
                flag: Picker.queryFlags.ROOT,
                token: this.requestToken,
                callback: (items) => {
                    this.locations[Picker.titles.ROOT].items = items;
                },
            });
        }
    }

    private fetchFolder(item: Item) {
        this.requestToken++;
        this.breadcrumbs.currentFolder = item;
        this.clearTable();
        this.searchBox.clear();
        this.query({
            flag: Picker.queryFlags.FOLDER,
            token: this.requestToken,
            itemId: item.id,
        });
        this.fetchBreadcrumbs(item.id);
    }

    private fetchShared() {
        this.requestToken++;
        this.clearTable();
        this.searchBox.clear();
        this.breadcrumbs.altCrumbs(Picker.titles.SHARED);
        // if already have
        if (this.locations[Picker.titles.SHARED].items) {
            this.table.empty = Picker.NO_RESULTS;
            this.table.setAndShow(this.locations[Picker.titles.SHARED].items);
            this.intervalSelect.update();
            this.breadcrumbs.altCrumbs(Picker.titles.SHARED);
        } else {
            this.query({
                flag: Picker.queryFlags.SHARED,
                token: this.requestToken,
                callback: (items) => {
                    this.locations[Picker.titles.SHARED].items = items;
                },
            });
        }
    }

    private fetchFavorites() {
        this.requestToken++;
        this.clearTable();
        this.searchBox.clear();
        this.breadcrumbs.altCrumbs(Picker.titles.FAVORITES);
        // if already have
        if (this.locations[Picker.titles.FAVORITES].items) {
            this.table.empty = Picker.NO_RESULTS;
            this.table.setAndShow(this.locations[Picker.titles.FAVORITES].items);
            this.intervalSelect.update();
            this.breadcrumbs.altCrumbs();
        } else {
            this.query({
                flag: Picker.queryFlags.FAVORITES,
                token: this.requestToken,
                callback: (items) => {
                    this.locations[Picker.titles.FAVORITES].items = items;
                },
            });
        }
    }

    private fetchSearch() {
        this.requestToken++;
        this.clearTable(true);
        this.breadcrumbs.altCrumbs(Picker.titles.SEARCH);
        const term = this.searchBox.getValue();
        if (!term) {
            this.fetchRoot();
            return;
        }
        this.query({
            flag: Picker.queryFlags.SEARCH,
            token: this.requestToken,
            searchTerm: term,
        });
    }

    private fetchBreadcrumbs(itemId) {
        this.query({
            flag: Picker.queryFlags.BREADCRUMBS,
            token: this.requestToken,
            itemId: itemId,
        });
    }

    private query(params: QueryParams) {
        Rest.get(`/parcel/${Project.CURRENT.parcel}/sharefileQuery.rest`, params).then(
            (res: { items: any[]; token: number }) => {
                // on success
                // Check response is still relevant
                if (res.token !== this.requestToken) {
                    return;
                }
                // convert to list of items
                const resultItems = [];
                for (const itemJson of res.items) {
                    resultItems.push(new Item(itemJson));
                }
                // update table
                if (params.flag !== Picker.queryFlags.BREADCRUMBS) {
                    if (!resultItems.length) {
                        this.table.empty = Picker.NO_RESULTS;
                        this.table.refresh();
                    } else {
                        this.table.setAndShow(resultItems);
                        this.intervalSelect.update();
                    }
                } else {
                    // For the case where the user clicked a folder in favorites or search, we need to
                    // use breadcrumbs to determine if Personal Folders or Shared Folders should
                    // be highlighted
                    const location = this.breadcrumbs.setBreadcrumbsFromResult(resultItems);
                    this.toggleLocations(location);
                }
                if (
                    params.flag === Picker.queryFlags.SEARCH
                    // Let user know there may be more results not shown
                    && resultItems.length === Picker.maxSearchResults
                ) {
                    this.breadcrumbs.altCrumbs(
                        Picker.titles.SEARCH + " (" + Picker.maxSearchResults + " results max)",
                    );
                }
                // if callback, call
                if (params.callback) {
                    params.callback(resultItems);
                }
            },
            (res) => {
                // on failure
                this.table.empty = res.message
                    ? res.message
                    : "Error getting data. Please contact Everlaw support or try again later.";
                this.table.refresh();
            },
        );
    }
}

interface QueryParams {
    flag: string;
    token: number;
    itemId?: string;
    searchTerm?: string;
    callback?: (items: Item[]) => void;
}
