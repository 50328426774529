import Base = require("Everlaw/Base");
import BaseRedaction = require("Everlaw/Redaction");
import Geom = require("Everlaw/Geom");
import Highlighting = require("Everlaw/Review/Highlighting");
import HighlightColor = require("Everlaw/Review/HighlightColor");
import ImageAnnotation = require("Everlaw/Review/ImageAnnotation");
import { Is } from "core";
import RedactionStamp = require("Everlaw/Review/RedactionStamp");
import Rest = require("Everlaw/Rest");
import { elevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";
import { NoteUtil } from "Everlaw/Note";

export class FpiRedactionRecord extends Base.Object {
    markedText: string[];
    override id: number;
    get className(): string {
        return "FpiRedactionRecord";
    }
    constructor(params) {
        super(params);
        this.markedText = params.markedText;
    }
}

export class FpiRedaction
    extends BaseRedaction.Redaction
    implements ImageAnnotation.ImageAnnotation
{
    get className() {
        return "FpiRedaction";
    }
    get redactionType() {
        return NoteUtil.ParentType.FpiRedaction;
    }
    pageNum: number;
    markedTextLines: string[];
    rectangles: Highlighting.ReviewRectangles[];

    constructor(params: any) {
        super(params);
        if (!this.rectangles) {
            this.rectangles = [];
        }
    }

    override _mixin(params: any) {
        let newRectangles: Highlighting.ReviewRectangles[];
        if (Is.defined(params.rectangles)) {
            newRectangles = [];
            (<Highlighting.ReviewRectangles[]>params.rectangles).forEach((r) =>
                newRectangles.push(
                    new Highlighting.ReviewRectangles(this, r.rectangles, r.markedText, r.pageNum),
                ),
            );
            delete params.rectangles;
        }
        if (newRectangles && this.rectangles) {
            if (
                newRectangles.length !== this.rectangles.length
                || newRectangles.some((r, i) => {
                    return r.locationHash() !== this.rectangles[i].locationHash();
                })
            ) {
                Base.remove(this);
            }
        }
        if (newRectangles) {
            this.rectangles = newRectangles;
        }
        if (Is.defined(params.markedTextLines)) {
            this.markedTextLines = params.markedTextLines;
        }
        super._mixin(params);
        if (Is.defined(this.id)) {
            Base.add(this);
        }
    }

    getRedactionStamp() {
        return this.redactionStamp;
    }

    getType() {
        return this.redactionType;
    }

    getId() {
        return this.id;
    }

    getDocId() {
        return this.docId;
    }

    getColor() {
        return HighlightColor.COLOR_REDACTION;
    }

    getNotes() {
        return this.notes;
    }

    getPageNum() {
        return this.pageNum;
    }

    getText(): string {
        return null;
    }

    getScrollLocation() {
        return { x: 0, y: 0, width: 1, height: 1 };
    }

    getLocationHash() {
        return this.pageNum;
    }

    getSelectedText(): string[] {
        const record = Base.get(FpiRedactionRecord, this.id);
        return record?.markedText || [];
    }

    /**
     * Commit changes to this full-page / inverse redaction.
     */
    commit(updatedRects?: Geom.Rectangle[], stamp?: RedactionStamp) {
        if (Is.defined(stamp)) {
            this.redactionStamp = stamp;
        }
        if (updatedRects) {
            throw Error("This ImageAnnotation's commit function does not take updatedRects");
        }
        const rectangles = this.rectangles;
        const rectangleObjects = rectangles.map((r: Highlighting.ReviewRectangles) =>
            r.stringifiableObject(),
        );
        return Rest.post("documents/saveFpiRedaction.rest", {
            // Always has:
            docId: this.docId,
            // If new, doesn't have:
            fpiRedactionId: this.id,
            pageNum: this.pageNum,
            rectangles: JSON.stringify(rectangleObjects),
            notes: JSON.stringify(this.notes),
            stampId: this.redactionStamp.id,
        }).then((data) => {
            // TODO: We add/publish the redaction here, but saveFpiRedaction.rest publishes the updated
            // document, and that also contains our redaction. Document._mixin then calls Base.set
            // with the JSON of our redaction as well. This doesn't actually cause problems since the
            // document stores these redaction by their ID, it just causes lots of extra publish
            // events. Since we use `add`, we ensure that `this` is actually the global redaction,
            // even in the event of a race condition where we finish after the mux notification.
            // Since the document uses Base.set, it will only update these fields. In either
            // case, anyone with a reference to this continues to be valid.
            //
            // If we want to remove this logic and rely on the mux notification, then we'll have
            // to be sure that none of the callers hold onto this FpiRedaction object. We can't remove
            // the publishing logic from the notification side, because then other users won't
            // learn about our new redaction.
            if (!Is.number(this.id)) {
                this.id = data.id;
                const record = new FpiRedactionRecord({
                    id: this.id,
                    markedText: this.markedTextLines,
                });
                Base.add(record);
                Base.add(this);
            }
            this._mixin(data);
            Base.publish(this);
        });
    }

    @elevatedRoleConfirm("removing a full-page / inverse redaction")
    remove(callback?: (r: BaseRedaction.Redaction, msg?: string) => void, error?: Rest.Callback) {
        Base.remove(this.notes);
        if (Is.number(this.id)) {
            Rest.post("documents/deleteFpiRedaction.rest", { fpiRedactionId: this.id })
                .then((value) => {
                    Base.remove(this);
                    callback && callback(this, value);
                })
                .catch(error);
        } else {
            // This wasn't a saved redaction - we don't have to do any deleting, but we should call the
            // callback!
            callback && callback(this);
        }
    }

    static deleteByDocumentId(docId: number, uid?: number) {
        const redactions = Base.get(FpiRedaction).filter(
            (r) => r.docId === docId && (!uid || (r.userId && r.userId === uid)),
        );
        Rest.post("documents/deleteFpiRedactions.rest", { docId: docId }).then(() => {
            Base.remove(redactions);
        });
    }
}
