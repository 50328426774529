import {
    Button,
    ButtonColor,
    ButtonSize,
    ButtonWidth,
    Checkbox,
    CommonIcon,
    DialogSize,
    DropdownMenu,
    emailValidator,
    EverColor,
    Form,
    FormSubmitButton,
    H1,
    Icon,
    Link,
    Modal,
    Paragraph,
    phoneValidator,
    TextArea,
    TextBanner,
    TextButton,
    TextField,
    TextFieldWidth,
    textValidator,
    useForm,
    UseFormResult,
    ZIndexTokens,
} from "design-system";
import { Str } from "core";
import * as User from "Everlaw/User";
import * as React from "react";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import * as Country from "Everlaw/Country";
import { usStates, caProvinces } from "Everlaw/StatesAndProvinces";

interface KeyValuePair {
    [key: string]: string;
}

// Declare any Marketo Form related functions here.
// Marketo API Reference: https://developers.marketo.com/javascript-api/forms/api-reference/
interface MktoFormObject {
    onSuccess(callback: () => boolean): unknown;
    submit(): MktoFormObject;
    setValues(vals: KeyValuePair): unknown;
    addHiddenFields(values: KeyValuePair): unknown;
    validate(): boolean;
    getValues(): unknown;
}

// Marketo Forms 2 API. Declare any other functions required here.
// Marketo API Reference: https://developers.marketo.com/javascript-api/forms/api-reference/
interface MktoForms2 {
    loadForm(
        url: string,
        munchkinId: string,
        formId: string,
        callback?: (form: MktoFormObject) => void,
    ): void;
    getForm(formId: number | string): MktoFormObject;
    allForms(): [MktoFormObject];
}

// Marketo Form is loaded in headHeader.jsp and available as window.MktoForms2 once loaded.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MktoForms2: MktoForms2 = (window as any).MktoForms2;

const requiredMsg = "This field is required";

// An interface representing the values in the form.
interface NewCaseFormValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    caseDetails: string;
}

interface NewCaseFormProps {
    showFormLoadError: () => unknown;
    showFormValidationError: () => unknown;
    setDisableFormSubmit: (_: boolean) => void;
}
function NewCaseForm({
    showFormLoadError,
    showFormValidationError,
    setDisableFormSubmit,
}: NewCaseFormProps): ReactElement<null> {
    const marketoFormId = 1606;
    const formResult: UseFormResult<NewCaseFormValues> = useForm<NewCaseFormValues>({
        initialValues: {
            firstName: User.me?.firstName ?? "",
            lastName: User.me?.lastName ?? "",
            email: User.me?.email ?? "",
            phone: "",
            caseDetails: "",
        },
        validator: {
            firstName: textValidator({
                name: "firstName",
                required: true,
                requiredFieldErrorMessage: requiredMsg,
            }),
            lastName: textValidator({
                name: "lastName",
                required: true,
                requiredFieldErrorMessage: requiredMsg,
            }),
            email: emailValidator({
                name: "email",
                required: true,
                incorrectValueErrorMessage: "Invalid email",
                requiredFieldErrorMessage: requiredMsg,
            }),
            phone: phoneValidator({
                name: "phone",
                required: true,
                incorrectValueErrorMessage: "Invalid phone number",
                requiredFieldErrorMessage: requiredMsg,
            }),
        },
        onSubmit: () => {
            const formValues = formResult.values;
            const myForm = MktoForms2.getForm(marketoFormId);
            if (!myForm) {
                // Marketo form failed to load.
                showFormLoadError();
                return;
            }
            myForm.setValues({ FirstName: formValues.firstName });
            myForm.setValues({ LastName: formValues.lastName });
            myForm.setValues({ Email: formValues.email });
            myForm.setValues({ Phone: formValues.phone });
            // ignoring eslint because this name is from a 3rd party API
            // eslint-disable-next-line camelcase
            myForm.setValues({ Inbound_Message__c: formValues.caseDetails });
            if (User.me && User.me.primaryOrg) {
                myForm.addHiddenFields({ organization: User.me.primaryOrg.name });
            }
            if (!myForm.validate()) {
                // Marketo form validation failed even though Everlaw validation passed.
                showFormValidationError();
                // Reset values of fields that may have caused the error.
                formValues.phone = "";
                formValues.email = User.me?.email ?? "";
                return;
            }
            myForm.submit();
        },
    });
    useEffect(() => {
        if (formResult && Object.keys(formResult.errors).length) {
            setDisableFormSubmit(true);
        } else {
            setDisableFormSubmit(false);
        }
    }, [formResult.errors]);
    return (
        <Form onSubmit={formResult.submit} id={"new-case-form"}>
            <div className={"bb-form__main"}>
                <TextField
                    name={"firstName"}
                    value={formResult.values.firstName}
                    error={formResult.blurred.firstName && !!formResult.errors.firstName}
                    errorMessage={formResult.errors.firstName}
                    width={TextFieldWidth.FULL}
                    horizontal={false}
                    onBlur={(e) => formResult.blur("firstName")}
                    onChange={(e) => formResult.change("firstName", e.target.value)}
                    label={"First name"}
                    required={true}
                />
                <TextField
                    name={"lastName"}
                    value={formResult.values.lastName}
                    error={formResult.blurred.lastName && !!formResult.errors.lastName}
                    errorMessage={formResult.errors.lastName}
                    width={TextFieldWidth.FULL}
                    horizontal={false}
                    onBlur={(e) => formResult.blur("lastName")}
                    onChange={(e) => formResult.change("lastName", e.target.value)}
                    label={"Last name"}
                    required={true}
                />
                <TextField
                    name={"email"}
                    value={formResult.values.email}
                    error={formResult.blurred.email && !!formResult.errors.email}
                    errorMessage={formResult.errors.email}
                    horizontal={false}
                    width={TextFieldWidth.FULL}
                    onBlur={(e) => formResult.blur("email")}
                    onChange={(e) => formResult.change("email", e.target.value)}
                    label={"Email"}
                    subLabel={"(Used to login to Everlaw)"}
                    placeholder={"Enter email"}
                    required={true}
                />
                <TextField
                    name={"phone"}
                    value={formResult.values.phone}
                    error={formResult.blurred.phone && !!formResult.errors.phone}
                    errorMessage={formResult.errors.phone}
                    horizontal={false}
                    width={TextFieldWidth.FULL}
                    onBlur={(e) => formResult.blur("phone")}
                    onChange={(e) => formResult.change("phone", e.target.value)}
                    label={"Phone number"}
                    placeholder={"Enter phone number"}
                    required={true}
                />
                <TextArea
                    name={"caseDetails"}
                    value={formResult.values.caseDetails}
                    error={formResult.blurred.caseDetails && !!formResult.errors.caseDetails}
                    errorMessage={formResult.errors.caseDetails}
                    horizontal={false}
                    onBlur={(e) => formResult.blur("caseDetails")}
                    onChange={(e) => formResult.change("caseDetails", e.target.value)}
                    label={"New case details"}
                    placeholder={
                        "Details about the new case, such as database size, data migration needs, or billing information"
                    }
                    required={false}
                />
            </div>
        </Form>
    );
}

interface CreateBuyNewCaseModalProps {
    buttonStyle: string;
    isHelpPage: boolean;
}

// Used to swap the onSuccess callback of the Marketo form between database settings and help pages.
let formOnSuccessCallback = () => false;

export function CreateBuyNewCaseForm({ buttonStyle, isHelpPage }: CreateBuyNewCaseModalProps) {
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [loadErrorVisible, setLoadErrorVisible] = useState(false);
    const [validationErrorVisible, setValidationErrorVisible] = useState(false);
    const [disableFormSubmit, setDisableFormSubmit] = useState(false);

    const formId = 1606;
    const showFormLoadError = () => {
        setLoadErrorVisible(true);
    };
    const showFormValidationError = () => {
        setValidationErrorVisible(true);
    };
    const newForm = (
        <NewCaseForm
            showFormLoadError={showFormLoadError}
            showFormValidationError={showFormValidationError}
            setDisableFormSubmit={setDisableFormSubmit}
        />
    );
    formOnSuccessCallback = () => {
        if (modalVisible) {
            setModalVisible(false);
            if (!confirmationVisible) {
                setConfirmationVisible(true);
            }
        }
        ga_event("MarketoForm", "Buy new case request");
        return false;
    };
    // Load marketo form if there is no form on the current page everytime the form dialog is opened.
    // If Marketo form load error is showed to the user, try loading the form again.
    useEffect(() => {
        const myForm = MktoForms2.getForm(formId);
        if (!myForm) {
            MktoForms2.loadForm("//go.everlaw.com", "314-QPM-328", "1606", (myForm) => {
                if (myForm) {
                    myForm.onSuccess(() => formOnSuccessCallback());
                }
            });
        }
    }, [modalVisible, loadErrorVisible]);

    const formContent = (
        <>
            <Paragraph>
                Complete the information below to fast-track adding a new case to Everlaw.{" "}
                <span className={"bb-text--bold"}>Please note this is not the Support portal.</span>{" "}
                For support issues, please email support@everlaw.com or click "Message us".
            </Paragraph>
            {newForm}
        </>
    );
    const successfulContent = (
        <Paragraph>
            Thanks for submitting your request! An Everlaw team member will reach out shortly.
        </Paragraph>
    );
    const formLoadErrorContent = (
        <Paragraph>
            An unexpected error occurred when submitting the request. Please try again. If the issue
            persists, please contact Everlaw support for assistance.
        </Paragraph>
    );
    const formValidationErrorContent = (
        <Paragraph>
            Your request could not be submitted due to validation error. Please check the email or
            phone number fields and try again.
        </Paragraph>
    );
    const helpPageElem = (
        <TextButton
            children={"Buy a new case"}
            icon={<Icon.Database color={EverColor.WHITE} />}
            className={buttonStyle}
            size={ButtonSize.SMALL}
            onClick={() => {
                setModalVisible(true);
            }}
        />
    );
    const databaseSettingPageElem = (
        <Button
            children={"Buy a new case"}
            className={buttonStyle}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => {
                setModalVisible(true);
            }}
        />
    );
    const openCaseButton = isHelpPage ? helpPageElem : databaseSettingPageElem;
    return (
        <>
            {openCaseButton}
            <Modal
                size={DialogSize.MD}
                primaryButton={
                    <FormSubmitButton
                        form={"new-case-form"}
                        children={"Submit request"}
                        disabled={disableFormSubmit}
                    />
                }
                secondaryButton={"Cancel"}
                // Greater z-index than help menu, so that the dialog shows up above the help menu (z-index 951).
                baseZIndex={ZIndexTokens.DIALOG_HELP + 1}
                visible={modalVisible}
                onHide={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                className={"marketo-form-modal"}
                title={"Buy a new case"}
            >
                {formContent}
            </Modal>
            <Modal
                size={DialogSize.SM}
                primaryButton={"OK"}
                secondaryButton={null}
                visible={confirmationVisible}
                onHide={() => setConfirmationVisible(false)}
                onComplete={() => setConfirmationVisible(false)}
                className={"marketo-form-modal"}
                title={"Buy a new case"}
            >
                {successfulContent}
            </Modal>
            <Modal
                size={DialogSize.SM}
                primaryButton={"OK"}
                secondaryButton={null}
                visible={loadErrorVisible}
                onHide={() => setLoadErrorVisible(false)}
                onComplete={() => setLoadErrorVisible(false)}
                title={"Error"}
            >
                {formLoadErrorContent}
            </Modal>
            <Modal
                size={DialogSize.SM}
                primaryButton={"OK"}
                secondaryButton={null}
                visible={validationErrorVisible}
                onHide={() => setValidationErrorVisible(false)}
                onComplete={() => setValidationErrorVisible(false)}
                title={"Validation error"}
            >
                {formValidationErrorContent}
            </Modal>
        </>
    );
}

// An interface representing the values in the form.
interface RequestDemoFormValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string;
    companyType: string;
    message: string;
    country: string;
    usState: string;
    caProvince: string;
    commPreference: string;
}

interface RequestDemoFormProps {
    showFormLoadError: () => unknown;
    showFormValidationError: () => unknown;
    setDisableFormSubmit: (_: boolean) => void;
}

const requestDemoFormId = 1784;

const companyTypeOptions = [
    "Law Firm",
    "Corporation",
    "Service/Technology Provider",
    "State/Local Government",
    "Federal Government",
    "Journalist",
    "Non-Profit",
    "Education",
    "Other",
];

// keeping this array here because it is very specific to the Marketo Form
const commPrefCountries = [
    // Non-EU Countries
    "United Kingdom",
    "Iceland",
    "Liechtenstein",
    "Switzerland",
    "Norway",
    "Argentina",
    "Australia",
    "Brazil",
    "Canada",
    "Chile",
    "Colombia",
    "India",
    "Israel",
    "Japan",
    "Malaysia",
    "Morocco",
    "New Zealand",
    "Philippines",
    "South Africa",
    "South Korea",
    "Taiwan",

    // EU Countries
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
];

function RequestDemoForm({
    showFormLoadError,
    showFormValidationError,
    setDisableFormSubmit,
}: RequestDemoFormProps): ReactNode {
    const [companyTypeValue, setCompanyTypeValue] = useState<string | undefined>(undefined);
    const [countryValue, setCountryValue] = useState<string | undefined>(undefined);
    const [usStateValue, setUsStateValue] = useState<string | undefined>(undefined);
    const [caProvinceValue, setCaProvinceValue] = useState<string | undefined>(undefined);
    const [isUsSelected, setIsUsSelected] = useState<boolean>(false);
    const [isCaSelected, setIsCaSelected] = useState<boolean>(false);
    const [showCommPreferences, setShowCommPreferences] = useState<boolean>(false);
    const [commPreferencesValue, setCommPreferencesValue] = useState<boolean | undefined>(
        undefined,
    );

    const usStateRef = useRef<HTMLInputElement>(null);
    const caProvinceRef = useRef<HTMLInputElement>(null);
    const commPrefRef = useRef<HTMLInputElement>(null);

    const formResult: UseFormResult<RequestDemoFormValues> = useForm<RequestDemoFormValues>({
        initialValues: {
            firstName: User.me?.firstName ?? "",
            lastName: User.me?.lastName ?? "",
            email: User.me?.email ?? "",
            phone: "",
            companyName: "",
            companyType: "",
            message: "",
            country: "",
            usState: "",
            caProvince: "",
            commPreference: "",
        },
        validator: {
            firstName: textValidator({
                name: "firstName",
                required: true,
                requiredFieldErrorMessage: requiredMsg,
            }),
            lastName: textValidator({
                name: "lastName",
                required: true,
                requiredFieldErrorMessage: requiredMsg,
            }),
            email: emailValidator({
                name: "email",
                required: true,
                incorrectValueErrorMessage: "Invalid email",
                requiredFieldErrorMessage: requiredMsg,
            }),
            phone: phoneValidator({
                name: "phone",
                required: true,
                incorrectValueErrorMessage: "Invalid phone number",
                requiredFieldErrorMessage: requiredMsg,
            }),
            companyName: textValidator({
                name: "companyName",
                required: true,
                requiredFieldErrorMessage: requiredMsg,
            }),
        },
        onSubmit: () => {
            const formValues = formResult.values;
            const myForm = MktoForms2.getForm(requestDemoFormId);
            if (!myForm) {
                // Marketo form failed to load.
                showFormLoadError();
                return;
            }
            myForm.setValues({
                FirstName: formValues.firstName,
                LastName: formValues.lastName,
                Email: formValues.email,
                Phone: formValues.phone,
                Company: formValues.companyName,
                // ignoring eslint because this name is from a 3rd party API
                // eslint-disable-next-line camelcase
                Company_Type__c: formValues.companyType,
                // ignoring eslint because this name is from a 3rd party API
                // eslint-disable-next-line camelcase
                Inbound_Message__c: formValues.message,
                Country: formValues.country,
                StateCode: formValues.usState
                    ? usStates[formValues.usState]
                    : formValues.caProvince
                      ? caProvinces[formValues.caProvince]
                      : "",
                communicationsPreferences: formValues.commPreference,
            });
            if (!myForm.validate()) {
                // Marketo form validation failed even though Everlaw validation passed.
                showFormValidationError();
                // Reset values of fields that may have caused the error.
                formValues.phone = "";
                formValues.email = User.me?.email ?? "";
                return;
            }
            myForm.submit();
        },
    });

    const {
        dropdownProps: companyDropdownProps,
        filterText: companyFilterText,
        onItemClick: companyOnItemClick,
        isItemSelected: companyIsItemSelected,
    } = DropdownMenu.useSingle({
        placeholder: "Select...",
        filterable: true,
        required: true,
        value: companyTypeValue,
        setValue: setCompanyTypeValue,
    });

    const companyItems = companyTypeOptions
        .filter((label) => {
            if (!companyFilterText) {
                return true;
            }
            return Str.containsIgnoreCase(label, companyFilterText);
        })
        .map((label) => (
            <DropdownMenu.Option
                key={label}
                label={label}
                selected={companyIsItemSelected(label)}
                onClick={() => companyOnItemClick(label)}
            />
        ));

    const {
        dropdownProps: countryDropdownProps,
        filterText: countryFilterText,
        onItemClick: countryOnItemClick,
        isItemSelected: countryIsItemSelected,
    } = DropdownMenu.useSingle({
        placeholder: "Select...",
        filterable: true,
        required: true,
        value: countryValue,
        setValue: setCountryValue,
    });

    const countryItems = Country.all()
        .filter((label) => {
            if (!countryFilterText) {
                return true;
            }
            return Str.containsIgnoreCase(label.id, countryFilterText);
        })
        .map((label) => {
            const name = label.id;
            return (
                <DropdownMenu.Option
                    key={name}
                    label={name}
                    selected={countryIsItemSelected(name)}
                    onClick={() => countryOnItemClick(name)}
                />
            );
        });

    const {
        dropdownProps: usStateDropdownProps,
        filterText: usStateFilterText,
        onItemClick: usStateOnItemClick,
        isItemSelected: usStateIsItemSelected,
    } = DropdownMenu.useSingle({
        placeholder: "Select...",
        filterable: true,
        required: true,
        value: usStateValue,
        setValue: setUsStateValue,
    });

    const usStateItems = Object.keys(usStates)
        .filter((stateName) => {
            if (!usStateFilterText) {
                return true;
            }
            return Str.containsIgnoreCase(stateName, usStateFilterText);
        })
        .map((stateName) => {
            return (
                <DropdownMenu.Option
                    label={stateName}
                    selected={usStateIsItemSelected(stateName)}
                    onClick={() => usStateOnItemClick(stateName)}
                    key={stateName}
                />
            );
        });

    const usStateDropdownComponent = (
        <DropdownMenu
            {...usStateDropdownProps}
            label={"State"}
            width={TextFieldWidth.FULL}
            ref={usStateRef}
        >
            {usStateItems}
        </DropdownMenu>
    );

    const {
        dropdownProps: caProvinceDropdownProps,
        filterText: caProvinceFilterText,
        onItemClick: caProvinceOnItemClick,
        isItemSelected: caProvinceIsItemSelected,
    } = DropdownMenu.useSingle({
        placeholder: "Select...",
        filterable: true,
        required: true,
        value: caProvinceValue,
        setValue: setCaProvinceValue,
    });

    const caProvinceItems = Object.keys(caProvinces)
        .filter((provinceName) => {
            if (!caProvinceFilterText) {
                return true;
            }
            return Str.containsIgnoreCase(provinceName, caProvinceFilterText);
        })
        .map((provinceName) => {
            return (
                <DropdownMenu.Option
                    label={provinceName}
                    selected={caProvinceIsItemSelected(provinceName)}
                    onClick={() => caProvinceOnItemClick(provinceName)}
                    key={provinceName}
                />
            );
        });

    const caProvinceDropdownComponent = (
        <DropdownMenu
            {...caProvinceDropdownProps}
            label={"Province"}
            width={TextFieldWidth.FULL}
            ref={caProvinceRef}
        >
            {caProvinceItems}
        </DropdownMenu>
    );

    const commPreferencesComponent = (
        <>
            <div className={"communication-preferences"} ref={commPrefRef}>
                <div>
                    <H1.ExtraSmall>Communications preferences</H1.ExtraSmall>
                </div>
                <div>
                    <Paragraph>
                        Our{" "}
                        <Link href={"https://www.everlaw.com/privacy/"} newTab={true}>
                            Privacy Notice
                        </Link>{" "}
                        explains how Everlaw collects uses and discloses information when you use or
                        access our websites and/or product. Everlaw protects and secures your data
                        and will never sell your personal information to other companies for
                        marketing purposes.
                    </Paragraph>
                </div>

                <div>
                    <Paragraph>
                        Everlaw would like to contact you in the future via email or phone with
                        updates, news, and other relevant information. You may unsubscribe at any
                        time.
                    </Paragraph>
                </div>

                <div>
                    <Checkbox
                        label={"Yes, I would like to receive relevant updates via email or phone"}
                        value={commPreferencesValue}
                        onChange={() => setCommPreferencesValue((selected) => !selected)}
                    />
                </div>
            </div>
        </>
    );

    useEffect(() => {
        if (formResult && companyTypeValue) {
            formResult.change("companyType", companyTypeValue);
        }
    }, [companyTypeValue]);

    useEffect(() => {
        if (formResult && countryValue) {
            formResult.change("country", countryValue);

            // We want to clear out the state/province field if user is switching between countries
            // in order to avoid sending Marketo incorrect state/province values
            setUsStateValue(undefined);
            setCaProvinceValue(undefined);
            switch (countryValue) {
                case Country.fromIso("US").id:
                    setIsUsSelected(true);
                    setIsCaSelected(false);
                    break;
                case Country.fromIso("CA").id:
                    setIsCaSelected(true);
                    setIsUsSelected(false);
                    break;
                default:
                    setIsUsSelected(false);
                    setIsCaSelected(false);
            }

            // We also want to show the Communication Preferences if needed
            if (commPrefCountries.includes(countryValue)) {
                setShowCommPreferences(true);
                setCommPreferencesValue(false);
            } else {
                setShowCommPreferences(false);
                setCommPreferencesValue(undefined);
            }
        }
    }, [countryValue]);

    useEffect(() => {
        if (isUsSelected && usStateRef.current) {
            usStateRef.current.scrollIntoView({ behavior: `smooth` });
        }
    }, [isUsSelected]);

    useEffect(() => {
        if (isCaSelected && caProvinceRef.current) {
            caProvinceRef.current.scrollIntoView({ behavior: `smooth` });
        }
    }, [isCaSelected]);

    useEffect(() => {
        if (showCommPreferences && commPrefRef.current) {
            commPrefRef.current.scrollIntoView({ behavior: `smooth` });
        }
    }, [showCommPreferences]);

    useEffect(() => {
        if (formResult && usStateValue) {
            // If a US state is selected, then set the Marketo form value
            formResult.change("usState", usStateValue);
        } else if (formResult) {
            // else, empty out the Marketo form value
            formResult.change("usState", "");
        }
    }, [usStateValue]);

    useEffect(() => {
        if (formResult && caProvinceValue) {
            // If a CA province is selected, then set the Marketo form value
            formResult.change("caProvince", caProvinceValue);
        } else if (formResult) {
            // else, empty out the Marketo form value
            formResult.change("caProvince", "");
        }
    }, [caProvinceValue]);

    useEffect(() => {
        // If the Communication Preference is updated...
        if (formResult && typeof commPreferencesValue !== "undefined") {
            // to a defined value, we set the Marketo form value to it's corresponding value
            formResult.change("commPreference", commPreferencesValue ? "Yes" : "No");
        } else {
            // to undefined, then we empty out the Marketo form value
            // this handles the case where a user selects a country that does
            // require comm preferences, then unselects it
            formResult.change("commPreference", "");
        }
    }, [commPreferencesValue]);

    useEffect(() => {
        if (
            (formResult && Object.keys(formResult.errors).length)
            || countryValue === undefined
            || (countryValue === Country.fromIso("US").id && usStateValue === undefined)
            || (countryValue === Country.fromIso("CA").id && caProvinceValue === undefined)
        ) {
            setDisableFormSubmit(true);
        } else {
            setDisableFormSubmit(false);
        }
    }, [formResult.errors, countryValue, usStateValue, caProvinceValue]);

    return (
        <Form onSubmit={formResult.submit} id={"request-demo-form"}>
            <div className={"bb-form__main"}>
                <div className={"names-section"}>
                    <TextField
                        name={"firstName"}
                        value={formResult.values.firstName}
                        error={formResult.blurred.firstName && !!formResult.errors.firstName}
                        errorMessage={formResult.errors.firstName}
                        width={TextFieldWidth.FULL}
                        horizontal={false}
                        onBlur={(e) => formResult.blur("firstName")}
                        onChange={(e) => formResult.change("firstName", e.target.value)}
                        label={"First name"}
                        required={true}
                        className={"name-section"}
                    />
                    <TextField
                        name={"lastName"}
                        value={formResult.values.lastName}
                        error={formResult.blurred.lastName && !!formResult.errors.lastName}
                        errorMessage={formResult.errors.lastName}
                        width={TextFieldWidth.FULL}
                        horizontal={false}
                        onBlur={(e) => formResult.blur("lastName")}
                        onChange={(e) => formResult.change("lastName", e.target.value)}
                        label={"Last name"}
                        required={true}
                        className={"name-section"}
                    />
                </div>
                <TextField
                    name={"email"}
                    value={formResult.values.email}
                    error={formResult.blurred.email && !!formResult.errors.email}
                    errorMessage={formResult.errors.email}
                    horizontal={false}
                    width={TextFieldWidth.FULL}
                    onBlur={(e) => formResult.blur("email")}
                    onChange={(e) => formResult.change("email", e.target.value)}
                    label={"Work email address"}
                    placeholder={"Enter email"}
                    required={true}
                />
                <TextField
                    name={"phone"}
                    value={formResult.values.phone}
                    error={formResult.blurred.phone && !!formResult.errors.phone}
                    errorMessage={formResult.errors.phone}
                    horizontal={false}
                    width={TextFieldWidth.FULL}
                    onBlur={(e) => formResult.blur("phone")}
                    onChange={(e) => formResult.change("phone", e.target.value)}
                    label={"Phone number"}
                    placeholder={"Enter phone number"}
                    required={true}
                />
                <TextField
                    name={"companyName"}
                    value={formResult.values.companyName}
                    error={formResult.blurred.companyName && !!formResult.errors.companyName}
                    errorMessage={formResult.errors.companyName}
                    width={TextFieldWidth.FULL}
                    horizontal={false}
                    onBlur={(e) => formResult.blur("companyName")}
                    onChange={(e) => formResult.change("companyName", e.target.value)}
                    label={"Company name"}
                    placeholder={"Enter company name"}
                    required={true}
                />
                <DropdownMenu
                    {...companyDropdownProps}
                    label={"Company type"}
                    width={TextFieldWidth.FULL}
                >
                    {companyItems}
                </DropdownMenu>
                <TextArea
                    name={"message"}
                    value={formResult.values.message}
                    error={formResult.blurred.message && !!formResult.errors.message}
                    errorMessage={formResult.errors.message}
                    horizontal={false}
                    onBlur={(e) => formResult.blur("message")}
                    onChange={(e) => formResult.change("message", e.target.value)}
                    label={"Message"}
                    placeholder={
                        "Give us any details you would like us to know about before your demo session"
                    }
                    required={false}
                />
                <DropdownMenu
                    {...countryDropdownProps}
                    label={"Country"}
                    width={TextFieldWidth.FULL}
                >
                    {countryItems}
                </DropdownMenu>
                {isUsSelected ? usStateDropdownComponent : null}
                {isCaSelected ? caProvinceDropdownComponent : null}
                {showCommPreferences ? commPreferencesComponent : null}
            </div>
        </Form>
    );
}

interface CreateRequestDemoModalProps {
    isButton: boolean;
    isModalVisible: boolean;
    onModalClose: () => void;
}

export function CreateRequestDemoForm({
    isButton,
    isModalVisible,
    onModalClose,
}: CreateRequestDemoModalProps) {
    const [modalVisible, setModalVisible] = useState(isModalVisible ?? false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [loadErrorVisible, setLoadErrorVisible] = useState(false);
    const [validationErrorVisible, setValidationErrorVisible] = useState(false);
    const [disableFormSubmit, setDisableFormSubmit] = useState(false);

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        onModalClose();
    };

    useEffect(() => {
        // this allows other components (where we don't want the button) to open the form modal
        if (isModalVisible) {
            openModal();
        }
    }, [isModalVisible]);

    const showFormLoadError = () => {
        setLoadErrorVisible(true);
    };
    const showFormValidationError = () => {
        setValidationErrorVisible(true);
    };
    const newForm = (
        <RequestDemoForm
            showFormLoadError={showFormLoadError}
            showFormValidationError={showFormValidationError}
            setDisableFormSubmit={setDisableFormSubmit}
        />
    );
    formOnSuccessCallback = () => {
        if (modalVisible) {
            closeModal();
            if (!confirmationVisible) {
                setConfirmationVisible(true);
            }
        }
        ga_event("MarketoForm", "Request a demo request");
        return false;
    };
    // Load marketo form if there is no form on the current page everytime the form dialog is opened.
    // If Marketo form load error is showed to the user, try loading the form again.
    useEffect(() => {
        const myForm = MktoForms2.getForm(requestDemoFormId);
        if (!myForm) {
            MktoForms2.loadForm(
                "//go.everlaw.com",
                "314-QPM-328",
                requestDemoFormId.toString(),
                (myForm) => {
                    if (myForm) {
                        myForm.onSuccess(() => formOnSuccessCallback());
                    }
                },
            );
        }
    }, [modalVisible, loadErrorVisible]);

    const formContent = (
        <>
            <TextBanner>
                <div className={"request-demo-banner-content"}>
                    <div>
                        <CommonIcon.SBBERestricted size={"20px"} />
                    </div>
                    <div>
                        <Paragraph>
                            To access all features, submit a demo request. An Everlaw representative
                            will reach out to you shortly using the information below.
                        </Paragraph>
                    </div>
                </div>
            </TextBanner>
            {newForm}
        </>
    );
    const successfulContent = (
        <Paragraph>
            Thanks for submitting your request! An Everlaw team member will reach out shortly.
        </Paragraph>
    );
    const formLoadErrorContent = (
        <Paragraph>
            An unexpected error occurred when submitting the request. Please try again. If the issue
            persists, please contact Everlaw support for assistance.
        </Paragraph>
    );
    const formValidationErrorContent = (
        <Paragraph>
            Your request could not be submitted due to validation error. Please check the email or
            phone number fields and try again.
        </Paragraph>
    );
    /** This button uses an id rather than a className for styling because it needs a more specific
     *   selector to overwrite the design system's button styling. */
    const buttonComponent = (
        <Button
            id={"request-demo-button"}
            icon={<CommonIcon.SBBERestricted />}
            children={"Request a demo"}
            size={ButtonSize.SMALL}
            width={ButtonWidth.FIXED}
            onClick={openModal}
        />
    );
    return (
        <>
            {isButton ? buttonComponent : null}
            <Modal
                size={DialogSize.MD}
                primaryButton={
                    <FormSubmitButton
                        form={"request-demo-form"}
                        children={"Submit"}
                        disabled={disableFormSubmit}
                    />
                }
                secondaryButton={"Cancel"}
                visible={modalVisible}
                baseZIndex={999}
                dismissable={false}
                onHide={closeModal}
                onCancel={closeModal}
                className={"marketo-form-modal"}
                title={"Request a demo"}
            >
                {formContent}
            </Modal>
            <Modal
                size={DialogSize.SM}
                primaryButton={"Done"}
                secondaryButton={null}
                visible={confirmationVisible}
                onHide={() => setConfirmationVisible(false)}
                onComplete={() => setConfirmationVisible(false)}
                className={"marketo-form-modal"}
                title={"Request a demo"}
            >
                {successfulContent}
            </Modal>
            <Modal
                size={DialogSize.SM}
                primaryButton={"OK"}
                secondaryButton={null}
                visible={loadErrorVisible}
                onHide={() => setLoadErrorVisible(false)}
                onComplete={() => setLoadErrorVisible(false)}
                title={"Error"}
            >
                {formLoadErrorContent}
            </Modal>
            <Modal
                size={DialogSize.SM}
                primaryButton={"OK"}
                secondaryButton={null}
                visible={validationErrorVisible}
                onHide={() => setValidationErrorVisible(false)}
                onComplete={() => setValidationErrorVisible(false)}
                title={"Validation error"}
            >
                {formValidationErrorContent}
            </Modal>
        </>
    );
}
