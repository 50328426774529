import { useLatest } from "hooks/useLatest";
import { RefCallback, RefObject, useCallback, useMemo, useRef } from "react";
import { ExcludeValues, StringLiteral } from "core";

type MultiValueRefAssignmentCallback<K extends string, V> = (key: K) => RefCallback<V>;
interface MultiValueRefObjectViewMixin<K extends string, V> {
    view: K[];
    withView: (view: K[]) => MultiValueRefObject<K, V>;
}

export type MultiValueRefObject<K extends string, V> = ExcludeValues<
    RefObject<Record<K, V | null>>,
    null
> &
    MultiValueRefAssignmentCallback<K, V> &
    MultiValueRefObjectViewMixin<K, V>;

/**
 * A hook for using a single ref for multiple values. Useful when you want to pass an unbound
 * set of values to a component or another hook. Given a record with defined keys and values of
 * a single type, will return a ref to a record with the same keys and values. The returned value
 * is also a function that, when passed a key of the map, will return a {@code RefCallback} which
 * assigns the value in the record with the given key to the given value.
 *
 * In addition to the standard {@code current} attribute of the ref (which contains the
 * aforementioned record), it will also contain a {@code view} attribute. This attribute
 * determines the subset of keys of the record that should be accessed in a given context, and in
 * what order. The {@code view} is initially determined by the second parameter of this hook. If
 * not provided, the {@code view} will default to all keys in the provided record, in an
 * effectively random order. Additionally, a copy of the returned ref with a different {@code view}
 * can be created, which will refer to the same value internally, using the {@code withView}
 * function on the returned ref.
 *
 * Example:
 * const mvRef = useMultiValueRef({ example: null, otherExample: null }, ["otherExample"]);
 * useFocusTrap(mvRef.withView(["example", "otherExample"]), true);
 * <span ref={mvRef("example")} />
 *
 * The above will put the span element in the "example" key's location in the map. Within the
 * call to useFocusTrap, only the keys ["example", "otherExample"] will be used, and in that order,
 * whereas elsewhere within this function, only the key ["otherExample"] will be used (unless a
 * different call is made to {@code withView}).
 *
 * @param initialValue The initial value to load in the record. Usually just full of null values,
 *  but all keys must be specified.
 * @param initialView The initial view to use for this MultiValueRefView. If not provided, will
 *  just be all keys of {@code initialValue} in an effectively random order.
 */
export function useMultiValueRef<K extends string, V>(
    // Using a StringLiteral here guarantees that we can't create a multi value ref with arbitrary
    // keys
    initialValue: Record<StringLiteral<K>, V | null>,
    initialView?: K[],
): MultiValueRefObject<K, V> {
    // Using latest because we only care about the order on first call
    const viewRef = useLatest((initialView || Object.keys(initialValue)) as K[]);
    const ref: ExcludeValues<RefObject<Record<K, V | null>>, null> = useRef({
        ...initialValue,
    } as Record<K, V | null>);
    const withView = useCallback(
        (view: K[]) =>
            Object.assign((key: K) => (value: V | null) => (ref.current[key] = value), ref, {
                view,
                withView,
            }),
        [ref],
    );
    return useMemo(() => withView(viewRef.current), [withView, viewRef]);
}

/**
 * Utility function to tell a MultiValueRefObject apart from a RefObject.
 */
export function isMultiValueRef<K extends string, E>(
    ref: RefObject<E> | MultiValueRefObject<K, E>,
): ref is MultiValueRefObject<K, E> {
    if (typeof ref !== "function") {
        return false;
    }
    return (
        "current" in ref
        && "view" in ref
        && Array.isArray(ref.view)
        && "withView" in ref
        && typeof ref.withView === "function"
    );
}

/**
 * Utility function to convert a {@code (RefObject<E> | MultiValueRefObject<any, E>)} to a
 * {@code E[]} containing either the current value of the given {@code RefObject<E>}, or all the
 * current values of the given {@code MultiValueRefObject<any, E>}.
 */
export function refObjectValues<K extends string, E>(
    ref: RefObject<E> | MultiValueRefObject<K, E>,
): (E | null)[] {
    return isMultiValueRef(ref) ? ref.view.map((v) => ref.current[v]) : [ref.current];
}
