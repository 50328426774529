import Base = require("Everlaw/Base");
import { Compare as Cmp } from "core";
import NameStatus = require("Everlaw/UI/Upload/Metadata/NameStatus");
import Type_type = require("Everlaw/Type");

// Name similarity being equal this means a non-canonical name must have occurred 2 times more
// often than a canonical name before we will prefer the non-canonical name. The hope is to stilt
// name guessing strongly in favor of canonical and existing field names.
// These values should be reduced if canonical names are often being assigned to fields they
// shouldn't be assigned to.
const CANONICAL_PREFERRED_RATIO = 1.5;
const EXISTING_PREFERRED_RATIO = 1.25;

// Each NameAnalysis is completely unique, so we can give them arbitrary unique IDs.
let id = 1;
class NameAnalysis extends Base.Object {
    get className() {
        return "NameAnalysis";
    }
    constructor(
        public name: string,
        public count: number,
        public similarity: number,
        private type: Type_type.FieldType,
        private nameRestrictions: NameStatus.NameRestriction[],
    ) {
        super({ id: id++ });
        if (this.count < 0) {
            throw new Error("NameAnalysis with negative count not allowed");
        }
    }
    override display() {
        return this.name;
    }
    /**
     * Checks for restrictions on this type and name. Returns the NameStatus describing the
     * restriction (or lack thereof).
     */
    nameStatus() {
        return NameStatus.of(this.type, this.nameRestrictions);
    }
    /**
     * Returns a count proportional to `log(1 + this.count)`, but which takes similarity into account.
     */
    logCount() {
        // The squared factor is a hedge against giving a high score to a disimilar name with a high
        // count (otherwise we'd value common names much too highly). Another approach would be to
        // have a similarity threshold below which the effective count is 0 (but choosing an
        // appropriate threshold isn't easy).
        //
        // If disimilar names start to receive good scores it may be necessary to adjust the relative
        // weighting of `count` vs `similarity`.
        return Math.log(1 + this.count) * this.similarity * this.similarity;
    }
    /**
     * Returns an effective count proportional to `this.count`, but which takes similarity into account.
     * This is a reasonable measure of how common this name choice has been historically.
     */
    effectiveCount() {
        return this.count * this.similarity * this.similarity;
    }
    score() {
        const ns = this.nameStatus();
        if (ns.canonical) {
            return CANONICAL_PREFERRED_RATIO * this.logCount();
        }
        if (!ns.new) {
            return EXISTING_PREFERRED_RATIO * this.logCount();
        }
        return this.logCount();
    }
    getColor() {
        return this.nameStatus().color;
    }
    override compare(other: NameAnalysis) {
        // Exact matches first (we've actually mapped in the suggested way historically), then
        // similar names ordered with better/larger scores first.
        return (
            (other.similarity === 1 ? 1 : 0) - (this.similarity === 1 ? 1 : 0)
            || other.score() - this.score()
            || Cmp.strCI(this.name, other.name)
            || <number>this.id - <number>other.id
        );
    }
}

export { NameAnalysis };
