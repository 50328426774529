import { useBrandedCallback } from "hooks/useBranded";
import { useEventListener } from "hooks/useEventListener";
import { MultiValueRefObject, refObjectValues } from "hooks/useMultiValueRef";
import { filterNonNullish } from "core";
import { getFocusableElements } from "util/dom";
import { RefObject } from "react";

/**
 * A hook that allows for trapping of focus within a certain HTML container. It assumes that focus
 * needs to be trapped within one HTML Element that contains multiple focusable elements.
 *
 * @param containerReference a reference to the HTML element that focus needs to be trapped in.
 * use useRef to get this reference from a React node.
 * @param active whether to trap focus or not
 */
export function useFocusTrap(
    // Disabling no explicit any for the key type, since we only care about the values
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    containerReference: RefObject<HTMLElement> | MultiValueRefObject<any, HTMLElement>,
    active: boolean,
): void {
    const eventHandler = useBrandedCallback(
        (event: Event) => {
            if (!active) {
                return;
            }
            const elements = filterNonNullish(refObjectValues(containerReference));
            if (
                elements.length === 0
                || !(event instanceof KeyboardEvent)
                || event.key !== "Tab"
                || !document.activeElement
            ) {
                return;
            }
            const focusableElements = getFocusableElements(elements);
            if (focusableElements.length === 0) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            let focusedIndex = focusableElements.indexOf(document.activeElement);
            if (focusedIndex === -1) {
                // If the currently focused element isn't detected as a focusable element,
                // add it to focusableElements in the correct position so that the correct next
                // element can be focused.
                focusableElements.push(document.activeElement);
                focusableElements.sort((a, b) => {
                    return a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_PRECEDING ? 1 : -1;
                });
                focusedIndex = focusableElements.indexOf(document.activeElement);
            }
            const newIndex =
                (focusedIndex + focusableElements.length + (event.shiftKey ? -1 : 1))
                % focusableElements.length;
            (focusableElements[newIndex] as HTMLElement).focus();
        },
        [active, containerReference],
    );
    useEventListener(containerReference, "keydown", eventHandler);
}
