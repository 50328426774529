import Base = require("Everlaw/Base");
import { Str } from "core";

class TrainingActivity extends Base.Object {
    static AdminCaseSetup = TrainingActivity.init("AdminCaseSetup");
    static AdminTraining = TrainingActivity.init("AdminTraining", "OD Admin Training");
    static AssignmentsAndPC = TrainingActivity.init(
        "AssignmentsAndPC",
        "Assignments and Predictive Coding",
    );
    static CaseConfiguration = TrainingActivity.init("CaseConfiguration");
    static Certified = TrainingActivity.init("Certified");
    static DocumentReview = TrainingActivity.init("DocumentReview");
    static DSAR = TrainingActivity.init("DSAR");
    static EarlyCaseAssessment = TrainingActivity.init("EarlyCaseAssessment");
    static ECA = TrainingActivity.init("ECA", "ECA Legacy");
    static EverlawIntroduction = TrainingActivity.init("EverlawIntroduction", undefined, true);
    static FifteenMinute = TrainingActivity.init("FifteenMinute", "15 Minute");
    static InCertificationPipeline = TrainingActivity.init(
        "InCertificationPipeline",
        undefined,
        true,
    );
    static IntroToEverlaw = TrainingActivity.init("IntroToEverlaw", "Intro to Everlaw");
    static LegalHolds = TrainingActivity.init("LegalHolds", "Legal Holds");
    static MiscTraining = TrainingActivity.init("MiscTraining");
    static NativeDataLoading = TrainingActivity.init("NativeDataLoading");
    static OrgAdminTraining = TrainingActivity.init("OrgAdminTraining");
    static ProcessedUploading = TrainingActivity.init(
        "ProcessedUploading",
        "Processed Data Loading",
    );
    static Productions = TrainingActivity.init("Productions");
    static QAndA = TrainingActivity.init("QAndA", "Q&A");
    static ReleaseTraining = TrainingActivity.init("ReleaseTraining");
    static ReviewerTraining = TrainingActivity.init("ReviewerTraining", "OD Reviewer Training");
    static Search = TrainingActivity.init("Search");
    static SentPassiveMaterials = TrainingActivity.init("SentPassiveMaterials", undefined, true);
    static Storybuilder = TrainingActivity.init("Storybuilder");
    static TrainedByPartner = TrainingActivity.init("TrainedByPartner", undefined, true);
    static VisualizingYourData = TrainingActivity.init("VisualizingYourData");
    private static init(id: string, name = Str.camelToHuman(id), inactive = false) {
        const ta = new TrainingActivity(id, name, inactive);
        Base.add(ta);
        return ta;
    }
    get className() {
        return "TrainingActivity";
    }
    override id: string;
    name: string;
    inactive: boolean;
    /**
     * Inactive TrainingActivities can no longer be associated with Users or Invitations via the
     * Projects and Users tab. However, if someone completed an inactive TrainingActivity
     * in the past, then it will still be displayed as expected. This is currently only
     * enforced by hiding the options from the selection widget.
     */
    protected constructor(id: string, name: string, inactive: boolean) {
        super({ id });
        this.name = name;
        this.inactive = inactive;
    }
    override display() {
        return this.name;
    }
}

export { TrainingActivity };
