import clsx from "clsx";
import { ButtonSize, TextButton } from "components/Button";
import { CheckboxValue } from "components/Checkbox";
import {
    BaseListBox,
    ListBoxSize,
    OptionId,
    SharedListBoxProps,
} from "components/Menu/ListBox/BaseListBox";
import { SelectionSummary, SelectionSummaryProps } from "components/Menu/ListBox/SelectionSummary";
import { Memo, useBrandedCallback, useBrandedState } from "hooks/useBranded";
import React, { cloneElement, ReactElement, useMemo, useState } from "react";
import * as ListBoxTokens from "tokens/typescript/ListBoxTokens";
import * as SpacingTokens from "tokens/typescript/SpacingTokens";
import * as TextButtonTokens from "tokens/typescript/TextButtonTokens";
import * as TextInputTokens from "tokens/typescript/TextInputTokens";
import { getSelectedItemIndices } from "core";
import { getSizePx } from "util/css";
import { FlattenedSection, getItemUtils } from "./ListBoxUtil";

export interface MultiListBoxProps<T extends OptionId = OptionId> extends SharedListBoxProps<T> {
    /**
     * The set of selected options.
     */
    selected: Memo<Set<T>>;
    /**
     * The function to call when one or more options have been selected/unselected.
     */
    onSelect: Memo<(options: T[], value: CheckboxValue.TRUE | CheckboxValue.FALSE) => void>;
    /**
     * The function to call when the user clicks the "Select none" button.
     */
    onSelectNone: () => void;
    /**
     * The selection summary to display to the right of the listbox. If a different layout
     * is needed, don't use this prop and just place a separate
     * {@link MultiListBox.SelectionSummary} where needed.
     */
    summary?: ReactElement<SelectionSummaryProps<T>>;
}

export function MultiListBox<T extends OptionId = OptionId>({
    className,
    height,
    selected,
    onSelect,
    onSelectNone,
    items,
    filterValue: filterValueProp,
    setFilterValue: setFilterValueProp,
    summary,
    disabled,
    ...props
}: MultiListBoxProps<T>) {
    const [filterValueInternal, setFilterValueInternal] = useBrandedState<string>("");
    const filterValue =
        typeof filterValueProp === "string" && setFilterValueProp
            ? filterValueProp
            : filterValueInternal;
    const setFilterValue = setFilterValueProp || setFilterValueInternal;

    const { optionOrder, ...itemUtilsResult } = useMemo(() => {
        return getItemUtils(items, filterValueInternal);
    }, [filterValueInternal, items]);
    const filteredSections = itemUtilsResult.filteredSections as Memo<FlattenedSection<T>[]>;
    const optionNames = itemUtilsResult.optionNames as Memo<Set<string>>;

    const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null);
    const onOptionClick = useBrandedCallback(
        (optionId: T, e: Event, index: number) => {
            window.getSelection()?.removeAllRanges();
            if (e instanceof MouseEvent && e.shiftKey && lastSelectedIndex !== null) {
                e.preventDefault();
                if (index !== lastSelectedIndex) {
                    const selectedOptionIds = getSelectedItemIndices(index, lastSelectedIndex).map(
                        (i) => optionOrder[i],
                    );
                    const value = selected.has(optionOrder[lastSelectedIndex])
                        ? CheckboxValue.TRUE
                        : CheckboxValue.FALSE;
                    onSelect(selectedOptionIds, value);
                }
            } else {
                onSelect(
                    [optionId],
                    selected.has(optionId) ? CheckboxValue.FALSE : CheckboxValue.TRUE,
                );
            }
            setLastSelectedIndex(index);
        },
        [lastSelectedIndex, onSelect, optionOrder, selected],
    );

    if (summary) {
        let maxHeight =
            height === ListBoxSize.SMALL
                ? getSizePx(ListBoxTokens.HEIGHT_SMALL)
                : getSizePx(ListBoxTokens.HEIGHT_LARGE);
        maxHeight +=
            getSizePx(TextInputTokens.HEIGHT_LARGE) // Filter input
            + getSizePx(TextButtonTokens.HEIGHT_SMALL) // Select all/none buttons
            + getSizePx(SpacingTokens.SCALE_100); // Space above select all/none buttons
        summary = cloneElement(summary, {
            maxHeight: maxHeight + "px",
        });
    }

    return (
        <div
            className={clsx(className, "bb-multi-list-box", {
                "bb-multi-list-box--with-summary": summary,
            })}
        >
            <div className={"bb-multi-list-box__list-box-wrapper"}>
                <BaseListBox
                    {...props}
                    height={height}
                    sections={filteredSections as Memo<FlattenedSection<T>[]>}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    selected={selected}
                    optionNames={optionNames}
                    onOptionClick={onOptionClick}
                    isMulti={true}
                    selectedInputDisplay={selected.size ? `(${selected.size} selected)` : ""}
                    disabled={disabled}
                />
                <div className={"bb-multi-list-box__batch-select-buttons"}>
                    <TextButton
                        size={ButtonSize.SMALL}
                        disabled={disabled}
                        onClick={() => {
                            onSelect(
                                optionOrder.filter((optionId) => !selected.has(optionId)),
                                CheckboxValue.TRUE,
                            );
                        }}
                    >
                        Select all
                    </TextButton>
                    /
                    <TextButton size={ButtonSize.SMALL} disabled={disabled} onClick={onSelectNone}>
                        Select none
                    </TextButton>
                </div>
            </div>
            {summary}
        </div>
    );
}

MultiListBox.SelectionSummary = SelectionSummary;
