import array = require("dojo/_base/array");
import Argument = require("Everlaw/Argument");
import Base = require("Everlaw/Base");
import Binder = require("Everlaw/Binder");
import Chronology = require("Everlaw/Chron/Chronology");
import Code = require("Everlaw/Code");
import { Is } from "core";
import Document = require("Everlaw/Document"); // Circular dependency - use for types only
import Freeform = require("Everlaw/Freeform");
import Metadata = require("Everlaw/Metadata");
import Rating = require("Everlaw/Rating");
import Type = require("Everlaw/Type");
import { parseUTC } from "Everlaw/ProjectDateUtil";

class DocumentMutator {
    notes: DocumentMutator.NoteContent[] = [];
    removeNotes: DocumentMutator.NoteContent[] = [];
    isRemoveAllNotes: boolean = null;
    codes: { [id: string]: boolean } = {};
    binders: { [id: string]: boolean } = {};
    metadata: { [id: string]: any } = {};
    freeformCode: { [id: string]: any } = {};
    rating: Rating = null;
    conflictResolved: boolean = null;
    arguments: { [id: string]: boolean } = {};
    chronologies: { [id: string]: DocumentMutator.ChronologyMutatorParams } = {};
    autoCoded: boolean = null;
    constructor(mutator?: any) {
        if (mutator) {
            const isArr = Is.array(mutator);
            const mutations: any[] = isArr ? mutator : mutator.mutations;
            mutations.forEach((m) => {
                const added = m[1] !== "REMOVE";
                const d = m[2];
                switch (m[0]) {
                    case DocumentMutator.Notes:
                        if (m[1] === "ADD") {
                            this.notes.push(d[0]);
                        } else if (m[1] === "REMOVE_BY_TEXT") {
                            this.removeNotes.push(d[0]);
                        } else if (m[1] === "REMOVE_ALL") {
                            this.isRemoveAllNotes = true;
                        }
                        break;
                    case DocumentMutator.Metadata:
                        this.metadata[d[0]] = added ? d[1] : null;
                        break;
                    case DocumentMutator.Codes:
                        this.codes[d[0]] = !!added;
                        break;
                    case DocumentMutator.Binders:
                        this.binders[d[0]] = !!added;
                        break;
                    case DocumentMutator.Rating:
                        this.rating = Base.get(Rating, d[0]);
                        break;
                    case DocumentMutator.ResolveConflict:
                        this.conflictResolved = true;
                        break;
                    case DocumentMutator.Argument:
                        this.arguments[d[0]] = !!added;
                        break;
                    case DocumentMutator.Chronology:
                        this.chronologies[d[0][0]] = {
                            added: !!added,
                            dateField: null,
                            dateVal: null,
                            nameField: null,
                        };
                        break;
                    case DocumentMutator.FreeformCodes:
                        this.freeformCode[d[0].codeId] = d[0].val || d[0].value;
                        break;
                    default:
                        return;
                }
            });
        }
    }
    /**
     * Apply the given visitor functions to this mutator. It's possible the visitor functions won't
     * be called at all, e.g. if relevant entities aren't loaded in Base.
     *
     * @param fSet: the set of visitor functions to apply to the mutator.
     * @param excludeRedundantRemoves: should we exclude redundant removes from the callback?
     *
     * NOTE: excludeRedundantRemoves currently has no effect. The logic in the CODES setter was
     * removed here:
     * https://github.com/Everlaw/servers/commit/d41a2148
     * as part of this issue:
     * https://trello.com/c/wu8SurLc/43-batch-modify-removed-codes-from-mutually-exclusive-categories-are-not-shown-in-the-dialog-box
     *
     * It's unclear to me (Jamal) if this should have only been removed for one workflow (i.e., one
     * call to apply() should have set excludeRedundantRemoves to false) or if this is proper
     * behavior now and excludeRedundantRemoves should be removed altogether. For now I'm keeping it
     * in case we figure out the removal needs to be re-added in certain cases.
     *
     * Redundant removes only happen for mutually exclusive categories - if X,Y and Z are mutually
     * exclusive, adding X will also automatically remove Y and Z, but for the sake of display we
     * might not want to show the two removals (since they are implied by the addition of X, and
     * the backend will remove them when X is added even if they aren't included explicitly).
     * If you're updating a document's state with this mutator you'll want to include them (so it's
     * clear they've been removed) but if you are e.g. displaying a preset you might not.
     */
    apply(fSet: DocumentMutator.Visitor, excludeRedundantRemoves = false) {
        // Auto code should always be applied first, as some implementors of the visitor need to
        // know up front if the mutator is auto-coded
        if (fSet.AUTO_CODE) {
            // eslint-disable-next-line new-cap
            fSet.AUTO_CODE(this.autoCoded);
        }
        if (fSet.RATING && this.rating !== null) {
            // eslint-disable-next-line new-cap
            fSet.RATING(this.rating);
        }
        if (fSet.RESOLVE_CONFLICT && this.conflictResolved) {
            // eslint-disable-next-line new-cap
            fSet.RESOLVE_CONFLICT();
        }
        if (fSet.NOTES) {
            array.forEach(this.notes, (note) => {
                // eslint-disable-next-line new-cap
                fSet.NOTES(note);
            });
        }
        if (fSet.REMOVE_NOTES) {
            array.forEach(this.removeNotes, (note) => {
                // eslint-disable-next-line new-cap
                fSet.REMOVE_NOTES(note);
            });
        }
        if (fSet.REMOVE_ALL_NOTES) {
            if (this.isRemoveAllNotes) {
                // eslint-disable-next-line new-cap
                fSet.REMOVE_ALL_NOTES();
            }
        }
        if (fSet.CODES) {
            // NOTE: Logic for handling excludeRedundantRemoves was removed here:
            // https://github.com/Everlaw/servers/commit/d41a2148
            Object.entries(this.codes).forEach(([codeId, added]) => {
                const code = Base.get(Code, codeId);
                if (code) {
                    // eslint-disable-next-line new-cap
                    fSet.CODES(code, added);
                }
            });
        }
        if (fSet.TAGS) {
            Object.entries(this.binders).forEach(([binderId, added]) => {
                const binder = Base.get(Binder, binderId);
                if (binder) {
                    // eslint-disable-next-line new-cap
                    fSet.TAGS(binder, added);
                }
            });
        }
        if (fSet.METADATA) {
            Object.entries(this.metadata).forEach(([fieldId, val]) => {
                const field = Base.get(Metadata.Field, fieldId);
                if (field) {
                    // eslint-disable-next-line new-cap
                    fSet.METADATA(field, val);
                }
            });
        }
        if (fSet.FREEFORM_CODE) {
            Object.entries(this.freeformCode).forEach(([codeId, val]) => {
                const code = Base.get(Freeform.Code, codeId);
                if (code) {
                    // eslint-disable-next-line new-cap
                    fSet.FREEFORM_CODE(code, !!val, val);
                }
            });
        }
        if (fSet.ARGUMENT) {
            Object.entries(this.arguments).forEach(([argumentId, added]) => {
                const argument = Base.get(Argument, argumentId);
                if (argument) {
                    // eslint-disable-next-line new-cap
                    fSet.ARGUMENT(argument, added);
                }
            });
        }
        if (fSet.CHRONOLOGY) {
            Object.entries(this.chronologies).forEach(([chronologyId, added]) => {
                const chronology = Base.get(Chronology, chronologyId);
                if (chronology) {
                    // eslint-disable-next-line new-cap
                    fSet.CHRONOLOGY(chronology, added);
                }
            });
        }
    }
    toJSON() {
        // And now we come full circle with serialization...
        return JSON.stringify(this.serialize(true));
    }
    serialize(withProject?: boolean): DocumentMutator.MutationSerialization {
        const argSet: DocumentMutator.Visitor = {};
        const addRemove = function (e: Base.Object, added: boolean) {
            return [added ? "ADD" : "REMOVE", [e.id]];
        };
        argSet.TAGS = addRemove;
        argSet.CODES = addRemove;
        argSet.METADATA = function (field, val) {
            return [val === null ? "REMOVE" : "SET", [field.id, val]];
        };
        argSet.FREEFORM_CODE = (code: Freeform.Code, added: boolean, val: any) => {
            return [added ? "ADD" : "REMOVE", [{ codeId: code.id, val: val }]];
        };
        argSet.RATING = function (rating) {
            return ["SET", [rating.id]];
        };
        argSet.NOTES = function (note) {
            return ["ADD", [note]];
        };
        argSet.REMOVE_NOTES = function (note) {
            return ["REMOVE_BY_TEXT", [note]];
        };
        argSet.REMOVE_ALL_NOTES = function () {
            return ["REMOVE_ALL", [null]];
        };
        argSet.RESOLVE_CONFLICT = function () {
            return ["SET", [null]];
        };
        argSet.ARGUMENT = addRemove;
        argSet.CHRONOLOGY = function (
            e: Base.Object,
            params: DocumentMutator.ChronologyMutatorParams,
        ) {
            return [
                params.added ? "ADD" : "REMOVE",
                [[e.id, params.dateField, params.dateVal, params.nameField]],
            ];
        };
        const mutations: unknown[][] = [];
        const newMap: { [domain: string]: Function } = {};
        Object.entries(<{ [domain: string]: Function }>argSet).forEach(([type, func]) => {
            newMap[type] = function () {
                if (type === "REMOVE_NOTES" || type === "REMOVE_ALL_NOTES") {
                    type = "NOTES";
                }
                mutations.push([type].concat(func.apply(null, arguments)));
            };
        });
        this.apply(newMap);
        const wrapped: DocumentMutator.MutationSerialization = {
            autoCoded: this.autoCoded === null ? true : this.autoCoded,
            mutations,
        };
        return wrapped;
    }
    // Uses the mutator contents to serialize as opposed to the Base Store. Trusts the objects
    // exist for the given project. Meant to be used if mutator objects stored are for a project
    // outside of the current project context.
    serializeUnsecured(): DocumentMutator.MutationSerialization {
        const mutations: any[][] = [];
        const added = (toAdd: boolean) => {
            return toAdd ? "ADD" : "REMOVE";
        };
        if (this.rating !== null) {
            mutations.push(["RATING", "SET", [this.rating.id]]);
        }
        this.notes.forEach((note) => {
            mutations.push(["NOTES", "ADD", [note]]);
        });
        if (this.conflictResolved) {
            mutations.push(["RESOLVE_CONFLICT", "SET", [null]]);
        }
        Object.entries(this.codes).forEach(([codeIdStr, toAdd]) => {
            mutations.push(["CODES", added(toAdd), [parseInt(codeIdStr)]]);
        });
        Object.entries(this.metadata).forEach(([metadataIdStr, value]) => {
            mutations.push([
                "METADATA",
                value === null ? "REMOVE" : "SET",
                [parseInt(metadataIdStr), value],
            ]);
        });
        Object.entries(this.binders).forEach(([binderIdStr, toAdd]) => {
            mutations.push(["TAGS", added(toAdd), [parseInt(binderIdStr)]]);
        });
        Object.entries(this.freeformCode).forEach(([codeIdStr, value]) => {
            mutations.push([
                "FREEFORM_CODE",
                added(value),
                [{ codeId: parseInt(codeIdStr), val: value }],
            ]);
        });
        Object.entries(this.arguments).forEach(([argIdStr, toAdd]) => {
            mutations.push(["ARGUMENT", added(toAdd), [parseInt(argIdStr)]]);
        });
        Object.entries(this.chronologies).forEach(([chronIdStr, params]) => {
            mutations.push([
                "CHRONOLOGY",
                added(params.added),
                [[parseInt(chronIdStr), params.dateField, params.dateVal, params.nameField]],
            ]);
        });
        return { autoCoded: this.autoCoded === null ? true : this.autoCoded, mutations: mutations };
    }
    // Append another mutator onto this one.
    appendMutator(other: DocumentMutator) {
        if (other.rating !== null) {
            this.rating = other.rating;
        }
        if (other.conflictResolved !== null) {
            this.conflictResolved = other.conflictResolved;
        }
        if (other.autoCoded !== null) {
            this.autoCoded = other.autoCoded;
        }
        this.notes = this.notes.concat(other.notes);
        this.removeNotes = this.removeNotes.concat(other.removeNotes);
        Object.assign(this.codes, other.codes);
        Object.assign(this.binders, other.binders);
        Object.assign(this.metadata, other.metadata);
        Object.assign(this.arguments, other.arguments);
        Object.assign(this.chronologies, other.chronologies);
        Object.assign(this.freeformCode, other.freeformCode);
        return this;
    }
    addNote(content: string) {
        this.notes.push({ text: content });
        return this;
    }
    removeNote(content: string): DocumentMutator {
        this.removeNotes.push({ text: content });
        return this;
    }
    removeAllNotes(): DocumentMutator {
        this.isRemoveAllNotes = true;
        return this;
    }
    updateFreeformCodeValue(code: Freeform.Code, val: string | Type.DateTime) {
        if (code.getType() === Type.DATE_TIME && Is.string(val)) {
            val = Type.dateOnly(parseUTC(val, "/").millis);
        }
        this.freeformCode[code.id] = val;
        return this;
    }
    removeFreeformCodeValue(code: Freeform.Code) {
        this.freeformCode[code.id] = null;
        return this;
    }
    removeProjectMetadataValue(field: Metadata.Field) {
        this.metadata[field.id] = null;
        return this;
    }
    updateProjectMetadataValue(field: Metadata.Field, val: any) {
        this.metadata[field.id] = val;
        return this;
    }
    addCode(code: Code) {
        this.codes[code.id] = true;
        if (code.getCategory() && code.getCategory().mutuallyExclusive) {
            // This is a mutually exclusive code. Make sure that the mutator removes any of its
            // siblings (this is also handled on the back-end - doing it here just simplifies state
            // management).
            code.getCategory().codes.forEach((c) => {
                if (c.id !== code.id) {
                    this.removeCode(c);
                }
            });
        }
        return this;
    }
    removeCode(code: Code) {
        this.codes[code.id] = false;
        return this;
    }
    addToBinder(binder: Binder) {
        this.binders[binder.id] = true;
        return this;
    }
    removeFromBinder(binder: Binder) {
        this.binders[binder.id] = false;
        return this;
    }
    removeRating() {
        return this.setRating(Rating.Unrated);
    }
    setRating(rating: Rating) {
        this.rating = rating;
        return this;
    }
    resolveConflict() {
        this.conflictResolved = true;
        return this;
    }
    addToArgument(argument: Argument) {
        this.arguments[argument.id] = true;
        return this;
    }
    removeFromArgument(argument: Argument) {
        this.arguments[argument.id] = false;
        return this;
    }
    addToChronology(chronology: Chronology) {
        this.chronologies[chronology.id] = {
            added: true,
            dateField: null,
            dateVal: null,
            nameField: null,
        };
        return this;
    }
    removeFromChronology(chronology: Chronology) {
        this.chronologies[chronology.id] = {
            added: false,
            dateField: null,
            dateVal: null,
            nameField: null,
        };
        return this;
    }
    setAutoCode(value: boolean) {
        this.autoCoded = value;
        return this;
    }
    isEmpty() {
        return (
            this.notes.length === 0
            && this.removeNotes.length === 0
            && this.isRemoveAllNotes === null
            && this.rating === null
            && !this.conflictResolved
            && this.isEmptyOrDeleted("Code", this.codes)
            && this.isEmptyOrDeleted("Binder", this.binders)
            && this.isEmptyOrDeleted("MetadataField", this.metadata)
            && this.isEmptyOrDeleted("Argument", this.arguments)
            && this.isEmptyOrDeleted("FreeformCode", this.freeformCode)
            && this.isEmptyOrDeleted("Chronology", this.chronologies)
        );
    }
    // A mutator should be considered empty if it contains only deleted objects
    private isEmptyOrDeleted(obj: string, mutator: Record<string, any>) {
        const keys = Object.keys(mutator);
        return keys.length === 0 || keys.every((key) => !Base.get(obj, key));
    }
    // Note: Only tested for rating, codes, binders, and notes because this function is currently
    // only used for comparing coding presets in the review window
    equals(other: DocumentMutator) {
        if (JSON.stringify(other.notes) !== JSON.stringify(this.notes)) {
            return false;
        }
        if (JSON.stringify(other.removeNotes) !== JSON.stringify(this.removeNotes)) {
            return false;
        }
        if (other.isRemoveAllNotes !== this.isRemoveAllNotes) {
            return false;
        }
        if (JSON.stringify(other.codes) !== JSON.stringify(this.codes)) {
            return false;
        }
        if (JSON.stringify(other.binders) !== JSON.stringify(this.binders)) {
            return false;
        }
        if (JSON.stringify(other.metadata) !== JSON.stringify(this.metadata)) {
            return false;
        }
        if (
            !!this.rating !== !!other.rating
            || (!!this.rating && !!other.rating && !!this.rating.compare(other.rating))
        ) {
            return false;
        }
        if (this.conflictResolved !== other.conflictResolved) {
            return false;
        }
        if (JSON.stringify(other.arguments) !== JSON.stringify(this.arguments)) {
            return false;
        }
        if (JSON.stringify(other.chronologies) !== JSON.stringify(this.chronologies)) {
            return false;
        }
        return other.autoCoded === this.autoCoded;
    }
    // Would applying the given mutator to the existing document/mutator pair not change anything?
    // This ignores notes in that you can always repeat notes (we could try and be smart here about
    // looking for notes by the current user with the same content, but right now notes aren't included
    // as mutations on the review page anyway).
    isEigensystem(document: Document, otherMutator: DocumentMutator) {
        if (this.rating !== null) {
            const otherRating = otherMutator.rating || document.rating;
            if (otherRating !== this.rating) {
                return false;
            }
        }
        for (const code in this.codes) {
            const added = this.codes[code];
            if (
                (code in otherMutator.codes
                    ? otherMutator.codes[code]
                    : document.codes.indexOf(<Code.Id>+code) > -1) !== added
            ) {
                return false;
            }
        }
        for (const binder in this.binders) {
            const added = this.binders[binder];
            if (
                (binder in otherMutator.binders
                    ? otherMutator.binders[binder]
                    : document.binders.indexOf(<Binder.Id>+binder) > -1) !== added
            ) {
                return false;
            }
        }
        for (const field in this.metadata) {
            const val = this.metadata[field];
            let curr: any;
            if (field in otherMutator.metadata) {
                curr = otherMutator.metadata[field];
            } else {
                curr = field in document.metadata ? document.metadata[field].value : null;
            }
            if (curr !== val) {
                return false;
            }
        }
        return true;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module DocumentMutator {
    export interface Visitor {
        CODES?(c: Code, added: boolean): void;
        TAGS?(t: Binder, added: boolean): void;
        RATING?(r: Rating): void;
        METADATA?(f: Metadata.Field, val: any): void;
        FREEFORM_CODE?(c: Freeform.Code, added: boolean, val: any): void;
        NOTES?(n: NoteContent): void;
        REMOVE_NOTES?(n: NoteContent): void;
        REMOVE_ALL_NOTES?(): void;
        RESOLVE_CONFLICT?(): void;
        ARGUMENT?(a: Argument, added: boolean): void;
        CHRONOLOGY?(c: Chronology, params: ChronologyMutatorParams): void;
        AUTO_CODE?(state: boolean): void;
    }

    export interface ChronologyMutatorParams {
        added: boolean;
        // The metadata field is the field to use to populate the story document's date.
        dateField: Metadata.FieldId;
        // The raw date as a long to populate the story document's date. dateField and dateLong
        // are mutually exclusive, so at least one should be null.
        dateVal: { lower: number; precision: number };
        // The metadata field used to rename the story documents.
        nameField: Metadata.FieldId;
    }

    export interface NoteContent {
        text: string;
        pageNum?: number;
    }

    export interface MutationSerialization {
        mutations: any[][];
        autoCoded: boolean;
    }

    export const Codes = "CODES";
    export const Binders = "TAGS";
    export const Rating = "RATING";
    export const Metadata = "METADATA";
    export const Notes = "NOTES";
    export const ResolveConflict = "RESOLVE_CONFLICT";
    export const Argument = "ARGUMENT";
    export const Chronology = "CHRONOLOGY";
    export const FreeformCodes = "FREEFORM_CODE";
}

export = DocumentMutator;
