/**
 * Common definitions used across the Storybuilder Page.
 */
import ChatMessage = require("Everlaw/Chat/ChatMessage");
import ChatRoom = require("Everlaw/Chat/ChatRoom");
import ChronTask = require("Everlaw/Chron/ChronologyTask");
import Chronology = require("Everlaw/Chron/Chronology");
import { PubSubChannel } from "Everlaw/PubSubChannel";
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");
import Type = require("Everlaw/Type");
import User = require("Everlaw/User");
import { objectToQuery } from "core";

/** Params for a generic Storybuilder widget. */
export interface WidgetParams {
    node: HTMLElement;
    chron: Chronology;
}

/** Core channels used for notifications or action triggering. */
export const CHANNEL = {
    onInitialTaskSubscription: new PubSubChannel<ChronTask.EntityType>(),
    onChatSubscription: new PubSubChannel<{
        entityType: ChatRoom.EntityType;
        subInfo: ChatRoom.SubscriptionInfo[];
    }>(),
    onNewChatMessage: new PubSubChannel<ChatMessage>(),
    filterDocsByChronDate: new PubSubChannel<Type.DateTimeSearch>(),
    sortAllByDateAdded: new PubSubChannel<{}>(),
};

/** Interface used by tab managers on the Storybuilder page */
export interface TabManager {
    node: HTMLElement;
    onShow?: (initial: boolean) => void;
    onHide?: () => void;
}

export function logGAEvent(action: string) {
    ga_event("Storybuilder", action);
}

export function canWriteChron(chron: Chronology) {
    return User.me.can(Perm.WRITE, chron, User.Override.ELEVATED_OR_ORGADMIN);
}

export function canAdminChron(chron: Chronology) {
    return User.me.can(Perm.ADMIN, chron, User.Override.ELEVATED_OR_ORGADMIN);
}

export function canShareChron(chron: Chronology) {
    return User.me.can(Perm.ADMIN, chron, User.Override.ELEVATED_OR_ORGADMIN);
}

export enum TabId {
    dashboard = "sb-dash",
    timeline = "timeline",
    depos = "depos",
    drafts = "drafts",
}

export type UrlHash = {
    chronId: string;
    tabId?: string;
    testimonyFilter?: string;
    bates?: string;
};

export interface UrlParams {
    chronId: number;
    tabId?: TabId;
    testimonyFilter?: number;
    bates?: string;
}

export const ALL_TESTIMONY_FILTER = -1;

export function buildUrl(params: UrlParams) {
    return Project.CURRENT.url("chron.do#" + objectToQuery({ ...params }));
}

export function hashToParams(hash: UrlHash) {
    const params: UrlParams = { chronId: +hash.chronId };
    if (hash.testimonyFilter) {
        params.testimonyFilter = +hash.testimonyFilter;
    }
    if (hash.bates) {
        try {
            params.bates = decodeURIComponent(hash.bates);
        } catch (e) {}
    }
    if (hash.tabId && hash.tabId in TabId) {
        params.tabId = (<any>TabId)[hash.tabId];
    }
    return params;
}
