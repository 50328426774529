import clsx from "clsx";
import { IconProps } from "components/Icon/IconProps";
import { Tooltip, TooltipPlacement, TooltipProps } from "components/Tooltip";
import { Num } from "core";
import { everIdProp } from "EverAttribute/EverId";
import { useCombinedRef } from "hooks/useCombinedRef";
import React, { cloneElement, forwardRef, ReactElement, useId, useRef } from "react";
import { EverIdProp, FFC } from "util/type";
import "./Counter.scss";

export enum CounterColor {
    DEFAULT = "default",
    WARN = "warn",
    TRANSPARENT = "transparent",
}

export interface CounterProps extends EverIdProp {
    /**
     * A non-negative number to display on the counter. If {@link isPercent} is false, then
     * it will be truncated to a maximum of four total characters.
     */
    children: number;
    /**
     * An optional icon to add to the left hand side of the counter.
     *
     * Its size will be overridden to 16px.
     */
    icon?: ReactElement<IconProps>;
    /**
     * An optional class name to apply to the counter.
     */
    className?: string;
    /**
     * The background color of the counter. Default {@link CounterColor.DEFAULT}.
     *
     * DEFAULT: gray
     * WARN: yellow
     * TRANSPARENT: translucent white (should only be used on buttons)
     */
    color?: CounterColor;
    /**
     * Whether the background color behind the counter is white. If false, non-transparent
     * counters will use a slightly darker color for contrast purposes.
     * Default true.
     */
    hasWhiteBackground?: boolean;
    /**
     * Whether a percent sign should be added at the end. If true, the value will be rounded to
     * the nearest integer. Default false.
     */
    isPercent?: boolean;
    /**
     * A tooltip to render on the counter. If not provided and the counter content is truncated,
     * a tooltip will be rendered with the full formatted number.
     */
    tooltip?: ReactElement<TooltipProps>;
}

const DEFAULT_TOOLTIP_PLACEMENT = [
    TooltipPlacement.TOP,
    TooltipPlacement.TOP_START,
    TooltipPlacement.TOP_END,
    TooltipPlacement.BOTTOM,
    TooltipPlacement.BOTTOM_START,
    TooltipPlacement.BOTTOM_END,
    TooltipPlacement.LEFT,
    TooltipPlacement.RIGHT,
];

/**
 * A small counter tag with a truncated representation of the given number.
 */
export const Counter: FFC<HTMLSpanElement, CounterProps> = forwardRef<
    HTMLSpanElement,
    CounterProps
>(
    (
        {
            children,
            icon,
            className,
            color = "default",
            hasWhiteBackground = true,
            isPercent = false,
            tooltip,
            everId,
        },
        ref,
    ) => {
        const internalRef = useRef<HTMLSpanElement>(null);
        const tooltipId = useId();
        const combinedRef = useCombinedRef(ref, internalRef);
        const counterClassName = clsx(
            "bb-counter",
            `bb-counter--${color}`,
            { "bb-counter--dark-background": !hasWhiteBackground },
            className,
        );
        let display: string;
        tooltip &&= cloneElement(tooltip, {
            id: tooltipId,
            target: internalRef,
            placement: tooltip?.props.placement || DEFAULT_TOOLTIP_PLACEMENT,
        });
        if (isPercent) {
            display = Math.round(children) + "%";
        } else {
            display = Num.displayNumber(children, {
                decimalPlaces: 1,
                useMetricUnit: false,
                uppercase: false,
                collapseDecimalZero: true,
            });
            if (!tooltip && display !== children.toString()) {
                tooltip = (
                    <Tooltip
                        id={tooltipId}
                        target={internalRef}
                        placement={DEFAULT_TOOLTIP_PLACEMENT}
                    >
                        {Num.toString(children)}
                    </Tooltip>
                );
            }
        }
        if (icon) {
            icon = React.cloneElement(icon, {
                className: clsx(icon.props.className, "bb-counter__icon"),
                size: 16,
                "aria-hidden": true,
            });
        }
        return (
            <>
                <span
                    ref={combinedRef}
                    className={counterClassName}
                    aria-describedby={tooltip ? tooltipId : undefined}
                    tabIndex={tooltip ? 0 : undefined}
                    {...everIdProp(everId)}
                >
                    {icon}
                    {display}
                </span>
                {tooltip}
            </>
        );
    },
);
Counter.displayName = "Counter";
