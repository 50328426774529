import Rest = require("Everlaw/Rest");
import {
    BasicChip,
    Button as ReactButton,
    ButtonColor,
    ButtonSize,
    Confirmation,
    DialogSize,
    H4,
    Icon,
    IconButton,
    Link,
    Paragraph,
    Radio,
    RadioGroup,
    ToggleSwitch,
} from "design-system";
import { Organization, SamlInfo, SamlState } from "Everlaw/Organization";
import * as React from "react";
import { ReactNode, useEffect, useRef, useState } from "react";

interface AuthenticationSettingPanelProps {
    org: Organization;
    authSelected: SamlState | undefined;
    setSamlInfo: React.Dispatch<React.SetStateAction<SamlInfo | undefined>>;
}

export function AuthenticationSettingPanel({
    org,
    authSelected,
    setSamlInfo,
}: AuthenticationSettingPanelProps): ReactNode {
    function handleAuthSettingsChange(samlState: SamlState) {
        Rest.post("setSsoState.rest", {
            org: org.id,
            ssoState: samlState,
        }).then((newSamlInfo: SamlInfo) => {
            setSamlInfo(newSamlInfo);
        });
    }

    return (
        <form className={"authentication-setting"}>
            <div>
                <RadioGroup
                    horizontal={true}
                    header={
                        <Paragraph.Default className={"margin-right-24"}>
                            Authentication setting
                        </Paragraph.Default>
                    }
                    twoColumn={true}
                >
                    <Radio
                        name={"AuthenticationSettingsRadio"}
                        value={authSelected === SamlState.DISABLED}
                        label={"Off"}
                        onChange={() => {
                            handleAuthSettingsChange(SamlState.DISABLED);
                        }}
                    />
                    <Radio
                        name={"AuthenticationSettingsRadio"}
                        value={authSelected === SamlState.REQUIRED}
                        label={"Required"}
                        onChange={() => {
                            handleAuthSettingsChange(SamlState.REQUIRED);
                        }}
                    />
                    <Radio
                        name={"AuthenticationSettingsRadio"}
                        value={authSelected === SamlState.OPTIONAL}
                        label={"Optional"}
                        onChange={() => {
                            handleAuthSettingsChange(SamlState.OPTIONAL);
                        }}
                    />
                </RadioGroup>
            </div>
        </form>
    );
}

export interface SamlPanelProps {
    includeHeader: boolean;
    org: Organization;
    samlInfo?: SamlInfo;
}

export function SamlPanel({
    includeHeader,
    org,
    samlInfo: samlInfoProp,
}: SamlPanelProps): ReactNode {
    const [samlInfo, setSamlInfo] = useState<SamlInfo | undefined>(samlInfoProp);
    const [showDeleteSAML, setShowDeleteSAML] = useState(false);
    const [showBypassMFA, setShowBypassMFA] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setSamlInfo(samlInfoProp);
    }, [samlInfoProp]);

    useEffect(() => {
        if (!org) {
            return;
        }
        Rest.get("getSamlInfo.rest", {
            org: org.id,
        }).then((newSamlInfo: SamlInfo) => {
            setSamlInfo(newSamlInfo);
        });
    }, [org]);

    return (
        <div>
            {includeHeader && <H4>SAML single sign-on</H4>}
            {samlInfo && (
                <div className={"margin-bottom-8"}>
                    <Link
                        href={
                            "/saml2/service-provider-metadata/"
                            + base64UrlSafeEncode(samlInfo.entityId)
                        }
                    >
                        Download Everlaw service provider metadata
                    </Link>
                </div>
            )}
            <div className={"flex-centered"}>
                {samlInfo ? (
                    <>
                        <Paragraph>Metadata uploaded</Paragraph>
                        <BasicChip
                            className={"margin-left-8"}
                            rightButton={
                                <IconButton
                                    aria-label={"Delete metadata"}
                                    onClick={() => {
                                        setShowDeleteSAML(true);
                                    }}
                                >
                                    <Icon.X />
                                </IconButton>
                            }
                        >
                            {samlInfo.updateTime}
                        </BasicChip>
                    </>
                ) : (
                    <Paragraph>Upload the metadata XML for your identity provider</Paragraph>
                )}
                <Confirmation
                    size={DialogSize.MD}
                    visible={showDeleteSAML}
                    title={"Delete SAML metadata"}
                    primaryButton={"Submit"}
                    onComplete={() => {
                        Rest.post("deleteSamlMetadata.rest", {
                            org: org.id,
                        }).then((newSamlInfo: SamlInfo) => {
                            setSamlInfo(newSamlInfo);
                        });
                        setShowDeleteSAML(false);
                    }}
                    onCancel={() => {
                        setShowDeleteSAML(false);
                    }}
                    onHide={() => {
                        setShowDeleteSAML(false);
                    }}
                >
                    <Paragraph>
                        {"Deleting the metadata will disable single sign-on for "
                            + org.name
                            + ". Are you sure you want to do this?"}
                    </Paragraph>
                </Confirmation>
                <div className={"padding-left-16"}>
                    <ReactButton
                        size={ButtonSize.SMALL}
                        color={samlInfo ? ButtonColor.DANGER : ButtonColor.SECONDARY}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        {samlInfo ? "Replace" : "Upload"}
                    </ReactButton>
                    <input
                        ref={fileInputRef}
                        className={"hidden"}
                        type={"file"}
                        accept={"text/xml"}
                        onChange={() => {
                            if (!fileInputRef.current?.files?.length) {
                                return;
                            }
                            const reader = new FileReader();
                            reader.onload = () => {
                                Rest.post("updateSamlMetadata.rest", {
                                    org: org.id,
                                    content: reader.result,
                                }).then((newSamlInfo: SamlInfo) => {
                                    setSamlInfo(newSamlInfo);
                                });
                            };
                            reader.readAsText(fileInputRef.current.files[0], "utf-8");
                        }}
                    />
                </div>
            </div>
            {samlInfo && (
                <>
                    <AuthenticationSettingPanel
                        org={org}
                        authSelected={samlInfo?.state}
                        setSamlInfo={setSamlInfo}
                    />
                    <ToggleSwitch
                        value={samlInfo?.mfaBypassEnabled}
                        label={"Bypass Everlaw multifactor authentication for authenticated users"}
                        onChange={() => {
                            if (!samlInfo) {
                                return;
                            }
                            if (!samlInfo.mfaBypassEnabled) {
                                setShowBypassMFA(true);
                            } else {
                                Rest.post("toggleMfaBypass.rest", {
                                    org: org.id,
                                    bypassEnabled: false,
                                }).then((newSamlInfo: SamlInfo) => {
                                    setSamlInfo(newSamlInfo);
                                });
                            }
                        }}
                    />
                    <Confirmation
                        size={DialogSize.MD}
                        visible={showBypassMFA}
                        title={"Bypass Everlaw multi-factor authentication"}
                        onComplete={() => {
                            Rest.post("toggleMfaBypass.rest", {
                                org: org.id,
                                bypassEnabled: true,
                            }).then((newSamlInfo: SamlInfo) => {
                                setSamlInfo(newSamlInfo);
                            });
                            setShowBypassMFA(false);
                        }}
                        onCancel={() => setShowBypassMFA(false)}
                        onHide={() => setShowBypassMFA(false)}
                    >
                        <Paragraph>
                            You should only bypass Everlaw multi-factor authentication if your
                            identity provider also provides MFA. Are you sure you want to do this?
                        </Paragraph>
                    </Confirmation>
                </>
            )}
        </div>
    );
}

// This must be consistent with base64UrlSafeEncode in SAMLUtils.java!!
export function base64UrlSafeEncode(str: string) {
    return btoa(str).replace("/", "_").replace("+", "-");
}
