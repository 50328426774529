import { FFC } from "util/type";

import {
    Checkbox as CheckboxWithHiddenProps,
    CheckboxProps as CheckboxPropsWithHiddenProps,
    CheckboxValue,
} from "./Checkbox";
type CheckboxProps = Omit<
    CheckboxPropsWithHiddenProps,
    | "isMenuCheckbox"
    | "nestingLevel"
    | "rightContent"
    | "rightContentWidth"
    | "role"
    | "tabFocusable"
>;
const Checkbox: FFC<HTMLInputElement, CheckboxProps> = CheckboxWithHiddenProps;
export { Checkbox, CheckboxValue };
export type { CheckboxProps };

export * from "./CheckboxGroup";
