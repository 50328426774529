import * as TypographyTokens from "tokens/typescript/TypographyTokens";
import { wrap } from "core";

let canvas: HTMLCanvasElement | undefined = undefined;

/**
 * Computes the width of the given text with the given font style, if a font style is provided,
 * or in the given element, if an element is provided.
 *
 * @param text the text to calculate the width of
 * @param fontStyleOrElement the font style, or the element to pull the font style from, to
 * calculate the width
 */
export function getTextWidth(text: string, fontStyleOrElement: string | HTMLElement): number {
    if (fontStyleOrElement instanceof HTMLElement) {
        fontStyleOrElement = getCanvasFontStyle(fontStyleOrElement);
    }
    canvas ||= document.createElement("canvas");
    if (!(canvas.getContext && canvas.getContext("2d"))) {
        // Canvas does not exist when running on node
        return -1;
    }
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    context.font = fontStyleOrElement;
    return context.measureText(text).width;
}

function getCanvasFontStyle(element: HTMLElement): string {
    const style = window.getComputedStyle(element);
    const fontWeight = style.fontWeight || TypographyTokens.WEIGHT_NORMAL;
    const fontSize = style.fontSize || TypographyTokens.BODY_SIZE_MEDIUM;
    const fontFamily = style.fontFamily || TypographyTokens.STACK;
    return `${fontWeight} ${fontSize} ${fontFamily}`;
}

/**
 * String containing selectors for focusable elements within a Document or Element. Can be passed
 * to `querySelectorAll`.
 */
const FOCUSABLE_ELEMENT_SELECTORS = [
    "a[href]",
    "button",
    "input",
    "select",
    "textarea",
    "[tabindex]",
    "[contenteditable=true]",
].map((s) => s + ":not([disabled]):not([hidden])");

function focusableElementSelectors(
    excludeSelectors: string[] = [],
    tabbableElementsOnly = true,
    excludeChildren = false,
): string {
    const excluded = excludeSelectors
        .map((s) => {
            let selector = `:not(${s})`;
            if (excludeChildren) {
                selector += `:not(${s} *)`;
            }
            return selector;
        })
        .join();
    const selectors = tabbableElementsOnly
        ? FOCUSABLE_ELEMENT_SELECTORS.map((s) => s + ':not([tabindex="-1"])')
        : FOCUSABLE_ELEMENT_SELECTORS;
    return selectors.map((s) => `${s}${excluded}`).join(", ");
}

export interface GetFocusableElementsProps {
    /**
     * An array of CSS selectors to exclude from the focusable elements query.
     *
     * Defaults to [].
     */
    excludeSelectors?: string[];
    /**
     * Whether to exclude children of elements captured by the {@link excludeSelectors}.
     *
     * Defaults to false.
     */
    excludeChildren?: boolean;
    /**
     * Whether to filter out focusable elements hidden by CSS that would normally
     * remove them from the tabbing order.
     *
     * Defaults to false.
     */
    filterHidden?: boolean;
    /**
     * Whether to only include tabbable elements.
     *
     * Defaults to true.
     */
    tabbableElementsOnly?: boolean;
}

/**
 * Returns an array of focusable elements within the provided container in document/DOM order.
 * Enabling `filterHidden` will better emulate the actual list of focusable elements used by
 * browsers (i.e. it will filter out elements with styles that remove them from the real tabbing
 * order), but it will come at a performance cost.
 * @param container Container to get focusable elements from.
 */
export function getFocusableElements(
    container: Document | Element | Element[],
    {
        excludeSelectors = [],
        excludeChildren = false,
        filterHidden = false,
        tabbableElementsOnly = true,
    }: GetFocusableElementsProps = {},
): Element[] {
    const querySelector = focusableElementSelectors(
        excludeSelectors,
        tabbableElementsOnly,
        excludeChildren,
    );
    const queryResult: Element[] = wrap(container)
        .map((e) => Array.from(e.querySelectorAll(querySelector)))
        .flat();
    if (!filterHidden) {
        return queryResult;
    }
    return queryResult.filter((el) => {
        const takesUpSpace = !!(
            (el instanceof HTMLElement && (el?.offsetWidth || el?.offsetHeight))
            || el.getClientRects().length
        );
        const style = getComputedStyle(el);
        const hasHiddenStyling = style.visibility === "hidden" || style.display === "none";
        return takesUpSpace && !hasHiddenStyling;
    });
}

/**
 * Given an Element, checks whether it is in a focus-visible state.
 * @param element Element to check the focus-visible state for.
 */
export function hasFocusVisible(element: Element | null | undefined): boolean {
    try {
        return element?.matches(":focus-visible") || false;
    } catch (e: unknown) {
        // :focus-visible not supported.
        // TODO: remove this fallback when jsdom or nswapi fixes the bug preventing use of
        //  focus-visible selector. Currently returns true for unit tests.
        return true;
    }
}

/**
 * React's typing for the HTML inert property
 * (https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert) is messed up, but
 * this allows you to circumvent this issue. To use, simply spread the result of this function
 * into the props for your element, e.g. {@code <div {...inert(isInert)} />}.
 *
 * In most cases, <b>disabled should be used instead</b>. In some cases, such as non-disableable
 * elements like divs being used as buttons, disabled does nothing, and inert must be used instead.
 */
export function inert(inert: boolean): { inert: "" | undefined } {
    return { inert: inert ? "" : undefined };
}
