import Base = require("Everlaw/Base");
import BaseRedaction = require("Everlaw/Redaction");
import Document = require("Everlaw/Document");
import { Is } from "core";
import Metadata = require("Everlaw/Metadata");
import { elevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";
import { NoteUtil } from "Everlaw/Note";
import RedactionStamp = require("Everlaw/Review/RedactionStamp");
import Rest = require("Everlaw/Rest");

export class MetadataRedaction extends BaseRedaction.Redaction {
    get className() {
        return "MetadataRedaction";
    }
    get redactionType() {
        return NoteUtil.ParentType.MetadataRedaction;
    }
    override id: number;
    fieldId: number;
    doc: Document;
    field: Metadata.Field;
    source: string;
    static gaCategory = "Metadata Redaction";
    override _mixin(params: any) {
        if (!params) {
            return;
        }
        if (params.doc) {
            this.docId = params.doc.id;
        }
        if (params.field) {
            this.fieldId = params.field.id;
        }
        super._mixin(params);
        if (Is.defined(this.id)) {
            Base.add(this);
        }
    }
    setOrigin(source: string) {
        this.source = source;
        return this;
    }
    commit() {
        this.logGaEvent(this.id ? "update" : "commit");
        return Rest.post("documents/saveMetadataRedaction.rest", {
            docId: this.docId,
            id: this.id,
            fieldId: this.fieldId,
            stampId: this.redactionStamp.id,
        }).then((data) => {
            if (!Is.number(this.id)) {
                this.id = data.id;
                Base.add(this);
            }
            this._mixin(data);
            Base.publish(this);
        });
    }
    /*
     * If a user redacts a set of fields with the metadata hit redactor suggestion panel,
     * then manually deletes a redaction from the metadata panel, then clicks "undo" on the
     * metadata hit redactor, the panel may not have learned that one of its child hits has
     * already been deleted. The failSilently flag tells the backend not to return an error
     * if this is the case.
     */
    @elevatedRoleConfirm("removing a metadata redaction")
    undo() {
        this.logGaEvent("undo");
        return Rest.post("documents/deleteMetadataRedaction.rest", {
            id: this.id,
        }).then(() => {
            Base.remove(this);
        });
    }

    getField(): Metadata.Field {
        if (!this.field) {
            this.field = Base.get(Metadata.Field, this.fieldId);
        }
        return this.field;
    }
    // Get the fieldId of the highest precedence field in this family, which is either
    // the current field or an Alias field which covers it
    getTopLevelFieldId(): number {
        const baseField = this.getField();
        if (!baseField || baseField.category !== Metadata.Category.SEMANTIC) {
            return this.fieldId;
        }
        const coveringField: Metadata.AliasingField = baseField.isCoveredBy();
        return coveringField ? coveringField.id : this.fieldId;
    }
    getTopLevelField(): Metadata.Field {
        return Base.get(Metadata.Field, this.getTopLevelFieldId());
    }
    logGaEvent(action: string) {
        MetadataRedaction.logGaEvent({
            action: action,
            source: this.source,
            field: this.getField(),
            redactionStamp: this.redactionStamp,
        });
    }

    static logGaEvent(params: {
        action: string;
        source?: string;
        field?: Metadata.Field;
        redactionStamp: RedactionStamp;
    }) {
        params.source && ga_event(this.gaCategory, params.action, params.source);
        params.field && ga_event(this.gaCategory, params.action, params.field.name);
        params.field && ga_event(this.gaCategory, params.action, params.field.type.displayName());
        params.redactionStamp
            && ga_event(this.gaCategory, params.action, params.redactionStamp.display());
    }

    static deleteByDocumentId(docId: number, uid?: number) {
        const redactions = Base.get(MetadataRedaction).filter(
            (r) => r.docId === docId && (!uid || (r.userId && r.userId === uid)),
        );
        if (redactions.length === 0) {
            return;
        }
        Rest.post("documents/bulkDeleteMetadataRedactions.rest", {
            docId: docId,
            redactionIds: redactions.map((r) => r.id),
        }).then(() => {
            Base.remove(redactions);
        });
    }
}
