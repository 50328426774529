import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import { Arr, Is } from "core";
import { hasAttr, isHidden, isInteractive } from "Everlaw/Dom";
import * as Util from "Everlaw/Util";

/**
 * Legacy class for keyboard navigation.
 * @deprecated Although FocusDivs enable keyboard navigation for sighted users, they interact poorly
 * with screen readers. Instead, use components that are natively focusable (such as components in
 * our design system). See the {@link https://everlaw.atlassian.net/wiki/spaces/ENG/pages/490733588/Accessibility+Coding+Standards#Keyboard-Navigation|Confluence documentation}
 * for more info.
 */
export class FocusDiv {
    // This is an empty div that receives focus and has event listeners attached to it.
    node: HTMLElement;
    // The HTMLElement of the component being made accessible. This is what receives focus styling.
    parent: HTMLFocusTarget | null;
    // Any focus styles to apply are saved here. Usually these will be the .focus-* classes in
    // _layout.scss.
    styleClasses: string[] | string;
    // An optional positional parameter denoting where to place the focusable div. This should only
    // be used if the default, "last", breaks the structure of the DOM (common in tables).
    pos: string;
    parentPriorTabindex: number;
    private toDestroy: Util.Destroyable[] = [];
    constructor(
        parent: HTMLFocusTarget,
        styleClasses: string[] | string,
        pos?: string,
        useInline = false,
    ) {
        Dom.addClass(parent, "focusable");
        this.parent = parent;
        this.parent.focusDiv = this;

        this.styleClasses = styleClasses || "";

        this.node = Dom.div({ tabindex: "0", class: "focusDiv" });
        useInline && Dom.style(this.node, "display", "inline");
        this.pos = pos ? pos : "last";
        // When the focusable div is focused/blurred, it programmatically applies/removes the focus
        // styles to its parent element.
        this.node.onfocus = (evt) => {
            this.parent && Dom.addClass(this.parent, this.styleClasses);
        };
        this.node.onblur = (evt) => {
            this.parent && Dom.removeClass(this.parent, this.styleClasses);
        };
        Dom.place(this.node, this.parent, this.pos);
        // If this class is being used, the parent should not be focusable.
        if (Dom.hasAttr(this.parent, "tabindex")) {
            this.parentPriorTabindex = this.parent.tabIndex;
            Dom.setAttr(this.parent, "tabindex", "-1");
        } else if (Dom.isInteractive(this.parent)) {
            Dom.setAttr(this.parent, "tabindex", "-1");
        }
    }
    // Convenience function.
    focus() {
        this.node.focus();
    }
    destroy() {
        Dom.destroy(this.node);
        if (this.parent) {
            Dom.removeClass(this.parent, "focusable");
            this.parentPriorTabindex
                && Dom.setAttr(this.parent, "tabindex", this.parentPriorTabindex.toString());
            this.parent.focusDiv = undefined;
        }
        this.parent = null;
        Util.destroy(this.toDestroy);
    }
    /**
     * Places the FocusDiv again using the supplied or default position argument. Useful
     * if the FocusDiv was not nested inside its parent and gets lost while the Dom is built.
     */
    replace() {
        if (this.parent) {
            Dom.place(this.node, this.parent, this.pos);
        }
    }
    registerDestroyable(...items: Util.Destroyable[]): void {
        this.toDestroy.push(items);
    }
}

/**
 * Makes parent keyboard-focusable by creating a FocusDiv object and attaching
 * it to parent. When the focusable div receives focus, it will apply all classes in
 * styleClasses to parent, and when blurred, it will remove them. If the
 * default position used to place the focusable div breaks the DOM structure, an
 * alternate position may be given. The same positions are allowed as in Dom.place(). If
 * the FocusDiv is placed as a sibling to parent, it must be added to the
 * parent's list of Destroyables or else it may not get cleaned up properly. The "focusable"
 * class will be added to parent, and will be removed if the FocusDiv returned by the method
 * is destroyed. If parent already has a FocusDiv attached to it, the FocusDiv will be destroyed
 * and recreated if any of the arguments differ. If the arguments are the same, the existing
 * FocusDiv will be returned.
 *
 * IMPORTANT: If a reference to this FocusDiv is stored in an instance variable, it must be set
 * null when the parent object is destroyed. This can either be done directly in the parent
 * element's destroy() method, or by passing an anonymous function to the parent element's
 * registerDestroyable() method that sets the reference to null.
 *
 * @deprecated See {@link FocusDiv} for context on deprecation.
 */
export function makeFocusable(
    parent: HTMLFocusTarget,
    styleClasses: string[] | string,
    pos?: string,
    useInline = false,
): FocusDiv {
    if (parent.focusDiv) {
        const otherFD = parent.focusDiv;

        if (parent === otherFD.parent && (pos || "last") === otherFD.pos) {
            if (
                (Is.string(styleClasses) && styleClasses === otherFD.styleClasses)
                || (Is.array(styleClasses)
                    && Is.array(otherFD.styleClasses)
                    && Arr.equals(styleClasses, otherFD.styleClasses))
            ) {
                return otherFD;
            }
        }
        otherFD.destroy();
    }
    return new FocusDiv(parent, styleClasses, pos, useInline);
}

/**
 * Utility function for the common case of creating a FocusDiv and then attaching a keyboard event
 * listener. Like `makeFocusable`, the resulting FocusDiv should be registered as a destroyable.
 *
 * @param node the node to make focusable
 * @param keys list of keys which trigger callback. See the constants defined in Input.ts.
 * @param callback executed when a key from keys is pressed
 * @param styleClasses CSS classes which the created FocusDiv applies to node.
 * @param pos position to place the focus div inside node. Defaults to "last". Generally this
 * only needs to be changed from the default when the resulting tab order is incorrect.
 * @param useInline whether to apply inline styling to the focus div
 *
 * @deprecated See {@link FocusDiv} for context on deprecation.
 */
export function makeFocusableWithCallback(
    node: HTMLElement,
    keys: string[],
    callback: (evt: KeyboardEvent) => void,
    styleClasses: string | string[],
    pos?: string,
    useInline = false,
): FocusDiv {
    const focusDiv = makeFocusable(node, styleClasses, pos, useInline);
    focusDiv.registerDestroyable(
        Input.fireCallbackOnKey(focusDiv.node, keys, (e: KeyboardEvent) => callback(e)),
    );
    return focusDiv;
}

/**
 * @deprecated {@link FocusDiv} is deprecated. Note also that anchor elements are natively focusable,
 * assuming that they point to an actual link (i.e. their `href` is set). Using an anchor without a
 * link and just an onClick is incorrect; instead use {@link TextButton}.
 */
export function makeFocusableAnchor(anchor: HTMLAnchorElement, useInline = false): FocusDiv {
    return makeFocusableWithCallback(
        anchor,
        [Input.ENTER],
        () => anchor.click(),
        "focus-text-style",
        undefined,
        useInline,
    );
}

/**
 * Returns the FocusDiv attached to node. If there is no FocusDiv associated with node, returns
 * undefined.
 *
 * @deprecated See {@link FocusDiv} for context on deprecation.
 */
export function getFocusDiv(node: HTMLElement): FocusDiv | undefined {
    return (<HTMLFocusTarget>node).focusDiv;
}

/**
 * Interface adding the optional focusDiv field to HTMLElements in order to make the parent-focusDiv
 * relationship doubly-linked.
 */
interface HTMLFocusTarget extends HTMLElement {
    focusDiv?: FocusDiv;
}

/**
 * Method for making SVG elements focusable. parents can be any selection of SVG elements. In
 * this method, styleClass should be a single string representing the focus style class that should
 * be applied to the elements. Returns a selection of the focusable elements. Use the
 * returned selection with Input.svgFireCallbackOnKey to attach event handlers. If this is called
 * on a selection that currently contains some focusable SVG elements, all existing FocusG elements
 * will be removed and recreated.
 *
 * @deprecated See {@link FocusDiv} for context on deprecation.
 */
export function makeSVGFocusable(
    parents: d3.Selection<any>,
    styleClass: string,
): d3.Selection<any> {
    parents.each(function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore `this` is bound by d3 to be the current element of the selection
        d3.select(this).select(".focusG").remove();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore `this` is bound by d3 to be the current element of the selection
        d3.select(this)
            .append("g")
            .attr("focusable", true)
            .attr("tabindex", 0)
            .classed("focusG", true);
    });
    const focusGs = parents.selectAll(".focusG");
    focusGs.on("focus", function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore `this` is bound by d3 to be the current element of the selection
        d3.select(this.parentNode).classed(styleClass, true);
    });
    focusGs.on("blur", function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore `this` is bound by d3 to be the current element of the selection
        d3.select(this.parentNode).classed(styleClass, false);
    });
    return focusGs;
}

/**
 * Returns the first child of elem that is focusable according to some custom conditions (relying
 * mostly on FocusDivs). Executed depth-first, and returns null if no appropriate element is found.
 *
 * @deprecated See {@link FocusDiv} for context on deprecation.
 */
export function findFocusableChild(elem: HTMLElement): FocusDiv | HTMLElement | null {
    const elemFocusDiv = getFocusDiv(elem);
    if (elemFocusDiv) {
        return elemFocusDiv;
    }
    if (isInteractive(elem) || (hasAttr(elem, "tabindex") && elem.tabIndex === 0)) {
        return elem;
    }
    const children = elem.children;
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child instanceof HTMLElement && !isHidden(child)) {
            const focusableChildOfChild = findFocusableChild(child);
            if (focusableChildOfChild) {
                return focusableChildOfChild;
            }
        }
    }
    return null;
}
