import * as Base from "Everlaw/Base";
import * as Dom from "Everlaw/Dom";
import { getDefaultShareSubject, getShareDialogTitle } from "Everlaw/Messaging/MessageComposer";
import Checkbox = require("Everlaw/UI/Checkbox");
import Dialog = require("Everlaw/UI/Dialog");
import Icon = require("Everlaw/UI/Icon");
import MessageComposer = require("Everlaw/Messaging/MessageComposer");
import NewMessage = require("Everlaw/Messaging/NewMessage");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import ShareableObject = require("Everlaw/Sharing/ShareableObject");

export interface ShareableObjectParams extends NewMessage.Params {
    // Required attachment.
    attachment: Base.Object;
}

/**
 * Use this method for shared objects to generate a default subject line.
 */
export function composeShareableBinder(
    messageParams: ShareableObjectParams,
    composerParams: NewMessage.ComposerParams = {},
): Promise<NewMessage.Composer | null> {
    messageParams.subject = getDefaultShareSubject(messageParams.attachment);

    composerParams.dialogTitle = getShareDialogTitle(messageParams.attachment);
    composerParams.submitText = ShareableBinderComposer.shareAndNotifyText;

    return MessageComposer.compose(messageParams, composerParams, ShareableBinderComposer);
}

/**
 * This class is effectively the NewMessage.Composer class but with a checkbox to either send a
 * message or not.  Both the `buildDom()` and `onSubmit()` methods are taken from functionality
 * present in `NewObjectComposer`.
 */
class ShareableBinderComposer extends NewMessage.Composer {
    private optionalMessageCheckbox: Checkbox;
    static shareAndNotifyText: string = "Share and notify";
    static shareWithoutNotifyingText: string = "Share without notifying";

    /**
     * Must pay attention to that this method is called in base constructor.
     */
    protected override buildDom(params: NewMessage.ComposerParams): Dom.Content {
        const attachmentType: string = ShareableObject.getClassDisplayName(
            this.message.attachment,
        ).toLowerCase();
        const tooltipIcon: Icon = new Icon("info-circle-20", {
            tooltip:
                "If unchecked, recipients will not receive an email or message notification "
                + `indicating that this ${attachmentType} was shared with them, but the newly `
                + `created ${attachmentType} will still show up on their homepage`,
        });

        const optionalMessageContainer = Dom.div(
            { class: "margin-top-8" },
            this.subjectContainer,
            this.editorContainer,
        );

        this.optionalMessageCheckbox = new Checkbox({
            label: Dom.div(
                "Send notification to recipients",
                Dom.span({ class: "margin-left-4" }, Dom.node(tooltipIcon)),
            ),
            state: true,
            onChange: (state) => {
                Dom.show(optionalMessageContainer, state);
                this.dialog.setSubmitText(
                    state
                        ? ShareableBinderComposer.shareAndNotifyText
                        : ShareableBinderComposer.shareWithoutNotifyingText,
                );
            },
        });

        this.toDestroy.push(this.optionalMessageCheckbox);

        return [
            this.recipientsContainer,
            this.attachmentPermsContainer,
            Dom.node(this.optionalMessageCheckbox),
            optionalMessageContainer,
            this.attachmentContainer,
            this.attachmentWarningContainer,
        ];
    }

    /** Can be overridden to add additional dialog parameters. */
    protected override getDialogParams(params: NewMessage.ComposerParams): QueryDialog.Params {
        return Object.assign(super.getDialogParams(params), {
            submitButtonParams: {
                // this makes the width adjust to the content, which is too wide for the normal button
                width: null,
                // trying to make this a class overwrites classes added in the QueryDialog constructor
                style: "padding-right: 20px; padding-left: 20px;",
            },
        });
    }

    protected override buildAttachmentContainer(): void {
        super.buildAttachmentContainer();
        Dom.addClass(this.attachmentContainer, "sharing");
    }

    override onSubmit(): boolean {
        if (!this.recipientsWidget.getValues().length) {
            // should never happen because this dialog's submit should be disabled when no recipients are selected
            Dialog.ok(
                "No recipients",
                "Please specify at least one recipient to share this "
                    + `${ShareableObject.getClassDisplayName(this.message.attachment).toLowerCase()} with.`,
            );
            return false;
        }
        if (this.optionalMessageCheckbox.isSet()) {
            this.send();
        } else {
            this.getRecipientSids().forEach((sid) => {
                ShareableObject.setPerms(
                    this.message.attachment.className,
                    this.message.attachment.id,
                    sid,
                    this.getAttachmentPerm(),
                );
            });
        }
        this.onSubmitCallback && this.onSubmitCallback();
        return true;
    }
}
