import Base = require("Everlaw/Base");
import { Str } from "core";
import { ColorTokens } from "design-system";
import { Color } from "Everlaw/ColorUtil";

class Rating extends Base.Object implements Base.Colored {
    get className() {
        return "Rating";
    }
    override id: string;
    name = Str.capitalize(this.id);
    private readOnlyColor: Color | null = null;
    // This constructor should NEVER be called externally. Use the constants below or byString().
    constructor(
        id: string,
        public order: number,
        public color: string,
    ) {
        super({ id });
    }
    override compare(other: Rating) {
        return this.order - other.order;
    }
    override display() {
        return this.name;
    }
    getColor() {
        return Color.fromHexString(this.color);
    }
    /**
     * ClusteringDisplay requires grabbing a lot of these colors.
     */
    getReadonlyColor(): Color {
        if (this.readOnlyColor == null) {
            this.readOnlyColor = this.getColor();
        }
        return this.readOnlyColor;
    }
}

module Rating {
    export function byString(str: string) {
        return Base.get(Rating, str);
    }

    // Used in single selects, specifically for clustering display selectors.
    export const SelectorSelectableRating = new Rating(
        "Ratings",
        -1,
        ColorTokens.OBJECT_RATING_HOT,
    );

    export const Hot = new Rating("hot", 0, ColorTokens.OBJECT_RATING_HOT);
    export const Warm = new Rating("warm", 1, ColorTokens.OBJECT_RATING_WARM);
    export const Cold = new Rating("cold", 2, ColorTokens.OBJECT_RATING_COLD);
    export const Unrated = new Rating("unrated", 3, ColorTokens.OBJECT_RATING_UNRATED);

    export const all = [Hot, Warm, Cold, Unrated];
    all.forEach(Base.add);
}

export = Rating;
