import clsx from "clsx";
import * as Icon from "components/Icon";
import { TooltipProps } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import React, { cloneElement, ReactElement, useId, useRef, useState } from "react";
import { EverColor } from "tokens/typescript/EverColor";
import { EverIdProp } from "util/type";
import "./PrevNext.scss";

export interface PrevNextProps extends EverIdProp {
    /**
     * An optional class name to apply to the previous/next buttons.
     */
    className?: string;
    /**
     * The function to call when the previous/next buttons are clicked.
     */
    onMove: (direction: 1 | -1) => void;
    /**
     * The aria-label to apply to the "previous" button.
     */
    prevAriaLabel: string;
    /**
     * The aria-label to apply to the "next" button.
     */
    nextAriaLabel: string;
    /**
     * Whether to disable the "previous" button. Default false.
     */
    prevDisabled?: boolean;
    /**
     * Whether to disable the "next" button. Default false.
     */
    nextDisabled?: boolean;
    /**
     * An optional tooltip to render on the "previous" button.
     */
    prevTooltip?: ReactElement<TooltipProps>;
    /**
     * An optional tooltip to render on the "next" button.
     */
    nextTooltip?: ReactElement<TooltipProps>;
}

/**
 * A pair of previous/next buttons for navigating through a list of items.
 *
 * Note: This is a v0 component that has not been fully defined by design. It will likely be
 * updated in the future when it is fully defined.
 */
export function PrevNext({
    everId,
    className,
    onMove,
    prevAriaLabel,
    nextAriaLabel,
    prevDisabled = false,
    nextDisabled = false,
    prevTooltip,
    nextTooltip,
}: PrevNextProps) {
    const [prevHovered, setPrevHovered] = useState(false);
    const [nextHovered, setNextHovered] = useState(false);

    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);
    const buttonId = useId();
    const prevTooltipId = buttonId + "-prev";
    const nextTooltipId = buttonId + "-next";

    prevTooltip &&= cloneElement(prevTooltip, {
        id: prevTooltipId,
        target: prevRef,
    });
    nextTooltip &&= cloneElement(nextTooltip, {
        id: nextTooltipId,
        target: nextRef,
    });

    return (
        <div className={clsx(className, "bb-prev-next")} {...everIdProp(everId)}>
            <button
                ref={prevRef}
                className={clsx("bb-prev-next__button", "bb-prev-next__prev", {
                    "bb-prev-next__button--hover": prevHovered,
                    "bb-prev-next__button--disabled": prevDisabled,
                    "bb-prev-next__prev--disabled": prevDisabled,
                })}
                type={"button"}
                onClick={() => !prevDisabled && onMove(-1)}
                aria-disabled={prevDisabled}
                aria-label={prevAriaLabel}
                aria-describedby={prevTooltip ? prevTooltipId : undefined}
                onMouseEnter={() => setPrevHovered(true)}
                onMouseLeave={() => setPrevHovered(false)}
                onFocus={() => setPrevHovered(true)}
                onBlur={() => setPrevHovered(false)}
            >
                <Icon.ChevronLeft
                    color={prevHovered && !prevDisabled ? EverColor.WHITE : EverColor.PARCHMENT_60}
                    aria-hidden={true}
                />
            </button>
            {prevTooltip}
            <button
                ref={nextRef}
                className={clsx("bb-prev-next__button", "bb-prev-next__next", {
                    "bb-prev-next__button--hover": nextHovered,
                    "bb-prev-next__button--disabled": nextDisabled,
                    "bb-prev-next__next--disabled": nextDisabled,
                })}
                type={"button"}
                onClick={() => !nextDisabled && onMove(1)}
                aria-disabled={nextDisabled}
                aria-label={nextAriaLabel}
                aria-describedby={nextTooltip ? nextTooltipId : undefined}
                onMouseEnter={() => setNextHovered(true)}
                onMouseLeave={() => setNextHovered(false)}
                onFocus={() => setNextHovered(true)}
                onBlur={() => setNextHovered(false)}
            >
                <Icon.ChevronRight
                    color={nextHovered && !nextDisabled ? EverColor.WHITE : EverColor.PARCHMENT_60}
                    aria-hidden={true}
                />
            </button>
            {nextTooltip}
        </div>
    );
}
