import Dom = require("Everlaw/Dom");
import { Arr, objectToArray } from "core";
import { Recipient } from "Everlaw/Recipient";
import SearchObjectPermission = require("Everlaw/Messaging/SearchSharing/SearchObjectPermission");

/**
 * Contains data structure to store Secured Objects to which recipients will be granted View
 * permission as well as display the information in sharing dialog.
 *
 * Author: Jiatao
 */
class SearchObjectPermissionGrant {
    node: HTMLElement;
    /** All objects that each user/group/org/role has permission to view. */
    searchObjectPermission: SearchObjectPermission;
    objPermGrantData: SearchObjectPermissionGrantData;
    objPermGrantContent: HTMLElement;

    constructor(searchObjectPermissionConflicts: SearchObjectPermission) {
        this.searchObjectPermission = searchObjectPermissionConflicts;
        this.objPermGrantData = new SearchObjectPermissionGrantData(
            searchObjectPermissionConflicts,
        );

        this.node = Dom.div(
            { class: "obj-perm-grant-container" },
            Dom.div({ class: "obj-perm-grant-headline" }, [
                "The following recipients will be granted ",
                Dom.span({ class: "semi-bold" }, "view"),
                " permissions on these objects:",
            ]),
            (this.objPermGrantContent = Dom.div({ class: "obj-perm-grant-content" })),
        );
    }

    /** Change object granting information upon user add/remove a recipient. */
    changeObjPermGrant(recipient: Recipient, added: boolean): void {
        if (this.searchObjectPermission.hasShareableObjectsRecipientCannotView(recipient)) {
            if (added) {
                if (this.objPermGrantData.isEmpty()) {
                    Dom.show(this.node);
                }
                this.objPermGrantData.add(
                    recipient,
                    this.searchObjectPermission.getShareableObjectsRecipientCannotView(recipient),
                );
            } else {
                this.objPermGrantData.remove(
                    recipient,
                    this.searchObjectPermission.getShareableObjectsRecipientCannotView(recipient),
                );
                if (this.objPermGrantData.isEmpty()) {
                    Dom.hide(this.node);
                }
            }
            Dom.setContent(this.objPermGrantContent, this.objPermGrantData.displayData());
        }
    }

    isEmpty(): boolean {
        return this.objPermGrantData.isEmpty();
    }

    destroy(): void {
        Dom.destroy(this.node);
    }
}

/**
 * Data structure to store Secured Object-Recipient mappings which will be used to granted object
 * View. Contains behaviors such as add, remove, display, sort and toJson in order support user
 * adding/removing recipients, displaying information in sharing dialog and converting data to JSON
 * to send to backend.
 */
class SearchObjectPermissionGrantData {
    private data: Map<string, Map<string, { recipients: Set<Recipient>; display: string }>> =
        new Map();

    private numObjsWithRecipients = 0;

    constructor(searchObjectPermissionConflicts: SearchObjectPermission) {
        this.initialize(searchObjectPermissionConflicts);
    }

    private initialize(objPermConflicts: SearchObjectPermission) {
        // Initialize data by getting all objectClasses and objectIds. Set empty set for recipients
        // associated with each objectIds.
        const objectsRecipientsCannotView =
            objPermConflicts.getAllShareableObjectsRecipientsCannotView();
        for (const recipientType in objectsRecipientsCannotView) {
            for (const recipient in objectsRecipientsCannotView[recipientType]) {
                const securedObjectInfo = objectsRecipientsCannotView[recipientType][recipient];
                for (const objectClass in securedObjectInfo) {
                    let objectClassData = this.data.get(objectClass);
                    if (!objectClassData) {
                        objectClassData = new Map();
                        this.data.set(objectClass, objectClassData);
                    }

                    for (const objectId in securedObjectInfo[objectClass]) {
                        if (!objectClassData.has(objectId)) {
                            objectClassData.set(objectId, {
                                recipients: new Set(),
                                display: securedObjectInfo[objectClass][objectId],
                            });
                        }
                    }
                }
            }
        }
        // Sort objectClasses by name, and also objects inside each class by their name.
        this.sort();
    }

    private sort() {
        const dataArray: {
            objectClass: string;
            objectClassData: Map<string, { recipients: Set<Recipient>; display: string }>;
        }[] = [];

        this.data.forEach((objectClassData, objectClass) => {
            const objectClassDataArray = objectToArray(objectClassData);
            Arr.sort(objectClassDataArray, { key: (o) => o.value.display });
            const objectClassDataNew = Arr.toMap(
                objectClassDataArray,
                (t) => t.key,
                (t) => t.value,
            );
            dataArray.push({ objectClass: objectClass, objectClassData: objectClassDataNew });
        });

        Arr.sort(dataArray, {
            key: (o) => SearchObjectPermission.displayObjectClass(o.objectClass),
        });
        this.data = Arr.toMap(
            dataArray,
            (t) => t.objectClass,
            (t) => t.objectClassData,
        );
    }

    add(
        recipient: Recipient,
        securedObjectInfo: { [objectClass: string]: { [objectId: string]: string } },
    ): void {
        for (const objectClass in securedObjectInfo) {
            const objectClassData = this.data.get(objectClass);
            for (const objId in securedObjectInfo[objectClass]) {
                if (objectClassData.get(objId).recipients.size === 0) {
                    this.numObjsWithRecipients++;
                }
                objectClassData.get(objId).recipients.add(recipient);
            }
        }
    }

    remove(
        recipient: Recipient,
        securedObjectInfo: { [objectClass: string]: { [objectId: string]: string } },
    ): void {
        for (const objectClass in securedObjectInfo) {
            const objectClassData = this.data.get(objectClass);
            for (const objId in securedObjectInfo[objectClass]) {
                objectClassData.get(objId).recipients.delete(recipient);
                if (objectClassData.get(objId).recipients.size === 0) {
                    this.numObjsWithRecipients--;
                }
            }
        }
    }

    displayData(): HTMLElement {
        const outputDiv = Dom.div();
        this.data.forEach((objectClassData, objectClass) => {
            let isNotEmpty = false;
            const objClassRecipients = Dom.span();
            objectClassData.forEach((objectData, objectId) => {
                const recipients = objectData.recipients;
                if (recipients.size > 0) {
                    isNotEmpty = true;

                    const objDisplay = Dom.span(
                        { class: "obj-perm-grant-class-or-obj-name" },
                        objectData.display,
                    );

                    const objRecipients = Dom.span(
                        " - ",
                        Array.from(recipients)
                            .map((recipient) => recipient.display())
                            .join(", "),
                    );

                    Dom.create("div", { content: [objDisplay, objRecipients] }, objClassRecipients);
                }
            });

            if (isNotEmpty) {
                const objPermGrantClassContent = Dom.create(
                    "div",
                    { class: "obj-perm-grant-class" },
                    outputDiv,
                );
                Dom.create(
                    "div",
                    {
                        class: "obj-perm-grant-class-or-obj-name",
                        content:
                            SearchObjectPermission.displayObjectClass(objectClass).toUpperCase(),
                    },
                    objPermGrantClassContent,
                );
                Dom.place(objClassRecipients, objPermGrantClassContent);
            }
        });
        return outputDiv;
    }

    isEmpty(): boolean {
        return this.numObjsWithRecipients === 0;
    }
}

export { SearchObjectPermissionGrant };
