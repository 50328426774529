/// <amd-dependency path="Everlaw/Dnd" /> // for the nicer Avatar
import { Arr } from "core";
import Base = require("Everlaw/Base");
import BasicRadio = require("Everlaw/UI/BasicRadio");
import { ColumnCombination } from "Everlaw/UI/Upload/Metadata/ColumnCombination";
import Dnd = require("Everlaw/Dnd");
import Dom = require("Everlaw/Dom");
import GridTable = require("Everlaw/UI/GridTable");
import Icon = require("Everlaw/UI/Icon");
import { LoadfileColumn } from "Everlaw/UI/Upload/Metadata/LoadfileColumn";
import MergeAnalysis = require("Everlaw/Model/Upload/Metadata/MergeAnalysis");
import MetadataDefinition_type = require("Everlaw/Model/Upload/Metadata/MetadataDefinition");
import NameStatus = require("Everlaw/UI/Upload/Metadata/NameStatus");
import TabView = require("Everlaw/UI/Upload/Util/TabView");
import Tooltip = require("Everlaw/UI/Tooltip");
import UploadUI = require("Everlaw/UI/Upload/Util/UploadUI");
import Util = require("Everlaw/Util");
import declare = require("dojo/_base/declare");

// Must match the ConflictPolicy enum in MetadataDefinition.java.
const DEFAULT_CONFLICT_POLICY = "SAVE";
const DELETE_CONFLICT_POLICY = "DELETE";

interface MergeGroupParams {
    targetName: string;
    // Either ColumnCombination objects or JSON from backend ColumnCombinations.
    columnCombinations: (ColumnCombination | any)[];
}

/**
 * Corresponds to MergeGroup.java.
 */
class MergeGroup extends TabView.Tabbable {
    targetName: string;
    columnCombinations: ColumnCombination[]; // priority ordered
    private conflictPolicy: string;
    private _destroyables: Util.Destroyable[];
    private _stats: HTMLElement;
    private _conflictPolicy: HTMLElement;
    private dragSamples: HTMLElement;
    private noDragSamples: HTMLElement;
    private previousMappingsTable: HTMLElement;
    private previousMappingsNode: HTMLElement;
    private _mergeSource: any; // Dnd.EmbeddedSource
    constructor(
        params: MergeGroupParams,
        public def: MetadataDefinition_type,
    ) {
        super();
        Object.assign(this, params);
        this.id = this.targetName;
        // This must be initialized after the mixin so that it will be empty.
        this.columnCombinations = [];
        params.columnCombinations.forEach((cc) => {
            if (cc instanceof ColumnCombination) {
                cc.mergeGroup = this;
            } else {
                cc = new ColumnCombination(cc.columns, def, this);
            }
            this.columnCombinations.push(cc);
        });
        this._destroyables = [];
    }
    toJSON() {
        return {
            targetName: this.targetName,
            columnCombinations: this.columnCombinations,
            conflictPolicy: this.conflictPolicy,
        };
    }
    /**
     * Will be null if the MergeGroup hasn't been analyzed with its current composition yet.
     */
    analysis() {
        return this.def.analyses.mergeAnalysis(this.analysisId());
    }
    /**
     * This should always match MergeAnalysis.java#id.
     */
    private analysisId() {
        const ccaIds = this.columnCombinations.map((cc) => cc.mergeAnalysisId());
        return this.targetName + "::" + JSON.stringify(ccaIds);
    }
    type() {
        // Assumes the merge group has a consistent type.
        return this.columnCombinations[0].type();
    }
    isMerge() {
        return this.columnCombinations.length > 1;
    }
    needsReview() {
        return this.analysis() && this.analysis().needsReview;
    }
    /**
     * Should always correspond to UploadState.java#dirtyMergeGroups.
     */
    isDirty() {
        return (this.isMerge() || this.def.isOverlay()) && !this.analysis();
    }
    hasConflicts() {
        return this.analysis() && this.analysis().numConflicts > 0;
    }
    errors() {
        return (
            this.isDirty()
            || (this.hasConflicts() && !this.conflictPolicy)
            || this.hasTypeInconsistency()
        );
    }
    hasTypeInconsistency() {
        const type = this.type();
        return this.columnCombinations.some((cc) => !cc.type().equals(type));
    }
    nameStatus() {
        if (this.hasTypeInconsistency()) {
            return NameStatus.INVALID_TYPE;
        }
        return NameStatus.of(this.type(), this.def.getNameRestrictions(this.targetName));
    }
    nameStatusTooltip() {
        return this.nameStatus().tooltip(this.isMerge());
    }
    columns() {
        return Arr.flat<LoadfileColumn>(this.columnCombinations.map((cc) => cc.columns));
    }
    add(cc: ColumnCombination, allowInvalid: boolean) {
        // Assumes cc has not already been added to this merge group.
        const invalid = this.hasTypeInconsistency() || !cc.type().equals(this.type());
        if (!allowInvalid && invalid) {
            return false;
        }
        this.columnCombinations.push(cc);
        cc.mergeGroup = this;
        this.conflictPolicy = null; // Force user to reinspect.
        if (invalid) {
            this.columnCombinations.forEach((cc) => cc.updateRow());
        }
        return true;
    }
    remove(cc: ColumnCombination) {
        const idx = this.columnCombinations.indexOf(cc);
        if (idx >= 0) {
            this.columnCombinations.splice(idx, 1);
            this.conflictPolicy = null; // Force user to reinspect.
        }
        if (!this.hasTypeInconsistency()) {
            this.columnCombinations.forEach((cc) => cc.updateRow());
        }
    }
    /**
     * Returns samples in the form [{primary: [true, false, ...], values: {V1, V2, ...}}, ...]
     * where the `primary` and `values` arrays have length this.columnCombinations.length and
     * values[i] corresponds to columnCombinations[i].
     */
    samples(): MergeAnalysis.Sample[] {
        if (this.isDirty()) {
            throw new Error("Attempting to retrieve samples from a dirty merge group");
        }
        if (this.analysis()) {
            return this.analysis().samples;
        }
        // Collect samples directly from the lone ColumnCombination.
        return this.columnCombinations[0]
            .sampleResults()
            .map((s) => ({ primary: [true], values: [s] }));
    }

    /**
     * Return any columns that were previously mapped to a different field.
     */
    differentPreviousMappingColumns(): LoadfileColumn[] {
        if (!this.analysis() || !this.analysis().previousMappings) {
            return [];
        }
        return this.columns().filter((c) => {
            const pm = this.analysis().previousMappings[c.header];
            return Object.keys(pm || {}).length > 0;
        });
    }

    /* Resolve Stage Operations */

    private reorderColumnCombination(id: string, newPos: number) {
        const curPos = Arr.first(this.columnCombinations, (cc) => {
            return cc.id === id;
        });
        if (curPos === newPos) {
            return;
        }
        const cc = this.columnCombinations[curPos];
        this.columnCombinations.splice(curPos, 1);
        this.columnCombinations.splice(newPos, 0, cc);
        if (this.isDirty()) {
            this.def.updateValidity(); // need to re-analyze
        } else {
            this.updateSampleDisplay();
        }
    }
    private setConflictPolicy(newPolicy: string) {
        if (this.conflictPolicy !== newPolicy) {
            this.conflictPolicy = newPolicy;
            this.updateTab();
            this.updatePane();
            this.def.updateValidity();
        }
    }

    /* Resolve Stage UI */

    override init() {
        super.init();
    }
    protected initTab() {
        Dom.place(
            [
                Dom.div({ class: "metadata-tab-title" }, this.targetName),
                Dom.div(this.type().displayName()),
            ],
            this._tab,
        );
        Dom.place([(this._stats = Dom.div({ class: "v-spaced-4" }))], this.tabDrawer);
        this.updateTab();
    }
    protected initPane() {
        const paneTitle = Dom.h6({ class: "metadata-pane__title" }, this.targetName);

        const dragInstructions = this.isMerge()
            ? Dom.div(`Drag the load file headers into the order of priority that you want to
                    populate each metadata field`)
            : null;

        const paneContent = Dom.div(
            { class: "metadata-pane__options v-spaced-16" },
            (this._conflictPolicy = Dom.div(
                { class: "v-spaced-8" },
                Dom.div(Dom.span({ class: "h7" }, "Handling conflicting values")),
            )),
            Dom.div(
                { class: "v-spaced-8" },
                Dom.div(Dom.span({ class: "h7" }, "Populating metadata field")),
                dragInstructions,
                Dom.div(
                    { class: "merge-samples" },
                    (this.dragSamples = Dom.div({
                        class: "metadata-def-options--end metadata-def-options",
                    })),
                    (this.noDragSamples = Dom.div({
                        class: "metadata-def-options--end metadata-def-options",
                    })),
                ),
            ),
            (this.previousMappingsNode = Dom.div(
                { class: "v-spaced-8 hidden" },
                UploadUI.buildUnusualMappingIntro("h7", this.toDestroy),
                (this.previousMappingsTable = Dom.div({ class: "v-spaced-8" })),
            )),
        );

        Dom.place([paneTitle, paneContent], this._pane);

        const mergePolicyRadio = new BasicRadio([
            {
                id: DEFAULT_CONFLICT_POLICY,
                display: "Upload conflicting values as supplementary metadata values",
            },
            {
                id: DELETE_CONFLICT_POLICY,
                display: "Only upload the primary metadata value and ignore conflicting values",
            },
        ]);
        mergePolicyRadio.onChange = (newPolicy) => {
            this.setConflictPolicy(newPolicy.id);
        };
        this.conflictPolicy
            && mergePolicyRadio.select(new Base.Primitive(this.conflictPolicy), true);
        this._destroyables.push(mergePolicyRadio);
        Dom.place(mergePolicyRadio, this._conflictPolicy);

        this._mergeSource = new MergeSource(this.dragSamples, {
            creator: function creator(columnCombination, hint) {
                if (hint === "avatar") {
                    return {
                        data: columnCombination,
                        node: Dom.div({ class: "name document" }, columnCombination),
                        type: ["text"],
                    };
                } else {
                    return this.inherited({ callee: creator }, arguments);
                }
            },
            handleMove: (id, newPos) => this.reorderColumnCombination(id, newPos),
        });

        this.updatePane();
    }

    protected getTabClass() {
        if (this.errors()) {
            return "resolution";
        } else if (this.hasConflicts() || this.differentPreviousMappingColumns().length) {
            return "inspection";
        }
        return "verification";
    }

    override updateTab() {
        super.updateTab();
        Dom.empty(this._stats);
        const analysis = this.analysis();
        const strongConflicts = analysis ? analysis.numConflicts : 0;
        const weakConflicts = analysis ? analysis.weakConflicts : 0;
        // If there's more than one ColumnCombination there ought to be an analysis.
        const nullValues = analysis ? analysis.nullValues : this.columnCombinations[0].numValues();
        const numDocs = this.def.getNumDocs();
        if (this.isMerge()) {
            this.addConflictStat("Conflicting values", strongConflicts, numDocs);
            this.addConflictStat("Same values", weakConflicts, numDocs);
            this.addConflictStat(
                "Only one value",
                numDocs - strongConflicts - weakConflicts - nullValues,
                numDocs,
            );
        } else {
            this.addConflictStat("Has value", numDocs - nullValues, numDocs);
        }
        if (this.def.isOverlay() && analysis) {
            this.addConflictStat("Value changed", analysis.changedValues, numDocs);
            this.addConflictStat("Value deleted", analysis.deletedWithoutReplacement, numDocs);
        } else {
            this.addConflictStat("No values", nullValues, numDocs);
        }
    }

    updatePane() {
        Dom.show(this._conflictPolicy, this.hasConflicts());
        this.updateSampleDisplay();
    }

    private updateSampleDisplay() {
        Dom.empty([this.dragSamples, this.noDragSamples]);
        const samples = this.samples();
        const nonCanonicalClass = (
            this.conflictPolicy || DEFAULT_CONFLICT_POLICY
        ).toLocaleLowerCase();
        const enableDnd = this.isMerge();
        this.columnCombinations.forEach((cc, i) => {
            const sampleCells = samples.map((s) => {
                const v = s.values[i];
                const clazz =
                    v == null ? "no-value" : s.primary[i] ? "canonical" : nonCanonicalClass;
                const div = Dom.div({ class: `metadata-def-option__sample ellipsed ${clazz}` }, v);
                this.addCanonicalCheckmark(div, clazz);
                if (v !== null) {
                    this._destroyables.push(new Tooltip.MirrorTooltip(div));
                }
                return div;
            });
            Dom.place(
                Dom.div(
                    {
                        class:
                            "metadata-def-option metadata-def-option--narrow action "
                            + (enableDnd ? "dojoDndItem dojoDndHandle" : ""),
                        dndData: cc.id,
                    },
                    this.buildOptionTitle(cc.fullName()),
                    Dom.div({ class: "metadata-def-option__samples" }, sampleCells),
                ),
                enableDnd ? this.dragSamples : this.noDragSamples,
            );
        });
        if (this.def.isOverlay()) {
            const maxExistingVals = Math.max(
                ...samples.map((s) => {
                    return s.existingSamples ? s.existingSamples.values.length : 0;
                }),
            );
            for (let i = 0; i < maxExistingVals; i++) {
                const sampleCells = samples.map((s) => {
                    if (s.existingSamples && s.existingSamples.values[i] != null) {
                        const cls = s.existingSamples.primary[i] ? "canonical" : "delete";
                        const div = Dom.div(
                            { class: `metadata-def-option__sample ellipsed ${cls}` },
                            s.existingSamples.values[i],
                        );
                        this.addCanonicalCheckmark(div, cls);
                        return div;
                    }
                    return Dom.div({ class: "metadata-def-option__sample no-value" });
                });
                Dom.place(
                    Dom.div(
                        { class: "metadata-def-option metadata-def-option--narrow" },
                        this.buildOptionTitle(i === 0 ? "(Current value)" : "(Existing conflict)"),
                        Dom.div({ class: "metadata-def-option__samples" }, sampleCells),
                    ),
                    this.noDragSamples,
                );
            }
        }
        this._mergeSource.sync();
        this.updatePreviousMappingDisplay();
    }

    private buildOptionTitle(title: string, italic: boolean = false) {
        const cls = `h6 ${italic ? "italic" : ""}`;
        const dragIcon = new Icon("grip-vertical-light-20");
        this.toDestroy.push(dragIcon);
        return Dom.div(
            { class: "metadata-def-option__title" },
            dragIcon.node,
            Dom.div({ class: cls }, title),
        );
    }

    private addCanonicalCheckmark(cell: HTMLElement, cls: string) {
        if (cls.indexOf("canonical") >= 0) {
            const checkmark = new Icon("circle-check-filled-green-20");
            this.toDestroy.push(checkmark);
            Dom.place(checkmark, cell, "first");
        }
    }

    private addConflictStat(name: string, value: number, total: number) {
        Dom.place(
            Dom.div(
                { class: "conflict-stat" },
                Dom.span(name),
                Dom.span(Util.percentOf(total, value)),
            ),
            this._stats,
        );
    }

    private updatePreviousMappingDisplay() {
        const table = MergeGroup.buildPreviousMappingsTable(this.def.getNumDocs(), [this], false);
        if (table) {
            Dom.setContent(this.previousMappingsTable, table.node);
            Dom.show(this.previousMappingsNode);
        }
    }

    static buildPreviousMappingsTable(numDocs: number, mgs: MergeGroup[], includeCurrent: boolean) {
        const rows: HTMLElement[][] = [];
        for (const mg of mgs) {
            const diffPreviousMappingColumns = mg.differentPreviousMappingColumns();
            if (diffPreviousMappingColumns.length) {
                diffPreviousMappingColumns.forEach((c) => {
                    const previousMappings = mg.analysis().previousMappings[c.header];
                    // We've already confirmed this exists and contains relevant entries.
                    Object.entries(previousMappings).forEach(([fieldName, count]) => {
                        const row = [
                            Dom.div(c.header),
                            Dom.div(fieldName),
                            Dom.div(Util.percentOf(numDocs, count)),
                        ];
                        if (includeCurrent) {
                            row.splice(1, 0, Dom.div(mg.targetName));
                        }
                        rows.push(row);
                    });
                });
            }
        }
        if (rows.length <= 0) {
            return null;
        }
        const headers = ["Load file header", "Previously mapped to", "Affected documents"];
        if (includeCurrent) {
            headers.splice(1, 0, "Currently mapped to");
        }
        const tableParams = {
            columnCount: includeCurrent ? 4 : 3,
            headers: headers.map((header) => Dom.div(header)),
            bodyElements: rows,
            minWidth: UploadUI.UPLOAD_GRIDTABLE_MINWIDTH,
            fitMinWidth: true,
        };
        return new GridTable(tableParams);
    }

    override destroy() {
        super.destroy();
        Dom.destroy(this.getTab());
        Dom.destroy(this.getPane());
        Util.destroy([this._destroyables, this._mergeSource]);
    }
}

const MergeSource = declare(Dnd.EmbeddedSource, {
    horizontal: true,
    singular: true,
    withHandles: true,
    accept: ["text"],
    copyState: function () {
        return false;
    },
    _createPlaceholder: () => {
        return Dom.div({ class: "merge-sample-placeholder placeholder item-box default-style" });
    },
    onDrop: function onDrop(source, nodes, copy) {
        this.inherited({ callee: onDrop }, arguments);
        const node = nodes[0];
        const id = this.getItem(node.id).data;
        const allNodes = this.getAllNodes();
        const idx = allNodes.indexOf(node);
        this.handleMove(id, idx);
    },
    /**
     * Override to be informed when a column has been moved to a new position.
     */
    handleMove: function (id, idx) {},
});

export { MergeGroup };
