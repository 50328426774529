import clsx from "clsx";
import * as Icon from "components/Icon";
import { IconProps } from "components/Icon/IconProps";
import { Link, Span } from "components/Text";
import { displayDate, Num } from "core";
import React, { FC, ReactElement, ReactNode, UIEventHandler, useId, useRef } from "react";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import "./ItemDisplay.scss";
import { displayDateTime } from "util/date";
import { TextVariant } from "components/Text";
import { Overwrite } from "core";
import { EverIdProp } from "util/type";
import * as CommonIcon from "components/Icon/CommonIcon";
import { Tooltip, TooltipPlacement } from "../Tooltip";

const SMALL_SIZE = 16;
const DEFAULT_SIZE = 20;

const ICON_SIZE_MAP: { [s in TextVariant]: number } = {
    [TextVariant.SMALL]: SMALL_SIZE,
    [TextVariant.SMALL_SEMIBOLD]: SMALL_SIZE,
    [TextVariant.SMALL_BOLD]: SMALL_SIZE,
    [TextVariant.SMALL_ITALIC]: SMALL_SIZE,
    [TextVariant.SMALL_NUMBER]: SMALL_SIZE,
    [TextVariant.DEFAULT]: DEFAULT_SIZE,
    [TextVariant.SEMIBOLD]: DEFAULT_SIZE,
    [TextVariant.BOLD]: DEFAULT_SIZE,
    [TextVariant.ITALIC]: DEFAULT_SIZE,
    [TextVariant.NUMBER]: DEFAULT_SIZE,
    [TextVariant.OVERLINE]: DEFAULT_SIZE,
};

const DEFAULT_TOOLTIP_PLACEMENT = [
    TooltipPlacement.BOTTOM,
    TooltipPlacement.BOTTOM_START,
    TooltipPlacement.BOTTOM_END,
    TooltipPlacement.RIGHT,
    TooltipPlacement.LEFT,
];

export interface ItemDisplayProps extends EverIdProp {
    /**
     * The Icon to display. The icon's size is ignored as it will be set appropriately based on
     * the component's {@code size} prop. The icon color, if not specified, will be set to reflect
     * whether the component is clickable.
     */
    icon: ReactElement<IconProps>;
    /**
     * The size of the component. Defaults to {@code DEFAULT}.
     */
    variant?: TextVariant;
    /**
     * If true, a loading icon will be displayed instead of the count. Defaults to false.
     */
    loading?: boolean;
    /**
     * The content to display.
     */
    children: ReactNode;
    /**
     * An optional (internal) URL to direct the user to.
     */
    href?: string;
    /**
     * When href is specified, determines if the URL should open in a new tab. Defaults to false.
     */
    newTab?: boolean;
    /**
     * An optional onclick handler applied to the component.
     */
    onClick?: UIEventHandler<HTMLAnchorElement>;
    /**
     * An optional class name to add to the component.
     */
    className?: string;
    /**
     * Whether the link is disabled.
     *
     * Defaults to false.
     */
    disabled?: boolean;
}

/**
 * Generic component to display a piece of information next to an icon, with support for hrefs,
 * onClicks, disabling, and loading. Also supports all our text variants. In general, you should
 * use other sub-components, like ItemDisplay.Count, ItemDisplay.Date, or ItemDisplay.Bytes,
 * instead of this one.
 */
export const ItemDisplay: FC<ItemDisplayProps> & {
    Count: FC<ItemCountProps>;
    Date: FC<ItemDateProps>;
    Bytes: FC<ItemBytesProps>;
} = ({
    icon,
    variant = TextVariant.DEFAULT,
    loading = false,
    href,
    newTab = false,
    onClick,
    className,
    disabled = false,
    children,
    everId,
}: ItemDisplayProps) => {
    // Even if an href/onClick is specified, the component shouldn't be clickable if:
    // (a) it's in the loading state, or
    // (b) disabled = true
    const clickable = (!!href || !!onClick) && !loading && !disabled;
    const size = ICON_SIZE_MAP[variant];

    className = clsx(
        className,
        "bb-item-display",
        `bb-item-display--size-${size === SMALL_SIZE ? "small" : "default"}`,
        { "bb-item-display--clickable": clickable },
    );

    const getConditionalContent = () => {
        if (loading) {
            return <CommonIcon.Loading size={size} aria-label={"Loading"} />;
        }
        return children;
    };

    const content = (
        <>
            {React.cloneElement(icon, {
                size,
                color: clickable ? icon.props.color || ColorTokens.TEXT_LINK : icon.props.color,
                className: clsx(icon.props.className, "bb-item-display__icon"),
                "aria-hidden": "true",
            })}
            {getConditionalContent()}
        </>
    );

    if (clickable) {
        return (
            <Link
                className={className}
                href={href}
                newTab={newTab}
                onClick={onClick}
                children={content}
                variant={variant}
                everId={everId}
            />
        );
    } else {
        return (
            <Span variant={variant} className={className} everId={everId}>
                {content}
            </Span>
        );
    }
};
ItemDisplay.displayName = "ItemDisplay";

export interface ItemCountProps
    extends Overwrite<
        Omit<ItemDisplayProps, "children" | "disabled">,
        { variant?: TextVariant.NUMBER | TextVariant.SMALL_NUMBER }
    > {
    /**
     * The number of items. Defaults to 0.
     */
    itemCount: number | null;
    /**
     * If specified, a filter icon will be displayed and the text will show filteredCount/itemCount.
     */
    filteredCount?: number;
    /**
     * When href or onClick is specified, determines whether to disable if itemCount or
     * filteredCount are 0.
     *
     * Defaults to false.
     */
    disabledWhenNoCount?: boolean;
    /**
     * A suffix to add on to the "itemCount/filteredCount" display.
     */
    suffix?: string;
}

/**
 * A component for displaying an icon and a number to indicate a count of a particular item (as
 * represented by the given icon). Supports links, suffixes, filtered counts (e.g. X / Y),
 * disabling, etc.
 */
function ItemCount({
    itemCount = 0,
    filteredCount,
    suffix,
    disabledWhenNoCount,
    variant = TextVariant.NUMBER,
    className,
    ...props
}: ItemCountProps) {
    const disabled =
        itemCount === null || (disabledWhenNoCount && (filteredCount === 0 || itemCount === 0));

    // Compute the count text to display.
    let text = "";
    if (itemCount !== null) {
        if (filteredCount !== undefined) {
            text += filteredCount.toLocaleString("en-US") + "/";
        }
        text += itemCount.toLocaleString("en-US");
        if (suffix) {
            text += suffix;
        }
    }

    className = clsx(className, "bb-item-count");

    const getConditionalContent = () => {
        return itemCount !== null && (filteredCount || filteredCount === 0) ? (
            <Icon.FilterFilled
                className={"bb-item-display__icon"}
                size={ICON_SIZE_MAP[variant]}
                color={ColorTokens.TEXT_LINK}
                aria-hidden={"true"}
            />
        ) : undefined;
    };

    return (
        <ItemDisplay {...props} disabled={disabled} variant={variant} className={className}>
            {getConditionalContent()}
            {text}
        </ItemDisplay>
    );
}

ItemDisplay.Count = ItemCount;

export interface ItemDateProps
    extends Omit<ItemDisplayProps, "children" | "icon">,
        Partial<Pick<ItemDisplayProps, "icon">> {
    /**
     * The date to display. If a number is provided, will be interpreted as a unix millisecond
     * timestamp.
     */
    date: Date | number;
    /**
     * A function to convert a date (or timestamp) to a string to display.
     *
     * By default, uses {@link displayDate}
     */
    dateToString?: (date: Date) => string;
    /**
     * A function to convert a Date to a more descriptive string for a tooltip.
     *
     * By default, uses {@link displayDateTime}
     */
    dateToTooltipContent?: (date: Date) => string;
    /**
     * The placement for the tooltip. Defaults to [BOTTOM, BOTTOM_START, BOTTOM_END, RIGHT, LEFT].
     */
    tooltipPlacement?: TooltipPlacement | TooltipPlacement[];
}

/**
 * A component for displaying dates. By default, dates are displayed in YYYY/MM/DD format, though
 * that can be changed by specifying a different dateToString function. Supports links,
 * disabling, etc.
 */
// TODO: move tooltip content and tooltipPlacement up into ItemDisplay?
function ItemDate({
    date,
    dateToString = displayDate,
    dateToTooltipContent = displayDateTime,
    icon = <Icon.Clock />,
    tooltipPlacement = DEFAULT_TOOLTIP_PLACEMENT,
    className,
    ...props
}: ItemDateProps) {
    if (typeof date === "number") {
        date = new Date(date);
    }

    const dateStringRef = useRef<HTMLSpanElement>(null);
    const tooltipId = useId();

    className = clsx(className, "bb-item-date");

    return (
        <ItemDisplay {...props} icon={icon} className={className}>
            <span aria-describedby={tooltipId} ref={dateStringRef} tabIndex={0}>
                {dateToString(date)}
            </span>
            <Tooltip id={tooltipId} placement={tooltipPlacement} target={dateStringRef}>
                {dateToTooltipContent(date)}
            </Tooltip>
        </ItemDisplay>
    );
}

ItemDisplay.Date = ItemDate;

export interface ItemBytesProps
    extends Overwrite<
        Omit<ItemDisplayProps, "children" | "icon"> & Partial<Pick<ItemDisplayProps, "icon">>,
        { variant?: TextVariant.NUMBER | TextVariant.SMALL_NUMBER | TextVariant.SMALL }
    > {
    /**
     * The number of bytes this display represents. Will be displayed, rounded, with nearest
     * base-10 unit less than the number of bytes.
     *
     * E.g. 1020 will be displayed as 1 KB, 2040 displayed as 2 KB, etc.
     */
    bytes: number;
    /**
     * Whether base2 units should be used (e.g. GiB instead of GB). Defaults to false.
     */
    base2?: boolean;
    /**
     * How many decimal places to display. Only has an effect if the places parameter is not
     * specified. If both are left undefined, one decimal place will be shown if there is only
     * one digit before the decimal place, otherwise no decimal places will be shown.
     */
    decimalPlaces?: number;
    /**
     * The number of places to display, including decimal places. Overrides the provided
     * decimalPlaces parameter when defined.
     */
    places?: number;
    /**
     * Whether to add a space between the number and the unit. Defaults to false.
     */
    addSpace?: boolean;
}

/**
 * A component for displaying a number of bytes with a byte suffix. Supports the various
 * formatting options of the displaySize function, supports adding links to the component, etc.
 */
function ItemBytes({
    bytes,
    base2,
    decimalPlaces,
    places,
    addSpace = true,
    icon = <Icon.File />,
    variant = TextVariant.NUMBER,
    className,
    ...props
}: ItemBytesProps) {
    className = clsx(className, "bb-item-bytes");
    return (
        <ItemDisplay {...props} icon={icon} className={className} variant={variant}>
            {Num.displaySize(bytes, { base2, decimalPlaces, places, addSpace })}
        </ItemDisplay>
    );
}

ItemDisplay.Bytes = ItemBytes;
