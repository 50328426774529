import Base = require("Everlaw/Base");
import Perm = require("Everlaw/PermissionStrings");
import User = require("Everlaw/User");
import ResultsTableView = require("Everlaw/ResultsTableView");
import { Str } from "core";

class SavedResultsTableView extends Base.SecuredObject implements ResultsTableView.LoadableView {
    get className() {
        return "SavedResultsTableView";
    }
    override id: SavedResultsTableView.Id;
    name: string;
    view: ResultsTableView;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        this.view = Base.set(ResultsTableView, this.view);
    }
    getColumnsWithWidths() {
        return this.view.getColumnsWithWidths();
    }
    getSort() {
        return this.view.getSort();
    }
    isMine() {
        return User.me.can(Perm.ADMIN, this);
    }
    override compare(other: SavedResultsTableView): number {
        // Project default view should always be at the top
        if (SavedResultsTableView.isDefault(this.name)) {
            return -1;
        }
        if (SavedResultsTableView.isDefault(other.name)) {
            return 1;
        }
        // First compare lowercased names so that we get A, a, B, b, C, c...
        // instead of A, B, C, ... a, b, c...
        let disp = this.name.toLowerCase().localeCompare(other.name.toLowerCase());
        if (disp !== 0) {
            return disp;
        }
        // If lowercased names are the same, compare names without lowercasing.
        disp = this.name.localeCompare(other.name);
        if (disp !== 0) {
            return disp;
        }
        // Compare by id.
        return this.id - other.id;
    }
    override display() {
        return this.name;
    }
}

module SavedResultsTableView {
    export const MAX_ENFORCED_NAME_LENGTH = 74;

    // Must match PROJECT_DEFAULT constant in ResultsTableViewService.java
    export const PROJECT_DEFAULT = " (Project default)";

    export type Id = number & Base.Id<"SavedResultsTableView">;

    export function isDefault(s: string) {
        return Str.endsWith(s, PROJECT_DEFAULT);
    }

    export function getNameWithoutDefaultText(sv: SavedResultsTableView) {
        if (!isDefault(sv.name)) {
            return sv.name;
        }
        return sv.name.substring(0, sv.name.length - PROJECT_DEFAULT.length);
    }
}

export = SavedResultsTableView;
