import Base = require("Everlaw/Base");
import Binder = require("Everlaw/Binder");
import Dom = require("Everlaw/Dom");
import NewMessage = require("Everlaw/Messaging/NewMessage");
import SearchResult = require("Everlaw/SearchResult");
import ShareableObject = require("Everlaw/Sharing/ShareableObject");
import User = require("Everlaw/User");
import { Composer } from "Everlaw/Messaging/NewMessage";
import {
    composeShareableSearch,
    NewSearchComposer,
    NewSearchMessage,
    NewSearchMessageParams,
    searchMessageComposer,
} from "Everlaw/Messaging/NewSearchMessage";
import {
    composeShareableSelectedSearch,
    NewSelectedSearchMessageParams,
} from "Everlaw/Messaging/NewSelectedSearchMessage";
import {
    composeShareableBinder,
    ShareableObjectParams,
} from "Everlaw/Messaging/ShareableBinderComposer";

/**
 * Convenience function that dispatches to the correct message/composer based on attachment, so the
 * caller doesn't need to worry about it.  If sharing an object, prefer calling `share()` below.
 */
export function compose(
    messageParams: NewMessage.Params = {},
    composerParams: NewMessage.ComposerParams = {},
    ComposerType: typeof Composer = Composer,
): Promise<NewMessage.Composer | null> {
    if (messageParams.attachment && messageParams.attachment instanceof SearchResult) {
        const searchMessageParams = messageParams as NewSearchMessageParams;
        searchMessageParams.searchObjectPermission = null;
        return searchMessageComposer(
            searchMessageParams,
            composerParams,
            NewSearchMessage,
            NewSearchComposer,
        );
    }

    return NewMessage.compose(messageParams, composerParams, ComposerType);
}

/**
 * Compose a message to Everlaw support.
 */
export function support(params?: {
    attach?: Base.Object;
    body?: Dom.Content;
}): Promise<NewMessage.Composer> {
    return NewMessage.compose(Object.assign({ to: User.byName("Everlaw") }, params));
}

/**
 * Use this method when sharing an object to generate a default subject line.  It will determine
 * what compose function should be used.
 */
export function share(
    messageParams: ShareableObjectParams,
    composerParams: NewMessage.ComposerParams = {},
): Promise<NewMessage.Composer | null> {
    // Searches + binders need to be handled differently.  Folders should also never go through this
    // function and instead should go through CardManager.FolderShareMessage.
    if (messageParams.attachment instanceof SearchResult) {
        // check for SelectedSearch params - true iff docIds exists and is populated
        if ((messageParams as NewSelectedSearchMessageParams).docIds?.length > 0) {
            return composeShareableSelectedSearch(
                messageParams as NewSelectedSearchMessageParams,
                composerParams,
            );
        } else {
            return composeShareableSearch(messageParams as NewSearchMessageParams, composerParams);
        }
    } else if (messageParams.attachment instanceof Binder) {
        return composeShareableBinder(messageParams, composerParams);
    } else {
        return composeShareableObject(messageParams, composerParams);
    }
}

export function getDefaultShareSubject(attachment: Base.Object): string {
    const classDisplay = ShareableObject.getClassDisplayName(attachment);
    return `${User.me.display()} has shared the ${classDisplay} ${attachment.display()} with you`;
}

export function getShareDialogTitle(attachment: Base.Object): string {
    const classDisplay = ShareableObject.getClassDisplayName(attachment).toLowerCase();
    return `Share ${classDisplay}`;
}

export function getShareDialogSubmitText(): string {
    return "Share";
}

/**
 * Construct a default composer when sharing an object on the platform.  Note that both searches and
 * binders should go through `NewSearchMessage.composeSharableSearch()` and
 * `ShareableBinderComposer.composeShareableBinder()` respectively.
 */
function composeShareableObject(
    messageParams: ShareableObjectParams,
    composerParams: NewMessage.ComposerParams = {},
): Promise<NewMessage.Composer | null> {
    messageParams.subject = getDefaultShareSubject(messageParams.attachment);

    composerParams.dialogTitle = getShareDialogTitle(messageParams.attachment);
    composerParams.submitText = getShareDialogSubmitText();

    return compose(messageParams, composerParams);
}
