import { userHasSupportedBrowser } from "core";

if (!userHasSupportedBrowser()) {
    const browserWarning = document.getElementById("browser-warning");
    if (browserWarning) {
        browserWarning.className = "";
    }
    const loginContent = document.getElementById("login-content");
    if (loginContent) {
        loginContent.classList.add("content--compact");
    }
}
