import { Arr, Str } from "core";
import Base = require("Everlaw/Base");
import Dom = require("Everlaw/Dom");
import Project = require("Everlaw/Project");
import { Recipient } from "Everlaw/Recipient";
import Security = require("Everlaw/Security");
import ShareableObject = require("Everlaw/Sharing/ShareableObject");
import { ProjectRole } from "Everlaw/Security";

/**
 * Contains everything related to Secured Object permission checking and granting.
 *
 * Author: Jiatao
 */
class SearchObjectPermission {
    private params: SearchObjectPermission.Params = {};

    constructor(params: SearchObjectPermission.Params) {
        Object.assign(this.params, params);
    }

    createWarningMessage(eqlString: string): Dom.Content[] {
        const warningMessageContent: Dom.Content[] = [];
        let warningStrings = [];
        warningStrings.push(
            'Object search terms using "Any object" are subject to recipients\' object permissions.',
        );

        const allObjectsReceiveWarning = this.params.shareableObjectClassesRecipientsCannotReceive
            ? "Receive permissions on all objects"
            : "";
        const allObjectsViewWarning = this.displayAllObjClassesRequireViewPermission();

        if (allObjectsReceiveWarning || allObjectsViewWarning) {
            const and = allObjectsReceiveWarning && allObjectsViewWarning ? " and " : "";
            warningStrings.push(
                `When sharing with
                "${Base.get(Security.ProjectRolePrimitive, ProjectRole.PROJECT_READ).name}", 
                or an organization, only users with ${allObjectsReceiveWarning}${and}
                ${allObjectsViewWarning} referenced in the search will receive the message`,
            );
        }

        // Add period if there are multiple warnings
        warningStrings =
            warningStrings.length >= 2
                ? warningStrings.map((warning) => (warning += "."))
                : warningStrings;

        for (const warning of warningStrings) {
            warningMessageContent.push(warning, Dom.br());
        }

        return warningMessageContent;
    }

    private displayAllObjClassesRequireViewPermission() {
        if (this.params.objectClassesRequireViewPermissions) {
            this.params.objectClassesRequireViewPermissions.sort();

            const classesExceptLast = this.params.objectClassesRequireViewPermissions
                .slice(0, this.params.objectClassesRequireViewPermissions.length - 1)
                .map((c) => Str.camelToHuman(Str.pluralForm(c, 2)))
                .join(", ");
            const and = this.params.objectClassesRequireViewPermissions.length > 1 ? " and " : " ";
            const maxIndex = this.params.objectClassesRequireViewPermissions.length - 1;
            const classesLast = Str.camelToHuman(
                Str.pluralForm(this.params.objectClassesRequireViewPermissions[maxIndex], 2),
            );

            return "View permissions to all " + classesExceptLast + and + classesLast;
        } else {
            return "";
        }
    }

    /**
     * Decide if lock the current recipient's row in the sharing dialog drop-down menu
     */
    shouldLockRecipient(recipient: Recipient, overrideSender = false): boolean {
        return (
            (!overrideSender && this.hasShareableObjectsSenderCannotShare(recipient))
            || this.hasShareableObjectClassesRecipientCannotReceive(recipient)
            || this.hasSecuredCodesRecipientCannotView(recipient)
            || this.hasReviewObjectClassesRecipientCannotView(recipient)
            || (recipient.className === "User" && this.hasProjectsRecipientsCannotView(recipient))
        );
    }

    private recipientNotHasAllObjectPermissions(recipient: Recipient) {
        return !(
            this.params.recipientsWithAllShareableObjectsPermissions
            && recipient.className in this.params.recipientsWithAllShareableObjectsPermissions
            && Arr.contains(
                this.params.recipientsWithAllShareableObjectsPermissions[recipient.className],
                recipient.id.toString(),
            )
        );
    }

    hasInvalidTerms(): boolean {
        return this.params.invalidTermsInSearch;
    }

    getAllShareableObjectsRecipientsCannotView(): ObjectsRecipientsCannotView {
        return this.params.shareableObjectsRecipientsCannotView;
    }

    hasShareableObjectsSenderCannotShare(recipient: Recipient): boolean {
        return (
            !!this.getShareableObjectsSenderCannotShare()
            && this.recipientNotHasAllObjectPermissions(recipient)
        );
    }

    getShareableObjectsSenderCannotShare(): ObjectsSenderCannotShare {
        return this.params.shareableObjectsSenderCannotShare;
    }

    hasShareableObjectClassesRecipientCannotReceive(recipient: Recipient): boolean {
        return !!this.getShareableObjectClassesRecipientCannotReceive(recipient);
    }

    getShareableObjectClassesRecipientCannotReceive(recipient: Recipient): string[] {
        return this.getRecipientData(
            this.params.shareableObjectClassesRecipientsCannotReceive,
            recipient,
        );
    }

    hasReviewObjectClassesRecipientCannotView(recipient: Recipient): boolean {
        return !!this.getReviewObjectClassesRecipientCannotView(recipient);
    }

    getReviewObjectClassesRecipientCannotView(recipient: Recipient): string[] {
        return this.getRecipientData(
            this.params.reviewObjectClassesRecipientsCannotView,
            recipient,
        );
    }

    hasSecuredCodesRecipientCannotView(recipient: Recipient): boolean {
        return !!this.getSecuredCodesRecipientCannotView(recipient);
    }

    getSecuredCodesRecipientCannotView(recipient: Recipient): { [objectClass: string]: string[] } {
        return this.getRecipientData(this.params.securedCodesRecipientsCannotView, recipient);
    }

    hasShareableObjectsRecipientCannotView(recipient: Recipient): boolean {
        return !!this.getShareableObjectsRecipientCannotView(recipient);
    }

    getShareableObjectsRecipientCannotView(recipient: Recipient): {
        [objectClass: string]: { [objectId: string]: string };
    } {
        return this.getRecipientData(this.params.shareableObjectsRecipientsCannotView, recipient);
    }

    hasProjectsRecipientsCannotView(recipient: Recipient): boolean {
        return !!this.getProjectsRecipientsCannotView(recipient);
    }

    getProjectsRecipientsCannotView(recipient: Recipient): string[] {
        return (
            this.params.projectsRecipientsCannotView
            && this.params.projectsRecipientsCannotView[recipient.id]
            && Base.get(Project, this.params.projectsRecipientsCannotView[recipient.id]).map(
                (proj) => proj.display(),
            )
        );
    }

    private getRecipientData<T>(
        field: { [recipientType: string]: { [recipientId: string]: T } },
        recipient: Recipient,
    ) {
        return field && field[recipient.className] && field[recipient.className][recipient.id];
    }
}

interface ObjectsSenderCannotShare {
    [objectClass: string]: string[];
}

interface ObjectClassesRecipientsCannotReceiveOrView {
    [recipientType: string]: { [userId: string]: string[] };
}

interface SecuredCodesRecipientsCannotView {
    [recipientType: string]: { [recipientId: string]: { [objectClass: string]: string[] } };
}

interface ObjectsRecipientsCannotView {
    [recipientType: string]: {
        [recipientId: string]: { [objectClass: string]: { [objectId: string]: string } };
    };
}

interface RecipientsWithAllObjPerms {
    [recipientType: string]: string[];
}

interface ProjectsRecipientsCannotView {
    [recipientId: string]: number[];
}

module SearchObjectPermission {
    export interface Params {
        /**
         * Counts of each class of SecuredObjects in search that are deleted or current user does not
         * have View access to.
         */
        invalidTermsInSearch?: boolean;
        /**
         * All shareable SecuredObjects of which the current user does not have Share/Delete permission.
         */
        shareableObjectsSenderCannotShare?: ObjectsSenderCannotShare;
        /**
         * All shareable SecuredObjects classes that each User/UserGroup does have project-level
         * Receive permission to.
         */
        shareableObjectClassesRecipientsCannotReceive?: ObjectClassesRecipientsCannotReceiveOrView;
        /**
         * All review objects classes, which could be Redaction, Note, Highlight, or Rating,
         * that each User/UserGroup does have project-level View permission to.
         */
        reviewObjectClassesRecipientsCannotView?: ObjectClassesRecipientsCannotReceiveOrView;
        /**
         * Codes that each User/UserGroup does not have View permission to.
         */
        securedCodesRecipientsCannotView?: SecuredCodesRecipientsCannotView;
        /**
         * All object classes some recipient may be required to have View permission to, which could be
         * any from Code, UserMetadataField, Redaction, Note, Highlight and Rating.
         */
        objectClassesRequireViewPermissions?: string[];
        /**
         * All shareable SecuredObjects that each recipient does not have View permission to.
         */
        shareableObjectsRecipientsCannotView?: ObjectsRecipientsCannotView;
        /**
         * Recipients who already have view permissions to all shareable SecuredObjects in the search.
         */
        recipientsWithAllShareableObjectsPermissions?: RecipientsWithAllObjPerms;
        /**
         * All projects some recipient may be required to have View permission
         */
        projectsRecipientsCannotView?: ProjectsRecipientsCannotView;
    }

    export function displayProjectPermission(objectClass: string) {
        const classInfo = ShareableObject.getClassInfo(objectClass);
        return classInfo?.projectPermissionName || Str.pluralForm(Str.camelToHuman(objectClass), 2);
    }

    export function displayObjectClass(objectClass: string) {
        const classInfo = ShareableObject.getClassInfo(objectClass);
        return classInfo?.displayName || Str.camelToHuman(objectClass);
    }
}

export = SearchObjectPermission;
