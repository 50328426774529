import { Memo } from "hooks/useBranded";
import { MultiValueRefObject, refObjectValues } from "hooks/useMultiValueRef";
import { RefObject, useEffect } from "react";
import { filterNonNullish, wrap } from "core";

/**
 * Handles registering event listeners and automatically performs cleanup. This hook should only be
 * used in cases where it's impractical to work within React's event system
 * (https://reactjs.org/docs/events.html).
 *
 * `events` is expected to either be a single or an array of valid event types. All events passed
 * through an array of events will be checked for changes between renders (order matters).
 *
 * If the listener function is defined in a functional component, it should be memoized using
 * something like `useCallback` so that referential equality is maintained between renders if
 * nothing relevant has changed. This is to prevent excess addition and removal of the listener
 * function during re-renders.
 */
export function useEventListener<E extends Node = Node>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: RefObject<E> | MultiValueRefObject<any, E> | E | null,
    events: string | string[],
    listener: Memo<EventListener>,
): void {
    const eventsArray = wrap(events);
    useEffect(() => {
        let els: E[] = [];
        if (element instanceof Node) {
            els = [element];
        } else if (element) {
            els = filterNonNullish(refObjectValues(element));
        }
        eventsArray.forEach((event) => els.forEach((el) => el.addEventListener(event, listener)));
        return () => {
            eventsArray.forEach((event) =>
                els.forEach((el) => el.removeEventListener(event, listener)),
            );
        };
        // Disable exhaustive-deps lint rule so that we can use eventsArray.join() to check if any
        // of the event strings has changed. Otherwise, useEffect could be unnecessarily triggered
        // if the array does not have referential equality between renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element, eventsArray.join(","), listener]);
}
