import Bugsnag = require("Everlaw/Bugsnag");
import { Arr, Compare as Cmp, Is } from "core";
import { PubSubChannel } from "Everlaw/PubSubChannel";
import Util = require("Everlaw/Util");
import React, { useEffect, useState } from "react";
import { Color, colorAsHex } from "Everlaw/ColorUtil";
import { useLatest } from "design-system";

/*
 * NOTES ABOUT THE Base.ts MODULE
 *
 * In this module, "Obj" is the Base.Object class - it needs a different local name to avoid
 * clashing with the JavaScript global. https://github.com/Microsoft/TypeScript/issues/17494.
 *
 * This module contains:
 * - The Everlaw Base Object (EBO) base class, used to store most of the backend entities we are
 *   sent by the server.
 * - The Store classes that provide a database-like interface for managing EBO state.
 * - The "global store" that consists of one object store per EBO className, along with its APIs.
 * - React hooks to use state from a Store.
 *
 * The traditional (non-React) API consists of the Base.{set,get,subscribe,publish,reset,add,remove}
 * functions, which are backed by the global store. Typically, data returned at page load time via
 * the JSP is used to populate the global store.
 *
 * Later, the Base.Store interface and class hierarchy was introduced. By making the store explicit,
 * we gain the flexibility of being able to create multiple independent stores of the same EBO
 * class, or even create "virtual" stores representing things like subsets or unions.
 *
 * The Base.ReactStore interface extends Base.Store by adding methods needed for React code to hook
 * into store functionality via the useStore() and useStoreObject() hooks below.
 */

/**
 * Untyped JSON data that can be converted to a Base Obj class instance.
 */
export type ObjJson = { id: string | number };

/**
 * Accepts untyped JSON data and converts it to object(s) of the given baseClass, returning the
 * object(s). The data can either be a single object--in which case a single object is returned--or
 * an array--in which case an array of objects is returned.
 *
 * If an existing object has the same type and id, the data is mixed into that object via a call to
 * that object's _mixin method. Otherwise, a new object is constructed from the data. This means
 * that, assuming Base.set is used for all object construction, there is at most one object for
 * every (classname, id) pair in memory at any given time. This means that any references to
 * existing objects will automatically get updated with new data as it is fetched.
 *
 * Base.subscribe can be used to subscribe to changes to a baseClass. When Base.set is called, it
 * calls Base.publish to synchronously notify all subscribers of the changes. Thus, by the time a
 * Base.set call returns, all subscribers have been notified of the changes to any EBOs described by
 * data.
 *
 * Base.update is like Base.set except that no new objects are created;
 * it also returns void no matter what.
 */
export function set<O extends Obj>(baseClass: Class<O>, data: ObjJson): O;
export function set<O extends Obj>(baseClass: Class<O>, data: ObjJson[]): O[];
export function set(baseClass: Class<Obj>, data: any) {
    if (!data) {
        Bugsnag.notify(
            Error(`Called Base.set(${baseClass.prototype.className}, ...) with null or undefined.`),
        );
        return null;
    }
    let objs: Obj | Obj[];
    let anyCreated = false;
    if (Is.array(data)) {
        objs = [];
        (data as ObjJson[]).forEach((d) => {
            const result = findOrCreate(baseClass, d);
            (objs as Obj[]).push(result.obj);
            anyCreated = anyCreated || result.created;
        });
    } else {
        const result = findOrCreate(baseClass, data);
        objs = result.obj;
        anyCreated = result.created;
    }
    publish(objs, false, anyCreated);
    return objs;
}

export function update<O extends Obj>(baseClass: Class<O>, data: ObjJson): void;
export function update<O extends Obj>(baseClass: Class<O>, data: ObjJson[]): void;
export function update(baseClass: Class<Obj>, data: any) {
    if (!data) {
        Bugsnag.notify(
            Error(
                `Called Base.update(${baseClass.prototype.className}, ...) with null or undefined.`,
            ),
        );
        return null;
    }
    const res: any[] = Arr.flat(
        Arr.wrap(data).map((d) => {
            if (d.id) {
                const old = get(baseClass, d.id);
                if (old) {
                    old._mixin(d);
                    return [old];
                }
            }
            return [];
        }),
    );
    publish(res);
}

// baseClass must be a class declared using declare, e.g. Base.Object.
// Four ways to call:
// 1. Base.get(ESI.Foo) --> returns an array of all Foo objects
// 2. Base.get(ESI.Foo, key) --> returns Foo with id, or undefined
// 3. Base.get(ESI.Foo, [keys]) --> returns Foos with matching ids, nulls removed
// 4. Base.get(ESI.Foo, [keys], true) --> returns Foos with matching ids, nulls in place
// In most cases the key is the id of the associated object, but Objects that override getKey should
// use that as the input instead.
export function get<O extends Obj>(baseClass: string | { prototype: O }, key: string | number): O;
export function get<O extends Obj>(
    baseClass: string | { prototype: O },
    keyList?: (string | number)[],
    wantnulls?: boolean,
): O[];
export function get(baseClass: string | { prototype: Obj }, keyList?: any): any {
    const s = globalStore(baseClass);
    if (!Is.defined(keyList) || Is.array(keyList)) {
        return s.getAll(keyList);
    } else {
        // just a single key
        return s.get(keyList);
    }
}

export interface Listener<O> {
    (val: O[], removed: boolean): void;
}

/**
 * Registers callback to receive notifications when objects of type baseClass are created or
 * updated. It is called with two arguments:
 *  - an array of updated objects
 *  - boolean that is true when the given objects are being removed
 */
export function subscribe<O extends Obj>(baseClass: { prototype: O }, callback: Listener<O>) {
    return {
        unsubscribe: globalStore(baseClass).subscribe(callback),
    };
}

// Gets called by set() when new items are registered; if your class updates its
// items internally after a REST call, you should call publish yourself.
export function publish(objs: Obj | Obj[], removed?: boolean, anyCreated?: boolean): void {
    const byClass: { [className: string]: Obj[] } = {};
    Arr.wrap(objs).forEach((o) => {
        Util.getDefault(byClass, o.className, []).push(o);
    });
    for (const [key, val] of Object.entries(byClass)) {
        globalStore(key).publish(val, removed, anyCreated);
    }
}

// Replaces all instance of the EBO class with newData.
export function reset(dojoCls: Class<Obj>, newData?: { id: any }[]) {
    globalStore(dojoCls).clear();
    if (newData) {
        set(dojoCls, newData);
    }
}

// Takes an array of primitives, returns an array of Base.Primitives
export function wrapPrimitives<T extends string | number>(ary: T[]) {
    return ary.map((a) => new Primitive(a));
}

abstract class Obj {
    abstract get className(): string;
    id: string | number;
    constructor(params: any) {
        this.id = params.id;
    }
    // Function that's called by set() when object gets updated with new data
    // from the server. Generally, your constructor will do some one-time stuff
    // and then invoke this function to perform actions that will occur on every
    // update.
    _mixin(params: any) {
        // You almost certainly want to override this.
    }
    /**
     * Return a unique key for the object, used to store the object in a map. In most cases the id
     * of the object suffices for this purpose. This should be overridden when distinct objects can
     * have the same id (e.g. situations where objects from multiple parcels are loaded to the
     * frontend at once).
     *
     * Similar to the id field of the property, you may consider overriding the return type here
     * with a type like `type FooKey = string & Base.Id<"FooKey">` for some added type safety; see
     * also comments on Base.Id.
     */
    getKeyFrom(data: ObjJson): string | number {
        return data.id;
    }
    /**
     * @see getKeyFrom
     */
    getKey(): string | number {
        return this.getKeyFrom(this);
    }
    // Override this to provide better type-safety if you want it.
    // If you override this method, make sure it is consistent with compare:
    // this.equals(other) iff this.compare(other) === 0
    equals(other: Obj | undefined | null) {
        return !!other && this.id === other.id && this.className === other.className;
    }
    // If you override this method, make sure it is consistent with equals:
    // this.equals(other) iff this.compare(other) === 0
    compare(other: Obj): number {
        return Cmp.str(this.display(), other.display()) || Cmp.full(this.id, other.id);
    }
    hash() {
        return this.className + this.id;
    }
    // Return a text string that will display this element in most contexts. Implementations of
    // this method should not escape the text in any way.
    display() {
        return this.className + this.id;
    }
    findIn(ary: Obj[]) {
        for (let i = 0; i < ary.length; i++) {
            if (this.equals(ary[i])) {
                return i;
            }
        }
        return -1;
    }
}
export { Obj as Object };

/**
 * An Obj that includes the parcel the entity came from. Used in cases where objects from multiple
 * parcels are loaded to the frontend at once, meaning the id of the object may not be unique.
 *
 * Remark: some existing objects are loaded from multiple parcels, but do not extend this. Instead,
 * they use enforce unique ids across parcels (see GlobalIds.java).
 */
export abstract class GlobalObject extends Obj {
    parcel: Parcel;
    static key(id: string | number, parcel: Parcel): string {
        return `id-${id}_parcel-${parcel}`;
    }
    override getKeyFrom(data: { id: string | number; parcel: Parcel }): string {
        return GlobalObject.key(data.id, data.parcel);
    }
    override getKey(): string {
        return this.getKeyFrom(this);
    }
}

// A list of permissions (from PermissionStrings.ts) that an sid has on an object.
export type PermList = string[];

// A map of sid --> permission list for an object.
export interface ACL {
    [sid: string]: PermList;
}

export interface Secured {
    security?: PermList;
    fullSecurity?: ACL; // serialized on-demand (e.g., settings page)
    readSecurity?: string[]; // list of user SIDs, serialized on-demand (e.g., storybuilder page)
}

/**
 * A base object that is controlled by permissions. NOTE: This frontend SecuredObject class is not
 * the same as the backend SecuredObject interface. The latter is only for objects that are
 * controlled by ACL entries, while the former refers to any objects where the Secured fields are
 * serialized to the frontend. Project and BaseNote, for example, are not controlled by ACL entries
 * and hence do not implement the Java SecuredObject interface, but their permissions are serialized
 * into the security fields and hence they are a frontend SecuredObject.
 */
export abstract class SecuredObject extends Obj implements Secured {
    security: PermList;
    fullSecurity: ACL;
    readSecurity: string[];
    constructor(params: any) {
        super(params);
        this.security = params.security;
        this.fullSecurity = params.fullSecurity;
        this.readSecurity = params.readSecurity;
    }
}

/**
 * Dummy class to add type-safety to the various object IDs we use. Intersect this with the actual
 * runtime representation (number or string) to denote an ID type that can't be accidentally used
 * where a different type of ID is needed. For example, given
 *     type FooId = number & Id<"Foo">;
 *     type BarId = number & Id<"Bar">;
 * TypeScript will report any attempt to use a FooId as a BarId, or vice versa.
 * By convention, we set the type parameter T to the corresponding class's className string.
 */
export declare class Id<T> {
    // declare = don't actually generate code for this class
    private constructor(); // don't allow instantiation or subclassing
    private idBrand: T;
}

/** Read-only Store interface suitable for UI widgets. */
export interface Store<O extends Obj> {
    readonly name: string;
    get: (id: O["id"]) => O | undefined;
    getAll: (idlist?: O["id"][]) => O[];
    subscribe: (callback: Listener<O>) => () => void;
}

/**
 * To trigger React component re-rendering, we wrap store objects in a "reference object" that
 * changes whenever the object is mutated.
 */
export interface ObjectRef<O extends Obj> {
    obj: O;
}

/**
 * useState() setter passed to reactSubscribe().
 */
export type ReactStoreSubSetter<O extends Obj> = React.Dispatch<
    React.SetStateAction<ObjectRef<O>[]>
>;

/**
 * Update published by the core channels used in reactSubscribeToObject(), to support the
 * useStoreObject() hook. The optional object allows the hook to know when an object is removed.
 */
export interface ReactStoreObjectSubUpdate<O extends Obj> {
    obj?: O;
}

/**
 * useState() setter passed to reactSubscribeToObject().
 */
export type ReactStoreObjectSubSetter<O extends Obj> = React.Dispatch<
    React.SetStateAction<ReactStoreObjectSubUpdate<O>>
>;
/** Type used to identify and track publish() events (i.e., changes to the store). */
export type ReactStorePublishId = Record<string, never>;

/**
 * Store that provides additional methods needed by the useStore and useStoreObject React hooks.
 */
export interface ReactStore<O extends Obj> extends Store<O> {
    /** Returns an ObjectRef for the given ID, or undefined if no such object exists. */
    reactGet: (id: O["id"]) => ObjectRef<O> | undefined;
    /** Returns an array of all store objects wrapped in their references. */
    reactGetAll: () => ObjectRef<O>[];
    /**
     * Subscribe to store updates.
     *
     * @param setter The useState() setter to call when the store is modified. The setter will be
     *               passed the updated list of all store objects.
     * @param ignoreMutations If true, only store additions and deletions will trigger a state
     *                        change. This can be a useful performance optimization when a parent
     *                        component that subscribes to a Store doesn't need to know when a child
     *                        component's object has been mutated.
     */
    reactSubscribe: (setter: ReactStoreSubSetter<O>, ignoreMutations?: boolean) => () => void;
    /**
     * Subscribe to individual object updates. If the object is deleted, a final publish will happen
     * with an undefined value passed.
     *
     * @param obj The object to subscribe to.
     * @param setter The useState() setter to call when the object is mutated or deleted.
     */
    reactSubscribeToObject: (obj: O, setter: ReactStoreObjectSubSetter<O>) => () => void;
    /**
     * Returns an ID that changes whenever a publish (modification) is made to the store. IDs can
     * be compared with === to check if the store has been modified.
     */
    getReactPublishId: () => ReactStorePublishId;
}

const globalStores: { [className: string]: SimpleStore<Obj> } = {};

export function globalStore<O extends Obj>(baseClass: string | { prototype: O }): SimpleStore<O> {
    const name = typeof baseClass === "string" ? baseClass : baseClass.prototype.className;
    return (globalStores[name]
        || (globalStores[name] = new SimpleStore(name))) as unknown as SimpleStore<O>;
}

/** The normal ReactStore implementation, allowing adding and removing objects. */
export class SimpleStore<O extends Obj> implements ReactStore<O> {
    private objMap: { [key in string | number]: ObjectRef<O> } = Object.create(null);
    // Channels for non-React subscriptions.
    private listeners = new PubSubChannel<{ objArray: O[]; removed: boolean }>();
    // Channels for React useStore() hook users.
    private reactChannel = new PubSubChannel<ObjectRef<O>[]>();
    // Channels for React useStore() hook users who specify ignoreMutations=true.
    private reactIgnoreMutationsChannel = new PubSubChannel<ObjectRef<O>[]>();
    // Channels for React useStoreObject() hook users. The channels are created on-demand as needed.
    private reactObjectChannels: {
        [key in string | number]: PubSubChannel<ReactStoreObjectSubUpdate<O>>;
    } = Object.create(null);
    // Object that gets updated whenever a publish() occurs.
    private reactPublishId: ReactStorePublishId = {};

    constructor(
        readonly name: string,
        registerGlobal = true,
    ) {
        if (registerGlobal) {
            if (globalStores[name]) {
                throw Error("Global store for class " + name + " already exists!");
            }
            globalStores[name] = this as unknown as SimpleStore<Obj>;
        }
    }
    add(obj: O): void {
        this.objMap[obj.getKey()] = { obj };
        // we don't publish on add because .set does it for us
    }
    remove(obj: O | O[]): void {
        obj = Arr.wrap(obj);
        for (const o of obj) {
            delete this.objMap[o.getKey()];
        }
        this.publish(obj, true);
    }
    get(key: O["id"]): O | undefined {
        const ref = this.objMap[<string | number>key];
        return ref ? ref.obj : undefined;
    }
    getAll(keyList?: O["id"][]): O[] {
        if (keyList) {
            return keyList.map(this.get, this).filter(Is.defined);
        } else {
            return Object.values(this.objMap).map((ref: ObjectRef<O>) => ref.obj);
        }
    }
    subscribe(callback: Listener<O>): () => void {
        return this.listeners.subscribe(({ objArray, removed }) => callback(objArray, removed));
    }

    /** Returns an ObjectRef for the given ID, or undefined if no such object exists. */
    reactGet(key: O["id"]): ObjectRef<O> | undefined {
        return this.objMap[<string | number>key];
    }

    /** Returns an array of all objects wrapped in their references. */
    reactGetAll(): ObjectRef<O>[] {
        return Object.values(this.objMap);
    }

    /**
     * Subscribes to store changes.
     * @param setter The React useState setter to update the list of store objects on change.
     * @param ignoreMutations If true, the setter will only be called when objects are added or
     *                        removed from the store, not when existing objects are mutated. This is
     *                        a useful performance optimization for parent components that don't
     *                        need to know when a child component's object has been mutated.
     */
    reactSubscribe(setter: ReactStoreSubSetter<O>, ignoreMutations?: boolean): () => void {
        return ignoreMutations
            ? this.reactIgnoreMutationsChannel.subscribe(setter)
            : this.reactChannel.subscribe(setter);
    }

    /**
     * Returns an object that changes whenever a change is made to the store. Can be used along with
     * Object.is() to compare two contexts and check if any calls to publish() have happened.
     */
    getReactPublishId(): ReactStorePublishId {
        return this.reactPublishId;
    }

    /**
     * Subscribes to individual object changes.
     * @param obj The object to subscribe to.
     * @param setter The React state setter that will update the (wrapped) object on change. If the
     *               object is deleted from the store, undefined will be passed to the setter.
     */
    reactSubscribeToObject(obj: O, setter: ReactStoreObjectSubSetter<O>): () => void {
        if (!obj) {
            return () => {};
        }
        // Create the channel if it doesn't exist yet.
        let channel = this.reactObjectChannels[obj.getKey()];
        if (!channel) {
            channel = new PubSubChannel();
            this.reactObjectChannels[obj.getKey()] = channel;
        }
        return channel.subscribe(setter);
    }

    /**
     * Publish changes to the store.
     * @param objs The list of updated objects
     * @param removed true if the entire list of objects was removed from the store.
     * @param anyCreated true if any of the updated objects were added to the store (can only be
     *                   true when removed is false).
     */
    publish(objs: O | O[], removed = false, anyCreated?: boolean): void {
        const objArray = Arr.wrap(objs);

        // Publish for non-React subscribers.
        this.listeners.publish({ objArray, removed });

        // Publish for React subscribers
        //
        // In the non-removal case, we need to iterate through the objects and update their wrappers
        // in the store before we publish any changes. However, we want to publish to store
        // subscribers first before publishing to object subscribers, in order to avoid the awkward
        // removal case where child components see the removal and update state before the parent
        // component has a chance to potentially clean up.
        //
        // To avoid iterating through the objects twice, on the first iteration we build an array
        // of publish() callbacks that we then invoke after store subscribers have been updated.
        const objectPublishCallbacks: (() => void)[] = [];
        objArray.forEach((obj) => {
            const newRef: ObjectRef<O> = { obj };
            if (!removed) {
                // Update the object in the store with the new wrapper.
                this.objMap[obj.getKey()] = newRef;
            }
            const channel = this.reactObjectChannels[obj.getKey()];
            channel && objectPublishCallbacks.push(() => channel.publish(removed ? {} : newRef));
        });

        // Publish to React store subscribers. Check first if we even have any subscribers to
        // notify before fetching the list of objects.
        const removedOrCreated = removed || anyCreated;
        if (
            this.reactChannel.hasSubscription()
            || (removedOrCreated && this.reactIgnoreMutationsChannel.hasSubscription())
        ) {
            const allObjs = this.reactGetAll();
            this.reactChannel.publish(allObjs);
            removedOrCreated && this.reactIgnoreMutationsChannel.publish(allObjs);
        }

        // Publish to React object subscribers.
        objectPublishCallbacks.forEach((cb) => cb());

        // Update our "context"
        this.reactPublishId = {};
    }

    clear(): void {
        const objs = this.getAll();
        this.objMap = Object.create(null);
        this.publish(objs, true);
    }
}

export function constantStore<O extends Obj>(objects: O[]): Store<O> {
    const store = new SimpleStore<O>(objects[0].className, false);
    objects.forEach(store.add, store);
    return store;
}

/**
 * Creates a SimpleStore with no elements, useful for example when creating an empty table.
 */
export function emptyStore<O extends Obj>(className: string) {
    return new SimpleStore<O>(className, false);
}

export interface Class<O extends Obj> {
    new (json: any): O;
    prototype: O;
}

/**
 * A store that is populated from JSON data supplied by the server.
 * <p>
 * Note that unlike Base.set, the .set/.setAll methods will throw TypeError if .id is missing, since
 * that likely indicates that the wrong object was passed in - an easy mistake to make with the
 * untyped data coming out of a Rest request.
 */
export class JsonStore<O extends Obj> extends SimpleStore<O> {
    constructor(
        readonly baseClass: Class<O>,
        registerGlobal?: boolean,
    ) {
        super(baseClass.prototype.className, registerGlobal);
    }
    set(data: { id: O["id"] }) {
        this.setAll(Arr.wrap(data));
    }
    setAll(data: { id: O["id"] }[]) {
        let anyCreated = false;
        const objs: O[] = [];
        data.forEach((d) => {
            const results = this.findOrCreate(d);
            objs.push(results.obj);
            anyCreated = anyCreated || results.created;
        });
        this.publish(objs, false, anyCreated);
        return objs;
    }
    private findOrCreate(data: { id: O["id"] }): { obj: O; created: boolean } {
        if (data.id == null) {
            throw TypeError("missing ID");
        }
        const old = this.get(data.id);
        if (old) {
            old._mixin(data);
            return { obj: old, created: false };
        }
        const obj = new this.baseClass(data);
        this.add(obj);
        return { obj, created: true };
    }
}

// Interface for EBOs that have a display color associated with them.
export interface Colored extends Obj {
    getColor(): Color;
}

export function isColored(e: any): e is Colored {
    return "getColor" in e;
}

// Dummy class for strings and other primitives
export class Primitive<ID extends string | number> extends Obj {
    get className() {
        return "Primitive";
    }
    override id: ID;
    color: string;
    constructor(
        id: ID,
        public name = String(id),
    ) {
        super({ id: id });
    }
    override display() {
        return this.name;
    }
}

// Dummy class that holds a type of data. Useful with UI_Select classes and others
// that require EBOs.
export class DataPrimitive<T> extends Primitive<string | number> {
    override get className() {
        return "DataPrimitive";
    }
    constructor(
        public data: T,
        id: string | number,
        name?: string,
    ) {
        super(id, name);
    }
    getColor() {
        return this.color || colorAsHex(this.data);
    }
}

/**
 * Almost always called by Base.set. You should call this in your child object when you've created
 * an id-less object on the client and then commit it to the server. The server responds with an id,
 * which you then set in your client object. Then you call Base.add(obj) to register the newly
 * formed object with Base.
 */
export function add(obj: Obj) {
    globalStore(obj.className).add(obj);
}

/**
 * Remove the object(s) specified by obj. If specifying an array of objects, it's assumed they all
 * of the same Base type (i.e. they have the same classname).
 */
export function remove(obj: Obj | Obj[]) {
    obj = Arr.wrap(obj);
    if (obj.length) {
        globalStore(obj[0].className).remove(obj);
    }
}

function findOrCreate(baseClass: Class<Obj>, data: ObjJson): { obj: Obj; created: boolean } {
    const key = baseClass.prototype.getKeyFrom(data);
    if (key) {
        const old = get(baseClass, key);
        if (old) {
            old._mixin(data);
            return { obj: old, created: false };
        }
    }
    const obj = new baseClass(data);
    if (obj.getKey() !== null) {
        add(obj);
    }
    return { obj, created: true };
}

/**
 * React hook for subscribing to ReactStore updates.
 *
 * @param store The store to subscribe to.
 * @param ignoreMutations If true, will only trigger a state change on store additions and removals,
 *                        not on existing object mutations. This is a useful performance
 *                        optimization for parent components that don't need to know when a child
 *                        component's object has been mutated.
 * @returns The list of (wrapped) objects in the store.
 */
export function useStore<O extends Obj>(
    store: ReactStore<O>,
    ignoreMutations = false,
): ObjectRef<O>[] {
    const initialPublishIdRef = useLatest<ReactStorePublishId>(store.getReactPublishId());
    const [storeObjects, setStoreObjects] = useState(store.reactGetAll());

    useEffect(() => {
        // Because useEffect() is run asynchronously after useStore() has been called, we need
        // to handle the potential race condition where a store update has been published in
        // between these two calls.
        const curPublishId = store.getReactPublishId();
        if (curPublishId && curPublishId !== initialPublishIdRef.current) {
            // Explicitly update now, as publish() was called before this useEffect() ran.
            setStoreObjects(store.reactGetAll());
        }
        store.reactSubscribe(setStoreObjects, ignoreMutations);
    }, [ignoreMutations, store, initialPublishIdRef]);

    return storeObjects;
}

/**
 * React hook for subscribing to ReactStore object updates. Triggers a state change whenever the
 * object is mutated or deleted (in which case the object will be undefined).
 *
 * @param store The store containing the object.
 * @param objId The ID of the object.
 * @returns The object, or undefined if the object no longer exists
 */
export function useStoreObject<O extends Obj>(store: ReactStore<O>, objId: O["id"]): O | undefined {
    // A weird variable name here, but useLatest() returns a React ref to an ObjectRef.
    const refToInitialObjRef = useLatest<ObjectRef<O> | undefined>(store.reactGet(objId));
    const [objUpdate, setObjUpdate] = useState<ReactStoreObjectSubUpdate<O>>(() => {
        return refToInitialObjRef.current ? { obj: refToInitialObjRef.current.obj } : {};
    });

    useEffect(() => {
        // Because useEffect() is run asynchronously after useStoreObject() has been called, we need
        // to handle the potential race condition where an object update has been published in
        // between these two calls.
        const curObjRef = store.reactGet(objId);
        if (objUpdate.obj) {
            if (curObjRef && !Object.is(curObjRef, refToInitialObjRef.current)) {
                // Explicitly update, as publish() was called before this useEffect() ran.
                setObjUpdate(curObjRef);
            }
            return store.reactSubscribeToObject(objUpdate.obj, setObjUpdate);
        }
    }, [refToInitialObjRef, objId, store, objUpdate.obj]);

    return objUpdate.obj;
}
