import { PopoverMenu } from "components/Menu";
import React, { ReactElement, ReactNode, useState } from "react";
import { wrap } from "core";
import { ComplexItemProps, ItemProps, ParentItemProps } from "./Item";

/**
 * The return type for {@link useSideNavigationBar}
 */
interface UseSideNavigationBarResult {
    /**
     * The index of the currently selected (active) navigation item.
     */
    activeIndex: number;
    /**
     * The sub-index of the currently selected (active) navigation item.
     * All navigation items that are not sub-items always have a subIndex of 0.
     * Sub-items' subIndices begin at 1.
     *
     * EXAMPLE: indexing for a list of items might look something like:
     * standard item - index: 0, subIndex: 0
     * standard item - index: 1, subIndex: 0
     * parent item - index: 2, subIndex: 0
     *      sub item - index: 2, subIndex 1
     *      sub item - index: 2, subIndex 2
     * standard item - index: 3, subIndex: 0
     */
    activeSubIndex: number;
    /**
     * The state setter to allow user to set an active navigation item programmatically.
     */
    setActiveIndex: React.Dispatch<React.SetStateAction<[number, number]>>;
    /**
     * The list of navigation items.
     */
    items: (
        | ReactElement<ItemProps>
        | ReactElement<ComplexItemProps>
        | ReactElement<ParentItemProps>
    )[];
    /**
     * The page content for the navigation item currently selected.
     */
    activePage: ReactNode;
}

/**
 * Associates a navigation item with its corresponding page content.
 */
export interface SideNavigationTab {
    /**
     * Navigation item
     */
    navItem:
        | ReactElement<ItemProps>
        | ReactElement<ComplexItemProps>
        | ReactElement<ParentItemProps>;
    /**
     * Page corresponding to the navigation item. There are multiple pages if the item is a
     * parent navigation item that has sub-items.
     *
     * Note: If a parent item has its own page (`hasActiveState` = true {@link ParentItemProps}),
     * then pages[0] = parent item's page, and pages[1], ..., pages[n] = sub-item's pages.
     * If a parent item doesn't have its own page (`hasActiveState` = false), then all pages
     * correspond to the sub-items' pages.
     */
    pages?: ReactNode[];
}

/**
 * Creates a functional side navigation bar so that clicking an item makes it appear selected
 * and switches to the page corresponding to that item. Returns a {@link SideNavigationBar}.
 * @param sideNavigationTabs
 * @param initialIndex
 * @param initialSubIndex
 */
export function useSideNavigationBar({
    sideNavigationTabs,
    initialIndex = 0,
    initialSubIndex = 0,
}: {
    sideNavigationTabs: SideNavigationTab[];
    initialIndex?: number;
    initialSubIndex?: number;
}): UseSideNavigationBarResult {
    const [[activeIndex, activeSubIndex], setActiveIndex] = useState([
        initialIndex,
        initialSubIndex,
    ]);
    const items: (
        | ReactElement<ItemProps>
        | ReactElement<ComplexItemProps>
        | ReactElement<ParentItemProps>
    )[] = [];

    for (let i = 0; i < sideNavigationTabs.length; i++) {
        let navItem = sideNavigationTabs[i].navItem;
        const onClick = (event: Event) => {
            setActiveIndex([i, 0]);
            sideNavigationTabs[i].navItem.props.onClick?.(event);
        };
        // navItem is a ParentItem, and we must set `onClick` and `active` for its sub-items as well.
        if ("children" in navItem.props) {
            navItem = React.cloneElement(navItem as ReactElement<ParentItemProps>, {
                onClick: navItem.props.hasActiveState ? onClick : navItem.props.onClick,
                active: navItem.props.collapsed
                    ? activeIndex === i
                    : activeIndex === i && activeSubIndex === 0,
                activeFamily: activeIndex === i,
                children: wrap(navItem.props.children).map((subItem, j) =>
                    React.cloneElement(subItem, {
                        onClick: (event: Event) => {
                            setActiveIndex([i, j + 1]);
                            subItem.props.onClick?.(event);
                        },
                        active: activeIndex === i && activeSubIndex === j + 1,
                    }),
                ),
                collapsedMenuContent: (
                    <PopoverMenu.Section>
                        {
                            // Note: We don't map the parent item itself to the collapsed item menu
                            // as there is not currently a use case for this type of item.
                            wrap(navItem.props.children).map((subItem, j) => (
                                <PopoverMenu.Option
                                    key={subItem.key ?? j}
                                    label={subItem.props.label}
                                    onClick={(event) => {
                                        setActiveIndex([i, j + 1]);
                                        event instanceof Event && subItem.props.onClick?.(event);
                                    }}
                                    selected={activeIndex === i && activeSubIndex === j + 1}
                                    selectable={true}
                                />
                            ))
                        }
                    </PopoverMenu.Section>
                ),
            });
            items.push(navItem as ReactElement<ParentItemProps>);
        } else if ("itemType" in navItem.props) {
            // navItem is an Item
            navItem = React.cloneElement(navItem as ReactElement<ItemProps>, {
                onClick,
                active: activeIndex === i && activeSubIndex === 0,
            });
            items.push(navItem as ReactElement<ItemProps>);
        } else {
            // navItem is a ComplexItem
            navItem = React.cloneElement(navItem as ReactElement<ComplexItemProps>, {
                onClick,
                active: activeIndex === i && activeSubIndex === 0,
            });
            items.push(navItem as ReactElement<ComplexItemProps>);
        }
    }
    // If a parent item doesn't have its own page, pages[0] will hold the first sub-item's page
    // so subtract 1 from activeSubIndex to get the correct corresponding page.
    const activeItem = items[activeIndex];
    const subIndexAdjust =
        "children" in activeItem.props && !activeItem.props.hasActiveState ? 1 : 0;
    const sideNavTab = sideNavigationTabs[activeIndex];
    const activePage = sideNavTab.pages ? sideNavTab.pages[activeSubIndex - subIndexAdjust] : null;
    return {
        activeIndex,
        activeSubIndex,
        setActiveIndex,
        items,
        activePage,
    };
}
