import clsx from "clsx";
import {
    Checkbox as CheckboxComponent,
    CHECKBOX_LABEL_RIGHT_CLASS,
    CHECKBOX_MAIN_LABEL_CLASS,
    CHECKBOX_SUBLABEL_CLASS,
    CheckboxProps,
    CheckboxValue,
} from "components/Checkbox/Checkbox";
import { Item, MenuItemProps } from "components/Menu/Item/Item";
import { LabelTooltipContent, onItemInputKeyUp } from "components/Menu/Item/ItemUtil";
import { Tooltip } from "components/Tooltip";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, { forwardRef, useRef } from "react";
import { FFC } from "util/type";
import "./Checkbox.scss";

export interface MenuCheckboxProps
    extends Omit<CheckboxProps, "className" | "menuItem">,
        Pick<MenuItemProps, "className" | "ellipsify" | "color"> {}

/**
 * A Menu version of a Checkbox. Handles the necessary styling for placing a Checkbox within
 * a Menu as a menu item.
 */
export const Checkbox: FFC<HTMLInputElement, MenuCheckboxProps> = forwardRef(
    ({ className, ellipsify = false, color, tooltip, ...checkboxProps }, ref) => {
        className = clsx("bb-popover-menu__checkbox", className, {
            "bb-popover-menu__checkbox--checked":
                checkboxProps.value === CheckboxValue.TRUE || checkboxProps.value === true,
        });
        const internalRef = useRef<HTMLInputElement>(null);
        const inputRef = useCombinedRef(ref, internalRef);
        const labelRef = useRef<HTMLLabelElement>(null);

        const [itemResizeRef, itemResizeEntry] = useResizeObserver<HTMLDivElement>();
        const labelContent = (
            <LabelTooltipContent
                label={checkboxProps.label}
                subLabel={checkboxProps.subLabel}
                rightContentText={
                    typeof checkboxProps.rightContent === "string"
                        ? checkboxProps.rightContent
                        : checkboxProps.rightContentText
                }
            />
        );
        const itemWidth = itemResizeEntry.contentRect.width;
        if (!tooltip && itemWidth && labelContent && ellipsify) {
            const mainLabelElement =
                labelRef.current?.getElementsByClassName(CHECKBOX_MAIN_LABEL_CLASS)[0];
            const subLabelElement =
                labelRef.current?.getElementsByClassName(CHECKBOX_SUBLABEL_CLASS)[0];
            const labelRightElement = labelRef.current?.getElementsByClassName(
                CHECKBOX_LABEL_RIGHT_CLASS,
            )[0];
            if (
                (!!mainLabelElement && mainLabelElement.scrollWidth > mainLabelElement.clientWidth)
                || (!!subLabelElement && subLabelElement.scrollWidth > subLabelElement.clientWidth)
                || (!!labelRightElement
                    && labelRightElement.scrollWidth > labelRightElement.clientWidth)
            ) {
                tooltip = <Tooltip aria-hidden={true}>{labelContent}</Tooltip>;
            }
        }

        return (
            <Item
                ref={itemResizeRef}
                className={className}
                color={color}
                ellipsify={ellipsify}
                tooltip={tooltip}
                everHashText={
                    typeof checkboxProps.label === "string" ? checkboxProps.label : undefined
                }
            >
                <CheckboxComponent
                    {...checkboxProps}
                    className={"bb-popover-menu__checkbox-selector"}
                    ref={inputRef}
                    labelRef={labelRef}
                    onKeyUp={onItemInputKeyUp(internalRef, checkboxProps.onKeyUp)}
                    isMenuCheckbox={true}
                />
            </Item>
        );
    },
);
Checkbox.displayName = "Checkbox";
