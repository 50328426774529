import { Is } from "core";
import { Sort as TableSort } from "Everlaw/Table";

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Filtering {
    /**
     * An empty {@link Selection}. Note other selections may also be considered empty.
     */
    export const EMPTY_SELECTION: Selection = {
        allSelected: false,
        negated: [],
        filters: [],
        count: 0,
    };

    export const EMPTY_TABLE_SELECTION: TableSelection = toDefaultTableSelection(EMPTY_SELECTION);

    /**
     * If the provided selection is already a TableSelection, casts and returns it.
     * If it is not already a TableSelection, creates a new TableSelection with its
     * values, along with an empty set of filter and no sort.
     */
    export function toDefaultTableSelection(selection: Selection): TableSelection {
        if (isTableSelection(selection)) {
            return selection as TableSelection;
        }
        return Object.assign(
            {
                rawFilters: new Map(),
                page: 0,
            },
            selection,
        );
    }

    /**
     * Parameters for specifying a filtering query.
     * Matches with {@code FilterParams.java} on the back-end.
     */
    export interface Params {
        sort: Sort | undefined;
        filters: Entry[];
        pageNumber: number;
        pageSize: number;
        reverseResultOrder: boolean;
        useKeysetPagination?: boolean;
        includeTotal?: boolean;
    }

    /**
     * Return value for a query using Params.
     * Matches with {@code FilteringResult.java} on the back-end.
     */
    export interface Result {
        total?: number;
        objects: unknown[];
    }

    /**
     * Matches with {@code Sort.java} on the back-end.
     */
    export interface Sort {
        column: string;
        direction: "ASC" | "DESC";
        paginationKey?: PaginationKey;
    }

    /**
     * Used for keyset pagination.
     * Only works if the key you are sorting on is unique
     */
    export interface PaginationKey {
        id: number;
        value: string | undefined;
        secondary?: string;
    }

    /**
     * Specifies a single filter on a column.
     * Matches with {@code FilterEntry.java} on the back-end.
     */
    export interface Entry {
        // Filter type, as defined by annotations in classes in package com.everlaw.vpc.model.filtering.impl
        type: string;
        // Database column name
        column: string;
        // Parameter to be used with the filter defined in com.everlaw.vpc.model.filtering.impl
        // How it's used is implementation specific
        params: string | (() => string);
        // If true, the condition is reversed (e.g. != instead of ==)
        negated?: boolean;
    }

    /**
     * Specifies a selection from a lazy-loaded selectable table.
     * Contains sufficient information for the back-end to be able to tell which ids are needed.
     * Matches with {@code FilteringSelection.java} on the back-end.
     *
     * - If {@code allSelected} is {@code true}, the resulting set includes everything
     *   except the items in {@code negated}.
     * - If {@code allSelected} is {@code false}, the resulting set includes only the
     *   items in {@code negated}.
     *
     */
    export interface Selection {
        allSelected: boolean;
        negated: number[];
        filters: Entry[];
        count: number;
    }

    /**
     * A selection from a filterable table, containing all information necessary
     * to recreate that table, including the filters, page, and sort order.
     */
    export interface TableSelection extends Selection {
        sort?: TableSort;
        rawFilters: Map<string, unknown>;
        page: number;
        scrollTop?: number;
    }

    export function isTableSelection(selection: Selection): boolean {
        return "rawFilters" in selection;
    }

    /**
     * Represents the results of intersecting two {@link #Selection}s together.
     * Matches up with FilterIntersectionResult on the back-end.
     */
    export interface IntersectionResult {
        intersectionIds: [];
        /** If included, represents the new Selection that the table should be set to. */
        newSelection?: Pick<Selection, "allSelected" | "negated">;
    }

    /**
     * Returns a shallow copy of the given selection, where each parameter of the result
     * simply references the same parameter of the given selection.
     */
    export function shallowCopySelection(selection: Selection): Selection {
        const clone: Filtering.Selection = {
            allSelected: selection.allSelected,
            count: selection.count,
            filters: selection.filters,
            negated: selection.negated,
        };
        return clone;
    }

    export function singleSelection(selected: number | { id: number }): Selection {
        const id = Is.number(selected) ? (selected as number) : (selected as { id: number }).id;
        return {
            allSelected: false,
            negated: [id],
            filters: [],
            count: 1,
        };
    }

    export function selectionFromList(selected: number[]): Selection {
        return {
            allSelected: false,
            negated: selected ?? [],
            filters: [],
            count: selected?.length ?? 0,
        };
    }

    export function tableSelectionFromList(selected: number[]): TableSelection {
        const selection = selectionFromList(selected);
        return toDefaultTableSelection(selection);
    }

    export function isEmptySelection(selection: Selection): boolean {
        return (
            !selection.allSelected
            && selection.negated.length === 0
            && selection.filters.length === 0
            && selection.count === 0
        );
    }
}

export = Filtering;
