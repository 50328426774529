import Dom = require("Everlaw/Dom");
import { Is } from "core";

type ColumnDef = string | { columnDef: string; count: number };
/**
 * CSS:Grid based table with a stationary header.
 *
 * If a scrollbar is desired, both a width and a height must be provided.
 * This table should be supported in all browsers (including IE11, which requires some vendor-specific
 * property names and other quirks).
 */
class GridTable {
    node: HTMLElement;
    private tableHeaders: HTMLElement;
    private tableBody: HTMLElement;
    private tableFooter: HTMLElement;

    constructor(params: GridTable.Params) {
        const bodyColumns =
            params.customColumnDefs
            || this.getColumnDefs(
                params.width,
                params.columnCount,
                params.columnWidths,
                params.percentageWidths,
            );
        this.buildBody(params.bodyHeight, params.bodyElements, bodyColumns, params.isBordered);
        let headerColumns = bodyColumns;
        if (params.headers.length < params.columnCount) {
            headerColumns = this.getColumnDefs(
                params.width,
                params.headers.length,
                params.columnWidths,
                params.percentageWidths,
            );
        }
        this.buildHeaders(params.headers, headerColumns, params.isBordered);
        this.tableFooter = Dom.div({ class: "grid-table-footer" });

        this.node = Dom.div(
            { class: "grid-table" },
            this.tableHeaders,
            this.tableBody,
            this.tableFooter,
        );
        if (params.minWidth) {
            this.node.style.minWidth = `${params.minWidth}px`;
        }
        // Width must be set so that scrollbar width does not increase the width of the table.
        if (params.width) {
            this.node.style.width = `${params.width}px`;
        }
        // Adding a flex wrapper forces the table to conform to its minimum width
        if (params.width || params.fitMinWidth) {
            this.node = Dom.div({ class: "grid-table-wrapper--flex" }, this.node);
        }
        this.node = Dom.div({ class: "grid-table-wrapper" }, this.node);
    }

    /**
     * Create the column styling for each row. Uses pixel widths in order to prevent columns in the
     * body shifting when a scrollbar appears.
     */
    private getColumnDefs(
        tableWidth: number | undefined,
        columnCount: number,
        columnWidths: number[] = [],
        percentageWidths = false,
    ): ColumnDef[] {
        let remainingWidth = tableWidth;
        const columns: ColumnDef[] = [];
        columnWidths.forEach((columnWidth) => {
            columns.push(`${columnWidth}${percentageWidths ? "%" : "px"}`);
            if (!percentageWidths && remainingWidth !== undefined) {
                remainingWidth -= columnWidth;
            }
        });
        const remainingColCount = columnCount - columnWidths.length;
        // If all columns have assigned widths, we don't add in styling for additional columns.
        if (remainingColCount > 0) {
            const remainingWidthPerCol =
                tableWidth && !percentageWidths && remainingWidth !== undefined
                    ? `${remainingWidth / remainingColCount}px`
                    : "1fr";
            columns.push({ columnDef: remainingWidthPerCol, count: remainingColCount });
        }
        return columns;
    }

    private cellStyle(cellIdx: number): { [prop: string]: number } {
        // IE11 seems to require this "grid-column" value to be set (with its own vendor-specific
        // prefix) - it doesn't seem necessary for other browsers, but I think we can set it anyway.
        return {
            "grid-column": cellIdx + 1,
            "-ms-grid-column": cellIdx + 1,
        };
    }

    private toColumnStyle(columns: ColumnDef[], ie: boolean): string {
        return columns
            .map((col) => {
                if (Is.string(col)) {
                    return col;
                }
                // IE11's format for repeated definitions is different than the standard one.
                return ie
                    ? `(${col.columnDef})[${col.count}]`
                    : `repeat(${col.count}, ${col.columnDef})`;
            })
            .join(" ");
    }

    private rowStyle(columns: ColumnDef[]): { [prop: string]: string } {
        // IE11 has its own vendor-specific name for this property and also has a different format
        // for repeated column definitions.
        return {
            "grid-template-columns": this.toColumnStyle(columns, false),
            "-ms-grid-columns": this.toColumnStyle(columns, true),
        };
    }

    private buildHeaders(
        headers: HTMLElement[],
        gridTemplateColumns: ColumnDef[],
        isBordered = false,
    ) {
        const cellClass = `grid-table-header ${isBordered ? "grid-table-element--bordered" : ""}`;
        const rowStyle = this.rowStyle(gridTemplateColumns);
        this.tableHeaders = Dom.div(
            { class: "grid-table-headers", style: rowStyle },
            headers.map((header, cellIdx) =>
                Dom.div({ class: cellClass, style: this.cellStyle(cellIdx) }, header),
            ),
        );
    }

    private buildBody(
        bodyHeight: number | undefined,
        bodyElements: HTMLElement[][],
        gridTemplateColumns: ColumnDef[],
        isBordered = false,
    ) {
        this.tableBody = Dom.div({ class: "grid-table-body" });
        if (bodyHeight) {
            Dom.addClass(this.tableBody, "grid-table-body--scroll");
            this.tableBody.style.maxHeight = `${bodyHeight}px`;
        }
        const cellClass = `grid-table-body__element ${isBordered ? "grid-table-element--bordered" : ""}`;
        const rowClass = `grid-table__row ${isBordered ? "grid-table-row--bordered" : ""}`;
        const rowStyle = this.rowStyle(gridTemplateColumns);
        bodyElements.forEach((bodyRow) => {
            Dom.place(
                Dom.div(
                    { class: rowClass, style: rowStyle },
                    bodyRow.map((rowElement, cellIdx) => {
                        Dom.addClass(rowElement, "ellipsed");
                        return Dom.div(
                            { class: cellClass, style: this.cellStyle(cellIdx) },
                            rowElement,
                        );
                    }),
                ),
                this.tableBody,
            );
        });
    }

    /**
     * Add message to the end of the grid body. This message takes up an entire row.
     */
    addTableFooterMessage(message: HTMLElement): void {
        const messageElement = Dom.div({ class: "grid-table-body__element" }, message);
        const messageRow = Dom.div(
            { class: "grid-table__row grid-table--free-space" },
            messageElement,
        );
        Dom.place(messageRow, this.tableBody);
    }

    /**
     * Add message to a fixed footer outside of the table.
     */
    addFooterMessage(message: HTMLElement): void {
        const messageElement = Dom.div({ class: "grid-table-body__element" }, message);
        const messageRow = Dom.div({ class: "grid-table__row" }, messageElement);
        Dom.place(messageRow, this.tableFooter);
    }

    hideFooter() {
        Dom.hide(this.tableFooter);
    }
}

module GridTable {
    export interface Params {
        /* Count of columns in the table */
        columnCount: number;
        /* Set of header elements in the table */
        headers: HTMLElement[];
        /* Set of body elements in the table, grouped by row */
        bodyElements: HTMLElement[][];
        /* Width of the table - necessary if a scrollbar is desired.*/
        width?: number;
        /* Minimum width of the table.*/
        minWidth?: number;
        /* Whether the table width will shrink to the minimum size necessary. */
        fitMinWidth?: boolean;
        /* Height of the scrollable portion of the table - necessary if a scrollbar is desired. */
        bodyHeight?: number;
        /**
         * Specific widths of each column in order, if not default. Columns without a specific width
         * evenly divide the remaining table width.
         */
        columnWidths?: number[];
        /* Whether columnWidths provided are percentages */
        percentageWidths?: boolean;
        /**
         *  If provided, these will be used as the column widths (ignoring columnWidths/internal
         *  calculations). Any repeated definitions should be specified as an object
         *  { columnDef: ..., count:... }
         *  so that it can be correctly formatted for both standard browsers and IE11.
         */
        customColumnDefs?: ColumnDef[];
        /* Whether there are borders between table rows. */
        isBordered?: boolean;
    }
}

export = GridTable;
