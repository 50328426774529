import Base = require("Everlaw/Base");
import Category = require("Everlaw/Category");
import DocumentGroupType = require("Everlaw/DocumentGroupType");
import Dom = require("Everlaw/Dom");
import { Is } from "core";
import Perm = require("Everlaw/PermissionStrings");
import Rating = require("Everlaw/Rating");
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");
import Project = require("Everlaw/Project");

import Autocodable = require("Everlaw/Autocodable");
import Freeform = require("Everlaw/Freeform");
import Icon = require("Everlaw/UI/Icon");

class AutoCodeRule extends Base.Object {
    get className() {
        return "Rule";
    }
    override id: AutoCodeRule.Id;
    contextId: DocumentGroupType.Id;
    designationId: number;
    type: Autocodable.Type;
    projectId: Project.Id;

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    getDesignation(): Autocodable {
        switch (this.type) {
            case Autocodable.Type.CATEGORY:
                return Base.get(Category, this.designationId);
            case Autocodable.Type.RATING:
                return AutoCodeRule.ratingCategory;
            case Autocodable.Type.FREEFORM_CODE:
                return Base.get(Freeform.Code, this.designationId);
        }
    }
    getContext() {
        return Base.get(DocumentGroupType, this.contextId);
    }
    remove() {
        return Rest.post("autoCodeRules/delete.rest", {
            id: this.id,
        }).then(() => {
            ga_event("Auto-Code Rules", "Delete");
            Base.remove(this);
            return this;
        });
    }
    override display() {
        let designationDescriptor;
        switch (this.type) {
            case Autocodable.Type.CATEGORY:
                designationDescriptor = "category ";
                break;
            case Autocodable.Type.RATING:
                designationDescriptor = "";
                break;
            case Autocodable.Type.FREEFORM_CODE:
                designationDescriptor = "code ";
                break;
        }

        return (
            `"${this.getContext().display()}" for `
            + `${designationDescriptor} "${this.getDesignation().display()}"`
        );
    }
    override compare(other: AutoCodeRule) {
        if (this.type === other.type) {
            if (this.type === Autocodable.Type.RATING) {
                return 0;
            } else {
                return this.getDesignation().compare(other.getDesignation());
            }
        } else {
            return this.type.localeCompare(other.type);
        }
    }
}

const RATING_CATEGORY_ID = 0;

class Ratings extends Base.Primitive<number> implements Autocodable {
    constructor() {
        super(RATING_CATEGORY_ID, "Rating");
    }
    getColor() {
        return Base.get(Rating, "hot").getColor();
    }

    descriptiveText(): HTMLSpanElement {
        return Dom.b(`${this.display()}s`);
    }

    designationType(): Autocodable.Type {
        return Autocodable.Type.RATING;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module AutoCodeRule {
    export const ratingCategory = new Ratings();

    export function isConfigured() {
        return Base.get(AutoCodeRule).length > 0;
    }
    export type Id = number & Base.Id<"AutoCodeRule">;
    export function validContextValues() {
        return [
            DocumentGroupType.ExactDuplicates,
            DocumentGroupType.Threading,
            DocumentGroupType.ChatConversations,
            DocumentGroupType.AllConversations,
            DocumentGroupType.Attachments,
            DocumentGroupType.Versions,
        ];
    }
    export function canPause() {
        return User.me.can(
            Perm.PAUSE_AUTO_CODE,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );
    }
    export function byDesignation(designation: Autocodable) {
        // only one rule per designation
        return Base.get(AutoCodeRule).filter((r) => r.designationId === designation.id)[0];
    }
    export function forRatings() {
        return Base.get(AutoCodeRule).filter((r) => r.type === Autocodable.Type.RATING)[0];
    }
    export function byContext(context: DocumentGroupType) {
        // multiple rules per context
        return Base.get(AutoCodeRule).filter((r) => r.contextId === context.id);
    }
    export function create(designation: Autocodable, context: DocumentGroupType) {
        return Rest.post("autoCodeRules/createOrUpdate.rest", {
            designationId: designation.id,
            context: context.id,
            type: designation.designationType(),
        }).then((data) => {
            ga_event("Auto-Code Rules", "Create");
            return Base.set(AutoCodeRule, data);
        });
    }
    export function logPauseEvent(value: boolean, source: string) {
        ga_event(source, `${value ? "Unpause" : "Pause"} auto-code`);
    }
    export function makeSparkle(autocodable: Autocodable | string, node?: HTMLElement): Icon {
        let typeDescriptor;
        if (Is.string(autocodable)) {
            typeDescriptor = autocodable;
        } else {
            typeDescriptor =
                autocodable instanceof Category ? "Codes in this category " : "This freeform code ";
        }
        return new Icon("sparkle-blue-16", {
            tooltip: Dom.fragment(
                typeDescriptor + "will be automatically added",
                Dom.br(),
                "to/removed from related documents per auto-code",
                Dom.br(),
                "rules when added to/removed from a document.",
            ),
            parent: node,
            tooltipPosition: ["above-centered"],
            alt: "autocode rule",
        });
    }
}

export = AutoCodeRule;
