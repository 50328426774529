import Base = require("Everlaw/Base");
import Preference = require("Everlaw/Preference");
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");
import RedactionStamp = require("Everlaw/Review/RedactionStamp");

export const minStampSize = 4;
export const maxStampSize = 20;

/**
 * Gets all the stamps defined for the current project.
 * necessary if the returned instances will be used in Base.
 */
export function getProjectStamps(): RedactionStamp[] {
    return Base.get(RedactionStamp).filter((stamp) => stamp.isProjectStamp());
}

export function getAllowCustom(): boolean {
    return Preference.REVIEW.customStamps.getProjectDefault().val;
}

export function setAllowCustom(allow: boolean): void {
    Preference.REVIEW.customStamps.setProjectDefault(allow);
}

export function getCustomStamps(bypassAllowCustom = false): RedactionStamp[] {
    if (!bypassAllowCustom && !getAllowCustom()) {
        return [];
    }
    return Base.get(RedactionStamp).filter((stamp) => !stamp.isProjectStamp());
}

export function getAllStamps(): RedactionStamp[] {
    return Base.get(RedactionStamp);
}

export function getRecentStamps(): number[] {
    const recentStamps: number[] = Preference.REVIEW.recentStamps.getUserValue().val;
    // If any recently used stamp is stale then remove it from recent stamps.
    const toRemove: number[] = [];
    recentStamps.forEach((stampId) => {
        if (!Base.get(RedactionStamp, stampId)) {
            toRemove.push(stampId);
        }
    });
    if (toRemove.length > 0) {
        toRemove.forEach((stampId) => {
            recentStamps.splice(recentStamps.indexOf(stampId), 1);
        });
        Preference.REVIEW.recentStamps.setUserValue(recentStamps);
    }
    return recentStamps;
}

export function getDefaultStampPrefs(): Preference.DefaultStampPrefs {
    let defaultStampPrefs = Preference.REVIEW.defaultStamp.getUserValue().val;
    if (!defaultStampPrefs.stampId) {
        return defaultStampPrefs;
    }
    // If the stamp is stale (i.e. has been deleted from the project)
    // then reset default stamp to be no stamp.
    const stamp = Base.get(RedactionStamp, defaultStampPrefs.stampId);
    if (!stamp) {
        defaultStampPrefs = {
            asPrevious: defaultStampPrefs.asPrevious,
            stampId: undefined,
        };
        setDefaultStamp(defaultStampPrefs.stampId, defaultStampPrefs.asPrevious);
    }
    return defaultStampPrefs;
}

export function getDefaultStamp(): RedactionStamp {
    const defaultStamp = getDefaultStampPrefs();
    if (!defaultStamp.stampId) {
        return RedactionStamp.noStamp;
    }
    // If not no stamp, the stamp must exist!
    // If the stamp is stale, it will have been reset by getDefaultStampPrefs().
    return Base.get(RedactionStamp, defaultStamp.stampId);
}

export function setDefaultStamp(stampId: number | undefined, asPrevious: boolean): void {
    Preference.REVIEW.defaultStamp.setUserValue({
        asPrevious,
        stampId,
    });
}

export function addStamp(
    content: string,
    abbr: string,
    isProjectStamp: boolean,
): Promise<RedactionStamp> {
    // For duplicate stamps, createStamp.rest just returns the existing database entry.
    return Rest.post("createStamp.rest", {
        stampUserId: isProjectStamp ? 0 : User.me.id,
        content: content,
        abbreviation: abbr,
    }).then((stamp: RedactionStamp) => {
        return Base.set(RedactionStamp, stamp);
    });
}

export function updateStampUser(
    stamp: RedactionStamp,
    isPromotion: boolean,
    isDelete = false,
): Promise<RedactionStamp> {
    // If deleting a stamp that is the default or in recently used, update preferences.
    // This is only for manual deletion of project stamps and only affects the user who deletes it.
    if (isDelete) {
        const defaultStamp = getDefaultStampPrefs();
        if (defaultStamp.stampId === stamp.id) {
            setDefaultStamp(undefined, defaultStamp.asPrevious);
        }
        const recentlyUsedStamps = getRecentStamps();
        if (recentlyUsedStamps.includes(stamp.id)) {
            recentlyUsedStamps.splice(recentlyUsedStamps.indexOf(stamp.id), 1);
            Preference.REVIEW.recentStamps.setUserValue(recentlyUsedStamps);
        }
    }
    return Rest.post("updateStampUser.rest", {
        stampId: stamp.id,
        stampUserId: isPromotion ? 0 : User.me.id,
        isDelete,
    }).then((stamp: RedactionStamp) => {
        return Base.set(RedactionStamp, stamp);
    });
}

/**
 * Updates per user per project setting of recently used stamps.
 * If user's default stamp is set to "last applied stamp", also updates the default.
 */
export function updateRecentlyUsed(stampId: number | undefined): void {
    const currentPrefs: number[] = getRecentStamps();
    // Check if the default stamp setting is "last applied".
    if (getDefaultStampPrefs().asPrevious) {
        setDefaultStamp(stampId, true);
    }
    // If the stamp used is no stamp, return, since recently used doesn't keep track of no stamp uses.
    // noStamp is above recently used in the selector, so there is no reason to add it to the recents.
    if (!stampId) {
        return;
    }
    // If the stamp used is already in recently used, remove it.
    if (currentPrefs.includes(stampId)) {
        currentPrefs.splice(currentPrefs.indexOf(stampId), 1);
    }
    // Add stamp used as the most recently used.
    currentPrefs.unshift(stampId);
    // Make sure we don't go above 3 recently used stamps shown to the user.
    while (currentPrefs.length > 3) {
        currentPrefs.pop();
    }
    // Set the preference value.
    Preference.REVIEW.recentStamps.setUserValue(currentPrefs);
}

export function getStampCount(stamp: RedactionStamp): Promise<number> {
    return getStampCountById(stamp.id);
}

export function getStampCountById(id: number): Promise<number> {
    return Rest.get("getStampCount.rest", { id });
}

export function getStampCountByProject(): Promise<Map<number, number>> {
    return Rest.get("getStampCountsByProject.rest").then((data) => {
        const map = new Map<number, number>();
        for (const key in data) {
            data[key] && map.set(Number(key), data[key]);
        }
        return map;
    });
}
