import { Arr } from "core";
import Bates = require("Everlaw/Bates");
import Files = require("Everlaw/Files");
import { UploadFile } from "Everlaw/UI/Upload/Util/PdfnlfUtils";
import * as UploadState from "Everlaw/Model/Upload/Util/UploadState";
import * as Rest from "Everlaw/Rest";
import XRegExp = require("xregexp");

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
export module BatesFilenameParse {
    const BAD_PREFIX_REGEX = new RegExp("[\\[\\]\\\\/{}]+", "g");
    // We split filenames on characters that cannot be in prefixes (or whitespace) If the user needs
    //  whitespace in their prefix, then it must be entered manually.
    const BAD_PREFIX_OR_SPACE_REGEX = new RegExp("[\\[\\]\\\\/{}\\s]+", "g");

    /*
     * If we do not remove the .pdf from the filename it could end up being detected as a suffix.
     */
    export function findBatesInFilename(filename: string, prefixes?: string[]): Bates[] {
        if (filename.length > 4) {
            // Currently only expecting pdf files this needs to be more generic for other filetypes.
            // Need to be careful since . followed by letters is a valid suffix for bates numbers.
            const last4 = filename.substr(-4).toLowerCase();
            filename = last4 === ".pdf" ? filename.slice(0, -4) : filename;
        }
        //  The optional prefix limits searching for that prefix, this may also include spaces
        if (prefixes) {
            const regx: RegExp = Bates.buildBatesRegex(prefixes);
            return findBatesInString([filename], regx, prefixes);
        }
        // Without a supplied prefix split the string and search for any valid Bates numbers
        return findBatesInString(filename.split(BAD_PREFIX_OR_SPACE_REGEX), Bates.DEFAULT_REGEX);
    }

    /*
     * Check for any match of the prefix in any filename. Used to validate user entered prefixes.
     */
    export function prefixExists(filenames: string[], prefix: string): boolean {
        return filenames.some(
            (filename) => BatesFilenameParse.findBatesInFilename(filename, [prefix]).length > 0,
        );
    }

    export function invalidPrefixReason(prefix: string): string {
        if (prefix && prefix.length > 0) {
            if (prefix.toLocaleUpperCase() === PrefixGroupUtil.DEFAULT_GROUP || prefix === "#") {
                return `"${prefix}" is not an allowed prefix`;
            } else if (prefix.length > 36) {
                return "Must be fewer than 36 characters";
            } else {
                const match = XRegExp.match(prefix, BAD_PREFIX_REGEX);
                if (match.length > 0) {
                    return `Prefix "${prefix}" contains invalid character []\\\/{}`;
                }
                return null;
            }
        }
        return "Bates prefixes are required";
    }
}

function findBatesInString(inputStrs: string[], regx: RegExp, prefixes: string[] = []): Bates[] {
    const foundBates: Bates[] = [];
    const lowerPrefixes = prefixes.map((prefix) => prefix.toLocaleLowerCase());

    for (let idx = 0; idx < inputStrs.length; ++idx) {
        XRegExp.forEach(inputStrs[idx], regx, (batesMatch) => {
            const adjustedMatch = checkMatch(inputStrs[idx], batesMatch, regx, lowerPrefixes);
            if (adjustedMatch) {
                const newBates = Bates.fromMatch(adjustedMatch);
                newBates.prefix = newBates.prefix.toLocaleUpperCase();
                foundBates.push(newBates);
            }
        });
    }
    return foundBates;
}

function checkMatch(
    matchStr: string,
    batesMatch: RegExpMatchArray,
    regx: RegExp,
    prefixes: string[],
): RegExpMatchArray {
    const batesGroup = <{ [group: string]: string }>(<any>batesMatch);
    if (!checkPrefix(batesGroup["prefix"], prefixes)) {
        return null;
    }
    const suffix = Bates.findGroup(batesGroup, "suffix");
    const adjustedMatch = checkSuffix(batesGroup["prefix"], suffix, matchStr, batesMatch, regx);

    if (
        adjustedMatch
        && badMatch(
            matchStr,
            adjustedMatch,
            prefixes.indexOf(batesGroup["prefix"].toLocaleLowerCase()) === -1,
        )
    ) {
        return null;
    }
    return adjustedMatch;
}

function validGenericPrefix(prefix: string) {
    if (BatesFilenameParse.invalidPrefixReason(prefix)) {
        return false;
    }
    // Choose to ignore prefixes that are mostly special characters. This number was chosen by looking
    //  at all existing prefixes (15000+) and finding the max ratio to be 50% (in 2 and 4 character prefixes)
    // Seems reasonable that (A) would be a prefix, so use 67 as the actual limit. The dynamic limit
    //  helps with longer prefixes which are expected to have more alphanumerics.
    const maxPercent = prefix.length < 5 ? 67 : 50;
    const nonAlphaIsh = (prefix.match(/[^\w#'-]/g) || []).length;
    const perNonAlphaIsh = (nonAlphaIsh * 100) / prefix.length;
    if (perNonAlphaIsh > maxPercent) {
        return false;
    }

    if (prefix.match(/^[0-9]+[._-]?$|^[0-9]*[._-]$/)) {
        return false;
    }

    const openParens = (prefix.match(/\(/g) || []).length;
    const closeParens = (prefix.match(/\)/g) || []).length;
    return openParens === closeParens;
}

function invalidPreOrPostChar(char: string): boolean {
    return char.length === 0 || /^[0-9a-zA-Z]+$/.test(char);
}

/*
 * Don't allow generated prefixes/suffixes to split longer words. There are length limits in the
 * regex so this may happen. User entered prefixes do not have this restriction.
 */
function badMatch(matchStr: string, batesMatch: RegExpMatchArray, checkStart = true): boolean {
    if (checkStart) {
        const matchStart = matchStr.indexOf(batesMatch[0]);
        if (matchStart > 0) {
            if (invalidPreOrPostChar(matchStr[matchStart - 1])) {
                return true;
            }
        }
    }
    if (batesMatch.index + batesMatch[0].length < batesMatch.input.length - 1) {
        if (invalidPreOrPostChar(batesMatch.input[batesMatch.index + batesMatch[0].length])) {
            return true;
        }
    }

    return false;
}

function removeSuffix(
    batesMatch: RegExpMatchArray,
    suffixLen: number,
    regx: RegExp,
): RegExpMatchArray {
    const suffixRemoved = batesMatch.input.substr(
        batesMatch.index,
        batesMatch[0].length - suffixLen,
    );
    return XRegExp.exec(suffixRemoved, regx);
}

function checkPrefix(prefix: string, validPrefixes: string[]): boolean {
    // User-entered prefixes are considered valid since they are validated before adding.
    return Arr.contains(validPrefixes, prefix) || validGenericPrefix(prefix);
}

function checkSuffix(
    prefix: string,
    suffix: string,
    matchStr: string,
    batesMatch: RegExpMatchArray,
    regx: RegExp,
): RegExpMatchArray {
    // Because these are filenames they often contain <prefix>><number>-<prefix><number> this causes
    // the first bates number to parse with a suffix of -<prefix> which is unwanted. Remove it.
    // Check for suffix === -<prefix> and remove it.  TODO: We could also parse expected end bates here.
    if (!!suffix && suffix.length > 1) {
        if (suffix[0] === "-") {
            const suffixIdx = batesMatch.index + batesMatch[0].length - suffix.length;
            // Check for the entire prefix existing after the - (the suffix may be trunacted)
            if (batesMatch.input.indexOf(prefix, suffixIdx) === suffixIdx + 1) {
                return removeSuffix(batesMatch, suffix.length, regx);
            }
        }
        // If there is a bad match removing the suffix may fix it (removeSuffix returns null otherwise)
        if (badMatch(matchStr, batesMatch, false)) {
            return removeSuffix(batesMatch, suffix.length, regx);
        }
    }
    return batesMatch;
}
/*
 * Track user, other. Build the comprehensive list "prefixes" from them.
 *     User prefixes: Added first into combined list, adding one causes removal from ignore list.
 * Detected prefixes: Added second into combined list, adding one does not change the ignore list.
 */
export enum BatesPrefixType {
    USER = "USER",
    DETECTED = "DETECTED",
}

export interface FileAndBates {
    fileEntry: Files.FileEntryJSON;
    bates: Bates;
}

/*
 * Prefix groups are identified by a normalized (ALL CAPS) prefix. A filename may have multiple
 *  prefix matches. A given filename is added to the highest priority group which it can be a
 *  member of. Priority is determined by the compare function. Ignored groups never have files assigned.
 */
class PrefixGroupUtil {
    static DEFAULT_GROUP = "NO BATES";

    static constructPrefixGroup(
        prefix: string,
        pType = BatesPrefixType.DETECTED,
    ): UploadState.PrefixGroup {
        return {
            enabled: true,
            normalized: PrefixGroupUtil.normalize(prefix),
            files: [],
            prefix,
            pType,
        };
    }

    static add(pg: UploadState.PrefixGroup, filename: FileAndBates) {
        pg.files.push(filename);
    }

    static normalize(prefix: string): string {
        return prefix.toLocaleUpperCase();
    }

    // Priority order for the groups.  Here are the high level rules:
    //  #1 Ignored groups go last (even after default so they won't have files assigned to them)
    //  #2 User Groups sort before Detected Groups
    //  #3 Groups with more members sort before groups with fewer members
    //  #4 Groups with longer names sort before groups with shorter names (This rule is a bit iffy,
    //     we instead may want to only consider 1 or 2 character groups to sort below others. Or ??)
    //     We are generally trying to find the most interesting groups for the user and for many test
    //     files there are many very short, and wrong possible prefixes detected.
    //  #5 The empty-prefix group sorts right before default group (if it's not ignored).
    //  #6 Sort groups by alphanumeric sort.
    static compare(g1: UploadState.PrefixGroup, g2: UploadState.PrefixGroup): number {
        if (!g1.prefix || g1.prefix === "") {
            return -1;
        } else if (!g2.prefix || g2.prefix === "") {
            return 1;
        }

        // If one is ignored then ignored group goes last
        if (g1.enabled && !g2.enabled) {
            return -1;
        } else if (!g1.enabled && g2.enabled) {
            return 1;
        }

        // If one is user then it goes first
        if (g1.pType !== BatesPrefixType.USER && g2.pType === BatesPrefixType.USER) {
            return 1;
        } else if (g1.pType === BatesPrefixType.USER && g2.pType !== BatesPrefixType.USER) {
            return -1;
        }

        // Either both are user or both or NOT user so sort by inter group rules
        const grp1Len = g1.files.length;
        const grp2Len = g2.files.length;
        if (
            BatesPrefixListUtil.isDefaultPrefix(g1.prefix)
            && BatesPrefixListUtil.isEmptyPrefix(g2.prefix)
        ) {
            return 1;
        } else if (
            BatesPrefixListUtil.isEmptyPrefix(g1.prefix)
            && BatesPrefixListUtil.isDefaultPrefix(g2.prefix)
        ) {
            return -1;
        }

        if (BatesPrefixListUtil.isEmptyPrefix(g1.prefix)) {
            if (grp2Len > 0) {
                return grp2Len;
            } else {
                return -1;
            }
        } else if (BatesPrefixListUtil.isEmptyPrefix(g2.prefix)) {
            if (grp1Len > 0) {
                return -grp1Len;
            } else {
                return 1;
            }
        }

        if (BatesPrefixListUtil.isDefaultPrefix(g1.prefix)) {
            if (grp2Len > 0) {
                return grp2Len;
            } else {
                return -1;
            }
        } else if (BatesPrefixListUtil.isDefaultPrefix(g2.prefix)) {
            if (grp1Len > 0) {
                return -grp1Len;
            } else {
                return 1;
            }
        }

        const grpLenCmp = grp2Len - grp1Len;
        if (grpLenCmp === 0) {
            const grpPrefixCmp = g2.prefix.length - g1.prefix.length;
            if (grpPrefixCmp === 0) {
                return g1.prefix.localeCompare(g2.prefix, undefined, {
                    numeric: true,
                    sensitivity: "base",
                });
            }
            return grpPrefixCmp;
        }
        return grpLenCmp;
    }
}

/*
 * Map a prefix(string) to a PrefixGroupUtil with a set of convenience functions.
 */
class PrefixGroupMapUtil {
    static constructPrefixGroupMap(): UploadState.PrefixGroupMap {
        return {
            groupMap: new Map<string, UploadState.PrefixGroup>(),
            needsSorting: true,
            sortedGroups: null,
        };
    }

    static add(
        prefixGroupMap: UploadState.PrefixGroupMap,
        prefix: string,
        pType: BatesPrefixType,
        fab: FileAndBates,
    ): void {
        const normPrefix = PrefixGroupUtil.normalize(prefix);
        if (!prefixGroupMap.groupMap.has(normPrefix)) {
            prefixGroupMap.groupMap.set(
                normPrefix,
                PrefixGroupUtil.constructPrefixGroup(prefix, pType),
            );
        }
        PrefixGroupUtil.add(prefixGroupMap.groupMap.get(normPrefix), fab);
        prefixGroupMap.needsSorting = true;
    }

    static addAll(
        prefixGroupMap: UploadState.PrefixGroupMap,
        prefix: string,
        pType: BatesPrefixType,
        fabs: FileAndBates[],
    ): void {
        fabs && fabs.forEach((fab) => PrefixGroupMapUtil.add(prefixGroupMap, prefix, pType, fab));
    }

    static get(
        prefixGroupMap: UploadState.PrefixGroupMap,
        prefix: string,
    ): UploadState.PrefixGroup | undefined {
        return prefix && prefixGroupMap.groupMap.get(prefix.toLocaleUpperCase());
    }

    static getSortedGroups(prefixGroupMap: UploadState.PrefixGroupMap): UploadState.PrefixGroup[] {
        if (prefixGroupMap.needsSorting) {
            PrefixGroupMapUtil.sort(prefixGroupMap);
        }
        return prefixGroupMap.sortedGroups;
    }

    static getAllPrefixesByType(
        prefixGroupMap: UploadState.PrefixGroupMap,
        pType: BatesPrefixType,
    ): string[] {
        const prefixes: string[] = [];
        prefixGroupMap.groupMap.forEach((pg, prefix) => {
            if (pg.pType === pType) {
                prefixes.push(prefix);
            }
        });
        return prefixes;
    }

    static getAllPrefixes(prefixGroupMap: UploadState.PrefixGroupMap): string[] {
        return [...prefixGroupMap.groupMap.keys()];
    }

    static sort(prefixGroupMap: UploadState.PrefixGroupMap): void {
        prefixGroupMap.sortedGroups = [...prefixGroupMap.groupMap.values()].sort(
            PrefixGroupUtil.compare,
        );
        prefixGroupMap.needsSorting = false;
    }
}
/*
 * BatesPrefixList tracks which filename belongs to which PrefixGroup. The priority of each group
 *  is determined by its sort order. See the compare function in PrefixGroupUtil for sorting rules. New
 *  prefixes may be added, but existing prefixes can only be ignored. Ignored prefixes never have
 *  files assigned to them in the current prefix group map. There is a default group which holds all
 *  files which fit into no other group.
 *
 * Basic Steps:
 *  Find every prefix in every file. For each detected prefix add it to the allPrefixGroup with every
 *   possible file for that group.
 *
 *  Sort the PrefixGroup - Sort order determines the priority for assigning files to groups.
 *
 *  Traverse all files and assign each to the first group it may be part of. Store the results in
 *   curPrefixGroups.
 *
 */
export class BatesPrefixListUtil {
    static constructBatesPrefixList(
        filenameToUploadFile: Map<string, UploadFile>,
        parcel: number,
        uploadId: number,
        projectId: number,
    ): Promise<UploadState.BatesPrefixList> {
        const newBatesPrefixList = {
            allPrefixGroups: null,
            curPrefixGroups: null,
        };
        return BatesPrefixListUtil.reset(
            newBatesPrefixList,
            filenameToUploadFile,
            parcel,
            uploadId,
            projectId,
        ).then((newBatesPrefixList) => {
            return newBatesPrefixList;
        });
    }

    // Remove all changes to prefixes and ignored state to build the default curPrefixGroups
    static reset(
        batesPrefixList: UploadState.BatesPrefixList,
        filenameToUploadFile: Map<string, UploadFile>,
        parcel: number,
        uploadId: number,
        projectId: number,
    ): Promise<UploadState.BatesPrefixList> {
        return Rest.post(`/parcel/${parcel}/upload/resetBatesPrefixList.rest`, {
            uploadId,
            projectId,
        }).then((newBatesPrefixList) => {
            return JSON.parse(newBatesPrefixList, (key, value) => {
                return UploadState.reviver(true, key, value);
            });
        });
    }

    private static addFilesToCurGroup(
        batesPrefixList: UploadState.BatesPrefixList,
        fromGroup: UploadState.PrefixGroup,
        assignedFiles: Set<string>,
    ): void {
        const curFiles: FileAndBates[] = [];
        if (fromGroup.enabled || fromGroup.prefix === PrefixGroupUtil.DEFAULT_GROUP) {
            for (let fdx = 0; fdx < fromGroup.files.length; fdx++) {
                const curFile = fromGroup.files[fdx];
                if (!assignedFiles.has(curFile.fileEntry.name)) {
                    assignedFiles.add(curFile.fileEntry.name);
                    curFiles.push(curFile);
                }
            }
        }
        if (curFiles.length > 0) {
            PrefixGroupMapUtil.addAll(
                batesPrefixList.curPrefixGroups,
                fromGroup.normalized,
                fromGroup.pType,
                curFiles,
            );
        }
    }

    private static assignFilesToGroup(
        batesPrefixList: UploadState.BatesPrefixList,
        filenameToUploadFile: Map<string, UploadFile>,
    ): void {
        batesPrefixList.curPrefixGroups = PrefixGroupMapUtil.constructPrefixGroupMap();
        // assignedFiles is populated by addFilesToCurGroup
        const assignedFiles = new Set<string>();
        const allGroups = PrefixGroupMapUtil.getSortedGroups(batesPrefixList.allPrefixGroups);
        for (let gdx = 0; gdx < allGroups.length; gdx++) {
            if (allGroups[gdx].prefix) {
                BatesPrefixListUtil.addFilesToCurGroup(
                    batesPrefixList,
                    allGroups[gdx],
                    assignedFiles,
                );
            }
        }
        // TODO: This test likely is false every time. Build a proper test set and see if this is needed.
        if (assignedFiles.size < filenameToUploadFile.size) {
            const defaultGroup = PrefixGroupMapUtil.get(
                batesPrefixList.allPrefixGroups,
                PrefixGroupUtil.DEFAULT_GROUP,
            );
            BatesPrefixListUtil.addFilesToCurGroup(batesPrefixList, defaultGroup, assignedFiles);
        }
    }

    /**
     * The returned Promise resolves to null if nothing was changed about the BatesPrefixList as a
     * result of adding the desired custom prefixes/marking the desired prefixes that are existing
     * as custom. If something was changed, it resolves to the new BatesPrefixList.
     */
    static addPrefixes(
        { prefixes, allowEmptyPrefix, parcel, uploadId, projectId }: CustomPrefixParams,
        pType = BatesPrefixType.DETECTED,
    ): Promise<UploadState.BatesPrefixList | null> {
        if (!prefixes || prefixes.length < 1) {
            return Promise.resolve(null);
        }
        return Rest.post(`/parcel/${parcel}/upload/addCustomBatesPrefixes.rest`, {
            uploadId,
            projectId,
            prefixes,
            allowEmptyPrefix,
            pType,
        }).then((newBatesPrefixList) => {
            return JSON.parse(newBatesPrefixList, (key, value) => {
                return UploadState.reviver(true, key, value);
            });
        });
    }

    /*
     * Add a user prefix, or modify detected prefix to now be of type user. Returns the new
     * BatesPrefixList after sorting allPrefixGroups and populating curPrefixGroups.
     */
    static addOrModifyUserPrefix(
        customPrefixParams: CustomPrefixParams,
    ): Promise<UploadState.BatesPrefixList | null> {
        return BatesPrefixListUtil.addPrefixes(customPrefixParams, BatesPrefixType.USER);
    }

    /*
     * By default all prefixes are enabled. Disabled prefixes cannot have files assigned to them.
     * To disable a prefix set the enable flag to false. doSort is an optimization when adding
     * multiple prefixes at once.
     */
    static togglePrefix(
        batesPrefixList: UploadState.BatesPrefixList,
        filenameToUploadFile: Map<string, UploadFile>,
        prefix: string,
        enable: boolean,
        doSort = true,
    ): void {
        if (prefix.toLocaleUpperCase() === PrefixGroupUtil.DEFAULT_GROUP) {
            return;
        }
        const existingPrefix = PrefixGroupMapUtil.get(batesPrefixList.allPrefixGroups, prefix);

        if (existingPrefix && existingPrefix.enabled !== enable) {
            existingPrefix.enabled = enable;
        }
        if (doSort) {
            BatesPrefixListUtil.sortAndPopulateGroups(batesPrefixList, filenameToUploadFile);
        }
    }

    static toggleAll(
        batesPrefixList: UploadState.BatesPrefixList,
        filenameToUploadFile: Map<string, UploadFile>,
        enable = true,
        prefixes: string[] = null,
    ): void {
        if (prefixes === null) {
            prefixes = PrefixGroupMapUtil.getAllPrefixes(batesPrefixList.allPrefixGroups);
        }
        prefixes.forEach((prefix) => {
            BatesPrefixListUtil.togglePrefix(
                batesPrefixList,
                filenameToUploadFile,
                prefix,
                enable,
                false,
            );
        });
        BatesPrefixListUtil.sortAndPopulateGroups(batesPrefixList, filenameToUploadFile);
    }

    /*
     *  If a prefix is enabled and has 0 members in its group, then toggling it cannot alter its
     *   state. All files which may belong to that group have been assigned to higher-priority groups.
     *  If a prefix is ignored and has 0 members it is not clear why there are 0 members. It may be
     *   only because it is ignored, or it may be because higher-priority groups exist.
     */
    static isSelectable(batesPrefixList: UploadState.BatesPrefixList, prefix: string): boolean {
        const cpg =
            batesPrefixList.curPrefixGroups
            && PrefixGroupMapUtil.get(batesPrefixList.curPrefixGroups, prefix);
        if (cpg) {
            if (!cpg.enabled) {
                // This is the odd case where we are not certain if the prefix may be selected.
                return true;
            }
            // Cannot select an unIgnored prefix without files
            return cpg.files.length > 0;
        }
        const apg = PrefixGroupMapUtil.get(batesPrefixList.allPrefixGroups, prefix);
        if (!apg) {
            return false; // Should never happen, this prefix does not exist.
        }
        // This prefix does not exist in the current groups (0 files), if it is enabled it cannot
        //  be selected since all files that may be in its prefix group are in higher-priority groups.
        return !apg.enabled;
    }

    private static sortAndPopulateGroups(
        batesPrefixList: UploadState.BatesPrefixList,
        filenameToUploadFile: Map<string, UploadFile>,
    ): void {
        // Sorting order is critical to get things into proper group
        PrefixGroupMapUtil.sort(batesPrefixList.allPrefixGroups);
        BatesPrefixListUtil.assignFilesToGroup(batesPrefixList, filenameToUploadFile);
    }

    /*
     * Return all sorted prefixes which currently have files assigned.
     */
    static getCurPrefixes(
        batesPrefixList: UploadState.BatesPrefixList,
        includeIgnoredAndEmptyUserGroups = true,
        excludeDefault = false,
        excludeEmpty = false,
    ): string[] {
        // This relies on curPrefixGroups.groupMap being sorted by element insertion order (which is
        //  what necessitates the use of a LinkedHashMap on the backend)
        const prefixes = PrefixGroupMapUtil.getAllPrefixes(batesPrefixList.curPrefixGroups);
        if (includeIgnoredAndEmptyUserGroups) {
            const otherPrefixes: string[] = [];
            PrefixGroupMapUtil.getSortedGroups(batesPrefixList.allPrefixGroups).forEach((pg) => {
                if (!pg.enabled || pg.pType === BatesPrefixType.USER) {
                    if (!Arr.contains(prefixes, pg.normalized)) {
                        otherPrefixes.push(pg.normalized);
                    }
                }
            });
            if (excludeDefault) {
                Arr.remove(prefixes, PrefixGroupUtil.DEFAULT_GROUP);
            }
            if (excludeEmpty) {
                Arr.remove(prefixes, Bates.NO_PREFIX);
                Arr.remove(otherPrefixes, Bates.NO_PREFIX);
            }
            // Ignored prefixes are sorted to the end of the list.
            return prefixes.concat(otherPrefixes);
        }

        if (excludeEmpty) {
            Arr.remove(prefixes, Bates.NO_PREFIX);
        }

        Arr.remove(prefixes, PrefixGroupUtil.DEFAULT_GROUP);
        !excludeDefault && prefixes.push(PrefixGroupUtil.DEFAULT_GROUP);
        return prefixes;
    }

    /*
     * The all prefix list is sorted by potential matches, not the actual current matches. Ensure this
     * list matches the current sort, with the remaining prefixes after the sorted ones.
     */
    static getAllPrefixes(
        batesPrefixList: UploadState.BatesPrefixList,
        excludeDefault = false,
    ): string[] {
        const prefixes = BatesPrefixListUtil.getCurPrefixes(batesPrefixList);
        PrefixGroupMapUtil.getAllPrefixes(batesPrefixList.allPrefixGroups).forEach((prefix) => {
            if (!Arr.contains(prefixes, prefix)) {
                prefixes.push(prefix);
            }
        });
        if (excludeDefault) {
            Arr.remove(prefixes, PrefixGroupUtil.DEFAULT_GROUP);
        }
        return prefixes;
    }

    static getUserPrefixes(batesPrefixList: UploadState.BatesPrefixList): string[] {
        return PrefixGroupMapUtil.getAllPrefixesByType(
            batesPrefixList.allPrefixGroups,
            BatesPrefixType.USER,
        );
    }

    static getDetectedPrefixes(batesPrefixList: UploadState.BatesPrefixList): string[] {
        return PrefixGroupMapUtil.getAllPrefixesByType(
            batesPrefixList.allPrefixGroups,
            BatesPrefixType.DETECTED,
        );
    }

    static getFileCount(batesPrefixList: UploadState.BatesPrefixList, prefix: string): number {
        const prefixGroup = PrefixGroupMapUtil.get(batesPrefixList.curPrefixGroups, prefix);
        if (prefixGroup) {
            return prefixGroup.files.length;
        }
        return 0;
    }

    static isDefaultPrefix(prefix: string): boolean {
        return prefix === PrefixGroupUtil.DEFAULT_GROUP;
    }

    static isEmptyPrefix(prefix: string): boolean {
        return prefix === Bates.NO_PREFIX;
    }

    static getDefaultGroup(batesPrefixList: UploadState.BatesPrefixList): UploadState.PrefixGroup {
        return BatesPrefixListUtil.getGroup(batesPrefixList, PrefixGroupUtil.DEFAULT_GROUP);
    }

    static countDefaultGroup(batesPrefixList: UploadState.BatesPrefixList): number {
        return BatesPrefixListUtil.getFileCount(batesPrefixList, PrefixGroupUtil.DEFAULT_GROUP);
    }

    static hasEmptyPrefixDocs(batesPrefixList: UploadState.BatesPrefixList): boolean {
        const emptyPrefixGroup = PrefixGroupMapUtil.get(
            batesPrefixList.allPrefixGroups,
            Bates.NO_PREFIX,
        );
        return emptyPrefixGroup?.files.length > 0 || false;
    }

    static getGroup(
        batesPrefixList: UploadState.BatesPrefixList,
        prefix: string,
    ): UploadState.PrefixGroup {
        return PrefixGroupMapUtil.get(batesPrefixList.curPrefixGroups, prefix);
    }

    static sampleFiles(
        batesPrefixList: UploadState.BatesPrefixList,
        prefix: string,
        sampleCount: number,
    ): FileAndBates[] {
        const group = BatesPrefixListUtil.getGroup(batesPrefixList, prefix);
        const fabc: FileAndBates[] = [];
        if (!group) {
            return fabc;
        }
        const sampleMax = Math.max(1, group.files.length - 1);
        const step = Math.ceil(sampleMax / sampleCount);
        let stepCnt = 0;
        for (let idx = 0; idx < group.files.length && stepCnt++ < sampleCount; idx += step) {
            fabc.push(group.files[idx]);
        }
        return fabc;
    }

    /*
     * Using the bates numbers parsed from a filename, and the page count calculated in processing
     *  determine the endBates number for each file. Check if this overlaps with the beginBates for
     *  any other file.
     */
    static detectOverlappingBatesInGroup(
        batesPrefixList: UploadState.BatesPrefixList,
        prefix: string,
        uploadFileList: UploadState.UploadFileList,
        allowOverlap = false,
    ): [FileAndBates[], FileAndBates[]] {
        const errorList: FileAndBates[] = [];
        const goodList: FileAndBates[] = [];
        if (allowOverlap) {
            // No need to do anything in this case, just proceed after fully populating goodList
            PrefixGroupMapUtil.get(batesPrefixList.curPrefixGroups, prefix).files.forEach((fab) => {
                goodList.push(fab);
            });
        } else {
            // Bates numbers that have already been assigned.
            const usedBatesNumbers = new Set<string>();
            // Duplicate filenames should already be removed but a few lines of code here
            //  can prevent more annoying errors during upload if there are any bugs.
            const usedFilenames = new Set<string>();
            PrefixGroupMapUtil.get(batesPrefixList.curPrefixGroups, prefix).files.forEach((fab) => {
                // We leave out suffix and page number here because the processed uploader
                // currently doesn't support them
                const displayBates = Bates.display(fab.bates, false, false);
                if (usedBatesNumbers.has(displayBates) || usedFilenames.has(fab.fileEntry.name)) {
                    errorList.push(fab);
                } else {
                    usedBatesNumbers.add(displayBates);
                    usedFilenames.add(fab.fileEntry.name);
                    goodList.push(fab);
                }
            });
        }
        // Sort by both prefix and number
        goodList.sort((a, b) => Bates.compare(a.bates, b.bates));
        for (let idx = 0; idx < goodList.length - 1; ++idx) {
            const filename = goodList[idx].fileEntry.name;
            const doc1Begin: Bates = goodList[idx].bates;
            const doc2Begin: Bates = goodList[idx + 1].bates;
            // Only compare numbers if the prefixes are identical (they should be normalized to upper-case)
            if (doc1Begin.prefix === doc2Begin.prefix) {
                const bates1Pages = uploadFileList.filenameToUploadFile.get(
                    filename.toLowerCase(),
                ).pageCount;
                const doc1EndNum = doc1Begin.number.add(bates1Pages - 1);
                const cmpBates = doc1EndNum.compare(doc2Begin.number);
                if (cmpBates >= 0) {
                    errorList.push(goodList[idx + 1]);
                }
            }
        }
        return [goodList, errorList];
    }

    /* The rest of these function are only used for some automated test cases which have not been
     *  included in the shipping code. Retain for future testing.
    toString() {
        let returnStr = "";
        this.curPrefixGroups.getSortedGroups().forEach((pg) => {
            returnStr += pg.normalized + "\n";
            returnStr += pg.files.map((fab) => fab.fileEntry.name).join(" , ");
            returnStr += "\n";
        });
        return returnStr;
    }

    toTestString() {
        return encodeURIComponent(this.toString());
    }

    getAllFilenames() {
        return this.allFileEntries.map((file) => file.name);
    }

    summaryString() {
        let nob = 0;
        const defGroup = this.curPrefixGroups.get(PrefixGroupUtil.DEFAULT_GROUP)
        if (defGroup) {
            nob = defGroup.files.length
        }
        return `pre:${this.allPrefixGroups.getAllPrefixes().length} `
            + `grp:${this.curPrefixGroups.getAllPrefixes().length} nob:${nob}`;
    }
    */
}

export interface CustomPrefixParams {
    // The list of desired custom prefixes to add
    prefixes: string[];
    // Whether to initially enable the empty prefix group
    allowEmptyPrefix: boolean;
    // The upload's parcel number (used to communicate with the backend via a REST request)
    parcel: number;
    // The upload's ID (used to communicate with the backend via a REST request)
    uploadId: number;
    // The upload's project's ID (used to communicate with the backend via a REST request)
    projectId: number;
}
