import {
    Button,
    ButtonColor,
    ButtonSize,
    ButtonWidth,
    IconButton,
    TextButton,
} from "components/Button";
import { BasicChip } from "components/Chip/Chip";
import { Counter } from "components/Counter/Counter";
import * as Icon from "components/Icon";
import * as CommonIcon from "components/Icon/CommonIcon";
import { Popover, PopoverPlacement } from "components/Popover";
import { H2, HeadingMargin, Span } from "components/Text";
import { Tooltip, TooltipPlacement } from "components/Tooltip";
import { capitalize, countOf } from "core";
import { EverId } from "EverAttribute/EverId";
import { Memo, useBrandedCallback } from "hooks/useBranded";
import { useCombinedRef } from "hooks/useCombinedRef";
import React, { Dispatch, forwardRef, ReactElement, RefObject, useRef, useState } from "react";
import * as ButtonTokens from "tokens/typescript/ButtonTokens";
import { EverColor } from "tokens/typescript/EverColor";
import * as TypographyTokens from "tokens/typescript/TypographyTokens";
import { getSizePx } from "util/css";
import { EverIdProp, FFC } from "util/type";
import "./ColumnFilter.scss";

/**
 * This file contains components related to table column filters such as
 * {@link ColumnFilterSummary}, as well as subcomponents that are used in within various
 * column filter components.
 *
 * All column filter components should extend these props. They should also be added as a
 * subcomponent of the {@link Table} component. See the bottom of Table.tsx.
 */
export interface ColumnFilterProps<T> extends EverIdProp {
    /**
     * The name of the column, which will be displayed at the top of the filter popover in the
     * format `Filter by ${columnName}`.
     */
    columnName: string;
    /**
     * The currently applied value of the column filter.
     */
    filterValue?: T;
    /**
     * The function to call when the filter has changed.
     */
    setFilterValue: Dispatch<T | undefined> | Memo<(value?: T) => void>;
    /**
     * The results count to display in the filter popover preview. This will be replaced with
     * a loading icon if {@link previewLoading} is true.
     */
    previewCount: number;
    /**
     * The function to call when the currently entered (but not necessarily applied) filter
     * value changes.
     */
    setPreviewFilterValue: Dispatch<T | undefined> | Memo<(value?: T) => void>;
    /**
     * Whether the preview results count is currently loading. If true, displays a loading icon
     * instead of the preview count.
     */
    previewLoading?: boolean;
    /**
     * Whether there are other filters currently applied.
     */
    otherActiveFilters: boolean;
    /**
     * The show state of the popover.
     */
    showPopover: boolean;
    /**
     * The setter for the show state of the popover.
     */
    setShowPopover: Dispatch<boolean>;
    /**
     * See {@link PopoverProps.placement}.
     */
    popoverPlacement?: PopoverPlacement | PopoverPlacement[];
    /**
     * {@link EverId} to assign to filter popover.
     */
    popoverEverId?: EverId;
    /**
     * See {@link TooltipProps.placement}.
     */
    tooltipPlacement?: TooltipPlacement | TooltipPlacement[];
}

const FILTER_SUMMARY_POPOVER_WIDTH = 320;

interface ActiveFilter {
    /**
     * The id of the column. This id should match {@link ColumnProps.id}.
     */
    columnId: string;
    /**
     * An optional column name, which will be displayed in the filter chip along with the value.
     * Defaults to the capitalized version of {@link columnId}.
     */
    columnName?: string;
    /**
     * The display for the value of the active filter. Use the display function specific
     * to the corresponding column filter variant for consistency. For example, use the
     * {@link Table.getCheckboxFilterDisplay} function for a column that uses
     * {@link Table.CheckboxColumnFilter}.
     *
     * If empty or not provided, the chip for this column will not be displayed.
     */
    filterDisplay?: string;
    /**
     * The setter for the show state of the column filter popover.
     */
    setShowPopover: Dispatch<boolean>;
}

export interface ColumnFilterSummaryProps extends EverIdProp {
    /**
     * A list of {@link ActiveFilter} objects corresponding to the currently applied column
     * filters. The summary popover will be populated with filter chips based on this list.
     */
    activeFilters: ActiveFilter[];
    /**
     * The function to call when the user clicks the "X" button a column filter chip or removes
     * all filters. When columnId is null, then all filters should be removed.
     */
    onRemoveFilter: (columnId: string | null) => void;
    /**
     * See {@link PopoverProps.placement}. Defaults to PopoverPlacement.BOTTOM_END.
     */
    popoverPlacement?: PopoverPlacement | PopoverPlacement[];
    /**
     * {@link EverId} to assign to the column filter summary popover.
     */
    popoverEverId?: EverId;
}

const SUMMARY_BUTTON_CLASS = "bb-table-filter-summary__button";

interface SummaryFilterChipProps {
    setShowChipPopover: Dispatch<boolean>;
    setShowSummaryPopover: Dispatch<boolean>;
    onRemove: (columnId: string | null) => void;
    filterDisplay?: string;
    columnId: string;
    columnName?: string;
    activeCount: number;
}

function SummaryFilterChip({
    setShowChipPopover,
    setShowSummaryPopover,
    onRemove,
    filterDisplay = "",
    columnId,
    columnName,
    activeCount,
}: SummaryFilterChipProps): ReactElement {
    columnName ||= capitalize(columnId);
    const onClick = useBrandedCallback(() => {
        setShowSummaryPopover(false);
        setShowChipPopover(true);
    }, [setShowChipPopover, setShowSummaryPopover]);
    return (
        <BasicChip
            onClick={onClick}
            aria-description={"Press enter to edit"}
            rightButton={
                <IconButton
                    aria-label={"Remove filter"}
                    onClick={() => {
                        if (activeCount <= 1) {
                            setShowSummaryPopover(false);
                        }
                        onRemove(columnId);
                    }}
                >
                    <Icon.X />
                </IconButton>
            }
        >
            <Span.SmallSemibold>{columnName}: </Span.SmallSemibold>
            {filterDisplay}
        </BasicChip>
    );
}

/**
 * A button and popover that summarizes the currently applied column filters.
 * This component can be passed to the {@link ActionBar.columnFilterSummary} prop, which
 * will display the summary button on the {@link ActionBar}.
 *
 * Each active filter is represented by a chip. Users can edit a filter by clicking on
 * the filter chip, remove a filter by clicking the "X" button, or remove all filters via
 * the "Remove all filters" button.
 */
export function ColumnFilterSummary({
    everId,
    activeFilters,
    onRemoveFilter,
    popoverPlacement = PopoverPlacement.BOTTOM_END,
    popoverEverId,
}: ColumnFilterSummaryProps) {
    activeFilters = activeFilters.filter((filter) => filter.filterDisplay);
    const [showPopover, setShowPopover] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const counterRef = useRef<HTMLSpanElement>(null);
    const counter = <Counter ref={counterRef}>{activeFilters.length}</Counter>;
    // The popover target is the counter, but we want the arrow to be below the button.
    // This value is the amount of space on the button below the counter.
    const counterSpaceBelow =
        (getSizePx(ButtonTokens.HEIGHT_SMALL) - getSizePx(TypographyTokens.BODY_LINE_HEIGHT_MEDIUM))
        / 2;
    return (
        <div className={"bb-table-filter-summary"}>
            <Button
                ref={buttonRef}
                className={SUMMARY_BUTTON_CLASS}
                everId={everId}
                size={ButtonSize.SMALL}
                color={ButtonColor.SECONDARY}
                counter={counter}
                disabled={!activeFilters.length}
                onClick={() => setShowPopover(!showPopover)}
            >
                Filters
            </Button>
            <Popover
                everId={popoverEverId}
                target={counterRef}
                show={showPopover}
                setShow={setShowPopover}
                placement={popoverPlacement}
                contentStyle={{ width: `${FILTER_SUMMARY_POPOVER_WIDTH}px` }}
                aria-label={"Active filters"}
                style={{ top: counterSpaceBelow }}
                triggerSelectors={["." + SUMMARY_BUTTON_CLASS]}
                footer={
                    <div className={"bb-table-filter-summary__footer"}>
                        <TextButton
                            icon={<Icon.Trash />}
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                setShowPopover(false);
                                onRemoveFilter(null);
                            }}
                        >
                            Remove all filters
                        </TextButton>
                    </div>
                }
            >
                <H2.Tiny className={"bb-table-filter-summary__heading"}>ACTIVE FILTERS</H2.Tiny>
                <div className={"v-spaced-8 h-spaced-8"}>
                    {activeFilters.map((filter) => {
                        return (
                            <SummaryFilterChip
                                setShowChipPopover={filter.setShowPopover}
                                setShowSummaryPopover={setShowPopover}
                                onRemove={onRemoveFilter}
                                columnId={filter.columnId}
                                activeCount={activeFilters.length}
                                columnName={filter.columnName}
                                filterDisplay={filter.filterDisplay}
                                key={filter.columnId}
                            />
                        );
                    })}
                </div>
            </Popover>
        </div>
    );
}

interface FilterButtonProps extends EverIdProp {
    filterApplied: boolean;
    descriptionText: string;
    showPopover: boolean;
    setShowPopover: Dispatch<boolean>;
    tooltipPlacement?: TooltipPlacement | TooltipPlacement[];
}

/**
 * A filter button that triggers showing/hiding a column filter popover.
 */
export const FilterButton: FFC<HTMLButtonElement, FilterButtonProps> = forwardRef(
    (
        {
            everId,
            filterApplied,
            descriptionText,
            showPopover,
            setShowPopover,
            tooltipPlacement,
        }: FilterButtonProps,
        buttonRef,
    ) => {
        const internalButtonRef = useRef<HTMLButtonElement>(null);
        const combinedRef = useCombinedRef(buttonRef, internalButtonRef);
        return (
            <>
                <IconButton
                    everId={everId}
                    className={"bb-table__header-filter-button"}
                    ref={combinedRef}
                    aria-label={descriptionText}
                    onClick={() => setShowPopover(!showPopover)}
                >
                    {filterApplied ? (
                        <Icon.FilterFilled size={20} color={EverColor.EVERBLUE_50} />
                    ) : (
                        <Icon.Filter size={20} />
                    )}
                </IconButton>
                {!showPopover && (
                    <Tooltip
                        target={internalButtonRef}
                        aria-hidden={true}
                        placement={tooltipPlacement}
                    >
                        {descriptionText}
                    </Tooltip>
                )}
            </>
        );
    },
);
FilterButton.displayName = "FilterButton";

interface FilterPopoverHeaderProps {
    headingText: string;
    onClear?: () => void;
    disableClear?: boolean;
    hideClear?: boolean;
    clearButtonRef?: RefObject<HTMLButtonElement>;
}

/**
 * A header containing a heading and clear button. This should be displayed at the top of most
 * if not all column filter popovers.
 */
export function FilterPopoverHeader({
    headingText,
    onClear,
    disableClear = false,
    hideClear = false,
    clearButtonRef,
}: FilterPopoverHeaderProps) {
    return (
        <div className={"bb-column-filter__header"}>
            <H2.ExtraSmall className={"bb-column-filter__heading"} marginType={HeadingMargin.NONE}>
                {headingText}
            </H2.ExtraSmall>
            {!hideClear && onClear && (
                <TextButton
                    ref={clearButtonRef}
                    size={ButtonSize.SMALL}
                    disabled={disableClear}
                    onClick={() => onClear()}
                >
                    Clear
                </TextButton>
            )}
        </div>
    );
}

interface FilterPopoverFooterProps {
    onCancel: () => void;
    onDelete: () => void;
    onSave: () => void;
    hasSavedValue: boolean;
    disableSave: boolean;
    applyCount: number | null;
}

export interface FilterPopoverPreviewProps {
    previewCount: number;
    previewLoading: boolean;
    otherActiveFilters: boolean;
}

/**
 * A preview of the number of results for the currently entered filter value. This should be
 * displayed above the footer of most if not all column filter popovers.
 */
export function FilterPopoverPreview({
    previewCount,
    previewLoading,
    otherActiveFilters,
}: FilterPopoverPreviewProps) {
    return (
        <div className={"bb-column-filter__preview"}>
            {previewLoading && <CommonIcon.Loading size={20} aria-hidden={true} />}
            <Span.Italic className={"bb-text--color-secondary"}>
                {previewLoading
                    ? "Previewing results"
                    : `${countOf(previewCount, "result")} from ${
                          otherActiveFilters ? "filters" : "filter"
                      }`}
            </Span.Italic>
        </div>
    );
}

/**
 * A footer containing a trash button as well as "Cancel" and "Apply" buttons. This should be
 * passed into the {@link PopoverProps.footer} prop for most if not all column filter popovers.
 */
export function FilterPopoverFooter({
    onCancel,
    onDelete,
    onSave,
    hasSavedValue,
    disableSave,
    applyCount,
}: FilterPopoverFooterProps) {
    const trashButtonRef = useRef<HTMLButtonElement>(null);
    return (
        <div className={"bb-column-filter__footer"}>
            <IconButton
                ref={trashButtonRef}
                className={"bb-column-filter__trash-button"}
                aria-label={"Remove filter"}
                disabled={!hasSavedValue}
                onClick={onDelete}
            >
                <Icon.Trash size={24} />
            </IconButton>
            <Tooltip target={trashButtonRef} aria-hidden={true}>
                Remove filter
            </Tooltip>
            <div className={"h-spaced-8"}>
                <Button
                    color={ButtonColor.SECONDARY}
                    size={ButtonSize.SMALL}
                    width={ButtonWidth.FIXED}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    color={ButtonColor.PRIMARY}
                    size={ButtonSize.SMALL}
                    width={ButtonWidth.FIXED}
                    disabled={disableSave}
                    onClick={onSave}
                    counter={applyCount !== null ? <Counter>{applyCount}</Counter> : undefined}
                >
                    Apply
                </Button>
            </div>
        </div>
    );
}

export function MultiValueHeading() {
    return (
        <>
            <Span.Semibold>Values</Span.Semibold>{" "}
            <Span className={"bb-text--color-secondary"}>(any of)</Span>
        </>
    );
}
