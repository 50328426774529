import { Str } from "core";

// This should match the enum in UploadSource.java
enum UploadSource {
    LOCAL = "LOCAL",
    LOCAL_DIR = "LOCAL_DIR",
    BOX = "BOX",
    DROPBOX = "DROPBOX",
    GOOGLE_DRIVE = "GOOGLE_DRIVE",
    ONEDRIVE = "ONEDRIVE",
    DIRECT_LINK = "DIRECT_LINK",
    VAULT = "VAULT",
    SHAREFILE = "SHAREFILE",
    OFFICE_365_TEAMS = "OFFICE_365_TEAMS",
    OFFICE_365_CALENDAR = "OFFICE_365_CALENDAR",
    OFFICE_365_OUTLOOK = "OFFICE_365_OUTLOOK",
    SLACK = "SLACK",
    ZOOM = "ZOOM",
    ZENDESK = "ZENDESK",
    ASANA = "ASANA",
    JIRA = "JIRA",
    SALESFORCE = "SALESFORCE",
    STAGING_AREA = "STAGING_AREA",
    STAGING_AREA_DIR = "STAGING_AREA_DIR",
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module UploadSource {
    export function isLocal(source: UploadSource): boolean {
        return source === UploadSource.LOCAL || source === UploadSource.LOCAL_DIR;
    }

    export function getO365Apps(): UploadSource[] {
        return [
            UploadSource.ONEDRIVE,
            UploadSource.OFFICE_365_TEAMS,
            UploadSource.OFFICE_365_CALENDAR,
            UploadSource.OFFICE_365_OUTLOOK,
        ];
    }

    export function getDisplayName(source: UploadSource): string {
        switch (source) {
            case UploadSource.SHAREFILE:
                return "ShareFile";
            case UploadSource.ONEDRIVE:
                return "OneDrive";
            case UploadSource.OFFICE_365_CALENDAR:
                return "Outlook Calendar";
            case UploadSource.OFFICE_365_OUTLOOK:
                return "Outlook Mail";
            case UploadSource.OFFICE_365_TEAMS:
                return "Teams";
            default:
                return Str.snakeToHuman(source.toLocaleLowerCase())
                    .split(" ")
                    .map((str) => Str.capitalize(str))
                    .join(" ");
        }
    }

    export function usesUploadWorkers(source: UploadSource) {
        return (
            source === UploadSource.ZOOM
            || source === UploadSource.SALESFORCE
            || source === UploadSource.ASANA
            || source === UploadSource.JIRA
            || source === UploadSource.ZENDESK
        );
    }
}
export = UploadSource;
