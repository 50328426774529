/**
 * PermissionStrings
 *
 * These strings map to permissions on the back-end (EsiPermission and/or ProjectPermission). They
 * should be used in all permission checks (User#can, User.Group#can, etc.).
 *
 * Convention: This module should be imported under the binding 'Perm', so that permission checks
 * are of the form Perm.READ, Perm.WRITE, etc.
 */

// placeholder for no permission
export const NONE = "NONE";

// EsiPermission and ProjectPermission
export const READ = "READ";
export const ADMIN = "ADMIN";

// EsiPermission only
export const WRITE = "WRITE";
export const CREATE = "CREATE";
export const DB_ADMIN = "DB_ADMIN";
export const DB_UPLOAD = "DB_UPLOAD";
export const DB_DELETE = "DB_DELETE";
export const DB_LEGAL_HOLD = "DB_LEGAL_HOLD";

// ProjectPermission only
export const PARTIAL_PROJECT_DOCS = "PARTIAL_PROJECT_DOCS";
export const DOCUMENT_HISTORY = "DOCUMENT_HISTORY";
export const BATCH_UPDATE = "BATCH_UPDATE";
export const APPLY_TO_SELECTED = "APPLY_TO_SELECTED";
export const EXPORT_CSV = "EXPORT_CSV";
export const EXPORT_PDF = "EXPORT_PDF";
export const EXPORT_ZIP = "EXPORT_ZIP";
export const DOWNLOAD = "DOWNLOAD";
export const ANALYTICS = "ANALYTICS";
export const RECEIVE_ASSIGNMENT_GROUPS = "RECEIVE_ASSIGNMENT_GROUPS";
export const CREATE_ASSIGNMENT_GROUPS = "CREATE_ASSIGNMENT_GROUPS";
export const ADMIN_ASSIGNMENT_GROUPS = "ADMIN_ASSIGNMENT_GROUPS";
export const READ_REDACTIONS = "READ_REDACTIONS";
export const CREATE_REDACTIONS = "CREATE_REDACTIONS";
export const ADMIN_REDACTIONS = "ADMIN_REDACTIONS";
export const READ_NOTES = "READ_NOTES";
export const CREATE_NOTES = "CREATE_NOTES";
export const ADMIN_NOTES = "ADMIN_NOTES";
export const READ_RATING = "READ_RATING";
export const WRITE_RATING = "WRITE_RATING";
export const SHARE_PRODUCTIONS = "SHARE_PRODUCTIONS";
export const ADMIN_PRODUCTIONS = "ADMIN_PRODUCTIONS";
export const RECEIVE_STRS = "RECEIVE_STRS";
export const CREATE_STRS = "CREATE_STRS";
export const ADMIN_STRS = "ADMIN_STRS";
export const RECEIVE_STORYBUILDER = "RECEIVE_STORYBUILDER";
export const CREATE_STORYBUILDER = "CREATE_STORYBUILDER";
export const ADMIN_STORYBUILDER = "ADMIN_STORYBUILDER";
export const RECEIVE_PREDICTION_MODELS = "RECEIVE_PREDICTION_MODELS";
export const CREATE_PREDICTION_MODELS = "CREATE_PREDICTION_MODELS";
export const ADMIN_PREDICTION_MODELS = "ADMIN_PREDICTION_MODELS";
export const UNITIZATION = "UNITIZATION";
export const PAUSE_AUTO_CODE = "PAUSE_AUTO_CODE";
export const FULL_DOC_ACCESS = "FULL_DOC_ACCESS";
export const EDIT_METADATA = "EDIT_METADATA";
export const ADMIN_CLUSTERING = "ADMIN_CLUSTERING";
export const READ_CLUSTERING = "READ_CLUSTERING";
export const PERMANENT_ROTATIONS = "PERMANENT_ROTATIONS";

export const VIEW_REVIEW_ASSISTANT = "VIEW_REVIEW_ASSISTANT";
export const GENERATE_REVIEW_ASSISTANT = "GENERATE_REVIEW_ASSISTANT";

export const VIEW_CODING_SUGGESTIONS = "VIEW_CODING_SUGGESTIONS";
export const GENERATE_CODING_SUGGESTIONS = "GENERATE_CODING_SUGGESTIONS";

export const VIEW_WRITING_ASSISTANT = "VIEW_WRITING_ASSISTANT";
export const GENERATE_WRITING_ASSISTANT = "GENERATE_WRITING_ASSISTANT";

export const BATCH_AI_TASKS = "BATCH_AI_TASKS";

export const STORE_TRANSLATIONS = "STORE_TRANSLATIONS";

export const VIEW_ORACLE = "VIEW_ORACLE";
export const GENERATE_ORACLE = "GENERATE_ORACLE";

// Organization permissions
export const ORG_ADMIN = "ORG_ADMIN";
export const CLOUD_MANAGEMENT_ADMIN = "CLOUD_MANAGEMENT_ADMIN";
export const LEGAL_HOLD_ADMIN = "LEGAL_HOLD_ADMIN";
export const AI_ADMIN = "AI_ADMIN";

// Categories and Codes permissions
export const CODES_ADMIN = "CODES_ADMIN";
