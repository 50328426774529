/**
 * A type-safe pub/sub channel implementation, usable by both React code (via the
 * {@link usePubSubChannel} hook below) and imperative code (via {@link PubSubChannel#publish}).
 */
import Bugsnag = require("Everlaw/Bugsnag");
import { useEffect, useState } from "react";
import { useLatest } from "design-system";

/**
 * The callback function for a {@link PubSubChannel} subscription.
 */
export type PubSubChannelCallback<U> = (update: U) => void;

/**
 * A type-safe pub/sub channel. Set the type parameter to the payload of the publish() method, which
 * is also the argument passed to subscriber callbacks. Defaults to an empty object.
 */
export class PubSubChannel<U> {
    private subs: PubSubChannelCallback<U>[] = [];
    private lastUpdate: U | null = null;

    /**
     * Subscribe to updates from the channel.
     *
     * @param callback The callback to invoke when the channel is updated.
     * @returns An unsubscribe function that should be called during cleanup
     */
    subscribe(callback: PubSubChannelCallback<U>): () => void {
        this.subs.push(callback);
        return () => {
            const idx = this.subs.lastIndexOf(callback); // O(1) assuming LIFO
            idx >= 0 && this.subs.splice(idx, 1);
        };
    }

    /**
     * Publish an update to the channel.
     *
     * @param update the update payload
     */
    publish(update: U): void {
        // Until we have strict null checks in place, we need this runtime check as publishing
        // null or undefined to a channel can break React support (since it won't be clear from
        // lastUpdate whether the channel has ever been published to or not).
        if (update === null || update === undefined) {
            Bugsnag.notify(Error("Cannot pass null/undefined to PubSubChannel#publish"));
        }
        this.lastUpdate = update;
        this.subs.forEach((sub) => sub(update));
    }

    /**
     * Get the payload from the last update. Useful if you subscribe to the channel after an update
     * has occurred. Also needed by the {@link usePubSubChannel} React hook.
     */
    getLastUpdate(): U | null {
        return this.lastUpdate;
    }

    /**
     * Unsubscribe everyone from the channel, for cleanup purposes.
     */
    unsubscribeAll(): void {
        this.subs = [];
    }

    /**
     * Returns true if there is at least one subscription to the channel.
     */
    hasSubscription(): boolean {
        return !!this.subs.length;
    }
}

/**
 * React hook for subscribing to channel updates. Whenever publish() is called on the channel, the
 * component will re-render.
 *
 * @param channel The PubSubChannel to subscribe to.
 * @returns The last published update to the channel, or null if the channel has never been used
 */
export function usePubSubChannel<U>(channel: PubSubChannel<U>): U | null {
    const initialUpdateRef = useLatest<U | null>(channel.getLastUpdate());
    const [updateWrapper, setUpdateWrapper] = useState<{ update: U | null }>({
        update: initialUpdateRef.current,
    });

    useEffect(() => {
        // Because useEffect() is run asynchronously after usePubSubChannel() has been called, we
        // need to handle the potential race condition where an update has been published to the
        // channel in between these two calls.
        const lastUpdate = channel.getLastUpdate();
        if (!Object.is(lastUpdate, initialUpdateRef.current)) {
            setUpdateWrapper({ update: lastUpdate });
        }
        return channel.subscribe((update) => setUpdateWrapper({ update }));
    }, [channel, initialUpdateRef]);

    return updateWrapper.update;
}
