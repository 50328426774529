import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import Icon = require("Everlaw/UI/Icon");
import { Is, Str } from "core";
import Tooltip = require("Everlaw/UI/Tooltip");
import Util = require("Everlaw/Util");
import dijit_Tooltip = require("dijit/Tooltip");
import dojo_on = require("dojo/on");
import { clsx } from "clsx";
import { Content } from "Everlaw/Dom";
import { getTextToHash, setEverHash } from "Everlaw/EverAttribute/EverHash";
import { EverId, removeEverId, setEverId } from "Everlaw/EverAttribute/EverId";
import { FocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";

class Button {
    node: HTMLButtonElement;
    icon: Icon;
    private iconClass: string | undefined;
    private hoverIconClass: string | undefined;
    private dimIconOnHover: boolean;
    private disabledIconClass: string | undefined;
    private toDestroy: Util.Destroyable[] = [];
    private onClickHandler: dojo_on.Handle;
    private useTap: boolean | undefined;
    private label: Dom.Content;
    private loadingLabel: Dom.Content;
    private loading: boolean;
    private iconLast: boolean | undefined;
    focusDiv: FocusDiv | null;
    constructor(params: Button.Params) {
        this.node = Dom.create(
            "button",
            {
                content: params.label,
                class: params.class || "",
                style: params.style,
            },
            params.parent,
        );
        params.everId && setEverId(this.node, params.everId);
        this.setEverHash(params.label);
        this.iconClass = params.icon;
        this.hoverIconClass = params.hoverIcon;
        this.dimIconOnHover = !!params.dimIconOnHover;
        this.disabledIconClass = params.disabledIcon;
        this.label = params.label;
        this.loadingLabel = params.loadingLabel;
        this.loading = false;
        this.iconLast = params.iconLast;
        let alt = params.alt;
        if (!alt && params.label) {
            alt = "";
        }
        this.iconClass
            && Dom.place(
                (this.icon = new Icon(this.iconClass, { alt })),
                this.node,
                params.iconLast ? "last" : "first",
            );
        // If width was explicitly null, don't style the button with any specific width.
        const width = Is.defined(params.width) ? params.width : "one";
        if (width) {
            if (Str.endsWith(width, "px") || Str.endsWith(width, "%")) {
                // If a pixel width was supplied, use that instead.
                Dom.style(this.node, "width", width);
            } else {
                Dom.addClass(this.node, width + "-width");
            }
        }
        if (params.makeFocusable) {
            this.focusDiv = makeFocusable(
                this.node,
                params.focusStyling || "focus-with-space-style",
                params.focusDivPos,
            );
            this.toDestroy.push(
                this.focusDiv,
                Input.fireCallbackOnKey(this.focusDiv.node, [Input.ENTER, Input.SPACE], (evt) => {
                    this.node.click();
                    evt.stopPropagation();
                    evt.preventDefault();
                }),
            );
        }
        // There must be a hoverIconClass for dimming to work.
        if (this.dimIconOnHover && !this.hoverIconClass) {
            this.hoverIconClass = this.iconClass;
        }
        if (this.iconClass && this.hoverIconClass) {
            this.toDestroy.push(
                dojo_on(this.node, "mouseenter", () => {
                    if (this.node.disabled) {
                        return;
                    }
                    Dom.replaceClass(
                        this.icon,
                        "icon_" + this.hoverIconClass,
                        "icon_" + this.iconClass,
                    );
                    this.dimIconOnHover && Dom.addClass(this.icon.node, "icon-hover");
                }),
                dojo_on(this.node, "mouseleave", () => {
                    if (this.node.disabled) {
                        return;
                    }
                    Dom.replaceClass(
                        this.icon,
                        "icon_" + this.iconClass,
                        "icon_" + this.hoverIconClass,
                    );
                    this.dimIconOnHover && Dom.removeClass(this.icon.node, "icon-hover");
                }),
            );
            if (params.makeFocusable && this.focusDiv) {
                this.toDestroy.push(
                    dojo_on(this.focusDiv.node, "focus", () => {
                        Dom.replaceClass(
                            this.icon,
                            "icon_" + this.hoverIconClass,
                            "icon_" + this.iconClass,
                        );
                        this.dimIconOnHover && Dom.addClass(this.icon.node, "icon_hover");
                    }),
                    dojo_on(this.focusDiv.node, "blur", () => {
                        Dom.replaceClass(
                            this.icon,
                            "icon_" + this.iconClass,
                            "icon_" + this.hoverIconClass,
                        );
                        this.dimIconOnHover && Dom.removeClass(this.icon.node, "icon-hover");
                    }),
                );
            }
        }
        // Input.tap does not get clicks simulated by the keyboard. On buttons, use the 'click'
        // event by default to encompasses all methods of pressing the button, not just the mouse.
        this.useTap = params.useTap;
        if (params.onClick) {
            this.setOnClick(params.onClick);
        }

        this.setDisabled(!!params.disabled);
    }
    setDisabled(disabled: boolean) {
        // The loading state is different from the disabled state, even though they both set the
        // actual dom node to disabled
        if (this.loading) {
            this.setLoading(false);
        }
        if (disabled === this.node.disabled) {
            return;
        }
        this.node.disabled = disabled;
        if (this.node.disabled) {
            this.icon
                && this.disabledIconClass
                && Dom.replaceClass(
                    this.icon,
                    "icon_" + this.disabledIconClass,
                    "icon_" + this.iconClass + " icon_" + this.hoverIconClass,
                );
            Dom.setAttr(this.node, "aria-disabled", "true");
        } else {
            /* Assume the mouse is not already over the disabled icon when re-enabling the
             * icon.  There is no easy way to check this since disabled buttons have no mouse
             * events, and there are also no mouse events if the page loads in a hovering
             * state.  At worst, the re-enabled button will lack the hover state until the
             * mouse moves out and back in.  The off === isDisabled check above prevents this
             * from being an issue unless the button was previously disabled.  */
            dijit_Tooltip.hide(this.node); // ensure no tooltips when disabled
            this.icon
                && this.disabledIconClass
                && Dom.replaceClass(
                    this.icon,
                    "icon_" + this.iconClass,
                    "icon_" + this.disabledIconClass,
                );
            Dom.removeAttr(this.node, "aria-disabled");
        }
    }
    setLoading(loading: boolean) {
        if (this.loading === loading) {
            return;
        }
        this.loading = loading;
        this.node.disabled = loading;
        if (loading) {
            // Replace the text content of the button. The icon needs to be placed again here too,
            // if it exists, because of how setContent works
            if (this.loadingLabel) {
                this.setContent(this.loadingLabel);
                if (this.icon) {
                    Dom.place(this.icon, this.node, this.iconLast ? "last" : "first");
                }
            }

            if (this.icon && !this.iconLast) {
                this.icon.setIconClass("animated-loader-white");
                Dom.addClass(this.icon, "step-spinning margin-right-4");
            } else {
                Dom.place(
                    new Icon("animated-loader-white step-spinning margin-right-4", {
                        alt: "loading",
                    }),
                    this.node,
                    "first",
                );
            }
            Dom.setAttr(this.node, "aria-disabled", "true");
        } else {
            this.setContent(this.label);
            if (this.icon) {
                Dom.place(this.icon, this.node, this.iconLast ? "last" : "first");
            }
            Dom.removeAttr(this.node, "aria-disabled");
        }
    }
    setContent(...items: Content[]): void {
        Dom.setContent(this, items);
        this.focusDiv && this.focusDiv.replace();
        this.setEverHash(items);
    }
    /** setOnClick destroys the previous onClick handler if it existed, and replaces it with a new one */
    setOnClick(onClick: (evt: Event, button: Button) => void): void {
        if (this.onClickHandler) {
            Util.destroy(this.onClickHandler);
        }
        this.onClickHandler = dojo_on(this.node, this.useTap ? Input.tap : "click", (e) => {
            onClick(e, this);
        });
        this.node.setAttribute("type", "button"); // no form submission
    }
    setIconClass(newIconClass: string): void {
        this.iconClass = newIconClass;
        this.icon.setIconClass(newIconClass);
    }
    setHoverIconClass(newHoverIconClass: string): void {
        Dom.removeClass(this.icon, "icon_" + this.hoverIconClass);
        this.hoverIconClass = newHoverIconClass;
    }
    setEverId(everId?: EverId): void {
        removeEverId(this.node);
        everId && setEverId(this.node, everId);
    }
    focus() {
        this.node.focus();
    }
    destroy() {
        if (this.onClickHandler) {
            Util.destroy(this.onClickHandler);
        }
        Util.destroy(this.toDestroy);
        Dom.destroy(this.node);
        this.focusDiv = null;
    }
    private setEverHash(content?: Dom.Content): void {
        const textToHash = getTextToHash(content);
        textToHash && setEverHash(this.node, textToHash);
    }
}

module Button {
    export interface Params {
        /** the text of the button */
        label?: Dom.Content;
        /** accessible label for icon buttons with no visible label */
        alt?: string;
        /** the function to call when the button is clicked */
        onClick?: (evt: Event, button: Button) => void;
        /** if true, binds onClick to Input.tap event, not "click" event. Note: Input.tap does not get
         * clicks simulated by the keyboard. */
        useTap?: boolean;
        /** the parent to which the button should be appended */
        parent?: string | Node;
        /**
         * additional classes to add to the node, including whether the action is safe, unsafe, generic
         */
        class?: string;
        width?: ButtonWidth;
        /** additional styles to apply to the node */
        style?: string | Dom.StyleProps;
        icon?: string;
        /** Places the icon last rather than the default first. */
        iconLast?: boolean;
        /**
         * Change icons on hover. hoverIcon holds the class of the hovering icon,
         * and icon is used otherwise. Ignored if icon is not also defined. If dimOnHover is true opacity
         * will be set to .7 on hover too.
         */
        hoverIcon?: string;
        dimIconOnHover?: boolean;
        // If true, the button will be disabled as initial state.
        disabled?: boolean;
        /**
         * Icon to show when disabled.
         */
        disabledIcon?: string;
        // If true, the button will be keyboard-focusable using the FocusDiv class.
        makeFocusable?: boolean;
        // If makeFocusable is true, the button will be styled with these classes when focused. The
        // default is focus-with-space-style.
        focusStyling?: string[] | string;
        // If makeFocusable is true, the FocusDiv attached to the button will be placed according to the
        // pos parameter.
        focusDivPos?: string;
        // Label to replace the normal label when the button is in the loading state
        loadingLabel?: Dom.Content;
        // The EverId to apply to the button node.
        everId?: EverId;
    }

    /**
     * Either a standard width: `default`, `max`, `one`, `half`, `three-halves`, `two`;
     * or an arbitrary pixel width (must be suffixed with "px"). The latter option is not recommended.
     *
     * If `undefined`, the default `one` will be used.
     * If `null`, the button will resize to fit the contents.
     *
     * For a lone button, `one` (the default) is an appropriate option for short labels, and `null`
     * (which resizes to fit its contents) is appropriate for longer labels. In an area with multiple
     * buttons, we conventionally use the smallest non-null standard width that works for all the
     * buttons.
     */
    export type ButtonWidth = string | null | undefined;

    /**
     * Creates and returns a button for adding/creating a new item.
     *
     * The button has a "+" icon and scales horizontally to fit its label. If the label is empty, the
     * button will be a circular "+" button.
     *
     * These buttons are styled uniformly across the site, so you SHOULD NOT use the usual button
     * classes (important, safe, skinny, etc.) in the class parameter.
     */
    export function newAddition(params: {
        label: string;
        onClick?: (evt: Event) => void;
        class?: string;
        parent?: string | Node;
        makeFocusable?: boolean;
        focusStyling?: string | string[];
    }) {
        let classes = "add-new safe important";
        if (params.class) {
            classes += " " + params.class;
        }
        return new Button({
            label: params.label,
            class: classes,
            icon: "plus-white-20",
            width: null,
            parent: params.parent,
            onClick: params.onClick,
            makeFocusable: Is.defined(params.makeFocusable) ? params.makeFocusable : true,
            focusStyling: params.focusStyling,
        });
    }

    /**
     * Creates and returns a button for left/right arrow buttons.
     */
    export function leftRight(
        left: boolean,
        params: {
            onClick: (evt: Event) => void;
            useHoverIcon: boolean;
            parent?: string | Node;
            dimIconOnHover?: boolean;
            makeFocusable?: boolean;
            focusStyling?: string | string[];
            isUpDown?: boolean;
        },
    ) {
        const buttonParams: Button.Params = {
            class: "button-left-right ",
            alt: left ? "previous" : "next",
            width: "default",
            parent: params.parent,
            onClick: params.onClick,
            dimIconOnHover: !!params.dimIconOnHover,
            makeFocusable: params.makeFocusable,
            focusStyling: params.focusStyling,
        };
        buttonParams.class += "skinny";
        buttonParams.icon = left ? "chevron-left" : "chevron-right";
        if (params.useHoverIcon) {
            buttonParams.hoverIcon = left ? "chevron-left-white" : "chevron-right-white";
        }
        if (params.isUpDown) {
            buttonParams.icon = left ? "chevron-up" : "chevron-down";
        }
        if (params.dimIconOnHover) {
            buttonParams.hoverIcon = ""; // Don't need to specify hoverIcon when dimming.
        }

        return new Button(buttonParams);
    }

    interface PrevNextOptionalParams {
        midContent?: HTMLElement;
        // If true, PrevNext buttons will display as up/down instead of left/right.
        isUpDown?: boolean;
        // If true, on hover will change button icon. Otherwise, on hover will just highlight.
        useHoverIcon?: boolean;
    }

    /**
     * Class for an adjacent left/right button pair.
     */
    export class PrevNext {
        node: HTMLElement;
        private readonly prev: Button;
        private readonly next: Button;
        private tooltips: Tooltip[] = [];
        private toDestroy: Util.Destroyable[] = [];
        constructor(onMove: (dir: 1 | -1) => void, params: PrevNextOptionalParams = {}) {
            this.node = Dom.div({ class: "prev-next-buttons" });
            const useHoverIcon = Is.defined(params.useHoverIcon) ? params.useHoverIcon : true;
            this.prev = Button.leftRight(true, {
                parent: this.node,
                onClick: () => onMove(-1),
                makeFocusable: true,
                useHoverIcon: useHoverIcon,
                isUpDown: params.isUpDown,
            });
            params.midContent && Dom.place(params.midContent, this.node);
            this.next = Button.leftRight(false, {
                parent: this.node,
                onClick: () => onMove(1),
                makeFocusable: true,
                useHoverIcon: useHoverIcon,
                isUpDown: params.isUpDown,
            });
            if (params.midContent) {
                Dom.addClass([this.prev, this.next], "spaced");
            }
            this.toDestroy.push(this.prev, this.next);
        }

        setDisabled(disabled = true, prevOrNext?: "prev" | "next"): void {
            if (prevOrNext === "prev") {
                this.prev.setDisabled(disabled);
            } else if (prevOrNext === "next") {
                this.next.setDisabled(disabled);
            } else {
                this.prev.setDisabled(disabled);
                this.next.setDisabled(disabled);
            }
        }

        addTooltips(tooltipPrev: Dom.Content, tooltipNext: Dom.Content): Tooltip[] {
            Util.destroy(this.tooltips);
            this.tooltips = [
                new Tooltip(this.prev, tooltipPrev),
                new Tooltip(this.next, tooltipNext),
            ];
            return this.tooltips;
        }

        destroy() {
            Util.destroy(this.toDestroy);
            Util.destroy(this.tooltips);
        }
    }

    export interface IconButtonParams {
        /**
         * An optional class to apply to the button element.
         */
        className?: string;
        /**
         * The class of the icon to display in the button. The icon class must represent a 24px or
         * 20px icon. A 24px icon will result in a 32px (large) icon button, and a 20px icon (any icon
         * class ending with "-20") will result in a 24px (small) icon button.
         */
        iconClass: string;
        /**
         * A text label to display within the button under the icon. If not provided, then ariaLabel
         * must be provided instead.
         */
        label?: string;
        /**
         * If provided, a tooltip with the given content will appear on hover. Generally, this
         * should be provided.
         */
        tooltip?: Dom.Content;
        /**
         * The list of valid positions for the tooltip.
         */
        tooltipPosition?: string[];
        /**
         * A descriptive string describing the purpose of a button. If ariaLabel is not provided,
         * but a string type tooltip is, then the tooltip content will be used as the aria-label.
         */
        ariaLabel?: string;
        /**
         * An optional boolean that, when true, disables the button using the 'disabled' attribute.
         * If no value is provided, defaults to false.
         * When ariaDisable is true, the 'aria-disabled' attribute will be used instead.
         */
        disabled?: boolean;
        /**
         * If true, tooltips should continue to be shown when the button is disabled. Defaults to false.
         * Only valid when {@link tooltip} is specified.
         */
        showTooltipOnDisable?: boolean;
        /**
         * The parent node (or its id) to which the button should be appended.
         */
        parent?: string | Node;
        /**
         * The function to call when the button is clicked.
         */
        onClick?: (evt: Event, button?: IconButton) => void;
        /**
         * If provided, suppresses the specified dojo event, where "tap" refers to Input.tap
         * and "press" refers to Input.press.
         *
         * If the button is positioned within an element that has a listener on Input.tap
         * or Input.press, then you may need to suppress that event to prevent button clicks
         * from activating the ancestor element.
         */
        suppressDojoEvent?: "tap" | "press";
        /**
         * If provided, the onClick callback is connected to Input.tap or Input.press instead
         * of the "click" event. This may be useful in edge cases where there are conflicting
         * interactions with Dojo event listeners. Generally, this should not be needed.
         */
        useTapOrPress?: "tap" | "press";
    }

    /**
     * An imperative version of our design system IconButton, which contains just an icon and
     * looks flush with the page until hovered.
     *
     * Generally, any icon that performs some action on click should use an IconButton rather than
     * a plain Icon with a click handler.
     */
    export class IconButton {
        node: HTMLButtonElement;
        icon: Icon;
        iconClass: string;
        disabled: boolean;
        showTooltipOnDisable: boolean;
        tooltip: Tooltip;
        onClick?: (evt: Event, button?: IconButton) => void;
        private toDestroy: Util.Destroyable[] = [];

        constructor({
            className,
            iconClass,
            label,
            ariaLabel,
            tooltip,
            tooltipPosition,
            disabled = false,
            showTooltipOnDisable = false,
            parent,
            onClick,
            suppressDojoEvent,
            useTapOrPress,
        }: IconButtonParams) {
            const size = iconClass.includes("20") || iconClass.includes("16") ? "small" : "large";
            this.node = Dom.create(
                "button",
                {
                    class: clsx(
                        className,
                        "bb-icon-button",
                        `bb-icon-button--${size}`,
                        "icon-button",
                        { "bb-icon-button--labeled": label },
                    ),
                    type: "button",
                },
                parent,
            );
            if (!label) {
                if (ariaLabel) {
                    this.node.ariaLabel = ariaLabel;
                } else if (Is.string(tooltip)) {
                    this.node.ariaLabel = tooltip;
                }
            }
            this.iconClass = iconClass;
            this.icon = Dom.place(new Icon(iconClass), this.node);
            label && Dom.place(Dom.div({ class: "bb-icon-button__label" }, label), this.node);
            Dom.setAttr(this.icon, "aria-hidden", "true");
            this.showTooltipOnDisable = showTooltipOnDisable;
            this.setDisabled(disabled);
            tooltip
                && this.toDestroy.push(
                    (this.tooltip = new Tooltip(this.node, tooltip, tooltipPosition)),
                );
            if (suppressDojoEvent && suppressDojoEvent !== useTapOrPress) {
                this.toDestroy.push(
                    dojo_on(
                        this.node,
                        suppressDojoEvent === "tap" ? Input.tap : Input.press,
                        (e) => {
                            e.stopPropagation();
                        },
                    ),
                );
            }
            this.setOnClick(onClick);
            if (useTapOrPress) {
                this.toDestroy.push(
                    dojo_on(this.node, useTapOrPress === "tap" ? Input.tap : Input.press, (e) => {
                        if (suppressDojoEvent === useTapOrPress) {
                            e.stopPropagation();
                        }
                        !this.disabled && this.onClick?.(e, this);
                    }),
                );
            } else {
                this.node.onclick = (e) => !this.disabled && this.onClick?.(e, this);
            }
        }

        setIconClass(newIconClass: string) {
            Dom.replaceClass(this.icon, "icon_" + newIconClass, "icon_" + this.iconClass);
            this.iconClass = newIconClass;
        }

        setAriaLabel(newAriaLabel: string) {
            this.node.ariaLabel = newAriaLabel;
        }

        setOnClick(onClick?: (evt: Event, button?: IconButton) => void) {
            this.onClick = onClick;
        }

        setDisabled(disabled = true): void {
            if (disabled === this.disabled) {
                return;
            }
            this.disabled = disabled;
            // If a tooltip should be shown while the button is disabled, we set `aria-disabled`
            // instead of the `disabled` attribute, since setting `disabled` would also disable
            // tab focusing, making it impossible to trigger the tooltip using keyboard navigation.
            this.node.disabled = !this.showTooltipOnDisable && disabled;
            this.showTooltipOnDisable
                && Dom.setAttr(this.node, "aria-disabled", disabled ? "true" : "false");
        }

        destroy() {
            Util.destroy(this.toDestroy);
            Dom.destroy(this.node);
        }
    }
}

export = Button;
