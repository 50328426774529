import React, { Dispatch, ReactNode, useRef } from "react";
import { IconButton } from "components/Button";
import * as Icon from "components/Icon";
import { PopoverMenu, PopoverMenuPlacement } from "components/Menu/PopoverMenu";
import "./DetailCard.scss";

export interface DetailCardThreeDotMenuProps {
    /**
     * The content of the popup menu triggered by clicking on the
     * three-dot icon. Expects one or more PopoverMenu.Section elements.
     */
    children: ReactNode;
    /**
     * If true, show the popover.
     */
    show: boolean;
    /**
     * Function that sets the show state. You'll also want to call
     * {@code setShow(false)} in the {@code onClick()} handlers of
     * menu options, to close the menu
     */
    setShow: Dispatch<boolean>;
}

/**
 * A three-dot menu to display in the far right of DetailCard's headerButtons
 */
export function DetailCardThreeDotMenu({ children, show, setShow }: DetailCardThreeDotMenuProps) {
    const iconButtonRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            <IconButton
                aria-label={"More options"}
                ref={iconButtonRef}
                onClick={() => setShow(!show)}
            >
                <Icon.Dots size={20} />
            </IconButton>
            {/* place menu so as to cover as little of the card as possible */}
            <PopoverMenu
                children={children}
                show={show}
                setShow={setShow}
                trigger={iconButtonRef}
                placement={[
                    PopoverMenuPlacement.RIGHT,
                    PopoverMenuPlacement.RIGHT_START,
                    PopoverMenuPlacement.RIGHT_END,
                    PopoverMenuPlacement.LEFT_END,
                    PopoverMenuPlacement.LEFT,
                    PopoverMenuPlacement.LEFT_START,
                ]}
            />
        </>
    );
}
