import ActionNode = require("Everlaw/UI/ActionNode");
import Button = require("Everlaw/UI/Button");
import Dom = require("Everlaw/Dom");
import { CombinationAnalysis } from "Everlaw/Model/Upload/Metadata/CombinationAnalysis";
import { EverColor } from "design-system";
import { Arr, Compare as Cmp, Str } from "core";
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import { LoadfileColumn } from "Everlaw/UI/Upload/Metadata/LoadfileColumn";
import { MergeGroup as MergeGroupType } from "Everlaw/UI/Upload/Metadata/MergeGroup";
import MetadataDefinition_type = require("Everlaw/Model/Upload/Metadata/MetadataDefinition");
import { NameAnalysis } from "Everlaw/Model/Upload/Metadata/NameAnalysis";
import SingleSelect = require("Everlaw/UI/SingleSelect");
import TabView = require("Everlaw/UI/Upload/Util/TabView");
import Tooltip = require("Everlaw/UI/Tooltip");
import Type = require("Everlaw/Type");
import UploadUI = require("Everlaw/UI/Upload/Util/UploadUI");
import Util = require("Everlaw/Util");
import dojo_on = require("dojo/on");

// These are smaller than those given to guesses derived directly from the column header and
// based on historical data (during analysis on the backend) to ensure that those are preferred.
const DEFAULT_CUSTOM_COUNT = 0.1;
const DEFAULT_CUSTOM_SIMILARITY = 0.01;

/**
 * Corresponds to ColumnCombination.java.
 */
class ColumnCombination extends TabView.Tabbable {
    // Ordered with the primary column first.
    columns: LoadfileColumn[];
    private _nameAnalyses: NameAnalysis[];
    private _content: HTMLElement;
    private _row: HTMLElement[];
    private namePicker: SingleSelect<NameAnalysis>;
    private nameStatusIcon: Icon;
    private nameStatusText: HTMLElement;
    private nameStatusTooltip: Tooltip;
    private _destroyables: Util.Destroyable[] = [];
    constructor(
        columns: any,
        private def: MetadataDefinition_type,
        public mergeGroup: MergeGroupType,
    ) {
        super();
        this.columns = [];
        columns.forEach((col) => {
            if (col instanceof LoadfileColumn) {
                (<LoadfileColumn>col).columnCombination = this;
            } else {
                col = new LoadfileColumn(col, def, this);
            }
            this.columns.push(col);
        });
        Arr.sort(this.columns, {
            key(c) {
                return c.header;
            },
        });
        this.id = this.columns.map((c) => c.header).join(" + ");
    }
    toJSON() {
        return { columns: this.columns };
    }
    fullName() {
        return this.id;
    }
    type() {
        return this.primary().selectedTypeFormat.type;
    }
    combinationAnalysis() {
        return this.isCombined()
            ? this.def.analyses.combinationAnalysis(
                  this.primary().selectedTypeFormat,
                  this.otherColumn().selectedTypeFormat,
              )
            : null;
    }
    combinationAnalysisId() {
        return this.combinationAnalysis()?.id();
    }
    /**
     * In cases where a `ColumnCombination` has 2 columns, this will be the same as
     * `combinationAnalysisId`, but since `ColumnCombination`s with only one column don't
     * have corresponding `CombinationAnalysis` objects, we need an alternative way to get
     * their `MergeAnalysis` IDs.
     * @returns a string representing this `ColumnCombination`'s contribution to a `MergeAnalysis` ID
     */
    mergeAnalysisId() {
        return JSON.stringify(this.columns.map((lc) => lc.selectedTypeFormat.id).sort());
    }
    isCombined() {
        return this.columns.length > 1;
    }
    isComboType() {
        return this.type().equals(Type.DATE_TIME);
    }
    canCombine() {
        return this.comboOptions().length > 0;
    }
    private comboOptions() {
        return !this.isCombined() ? this.def.comboOptions(this.primary()) : [];
    }
    nameAnalyses() {
        if (!this._nameAnalyses) {
            const best: { [name: string]: NameAnalysis } = {};
            this.columns.forEach((c) => {
                c.selectedNameAnalyses().forEach((a) => {
                    const curBest = best[a.name];
                    if (!curBest || a.score() > curBest.score()) {
                        best[a.name] = a;
                    }
                });
            });
            this._nameAnalyses = Object.values(best);
        }
        return this._nameAnalyses;
    }
    orderedNameOptions() {
        return Arr.sorted(this.nameAnalyses()).map((na) => na.name);
    }
    sampleResults() {
        if (this.isCombined()) {
            return this.combinationAnalysis().sampleResults;
        }
        return this.primary().sampleResults();
    }
    numValues() {
        if (this.isCombined()) {
            return this.combinationAnalysis().numValues;
        }
        return this.primary().selectedTypeFormat.numValues;
    }

    /* Combine Stage operations */

    private combine(combinationAnalysis: CombinationAnalysis) {
        this.def.combine(combinationAnalysis);
    }
    private split() {
        this.def.split(this);
    }

    /* Normalize Stage operations */

    private updateMergeName(name: string) {
        if (this.mergeGroup.targetName !== name) {
            this.def.changeMergeGroup(name, this);
            this.updateRow();
        }
    }

    /* Combine Stage UI */

    override init() {
        super.init();
        this.updateTab();
        this.updatePane();
    }
    /**
     * Creates the unchanging elements of the displayed tab of this column.
     */
    protected initTab() {
        Dom.place(Dom.div({ class: "metadata-tab-title" }, this.id), this._tab);
    }
    /**
     * Creates the unchanging elements of the displayed pane of this column.
     */
    protected initPane() {
        Dom.place(
            [
                Dom.div({ class: "metadata-pane__title" }, Dom.h6(this.fullName())),
                (this._content = Dom.div({ class: "metadata-pane__options v-spaced-16" })),
            ],
            this._pane,
        );
    }

    getTabClass() {
        if (this.isCombined()) {
            return "verification";
        } else if (this.canCombine()) {
            return "inspection";
        }
        return "ignored";
    }

    updatePane() {
        const content = this._content;
        Dom.empty(content);
        if (this.isCombined()) {
            Dom.place(this.buildAnalysisForCurrent(), content);
        } else if (this.canCombine()) {
            Dom.place(this.buildAnalysisForPotential(), content);
        } else {
            Dom.place(Dom.span("This column cannot be combined with another field"), content);
        }
    }

    /**
     * Build a summary of a current combination using its CombinationAnalysis.
     */
    private buildAnalysisForCurrent() {
        const ca: CombinationAnalysis = this.combinationAnalysis();
        const mainHeader = this.primary().header;
        const indices = this.getIndices(mainHeader, ca);
        const otherHeader = Arr.firstElement(ca.headers, (h) => h !== mainHeader);
        const mainColumn = this.primary();
        const otherColumn = this.otherCandidate(otherHeader);

        const mainOption = Dom.div(
            { class: "metadata-def-option__wrapper" },
            this.buildCombiningOption(mainColumn, ca.sampleInputs, indices.first),
        );
        const otherOption = Dom.div(
            { class: "metadata-def-option__wrapper" },
            this.buildCombiningOption(otherColumn, ca.sampleInputs, indices.second),
        );

        const result = Dom.div(
            { class: "metadata-def-option__wrapper" },
            this.buildCombinedResult(mainHeader, otherHeader, ca),
        );

        const analysisSummary = Dom.div(
            { class: "metadata-def-options h-spaced-16" },
            result,
            this.buildArrow(),
            mainOption,
            otherOption,
        );

        const button = new Button({
            label: "Split",
            onClick: () => {
                ga_event("Processed Upload", "Split column combination", this.fullName());
                this.split();
            },
            class: "safe",
        });
        this._destroyables.push(button);

        return [analysisSummary, Dom.div(button.node)];
    }

    /**
     * Build a summary of a current combination using its CombinationAnalysis.
     */
    private buildAnalysisForPotential() {
        const options = this.comboOptions();
        // Sort the compatibleName pairs to the top.
        Arr.sort(options, {
            cmp: (a, b) => {
                return (
                    Cmp.bool(b.compatibleNames, a.compatibleNames)
                    || Cmp.arr(a.typeAnalysisIds, b.typeAnalysisIds)
                );
            },
        });

        // Build the containers that are filled with initial or selected options.
        const mainOptionsContainer = Dom.div({ class: "metadata-def-option__wrapper" });
        const otherOptionsContainer = Dom.div({ class: "metadata-def-option__wrapper" });
        const resultsContainer = Dom.div({ class: "metadata-def-option__wrapper" });

        // Build the initial display elements before any options are clicked.
        const ca: CombinationAnalysis = options[0];
        const mainHeader = this.primary().header;
        const indices = this.getIndices(mainHeader, ca);

        const mainColumn = this.primary();
        const mainOption = this.buildCombiningOption(mainColumn, ca.sampleInputs, indices.first);
        Dom.place(mainOption, mainOptionsContainer);

        const optionMenu = Dom.div(
            {
                class: "metadata-comb-options-menu metadata-def-option metadata-def-option--narrow v-spaced-8",
            },
            Dom.div(Dom.span({ class: "h7" }, "Select the field to combine with")),
        );
        const optionMenuWrapper = Dom.div(
            {
                class: "metadata-def-option__inner-wrapper metadata-def-option__wrapper--short v-spaced-8",
            },
            Dom.div(Dom.span({ class: "h7" }, "Combining field")),
            optionMenu,
        );
        Dom.place(optionMenuWrapper, otherOptionsContainer);

        const resultInstructions = Dom.div(
            { class: "metadata-def-option__inner-wrapper v-spaced-8" },
            Dom.div(Dom.span({ class: "h7" }, "Resulting field")),
            Dom.div(
                { class: "metadata-comb-results-instruct metadata-def-option" },
                "Select a field to preview the results",
            ),
        );
        Dom.place(resultInstructions, resultsContainer);

        const initialChildren = [mainOption, optionMenuWrapper, resultInstructions];

        // Build the display elements for each potential CombinationAnalysis.
        const optionIntros: HTMLElement[] = [];
        const mainSamples: HTMLElement[] = [];
        const otherOptions: HTMLElement[] = [];
        const results: HTMLElement[] = [];
        const selectionChildren = [mainSamples, otherOptions, results];

        let currentIndex = -1;
        const displayPreview = (newIndex: number) => {
            Dom.hide(initialChildren);
            if (currentIndex >= 0) {
                selectionChildren.forEach((children) => Dom.hide(children[currentIndex]));
            }
            selectionChildren.forEach((children) => Dom.show(children[newIndex]));
            currentIndex = newIndex;
            Dom.show(button);
        };
        const clearPreview = () => {
            Dom.hide(button);
            selectionChildren.forEach((child) => Dom.hide(child[currentIndex]));
            currentIndex = -1;
            Dom.show(initialChildren);
        };

        options.forEach((ca, optionIndex) => {
            const mainHeader = this.primary().header;
            const indices = this.getIndices(mainHeader, ca);
            const otherHeader = Arr.firstElement(ca.headers, (h) => h !== mainHeader);
            const otherColumn = this.otherCandidate(otherHeader);

            const clear = ActionNode.textAction("Change", clearPreview);
            this.toDestroy.push(clear);

            const mainOption = this.buildCombiningOption(
                mainColumn,
                ca.sampleInputs,
                indices.first,
            );
            const otherOption = this.buildCombiningOption(
                otherColumn,
                ca.sampleInputs,
                indices.second,
                clear,
            );
            const result = this.buildCombinedResult(mainHeader, otherHeader, ca);
            const optionIntro = this.buildOptionIntro(otherColumn, () =>
                displayPreview(optionIndex),
            );

            optionIntros.push(optionIntro);
            mainSamples.push(mainOption);
            otherOptions.push(otherOption);
            results.push(result);
            Dom.hide([mainOption, otherOption, result]);
        });
        Dom.place(optionIntros, optionMenu);
        Dom.place(mainSamples, mainOptionsContainer);
        Dom.place(otherOptions, otherOptionsContainer);
        Dom.place(results, resultsContainer);

        const analysisSummary = Dom.div(
            { class: "metadata-def-options h-spaced-16" },
            mainOptionsContainer,
            otherOptionsContainer,
            this.buildArrow(),
            resultsContainer,
        );

        const button = new Button({
            label: "Combine",
            onClick: () => {
                this.combine(options[currentIndex]);
                ga_event("Processed Upload", "Combine column combination", this.fullName());
            },
            class: "safe",
        });
        this._destroyables.push(button);
        Dom.hide(button);

        return [analysisSummary, Dom.div(button.node)];
    }

    private getIndices(mainHeader: string, ca: CombinationAnalysis) {
        const invert = mainHeader !== ca.headers[0];
        const first = invert ? 1 : 0;
        const second = invert ? 0 : 1;
        return { first, second };
    }

    private primary() {
        return this.columns[0];
    }
    private otherColumn(column: LoadfileColumn = this.primary()) {
        return Arr.firstElement(this.columns, (c) => c !== column);
    }
    private otherCandidate(header: string) {
        return Arr.firstElement(this.def.columns, (c) => c.header === header);
    }

    private buildCombiningOption(
        column: LoadfileColumn,
        sampleInputs: string[][],
        index: number,
        titleAction?: ActionNode,
    ) {
        const subtitleContent = column.selectedTypeFormat.displaySubtitle();
        const title = this.buildTitle(column.header, subtitleContent);
        const numValues = this.buildCount(
            "values",
            column.selectedTypeFormat.numValues,
            this.def.getNumDocs(),
        );
        const label = this.isCombined() ? "Resulting field" : "Combining field";
        const samples = sampleInputs.map((inputPair) => inputPair[index]);
        return this.buildOption(title, [numValues], true, label, samples, titleAction);
    }

    private buildCombinedResult(mainHeader: string, otherHeader: string, ca: CombinationAnalysis) {
        const title = this.buildTitle(`${mainHeader} + ${otherHeader}`, null);
        const numDocs = this.def.getNumDocs();
        const comb = this.buildCount("combined values", ca.numCombined, numDocs);
        const one = this.buildCount("orphan values", ca.numValues - ca.numCombined, numDocs);
        const none = this.buildCount("no values", numDocs - ca.numValues, numDocs);
        const statsContent: HTMLElement[] = [comb, one, none];
        const label = this.isCombined() ? "Splitting field" : "Resulting field";
        return this.buildOption(title, statsContent, false, label, ca.sampleResults);
    }

    private buildOption(
        title: HTMLElement,
        statsContent: HTMLElement[],
        isNarrow: boolean,
        label: string,
        samples: string[],
        titleAction?: ActionNode,
    ) {
        let clazz = "metadata-def-option";
        if (isNarrow) {
            clazz += " metadata-def-option--narrow metadata-merge-option--narrow";
        }
        const samplesNode = Dom.div({ class: "metadata-def-option__samples" });
        samples.forEach((sample) => {
            let sampleClass = "metadata-def-option__sample ellipsed";
            if (this.type().isRightAligned()) {
                sampleClass += " align-right";
            }
            const sampleNode = Dom.div({ class: sampleClass }, sample);
            this.toDestroy.push(Tooltip.setDescription(sampleNode, sample));
            Dom.place(sampleNode, samplesNode);
        });
        const stats = Dom.div({ class: "metadata-def-option__stats" }, statsContent);
        let wrapperCls = "metadata-def-option__inner-wrapper v-spaced-8";
        if (isNarrow) {
            wrapperCls += " metadata-def-option__wrapper--short";
        }
        return Dom.div(
            { class: wrapperCls },
            Dom.div(
                { class: "upload-spaced-header" },
                Dom.div({ class: "h7" }, label),
                titleAction ? titleAction.node : null,
            ),
            Dom.div({ class: clazz }, title, stats, samplesNode),
        );
    }

    private buildCount(name: string, count: number, outOf: number, asStat = true) {
        const countDisplay = outOf ? Util.percentOf(outOf, count) : count;
        if (asStat) {
            return Dom.div(
                { class: "metadata-def-option__stat" },
                Dom.span({ class: "h7" }, Str.capitalize(name)),
                Dom.span(countDisplay),
            );
        } else {
            return Dom.div(
                { class: "metadata-comb-option-intro__subtitle" },
                `${countDisplay} ${name}`,
            );
        }
    }

    private buildTitle(titleContent: string, subtitleContent: HTMLElement[]) {
        return Dom.div(
            { class: "metadata-def-option__title" },
            Dom.div({ class: "h6" }, titleContent),
            Dom.div({ class: "metadata-def-option__subtitle" }, subtitleContent),
        );
    }

    private buildOptionIntro(column: LoadfileColumn, displayPreview: () => void) {
        const numValues = this.buildCount(
            "values",
            column.selectedTypeFormat.numValues,
            this.def.getNumDocs(),
            false,
        );
        const intro = Dom.div(
            { class: "metadata-comb-option-intro action" },
            Dom.div({ class: "h7 ellipsed" }, column.header),
            Dom.div(numValues),
        );
        const click = dojo_on(intro, Input.tap, () => {
            displayPreview();
        });
        this.toDestroy.push(click);
        return intro;
    }

    /* Normalize Stage UI */

    getRow() {
        return this._row;
    }

    /**
     * Looks like: | Status & Name picker | Header | Samples... |
     */
    initRow(numSampleColumns: number) {
        this.nameStatusIcon = new Icon("");
        this.toDestroy.push(this.nameStatusIcon);
        const nameDisplay = Dom.span(
            { class: "metadata-name-display ellipsed" },
            this.mergeGroup.targetName,
        );
        const nameTooltip = new Tooltip.MirrorTooltip(nameDisplay);
        this._destroyables.push(nameTooltip);
        const editIcon = new Button.IconButton({
            iconClass: "pencil-20",
            tooltip: "Edit",
            onClick: () => {
                Dom.hide(namePickerReplaces);
                Dom.show(namePicker);
                namePicker.focus();
            },
        });
        this.toDestroy.push(editIcon);
        this.nameStatusText = Dom.div({ class: "metadata-name-status" });
        const namePickerReplaces = [
            this.nameStatusIcon,
            nameDisplay,
            editIcon,
            this.nameStatusText,
        ];
        const namePicker = this.createNamePicker(
            () => {
                Dom.setContent(nameDisplay, namePicker.getValue().name);
            },
            () => {
                Dom.hide(namePicker);
                Dom.show(namePickerReplaces);
            },
        );
        Dom.hide(namePicker);
        const nameCell = Dom.div(
            { class: "metadata-name-cell" },
            Dom.div(
                { class: "metadata-name-summary h-spaced-8" },
                this.nameStatusIcon.node,
                nameDisplay,
                editIcon.node,
            ),
            this.nameStatusText,
            namePicker.getNode(),
        );

        const headerCell = Dom.div(this.fullName());
        this._destroyables.push(new Tooltip.MirrorTooltip(headerCell));

        this._row = [nameCell, headerCell];
        const samples = this.sampleResults().slice(0, numSampleColumns);
        samples.forEach((sample) => {
            const sampleNode = Dom.span(sample);
            this._destroyables.push(new Tooltip.MirrorTooltip(sampleNode));
            this._row.push(Dom.div(sampleNode));
        });
        if (samples.length < numSampleColumns) {
            this._row.push(UploadUI.buildNoMoreValues(samples.length));
        }

        this.updateRow();
    }

    updateRow(updateSiblings = true) {
        if (this.nameStatusText) {
            const nameStatus = this.mergeGroup.nameStatus();
            const isMerge = this.mergeGroup.isMerge();

            Dom.setContent(this.nameStatusText, nameStatus.summary(isMerge));
            Dom.toggleClass(
                this.nameStatusText,
                "metadata-name-status--invalid",
                !nameStatus.valid,
            );
            Dom.replaceClass(
                this.nameStatusIcon,
                nameStatus.iconClass(isMerge),
                UploadUI.STATUS_ICON_CLASSES,
            );

            this.nameStatusTooltip && this.nameStatusTooltip.destroy();
            const tooltip = this.mergeGroup.nameStatusTooltip();
            if (tooltip) {
                this.nameStatusTooltip = new Tooltip(this.nameStatusText, tooltip);
            }
        }
        if (updateSiblings) {
            this.mergeGroup.columnCombinations.forEach((cc) => cc.updateRow(false));
        }
    }

    private createNamePicker(onSelect: (elem: NameAnalysis) => void, onBlur: () => void) {
        this.namePicker = new SingleSelect<NameAnalysis>({
            elements: this.nameAnalyses(),
            newOption: true,
            newClass: NameAnalysis,
            newColor: EverColor.PARCHMENT_70,
            createNew: (name: string, clazz: string, callback: (a: NameAnalysis) => void) => {
                const na = new NameAnalysis(
                    name,
                    DEFAULT_CUSTOM_COUNT,
                    DEFAULT_CUSTOM_SIMILARITY,
                    this.type(),
                    this.def.getNameRestrictions(name),
                );
                this._nameAnalyses.push(na);
                callback(na);
            },
            canAdd: (name: string) =>
                this.nameAnalyses()
                    .map((ns) => ns.name)
                    .indexOf(name) < 0,
            popup: "after",
            headers: false,
            onSelect: (elem, isNew) => {
                this.updateMergeName(elem.name);
                onSelect(elem);
                this.namePicker.blur();
            },
            onBlur,
            selectOnSame: true, // don't allow deselect
        });
        this.namePicker.setValue(this.mergeGroup.targetName);
        return this.namePicker;
    }

    private buildArrow() {
        const arrow = new Icon("arrow-left-32");
        this.toDestroy.push(arrow);
        return Dom.div({ class: "metadata-def-arrow" }, arrow.node);
    }

    override destroy() {
        super.destroy();
        Dom.destroy(this._row);
        this.namePicker && this.namePicker.destroy();
        this.nameStatusTooltip && this.nameStatusTooltip.destroy();
        Util.destroy(this._destroyables);
    }
}

export { ColumnCombination };
