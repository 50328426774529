import clsx from "clsx";
import { Scrollbar } from "components/Scrollbar";
import {
    BasePopover,
    BasePopoverProps,
    POPOVER_DEFAULT_ARROW_HEIGHT,
    POPOVER_DEFAULT_ARROW_MARGIN,
    POPOVER_DEFAULT_ARROW_WIDTH,
    PopoverPlacement as PopoverMenuPlacement,
} from "components/util/BasePopover";
import * as CSS from "csstype";
import React, {
    createContext,
    CSSProperties,
    FC,
    ReactNode,
    RefCallback,
    RefObject,
    useLayoutEffect,
    useState,
} from "react";
import * as BorderTokens from "tokens/typescript/BorderTokens";
import * as PopoverMenuTokens from "tokens/typescript/PopoverMenuTokens";
import { getSizePx } from "util/css";
import { EverIdProp } from "util/type";

export interface MenuType {
    isDropdown: boolean;
    isFilterable?: boolean;
}

export const MenuContext = createContext<MenuType>({
    isDropdown: false,
    isFilterable: false,
});

export interface BasePopoverMenuProps
    extends EverIdProp,
        Pick<
            BasePopoverProps,
            | "arrow"
            | "arrowHeight"
            | "arrowMargin"
            | "arrowWidth"
            | "modal"
            | "nesting"
            | "offset"
            | "renderOutsideParent"
            | "show"
            | "style"
        > {
    /**
     * The label for the popover menu. Only applicable to listboxes.
     */
    "aria-labelledby"?: string;
    /**
     * Whether multiple elements can be selected for this popover menu. Only applicable for listbox
     * menus.
     */
    "aria-multiselectable"?: boolean;
    /**
     * Whether the popover menu is read only. Only applicable to listboxes.
     */
    "aria-readonly"?: boolean;
    /**
     * Whether an option is required to be selected from the menu. Only applicable to listboxes.
     */
    "aria-required"?: boolean;
    /**
     * Contents of the menu.
     */
    children?: ReactNode;
    /**
     * Class for the menu.
     */
    className?: string;
    /**
     * The maximum width for the popover menu. Generally should be left unset.
     */
    maxWidth?: CSS.Property.MaxWidth;
    /**
     * The id property for the main element of the PopoverMenu.
     */
    menuId?: string;
    /**
     * The minimum width for the popover menu. Generally should be left unset.
     */
    minWidth?: CSS.Property.MinWidth;
    /**
     * Placement(s) of the menu relative to trigger. If an array of placements is given and
     * there is not enough space on screen for the first placement, one of the placements listed
     * after the first will be chosen based on whichever one has the least amount of overflow. This
     * means that placement ordering from index 1 and greater will not necessarily be respected.
     *
     * Default `PopoverPlacement.BOTTOM`.
     */
    placement?: PopoverMenuPlacement | PopoverMenuPlacement[];
    /**
     * The role for the main element of the PopoverMenu.
     */
    role: "menu" | "listbox";
    /**
     * A ref to assign to the root element.
     */
    rootRef?: RefObject<HTMLDivElement> | RefCallback<HTMLDivElement>;
    /**
     * An optional element to place at the bottom of the menu, which stays displayed regardless of
     * scrolling.
     */
    stickyFooter?: ReactNode;
    /**
     * An optional element to place at the top of the menu, which stays displayed regardless
     * of scrolling.
     */
    stickyHeader?: ReactNode;
    /**
     * Ref of the element that serves as the trigger for the popover. This is used to position the
     * popover.
     */
    trigger: RefObject<Element>;
}

const MENU_SCROLLER_DEFAULT_MAX_HEIGHT =
    getSizePx(PopoverMenuTokens.MAX_HEIGHT) - 2 * getSizePx(BorderTokens.WIDTH_PRIMARY);

export const BasePopoverMenu: FC<BasePopoverMenuProps> = ({
    className,
    everId,
    children,
    trigger,
    placement = PopoverMenuPlacement.BOTTOM,
    arrow = true,
    arrowWidth = POPOVER_DEFAULT_ARROW_WIDTH,
    arrowHeight = POPOVER_DEFAULT_ARROW_HEIGHT,
    arrowMargin = POPOVER_DEFAULT_ARROW_MARGIN,
    offset,
    role,
    rootRef,
    show = false,
    stickyFooter,
    stickyHeader,
    menuId,
    modal = false,
    renderOutsideParent = false,
    style,
    "aria-labelledby": arialLabelledby,
    "aria-multiselectable": ariaMultiselectable,
    "aria-readonly": ariaReadOnly,
    "aria-required": ariaRequired,
    maxWidth,
    minWidth,
    nesting,
}) => {
    const [stickyHeaderEl, setStickyHeaderEl] = useState<HTMLDivElement | null>(null);
    const [stickyFooterEl, setStickyFooterEl] = useState<HTMLDivElement | null>(null);
    const [autoHeightMax, setAutoHeightMax] = useState<CSS.Property.MaxHeight>(
        MENU_SCROLLER_DEFAULT_MAX_HEIGHT + "px",
    );

    const onVisible = () => {
        const headerHeight: number = stickyHeaderEl?.offsetHeight || 0;
        const footerHeight: number = stickyFooterEl?.offsetHeight || 0;
        setAutoHeightMax(MENU_SCROLLER_DEFAULT_MAX_HEIGHT - headerHeight - footerHeight + "px");
    };

    useLayoutEffect(() => {
        const headerHeight: number = stickyHeaderEl?.offsetHeight || 0;
        const footerHeight: number = stickyFooterEl?.offsetHeight || 0;
        setAutoHeightMax(MENU_SCROLLER_DEFAULT_MAX_HEIGHT - headerHeight - footerHeight + "px");
    }, [stickyFooterEl, stickyHeaderEl]);

    return (
        <BasePopover
            ref={rootRef}
            target={trigger}
            show={show}
            onVisible={onVisible}
            placement={placement}
            arrow={arrow}
            arrowWidth={arrowWidth}
            arrowHeight={arrowHeight}
            arrowMargin={arrowMargin}
            offset={offset}
            className={clsx("bb-popover-menu", className)}
            everId={everId}
            modal={modal}
            renderOutsideParent={renderOutsideParent}
            nesting={nesting}
            style={
                {
                    "--bb-popoverMenu-maxWidth": maxWidth,
                    "--bb-popoverMenu-minWidth": minWidth,
                    ...style,
                } as CSSProperties
            }
        >
            {stickyHeader && (
                <div className={"bb-popover-menu__sticky-header"} ref={setStickyHeaderEl}>
                    {stickyHeader}
                </div>
            )}
            <Scrollbar
                autoHeight={true}
                autoHeightMax={autoHeightMax}
                hideTracksWhenNotNeeded={true}
                thumbSize={158}
            >
                <div
                    aria-labelledby={arialLabelledby}
                    aria-multiselectable={ariaMultiselectable}
                    aria-readonly={ariaReadOnly}
                    aria-required={ariaRequired}
                    role={role}
                    id={menuId}
                >
                    {children}
                </div>
            </Scrollbar>
            {stickyFooter && (
                <div className={"bb-popover-menu__sticky-footer"} ref={setStickyFooterEl}>
                    {stickyFooter}
                </div>
            )}
        </BasePopover>
    );
};
