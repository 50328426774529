import { Arr } from "core";
import { ColumnAnalysis } from "Everlaw/Model/Upload/Metadata/ColumnAnalysis";
import Dom = require("Everlaw/Dom");
import E = require("Everlaw/Entities");
import Type = require("Everlaw/Type");
import Util = require("Everlaw/Util");

// The sum of these thresholds is greater than 1.0. This enforces the condition that
// if type T is the preferred type, then a canonical field with a type other than T
// cannot be preferred. Therefore, being preferred on the basis of canonical field is
// never in conflict with being preferred on the basis of type alone.
const PREFERRED_TYPE_THRESHOLD = 0.8;
const PREFERRED_CANONICAL_THRESHOLD = 0.5;

/**
 * An amalgamation of a potential choice of Type plus formats for a LoadfileColumn, as well as an
 * analysis of the results of that choice.
 * Corresponds loosely to TypeFormat.java and TypeAnalysis.java.
 */
class TypeFormat {
    id: string; // Matches TypeAnalysis.java#TypeAnalysisId.toString
    header: string;
    type: Type.FieldType;
    formats: string[];
    sampleValues: { [value: string]: string };
    sampleErrors: Set<string>;
    numValues: number;
    numErrors: number;
    suspicious: boolean;
    isText: boolean;
    isHash: boolean;
    score = 0;
    constructor(
        params: any,
        private columnAnalysis: ColumnAnalysis,
        private numDocs: number,
    ) {
        Object.assign(this, params);
        this.id = TypeFormat.idFromJson(params.id);
        this.header = params.id.header;
        this.type = Type.TYPE_BY_NAME[params.id.type];
        this.formats = params.formats;
        this.suspicious = params.suspicious;
        this.sampleErrors = Arr.toSet(params.sampleErrors);
        this.isText = this.type === Type.TEXT;

        this.isHash = this.type === Type.MD5 || this.type === Type.SHA1;
        this.calculateScore();
    }
    /**
     * This should always match TypeAnalysis.java#TypeAnalysisId.toString.
     */
    static idFromJson(idJson: { header: string; type: string }) {
        return idJson.header + ":" + idJson.type;
    }
    idJSON() {
        return {
            header: this.header,
            type: this.type.name,
        };
    }
    equals(o: TypeFormat) {
        return o && this.id === o.id;
    }
    getNumErrors(ignoreErrors: boolean) {
        return ignoreErrors ? 0 : this.numErrors;
    }
    getNumNulls(ignoreErrors: boolean) {
        return this.numDocs - this.numValues - this.getNumErrors(ignoreErrors);
    }
    isMerge() {
        return this.formats.length > 1;
    }
    /**
     * The following properties affect scores (from most to least influential on score):
     *      - types that are historically preferred or likely to be a canonical field come first
     *      - text types come after non-text types
     *      - types with more errors come after types with less errors
     *      - types with fewer values come after types with more values
     * More negative scores indicate better formats.
     */
    private calculateScore() {
        const docs = this.numDocs;
        const docsSquared = docs * docs;
        const analysis = this.columnAnalysis;
        const type = this.type.name;
        const typeScore = analysis.typeScore(type) > PREFERRED_TYPE_THRESHOLD ? 4 * docsSquared : 0;
        const canonicalScore =
            analysis.canonicalScore(type) > PREFERRED_CANONICAL_THRESHOLD ? 4 * docsSquared : 0;
        // NOTE about 3 vs. 3.01: alwaysParses which is not actually text should not be selected
        // in case this is the tie-breaker (i.e. the thresholds above did not pass.)
        // This would prevent AddressFrom/List from being selected by default for anything that
        // is not clearly an address field.
        const textDetractor = this.type.alwaysParses ? (this.isText ? 3 : 3.01) * docsSquared : 0;
        const errorDetractor = this.numErrors * docs;
        const valuesScore = this.numValues;
        this.score = -typeScore - canonicalScore + textDetractor + errorDetractor - valuesScore;
    }

    displayTitle() {
        return this.type.displayName();
    }

    displaySubtitle(padNumFormats: number = 0) {
        let contents: HTMLElement[] = [];
        if (this.includeFormats()) {
            contents = this.formats.map((fmt) => Dom.div({ class: "ellipsed" }, fmt));
        }
        for (let padding = contents.length; padding < padNumFormats; padding++) {
            contents.push(Dom.div(E.NBSP));
        }
        return contents;
    }

    displaySummary() {
        const name = Dom.div({ class: "type-format-summary" }, Dom.span(this.type.displayName()));
        if (this.includeFormats()) {
            const numFormats = Dom.span(
                { class: "type-format-summary__formats" },
                Util.countOf(this.formats.length, "Format"),
            );
            Dom.place(numFormats, name);
        }
        return name;
    }

    getNumFormats() {
        return this.includeFormats() ? this.formats.length : 0;
    }

    private includeFormats() {
        return this.type.requiresFormat && !(this.formats.length === 1 && this.formats[0] === "");
    }
}

export { TypeFormat };
