import { Arr } from "core";
import Base = require("Everlaw/Base");
import ContextTable = require("Everlaw/Context/UI/Table");
import Table = require("Everlaw/Table");

export function updateTableParams<OBJ extends Base.Object, DATA extends Table.RowData>(
    tableParams: Table.TableParams<OBJ, DATA>,
    contextedTableParams: ContextTable.Params<OBJ, DATA>,
) {
    const allCells = Arr.concat(tableParams.cells, contextedTableParams.newCells);
    const allHeaders = Arr.concat(tableParams.header, contextedTableParams.newHeaders);
    const allColumns = Arr.concat(tableParams.columns, contextedTableParams.newColumns);
    const allIds = Arr.concat(tableParams.ids, contextedTableParams.newIds);
    const previousColumns = contextedTableParams.previousColumns || {};

    const allIdsIndexed = Arr.toIndexMap(allIds);

    tableParams.cells = [];
    tableParams.header = [];
    tableParams.columns = [];
    tableParams.ids = [];

    (contextedTableParams.columnOrder || allColumns)
        .filter((id) => id in allIdsIndexed)
        .map((id) => allIdsIndexed[id])
        .forEach((index) => {
            tableParams.cells.push(allCells[index]);
            tableParams.header.push(allHeaders[index]);
            const id = allIds[index];
            tableParams.columns.push(previousColumns[id] || allColumns[index]);
            tableParams.ids.push(id);
        });

    tableParams.ids.forEach((id, index) => {
        if (id in (contextedTableParams.replaceCells || {})) {
            tableParams.cells[index] = contextedTableParams.replaceCells[id];
        }
    });

    tableParams.onClick = !contextedTableParams.omitOnClick && tableParams.onClick;

    return tableParams;
}
