import Button = require("Everlaw/UI/Button");
import Dom = require("Everlaw/Dom");
import UI = require("Everlaw/UI");

import aspect = require("dojo/aspect");
import declare = require("dojo/_base/declare");
import dijit_Dialog = require("dijit/Dialog");
import { addEverClass, EVERCLASS, everClassProp } from "Everlaw/EverAttribute/EverClass";
import { FocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";
import * as Util from "Everlaw/Util";
import { Is, userHasIOS } from "core";

/**
 * TODO: implement resize() for our Dialogs.
 * Per https://www.vodori.com/demystifying-dijit-dialog-layout/:
 *
 * The sizing rules are as follows:
 *
 * 1. The dialog generally does not try to size your content or widget. Instead it detects the size
 * of your widget and sizes itself around it. So it starts by calling resize() without parameters
 * on your widget. Then it looks at the dimensions of your widget’s domNode to determine what size
 * your widget chose. The dialog then adds the size of the title bar to obtain the total dimensions
 * and sets its own size to fit both title bar and content.
 *
 * 2. The exception to this is that a dialog will not let itself be bigger than a certain
 * percentage of the screen. This is determined by the maxRatio property, which defaults to 0.9
 * (90%). If the natural size of your content pushes it beyond this, it constrains itself with
 * explicit height/width styles on its own domNode. Then it will call resize() on your widget with
 * explicit h/w dimensions to fit within itself, along with the title bar. Note that if your
 * widget/content does not have a resize() method, it will explicitly size its own containerNode
 * and add scrollbars to accommodate your content within it.
 *
 * 3. The dialog performs all this layout resizing every time its own resize() method is called. In
 * normal Dijit code, the dialog resize() method is called with no parameters, though you can call
 * it yourself with size parameters if you like. By default, dialog resize() method is called every
 * time the dialog is shown and every time the browser window changes size.
 */
const DijitDialog = declare(dijit_Dialog, {
    maxRatio: 1.0,
});

/**
 * For iOS, we don't want to reposition the dialog when the screen is resized (for instance, when
 * the virtual keyboard pops up), so we only position the dialog once.
 */
const FixedDialog = declare(dijit_Dialog, {
    _isPositioned: false,
    _position: function _position() {
        if (!this._isPositioned) {
            // Calls the superclass method
            this.inherited({ callee: _position }, arguments);
            this._isPositioned = true;
        }
    },
});

interface DigitDialogParams {
    content: Node;
    style?: string | Dom.StyleProps;
    refocus?: boolean;
    autofocus?: boolean;
    closable?: boolean;
    onShow?: () => void;
    onHide?: () => void;
    onCancel?: () => void;
    draggable?: boolean;
}

interface DigitDialog {
    domNode: HTMLElement;
    titleNode: HTMLElement;
    autofocus: boolean;
    show(): void;
    hide(): void;
    destroy(): void;
    resize(): void;
    readonly open: boolean;
}

/**
 * Base Dialog class
 */
class Dialog {
    node: HTMLElement;
    title: HTMLElement;
    protected content: Node;
    protected dialog: DigitDialog;
    private readonly onResize: ((dialog: Dialog) => void) | undefined;
    protected focusDiv: FocusDiv | null;
    protected toDestroy: Util.Destroyable[] = [];
    protected autofocus: boolean;
    private focusTimeout: number;
    constructor(params: Dialog.Params) {
        this.buildContent(params);
        this.onResize = params.onResize;
        const dijitParams: DigitDialogParams = {
            content: this.content,
            style: params.style,
            refocus: params.refocus,
            autofocus: params.autofocus,
            draggable: Is.defined(params.draggable) ? params.draggable : true,
        };
        if (params.onShow) {
            dijitParams.onShow = () => {
                params.onShow?.(this);
            };
        }
        if (params.onHide || params.destroyOnHide) {
            dijitParams.onHide = () => {
                params.onHide && params.onHide(this);
                params.destroyOnHide && this.destroy();
            };
        }
        if (params.onCancel) {
            dijitParams.onCancel = () => {
                params.onCancel?.(this);
            };
        }
        if (params.closable === false) {
            dijitParams.closable = false;
        }
        this.dialog = <DigitDialog>new (userHasIOS() ? FixedDialog : DijitDialog)(dijitParams);
        this.node = this.dialog.domNode;
        this.title = this.dialog.titleNode;
        this.setAutofocus(!!params.autofocus);
        this.setTitle(params.title);
        if (params.noTitleBar && this.title.parentElement) {
            Dom.style(this.title.parentElement, { backgroundColor: "white" });
            Dom.hide(this.title.parentElement, !params.closable);
        }
        params.classes && Dom.addClass(this.dialog, params.classes);
        addEverClass(this.dialog.domNode, EVERCLASS.DIALOG.DIALOG);
        // Creates a FocusDiv that applies no styles and gets focused automatically when the dialog
        // is shown. Primarily used to enable users to close the dialog with Escape.
        this.focusDiv = makeFocusable(this.title, "");
        this.toDestroy.push(this.focusDiv);
        this.autofocus = !Is.defined(params.autofocus) || params.autofocus;
    }
    protected buildContent(params: Dialog.Params) {
        this.content = Dom.fragment(params.content);
    }
    show() {
        this.dialog.show();
        if (this.autofocus && this.focusDiv) {
            this.focusTimeout = window.setTimeout(
                () => this.focusDiv && this.focusDiv.focus(),
                100,
            );
        }
    }
    hide() {
        this.dialog.hide();
    }
    isOpen() {
        return this.dialog.open;
    }
    destroy() {
        // This hide() call was originally added to avoid a memory leak with dijit's Underlay, but
        // it turns out Underlay is overwritten and cleaned up by future dialogs.
        this.dialog.hide();
        this.dialog.destroy();
        Util.destroy(this.toDestroy);
        // It's possible that this dialog is destroyed before the initial focus timeout fires.
        // This currently happens somewhat routinely but it isn't clear why. The bugsnag breadcrumbs
        // usually has a series of REST requests failing with 403 before the timeout throws an undefined error.
        clearTimeout(this.focusTimeout);
        this.focusDiv = null;
    }
    setAutofocus(autofocus = true) {
        this.dialog.autofocus = autofocus;
    }
    resize() {
        this.dialog.resize();
        this.onResize && this.onResize(this);
    }
    // Readjusts the height of the dialog to center on screen.
    // If the dialog is now too tall and a scrollbar isn't appearing, call resize() after calling this.
    readjustHeight() {
        const windowHeight = window.innerHeight;
        const dialogHeight = this.dialog.domNode.clientHeight;
        let newTop = (windowHeight - dialogHeight) / 2; // center it vertically.
        newTop = Math.max(0, newTop);
        Dom.style(this.dialog.domNode, "top", newTop + "px");
    }
    setTitle(title: HTMLHeadingElement | string | undefined): void {
        let titleContent;
        if (typeof title === "string") {
            titleContent = Dom.h2(
                { class: "dialog-title", ...everClassProp(EVERCLASS.DIALOG.TITLE) },
                title,
            );
        } else if (title instanceof HTMLHeadingElement) {
            titleContent = title;
        } else {
            titleContent = Dom.span({ innerHTML: "&nbsp;" });
        }
        Dom.setContent(this.title, titleContent);
    }
    registerDestroyable(d: Util.Destroyable) {
        this.toDestroy.push(d);
    }
}

module Dialog {
    export interface Params {
        // If dialog title needs special styling, use an h2 element and add styles.
        title?: string | HTMLHeadingElement;
        content: Dom.Content;
        style?: Dom.StyleProps;
        onShow?: (dialog: Dialog) => void;
        onHide?: (dialog: Dialog) => void;
        onCancel?: (dialog: Dialog) => void;
        onResize?: (dialog: Dialog) => void;
        refocus?: boolean;
        autofocus?: boolean;
        noTitleBar?: boolean;
        closable?: boolean;
        destroyOnHide?: boolean;
        classes?: string | string[];
        draggable?: boolean;
    }

    export interface SingleButtonParams extends Params {
        buttonText: string;
        autoshow?: boolean;
        buttonClass?: string;
        buttonStyle?: any;
        buttonWidth?: string;
    }

    /**
     * A simple single-button modal dialog.
     *
     * The title and content parameters may be any Dom.js content item.
     *
     * The onHide parameter is optional, and it is only called if `Is.func(onHide)`. This allows
     * `UI.ok.bind(null, title, content)` to be used safely in most cases.
     */
    export class SingleButton extends Dialog {
        private button: Button;
        constructor(params: SingleButtonParams) {
            params.style = params.style || {};
            if (!params.style["width"] && !params.style["minWidth"] && !params.style["maxWidth"]) {
                params.style["minWidth"] = "450px";
                params.style["maxWidth"] = "600px";
            }
            // Make the dialog destroy-on-hide unless explicity set to false.
            params.destroyOnHide = params.destroyOnHide !== false;
            super(params);
            const handle = aspect.after(this.dialog, "destroy", () => {
                handle.remove();
            });
            // Autoshow unless explicitly set to false.
            if (params.autoshow !== false) {
                this.show();
            }
        }
        protected override buildContent(params: SingleButtonParams) {
            this.button = new Button({
                label: params.buttonText,
                class: params.buttonClass || "important safe",
                style: params.buttonStyle || {},
                width: params.buttonWidth || "three-halves",
                onClick: () => this.dialog.hide(),
                makeFocusable: true,
            });
            this.content = Dom.fragment(
                params.content,
                Dom.div(
                    { class: "dialog-lower-div" },
                    Dom.div({ class: "dialog-lower-left-content" }),
                    Dom.div({ class: "dialog-buttons no-padding" }, this.button.node),
                ),
            );
        }
        disableSubmit(state = true) {
            UI.toggleDisabled(this.button, state);
        }
    }

    /**
     * A simple "OK" modal dialog.
     */
    export function ok(
        title: string,
        content: Dom.Content,
        onHide?: () => void,
        widthOverride?: string,
    ) {
        const params: SingleButtonParams = {
            title,
            content,
            buttonText: "OK",
            buttonWidth: "one",
            closable: true,
        };
        if (onHide) {
            params.onHide = onHide;
        }
        if (widthOverride) {
            params.style = { width: widthOverride };
        }
        return new SingleButton(params);
    }

    // See comment on dialogSize
    export function dialogHeight(
        screenPercent: number,
        minPixels: number,
        maxPixels: number,
    ): number {
        return dialogSize(screenPercent, minPixels, maxPixels, true);
    }

    // See comment on dialogSize
    export function dialogWidth(
        screenPercent: number,
        minPixels: number,
        maxPixels: number,
    ): number {
        return dialogSize(screenPercent, minPixels, maxPixels, false);
    }

    /**
     * Returns windowPercent of the current window dimension if inside the range, or minPixels or
     * maxPixels if outside the range.
     * @param screenPercent 0-100
     * @param isHeight calculate for height. False will calculate for width
     */
    function dialogSize(
        screenPercent: number,
        minPixels: number,
        maxPixels: number,
        isHeight: boolean,
    ): number {
        const pixelsByPercent =
            ((isHeight ? window.screen.availHeight : window.screen.availWidth) * screenPercent)
            / 100;
        if (pixelsByPercent < minPixels) {
            return minPixels;
        } else if (pixelsByPercent > maxPixels) {
            return maxPixels;
        }
        return pixelsByPercent;
    }
}

export = Dialog;
