import * as Base from "Everlaw/Base";
import { Constants as C, Is, Str } from "core";
import * as DateUtil from "Everlaw/DateUtil";
import { HR_MN_SC_MIL } from "Everlaw/Duration";
import * as Dom from "Everlaw/Dom";
import * as Input from "Everlaw/Input";
import { getProjectMomentJSDateFormat } from "Everlaw/ProjectDateUtil";
import { NO_VALUE } from "Everlaw/SearchConstants";
import * as UI from "Everlaw/UI";
import * as ActionNode from "Everlaw/UI/ActionNode";
import * as BaseComboBox from "Everlaw/UI/BaseComboBox";
import * as ComboBox from "Everlaw/UI/ComboBox";
import * as Icon from "Everlaw/UI/Icon";
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";
import * as TextBox from "Everlaw/UI/TextBox";
import * as Tooltip from "Everlaw/UI/Tooltip";
import * as ValidatedSubmit from "Everlaw/UI/ValidatedSubmit";
import * as Widget from "Everlaw/UI/Widget";
import * as Util from "Everlaw/Util";
import {
    emailValidator,
    legacyPasswordValidator,
    numberValidator,
    textValidator,
    TextAreaHeight,
    TextArea,
} from "design-system";
import * as React from "react";
import * as moment from "moment-timezone";
import { forgivingParsingFormats } from "Everlaw/DateUtil";

export type numberOrDate = number | moment.Moment;

export interface ValidatedParams {
    name: string;
    validator?: (v: any) => boolean;
    errorOnEmpty?: boolean;
    missingMessage?: string;
    invalidMessage?: string;
    invalid?: (v: any) => Promise<string> | string;
    required?: boolean;
    inline?: boolean;
    displayErrorMessage?: (valid: boolean) => boolean;
    validateIfTextUnchanged?: boolean;
    errorMessageClass?: string;
    styleClass?: string;
}

export interface ValidationMessage {
    icon: Icon;
    contents: HTMLElement;
    node: HTMLElement;
}

export function createErrorMessage(classes?: string): ValidationMessage {
    const errorIcon = new Icon("alert-triangle-red-20", { alt: "Error" });
    const errorContents = Dom.div({ class: "validated-error-content" }, "");
    Dom.addClass(errorIcon, "validated-error-icon-static");
    const errorDiv = Dom.div({ class: "validated-error" }, errorIcon.getNode(), errorContents);
    // Setting the aria live attribute causes error messages to be read out by a screen reader
    // when they appear.
    Dom.setAriaLive(errorDiv);
    const wrapper = Dom.div({ class: classes || "" }, errorDiv);
    Dom.hide(wrapper);
    return {
        icon: errorIcon,
        contents: errorContents,
        node: wrapper,
    };
}

/*
 * Base class for all of the validated widgets. Note that this class doesn't know anything about the
 * input format which has to be implemented by its children.
 */
export abstract class Validated extends Widget implements ValidatedSubmit.ValidatedSubmitForm {
    override node: HTMLElement;
    errorDiv: HTMLElement;
    protected name: string;
    protected preferredInvalid: (v: any) => string | Promise<string>;
    protected userValidator?: (v: any) => boolean;
    protected valid: boolean;
    protected noErrorDisplay: boolean;
    protected errorOnEmpty: boolean;
    protected required: boolean;
    protected errorIcon: HTMLElement;
    errorContents: HTMLElement;
    protected invalid: (v: any) => string | Promise<string | null> | null;
    protected missing: () => string;
    protected displayErrorMessage?: (valid: boolean) => boolean;
    protected validateIfTextUnchanged: boolean;
    constructor(params: ValidatedParams) {
        super();
        this.name = params.name;
        this.errorOnEmpty = params.errorOnEmpty ?? false;
        // If params.required is undefined then this.required should be true
        this.required = Is.boolean(params.required) ? params.required : true;
        this.displayErrorMessage = params.displayErrorMessage;
        this.userValidator = params.validator;
        const missingMessage = params.missingMessage;
        this.missing = missingMessage
            ? () => missingMessage
            : () => Str.capitalize(this.name || "field") + " is required";
        const invalidMessage = params.invalidMessage;
        // PreferredInvalid will return whatever message has been specified by its creator (if any)
        // which should override most other error messages.
        if (params.invalid) {
            this.preferredInvalid = params.invalid;
        } else if (invalidMessage) {
            this.preferredInvalid = (v) => invalidMessage;
        }
        this.invalid = this.preferredInvalid
            ? this.preferredInvalid
            : (v) => "Invalid " + (this.name ? Str.capitalize(this.name) : "input");
        const validationError = createErrorMessage(params.errorMessageClass);
        this.errorIcon = validationError.icon.getNode();
        this.errorContents = validationError.contents;
        this.errorContents.textContent = this.missing();
        this.errorDiv = validationError.node;
        this.node = Dom.div(params.styleClass ? { class: params.styleClass } : null, this.errorDiv);
        this.validateIfTextUnchanged = !!params.validateIfTextUnchanged;
        params.inline && Dom.style(this.node, { display: "inline-block" });
    }
    validate() {
        this.setErrorState(this.isValid());
        return this.valid;
    }
    // This has to be implemented by sub classes that have an idea of what they're validating.
    getValid() {
        return this.valid;
    }
    protected setErrorState(valid: boolean) {
        Promise.resolve(this.invalid(this.getValue())).then((error) => {
            Dom.setContent(this.errorContents, error);
            // Basically don't toggle the error state on when the input is empty
            if (this.noErrorDisplay || (this.isEmpty() && !this.errorOnEmpty)) {
                if (!this.displayErrorMessage || this.displayErrorMessage(valid)) {
                    Dom.show(this.errorDiv, false);
                }
            } else {
                // The default behavior is to choose the invalid message
                if (!this.displayErrorMessage || this.displayErrorMessage(valid)) {
                    Dom.show(this.errorDiv, !valid);
                }
            }
        });
    }
    reset() {
        this.setErrorState(true);
    }
    require(isRequired: boolean) {
        this.required = isRequired;
        this.isValid();
    }
    isRequired() {
        return this.required;
    }
    override getNode() {
        return this.node;
    }
    setDisplayErrorMessage(toShow: (valid: boolean) => boolean) {
        this.displayErrorMessage = toShow;
    }
    setErrorDiv(form: ValidatedTextBox): void {
        this.errorDiv = form.errorDiv;
        this.errorContents = form.errorContents;
    }

    setUserValidator(func: typeof this.userValidator) {
        this.userValidator = func;
    }

    setPreferredInvalid(func: typeof this.preferredInvalid) {
        this.preferredInvalid = func;
    }

    // All the methods that subclasses should implement with regards to their input system.
    abstract isValid(): boolean;
    abstract addToSubmit(submitLogic: (e?: any) => void): any;
    abstract subscribeToChanges(subscription: () => void): any;
    abstract getValue(): any;
    abstract setValue(value: any): any;
    abstract setDisabled(disabled: boolean): any;
    abstract isDisabled(): boolean;
    abstract isEmpty(): boolean;
}

/**
 * Generic class for any Validated widget that has an input field
 */
export abstract class ValidatedInput extends Validated {
    // The input field backing the widget
    abstract input: UI.WidgetWithTextBox;

    // If this widget is currently handling the onSubmit handler of the input field, this is necessary
    // as onBlur is called both when submitting and when the widget actually blurs and this helps
    // differentiate the two. This could be handled by adding "submitting" parameter to the onBlur
    // callback but doing so causes other typing errors which require additional refactorings.
    protected submitting: boolean;

    // alertOnChange and connectToSubmit are optional methods that can be called inside onChange
    // or onSubmit. They're intended for use by the validatedSubmit widget to track the state of
    // its validated widget(s) and alter the onSubmit behavior accordingly.
    protected alertOnChange: (v?: string | ComboBox.AutocompleteData) => void;

    protected connectToSubmit: (v?: any) => void;

    protected disabled: boolean;

    abstract isSubmitting(): boolean;
}

export interface ValidatedTextBoxParams extends ValidatedParams, TextBox.Params {
    onChange?: (value: any) => void;
    onSubmit?: (value: string, withShiftKey?: boolean) => void;
    placeholderMessage?: string;
    noPlaceholder?: boolean;
    min?: numberOrDate;
    max?: numberOrDate;
    errorDivs?: HTMLElement[];
    errorClasses?: string;
    spellcheck?: boolean;
    disabledTooltip?: HTMLElement;
    checkErrorOnKeydown?: boolean;
    labelClass?: string;
    blurred?: boolean;
}

/*
 * This class has a TextBox for an input and handles all the error display behavior for any
 * validated widget that uses a TextBox (Text, number, email, etc...).
 */
export abstract class ValidatedTextBox extends ValidatedInput implements UI.WidgetWithTextBox {
    input: TextBox;
    onBlur: () => void;
    onFocus: () => void;
    onSubmit: (v?: string, withShiftKey?: boolean) => void;
    onChange: (v?: any) => void;
    protected validator: (value: any) => boolean;
    protected placeholder: string;
    protected min?: numberOrDate;
    protected max?: numberOrDate;
    protected currentValue: string;
    // errorDivs and errorClasses are used to toggle the classes whenever an error message is toggled
    // in order to apply styling to divs that aren't part of the validated widget.
    private errorDivs?: HTMLElement[];
    private errorClasses?: string;
    private blurred: boolean;
    private checkErrorOnKeydown: boolean;
    private disabledTooltip: Tooltip;
    constructor(params: ValidatedTextBoxParams) {
        super(params);
        this.blurred = params.blurred ?? false;
        this.disabled = false;
        this.checkErrorOnKeydown = !!params.checkErrorOnKeydown;
        this.errorDivs = params.errorDivs;
        this.errorClasses = params.errorClasses;
        this.min = params.min;
        this.max = params.max;
        this.onBlur = params.onBlur ?? (() => {});
        this.onFocus = params.onFocus ?? (() => {});
        // This is an unsafe cast added to avoid changing logic while fixing strict TS errors.
        this.onSubmit = (params.onSubmit ?? (() => {})) as typeof this.onSubmit;
        this.onChange = params.onChange ?? (() => {});

        // In most cases the input's placeholder will be "Enter name" but a different placeholder
        // can be supplied.
        if (!params.noPlaceholder) {
            this.placeholder = params.placeholderMessage
                ? params.placeholderMessage
                : "Enter " + params.name;
        }
        this.input = new TextBox({
            placeholder: this.placeholder,
            value: params.value,
            width: params.width ? params.width : "100%",
            type: params.type,
            inputName: params.inputName,
            inputClass: params.inputClass,
            labelClass: params.labelClass,
            textBoxAriaLabel: params.textBoxAriaLabel,
            textBoxLabelContent: params.textBoxLabelContent,
            textBoxLabelPosition: params.textBoxLabelPosition,
            clearMark: params.clearMark,
            preventBrowserAutocomplete: params.preventBrowserAutocomplete,
            spellcheck: params.spellcheck,
            isTextArea: params.isTextArea,
            focusOnTap: params.focusOnTap,
            clearMarkOnClick: params.clearMarkOnClick,
            tabIndex: params.tabIndex,
            onKeyDown: params.onKeyDown,
        });
        this.input.onChange = (value: any) => {
            // This prevents the user from starting any input with a space.
            if (value) {
                value = value.trim ? value.trim() : value;
                if (value.length === 0) {
                    this.input.setValue(value);
                }
            }
            this.onChange && this.onChange(value);
            this.alertOnChange && this.alertOnChange(value);
            this.validate();
        };
        this.input.onBlur = () => {
            this.blurred = true;
            this.validate();
            this.onBlur && this.onBlur();
        };
        this.input.onFocus = () => {
            this.blurred = false;
            this.onFocus && this.onFocus();
        };
        this.input.onSubmit = (val, withShiftKey) => {
            this.submitting = true;
            this.input.onBlur();
            if (this.valid) {
                this.onSubmit?.(this.getValue(), withShiftKey);
            }
            this.connectToSubmit && this.connectToSubmit(this.getValue());
            this.submitting = false;
        };
        Dom.place(this.input, this.node, "first");
        params.inline && Dom.style(this.input.getNode(), "display", "inline-block");
        this.setErrorState(true);
        this.registerDestroyable(this.input);

        if (params.disabledTooltip) {
            this.disabledTooltip = new Tooltip(this.input, params.disabledTooltip);
            this.disabledTooltip.disabled = true;
            this.registerDestroyable(this.disabledTooltip);
        }
    }
    protected override setErrorState(valid: boolean) {
        if (this.showNoError(valid)) {
            this.displayError(true);
        } else {
            this.displayError(valid);
        }
    }
    // Don't show an error when the input is empty except if this.errorOnEmpty is true and don't
    // show an error when the input is focused and it isn't displayed already.
    protected showNoError(valid: boolean) {
        return (
            this.noErrorDisplay
            || (!valid
                && ((!this.blurred && !this.checkErrorOnKeydown)
                    || (this.isEmpty() && !this.errorOnEmpty)))
        );
    }
    shouldShowError(): boolean {
        return !this.showNoError(this.isValid()) && !this.isValid();
    }
    protected displayError(valid: boolean) {
        if (!this.displayErrorMessage || this.displayErrorMessage(valid)) {
            Promise.resolve(this.isEmpty() ? this.missing() : this.invalid(this.getValue())).then(
                (error) => {
                    Dom.setContent(this.errorContents, error);
                    Dom.show(this.errorDiv, !valid);
                },
            );
        }
        this.errorDivs
            && this.errorClasses
            && Dom.toggleClass(this.errorDivs, this.errorClasses, !valid);
        this.input.toggleErrorOutline(!valid);
    }
    toggleErrorOutline(isInvalid: boolean): void {
        this.input.toggleErrorOutline(isInvalid);
    }
    // Return null if current text value is valid else return appropriate invalid message.
    getErrorMessageIfInvalid(): string | Promise<string | null> | null {
        if (this.isValid()) {
            return null;
        } else {
            return this.invalid(this.getValue());
        }
    }
    protected resetCurrentValue() {
        this.currentValue = "";
    }
    isSubmitting(): boolean {
        return this.submitting;
    }
    getValue(): any {
        const rawVal = this.input.getValue();
        return rawVal ? rawVal.trim() : "";
    }
    setValue(value: any, silent?: boolean) {
        // 0 is treated as false annoyingly enough.
        if (value || value === 0) {
            this.input.setValue(value);
            !silent && this.input.onChange(value);
        } else {
            this.reset(silent);
        }
        this.validate?.();
    }
    override focus() {
        this.input.focus();
    }
    override blur() {
        this.input.blur();
    }
    change(value?: any) {
        this.input.onChange(value || this.getValue());
    }
    submit(value?: any) {
        this.input.onSubmit(value);
    }
    isValid() {
        if (this.isEmpty()) {
            this.resetCurrentValue();
            if (!this.required) {
                this.valid = true;
            } else {
                this.valid = false;
            }
            return this.valid;
        }
        const value = this.getValue();
        if (value !== this.currentValue || this.validateIfTextUnchanged) {
            this.valid =
                this.validator(value) && (!this.userValidator || this.userValidator(value));
            this.currentValue = value;
        }
        return this.valid;
    }
    override reset(silent?: boolean) {
        this.input.setValue("");
        this.resetCurrentValue();
        !silent && this.input.onChange("");
    }
    setPlaceholder(placeholder: string) {
        this.placeholder = placeholder;
        this.input.setPlaceholder(this.placeholder);
    }
    setDisabled(disabled = true) {
        this.input.setDisabled(disabled);
        this.disabled = disabled;
        if (this.disabledTooltip) {
            this.disabledTooltip.disabled = !disabled;
        }
    }
    isDisabled() {
        return this.disabled;
    }
    isEmpty() {
        return this.input.getValue() === "";
    }
    getMin() {
        return this.min;
    }
    setMin(min: numberOrDate) {
        this.min = min;
    }
    resetMin() {
        this.min = undefined;
    }
    getMax() {
        return this.max;
    }
    setMax(max: numberOrDate) {
        this.max = max;
    }
    resetMax() {
        this.max = undefined;
    }
    addToSubmit(submitLogic: () => void) {
        this.connectToSubmit = submitLogic;
    }
    override require(required: boolean, silent?: boolean) {
        this.required = required;
        !silent && this.change();
    }
    subscribeToChanges(subscription: (e?: any) => void) {
        this.alertOnChange = subscription;
    }
    // This links two inputs such that the error will show until both inputs are either valid
    // or empty.
    linkErrorMessage(form: ValidatedTextBox) {
        this.setErrorDiv(form);
        this.setDisplayErrorMessage((valid) => this.displayLinkedErrorMessage(form, valid));
        form.setDisplayErrorMessage((valid) => this.displayLinkedErrorMessage(this, valid));
    }
    private displayLinkedErrorMessage(other: ValidatedTextBox, valid: boolean) {
        const otherIsValid = other.isValid();
        const otherHasError = !otherIsValid && (!other.isEmpty() || other.errorOnEmpty);
        return !valid || !otherHasError;
    }
    // If the input's value is invalid then this will show the error message without the user having
    // to blur the input first.
    showErrorMessage(silent?: boolean) {
        this.resetCurrentValue();
        !silent && this.input.onChange(this.getValue());
        this.input.onBlur();
        this.input.onFocus();
    }
    setErrorDivsClasses(divs: HTMLElement[], classes: string) {
        this.errorDivs = divs;
        this.errorClasses = classes;
    }
    setWidth(width: string) {
        Dom.style(this.node, "width", width);
        this.input.setWidth(width);
    }
    setTextBoxAriaLabel(ariaLabel: string) {
        this.input.setTextBoxAriaLabel(ariaLabel);
    }
    setTextBoxLabelContent(labelContent: Dom.Content) {
        this.input.setTextBoxLabelContent(labelContent);
    }
    setTextBoxLabelPosition(position: TextBox.LabelPosition) {
        this.input.setTextBoxLabelPosition(position);
    }
}

abstract class Parsed<T> extends ValidatedTextBox {
    // This widget caches parse results in case they are expensive to generate.
    private parsedString: string;
    private parsedValue: T;
    protected abstract parseAndValidate(value: string): T;
    getParsedValue() {
        const stringValue = this.getValue();
        if (stringValue !== this.parsedString) {
            this.parsedValue = this.parseAndValidate(stringValue);
            this.parsedString = stringValue;
        }
        return this.parsedValue;
    }
}

interface TimeValue {
    valid: boolean;
    string?: string;
    msecOfDay?: number;
}

export class Time extends Parsed<TimeValue> {
    // momentJS format strings to accept
    private parsingFormats = [
        "H:m",
        "Hmm",
        "H:mm",
        "H:mm:ss",
        "H",
        "h:m a",
        "h:ma",
        "hmma",
        "h:mm a",
        "h:mm:ss a",
        "h a",
        "ha",
    ];
    // momentJS format string to display. This should be one of the above.
    displayFormat = "h:mm a";
    constructor(params: DateWidgetParams) {
        super(
            Object.assign(
                {
                    placeholderMessage: "e.g. 3:00 pm",
                    type: "text",
                    invalidMessage: "Invalid time, use e.g. 3:00 pm",
                    onBlur: () => {
                        const value: TimeValue = this.getParsedValue();
                        if (value.valid) {
                            this.setValue(value.string);
                        }
                    },
                },
                params,
            ),
        );
        this.min = params.min;
        this.max = params.max;
        this.validator = (value: any) => {
            const returnedValue = this.parseAndValidate(value);
            let withinBounds = false;
            if (returnedValue.valid) {
                const parsedValue = moment(value, this.parsingFormats, true);
                withinBounds =
                    (!this.min || parsedValue.isAfter(this.min))
                    && (!this.max || parsedValue.isBefore(this.max));
            }
            return returnedValue.valid && withinBounds;
        };
    }
    protected parseAndValidate(value: string): TimeValue {
        const m = moment(value, this.parsingFormats, true);
        if (!m.isValid()) {
            return { valid: false };
        } else {
            return {
                valid: true,
                string: m.format(this.displayFormat),
                msecOfDay: m.hour() * C.HR + m.minute() * C.MIN,
            };
        }
    }
    setDisplayFormat(displayFormat: string) {
        this.displayFormat = displayFormat;
        this.updatePlaceholder();
    }
    setTime(time: number) {
        this.setValue(DateUtil.msecTimeToString(time, this.displayFormat));
    }
    updatePlaceholder() {
        // When setting the placeholder, we want to ignore seconds, so we remove it from the
        // display format before getting the placeholder.
        this.setPlaceholder(
            "e.g. " + DateUtil.TIME_DISPLAY_FORMAT_EXAMPLE[this.displayFormat.replace(":ss", "")],
        );
    }
}

export interface DateWidgetParams extends ValidatedTextBoxParams {
    min?: moment.Moment;
    max?: moment.Moment;
    invalidRangeMessage?: string;
}

export class DateWidget extends Parsed<moment.Moment> {
    // momentJS format strings to accept. New formats added here should be added to
    // dateDisplayFormatExamples in DateUtil.ts, although generally the caller should set the
    // parsing format to a single format
    parsingFormats = [
        "MM/DD/YYYY",
        "MM-DD-YYYY",
        "M/D/YYYY",
        "M-D-YYYY",
        "MM/DD/YY",
        "MM-DD-YY",
        "M/D/YY",
        "M-D-YY",
        "YYYY/MM/DD",
        "YYYY-MM-DD",
        "YYYY/M/D",
        "YYYY-M-D",
        "MMMM YYYY",
    ];
    // momentJS format string to display. This should be one of the above.
    displayFormat: string = getProjectMomentJSDateFormat();
    invalidRangeMessage: string;
    constructor(params: DateWidgetParams) {
        super(
            Object.assign(
                {
                    type: "text",
                    placeholderMessage: "e.g. mm/dd/yyyy",
                    invalidMessage: "Invalid date, use e.g. 1/23/2000",
                    onBlur: () => {
                        const value = this.getParsedValue();
                        if (value.isValid()) {
                            this.setDate(value);
                        }
                    },
                },
                params,
            ),
        );
        this.min = params.min;
        this.max = params.max;
        this.invalidRangeMessage = params.invalidRangeMessage ?? "Invalid date range";
        this.setParsingFormat(this.displayFormat);
        if (params.validator) {
            this.validator = params.validator;
        } else {
            this.validator = (value: any) => {
                const parsedValue = this.parseAndValidate(value);
                const withinBounds =
                    (!this.min || parsedValue.isSameOrAfter(this.min))
                    && (!this.max || parsedValue.isSameOrBefore(this.max));
                return parsedValue.isValid() && withinBounds;
            };
        }
        this.invalid = (v) => {
            const parsedValue = this.getParsedValue();
            if (
                parsedValue.isValid()
                && !(
                    (!this.min || parsedValue.isSameOrAfter(this.min))
                    && (!this.max || parsedValue.isSameOrBefore(this.max))
                )
            ) {
                return this.invalidRangeMessage;
            } else {
                return this.preferredInvalid
                    ? this.preferredInvalid(v)
                    : "Invalid date, use e.g. "
                          + DateUtil.DATE_DISPLAY_FORMAT_EXAMPLE[this.parsingFormats[0]];
            }
        };
    }
    protected parseAndValidate(value: string) {
        return moment(value, this.parsingFormats, true);
    }
    setDate(d: moment.Moment | Date, silent?: boolean) {
        const m = moment(d);
        this.setValue(m.format(this.displayFormat), silent);
    }
    override setMin(min: moment.Moment) {
        this.min = min;
    }
    override setMax(max: moment.Moment) {
        this.max = max;
    }
    setParsingFormat(parsingFormat: string) {
        this.parsingFormats = forgivingParsingFormats(parsingFormat);
        this.preferredInvalid = (v) =>
            "Invalid date, use e.g. " + DateUtil.DATE_DISPLAY_FORMAT_EXAMPLE[parsingFormat];
    }
}

export class MonthYearWidget extends DateWidget {
    // momentJS format strings to accept
    override parsingFormats = ["YYYY/MM", "YYYY-MM", "MM/YYYY", "MM-YYYY"];
    // momentJS format string to display. This should be one of the above.
    override displayFormat = "YYYY/MM";
    constructor(params: DateWidgetParams) {
        super(
            Object.assign(
                {
                    invalidMessage: "Invalid month, use e.g. 01/2000",
                },
                params,
            ),
        );
    }
}

export class YearOnlyWidget extends DateWidget {
    // momentJS format strings to accept
    override parsingFormats = ["YYYY", "YY"];
    // momentJS format string to display. This should be one of the above.
    override displayFormat = "YYYY";
    constructor(params: DateWidgetParams) {
        super(
            Object.assign(
                {
                    invalidMessage: "Invalid year, use e.g. 2000",
                },
                params,
            ),
        );
    }
}

export interface TextParams extends ValidatedTextBoxParams {
    excludeForbiddenChars?: boolean;
    shorterMaxLengthText?: boolean;
    min?: number;
    max?: number;
}

/* Text input that by default checks if the input is less than or equal to 255 characters. Note: if the
 * validator is set in the constructor (to see if an input is a duplicate of an existing value for
 * instance) then it's helpful to set the invalidMethod field is set or the extraInvalid field
 * if there could be multiple distinct invalid states to create a clear error message or it will
 * default to "Invalid " + this.name.
 */
export class Text extends ValidatedTextBox {
    protected excludeForbiddenChars?: boolean;
    protected usesForbiddenChar: boolean;
    protected incorrectValue: any;
    protected incorrectValueError?: string;
    constructor(params: TextParams) {
        super(
            Object.assign(
                {
                    type: "text",
                    min: 1,
                    max: 255,
                },
                params,
            ),
        );
        this.usesForbiddenChar = false;
        this.excludeForbiddenChars = params.excludeForbiddenChars;
        const internalValidator = () =>
            textValidator({
                excludeForbiddenChars: params.excludeForbiddenChars,
                minLength: this.min as number,
                maxLength: this.max as number,
                required: this.required,
                name: this.name,
                incorrectValue: this.incorrectValue,
                incorrectValueErrorMessage: this.incorrectValueError,
                shortenMaxLengthError: params.shorterMaxLengthText,
            });
        this.validator = (s) => !internalValidator()(s);
        const previousInvalid = this.invalid;
        this.invalid = (v) => internalValidator()(v) || previousInvalid(v);
    }
    // This method will set the current value as invalid and provide the given error message if any
    // This is used by both the password and the large number input to temporarily show an error
    // state.
    setIncorrectValue(error?: string, silent?: boolean): void {
        this.incorrectValue = this.getValue();
        this.incorrectValueError = error;
        this.showErrorMessage(silent);
    }
}

export interface NumberParams extends ValidatedTextBoxParams {
    integerValued?: boolean;
}

/*
 * Basic validated number widget that uses the HTML number input field which only accepts numbers.
 * This means any validators can assume the current value is either a number or empty. Note that
 * the min value is set to 1 but the max is left undefined by default.
 */
export class ValidatedNumber extends ValidatedTextBox {
    private integerValued?: boolean;
    protected override min: number;
    protected override max: number;
    constructor(params: NumberParams) {
        super(
            Object.assign(
                {
                    missingMessage: "Field is required",
                    min: 1,
                    type: "text",
                },
                params,
            ),
        );
        this.integerValued = params.integerValued;
        const internalValidator = () =>
            numberValidator({
                min: this.min,
                max: this.max,
                required: this.required,
                name: this.name,
                allowFloat: !this.integerValued,
            });
        this.validator = (s) => !internalValidator()(s);
        const previousInvalid = this.invalid;
        this.invalid = (v) => internalValidator()(v) || previousInvalid(v);
    }
    getNumberValue() {
        return +this.input.getValue();
    }
    override setMin(min: number) {
        this.min = min;
    }
    override setMax(max: number) {
        this.max = max;
    }
    setIntegerValued(integerValued: boolean) {
        this.integerValued = integerValued;
    }
}

export class PrintRange extends ValidatedTextBox {
    private static rangeExamples = "e.g. 1-5, 8, 11-13";
    private outOfBounds = false;
    pagesInRange: Set<number> | null = new Set();
    constructor(
        params: ValidatedTextBoxParams,
        private maxPageNum: number,
        private zeroIndexPageNumbers = true,
    ) {
        super(
            Object.assign(
                {
                    type: "text",
                    placeholderMessage: PrintRange.rangeExamples,
                },
                params,
            ),
        );
        this.validator = (value: string) => {
            const valid = this.validateAndSetPagesInRange(value);
            if (!valid) {
                this.pagesInRange = null;
            }
            return valid;
        };
        this.invalid = () => {
            return this.outOfBounds
                ? `Out of bounds page reference, limit is 1-${this.maxPageNum}`
                : `Invalid page range, use ${PrintRange.rangeExamples}`;
        };
    }
    private validNumber(s: string) {
        return !isNaN(Number(s)) && s !== "";
    }
    getPlaceholder() {
        return this.placeholder;
    }
    private validateAndSetPagesInRange(value: string) {
        this.pagesInRange = new Set();
        this.outOfBounds = false;
        for (const range of this.getPageRanges(value)) {
            if (range.length < 1 || range.length > 2) {
                return false;
            }
            if (range.length === 1) {
                if (this.validNumber(range[0])) {
                    const pageNumOneIndexed = Number(range[0]);
                    this.pagesInRange.add(
                        this.zeroIndexPageNumbers ? pageNumOneIndexed - 1 : pageNumOneIndexed,
                    );
                } else {
                    return false;
                }
            }
            if (range.length === 2) {
                if (
                    this.validNumber(range[0])
                    && this.validNumber(range[1])
                    && Number(range[0]) < Number(range[1])
                ) {
                    const startOneIndexed = Number(range[0]);
                    const endOneIndexed = Number(range[1]);
                    for (let i = startOneIndexed; i <= endOneIndexed; i++) {
                        this.pagesInRange.add(this.zeroIndexPageNumbers ? i - 1 : i);
                    }
                } else {
                    return false;
                }
            }
            for (const nStr of range) {
                if (Number(nStr) > this.maxPageNum) {
                    this.outOfBounds = true;
                    return false;
                }
            }
        }
        return true;
    }
    getPageNumbers() {
        if (this.pagesInRange === null || !this.isValid()) {
            return null;
        } else {
            const pageNumberList: number[] = [];
            this.pagesInRange.forEach((n: number) => pageNumberList.push(n));
            return pageNumberList;
        }
    }
    private getPageRanges(value: string) {
        const pageRanges: string[][] = [];
        const valWithoutWhitespace = value.replace(/\s/g, "");
        const items = valWithoutWhitespace.split(",");
        for (const item of items) {
            pageRanges.push(item.split("-"));
        }
        return pageRanges;
    }
}

/* This ensures that the input contains a number but extraInvalid and validator can be overridden
 * in the constructor if a large input that allows text is desired.
 */
export class LargeNumberInput extends Text {
    constructor(params: TextParams) {
        super(
            Object.assign(
                {
                    type: "tel",
                    placeholder: "123456",
                    invalid: (s: string) => {
                        return Is.number(s) ? "Invalid " + this.name : "Must contain only digits";
                    },
                    validator: (s: string) => {
                        return Is.number(s);
                    },
                },
                params,
            ),
        );
    }
}

export class Email extends ValidatedTextBox {
    private static genericValidator = emailValidator({ required: true });
    static isEmail(value: string): boolean {
        return !Email.genericValidator(value);
    }
    constructor(params: ValidatedTextBoxParams) {
        super(
            Object.assign(
                {
                    placeholder: "email address",
                    name: "email",
                    type: "text",
                },
                params,
            ),
        );
        const internalValidator = () =>
            emailValidator({
                minLength: this.min as number,
                maxLength: this.max as number,
                required: this.required,
                name: this.name,
            });
        this.validator = (v) => !internalValidator()(v);
        this.invalid = (v) =>
            this.preferredInvalid ? this.preferredInvalid(v) : internalValidator()(v);
    }
}

interface SfdcRecordType {
    name: string;
    prefix: string;
}

export class SfdcRecordTypes {
    static CONTRACT: SfdcRecordType = { name: "Contract", prefix: "800" };
    static ACCOUNT: SfdcRecordType = { name: "ACCOUNT", prefix: "001" };
}

export class SalesforceId extends Text {
    protected static SF_ID_PATTERN = new RegExp("[a-zA-Z0-9]{18}");
    protected static A_Z_PATTERN = new RegExp("[A-Z]");
    protected static LOOKUP = "ABCDEFGHIJKLMNOPQRSTUVWXYZ012345";

    constructor(record: SfdcRecordType, params: TextParams) {
        super(
            Object.assign(
                {
                    name: "18 character " + record.name + " ID",
                    validator: (value: string) => {
                        if (
                            record.prefix !== value.slice(0, 3)
                            || !SalesforceId.SF_ID_PATTERN.test(value)
                        ) {
                            return false;
                        }
                        return this.checksumCheck(value);
                    },
                    invalidMessage: "The provided ID is not a valid " + record.name + " ID",
                },
                params,
            ),
        );
    }

    // This function determines whether the 18 character ID we would get by treating the first 15
    // characters as a case-sensitive Salesforce ID and converting it (see
    // https://salesforce.stackexchange.com/questions/1653/what-are-salesforce-ids-composed-of)
    // to an 18 character ID matches the user provided 18 character ID. If not, we know the ID is invalid
    protected checksumCheck(id: string): boolean {
        return (
            this.toChecksum(id.slice(0, 5)) === id[15]
            && this.toChecksum(id.slice(5, 10)) === id[16]
            && this.toChecksum(id.slice(10, 15)) === id[17]
        );
    }

    protected toChecksum(idPart: string): string {
        let val = 1;
        let lookup = 0;
        for (let offset = 0; offset < 5; offset++) {
            lookup += SalesforceId.A_Z_PATTERN.test(idPart[offset]) ? val : 0;
            val *= 2;
        }
        return SalesforceId.LOOKUP[lookup];
    }
}

export class EmojiTextBox extends Text {
    constructor(params: TextParams) {
        super(
            Object.assign(
                {
                    name: "emoji warning",
                    validator: (value: string) => {
                        return Str.isWindows1252(value);
                    },
                    invalidMessage:
                        "Some characters will not be produced as they are not supported",
                },
                params,
            ),
        );
    }
    // modify the method slightly so that we show errors while still focused on the text box
    protected override showNoError(valid: boolean): boolean {
        return this.noErrorDisplay || (!valid && !this.errorOnEmpty && this.isEmpty());
    }
}

interface PasswordParams extends TextParams {
    requireUppercase?: boolean;
    requireLowercase?: boolean;
    requireNumeric?: boolean;
    requireSpecial?: boolean;
}

/* This password field defaults to having no restrictions which is good for validation on the login
 * page and other places. If we want the user to create a new password then the restrictions can
 * be set to ensure a minimum length, special characters, etc...
 */
export class Password extends Text {
    private requireUppercase: boolean;
    private requireLowercase: boolean;
    private requireNumeric: boolean;
    private requireSpecial: boolean;

    constructor(params: PasswordParams) {
        super(
            Object.assign(
                {
                    placeholder: "password",
                    name: "password",
                    type: "password",
                    requireUppercase: false,
                    requireLowercase: false,
                    requireNumeric: false,
                    requireSpecial: false,
                },
                params,
            ),
        );
        const textValidator = this.validator;
        const internalValidator = () =>
            legacyPasswordValidator({
                excludeForbiddenChars: params.excludeForbiddenChars,
                minLength: this.min as number,
                maxLength: this.max as number,
                required: this.required,
                name: this.name,
                incorrectValue: this.incorrectValue,
                incorrectValueErrorMessage: this.incorrectValueError,
                shortenMaxLengthError: params.shorterMaxLengthText,
                requireUppercase: this.requireUppercase,
                requireLowercase: this.requireLowercase,
                requireNumeric: this.requireNumeric,
                requireSpecial: this.requireSpecial,
            });
        this.validator = (v) => textValidator(v) || !internalValidator()(v);
        const textInvalid = this.invalid;
        this.invalid = (v) => textInvalid(v) || internalValidator()(v);
    }
}

interface UniqueParams extends TextParams {
    caseSensitive?: boolean;
    usedValues?: string[];
}

/**
 * Automatically checks that the input isn't equal to a given set of strings.
 */
export class Unique extends Text {
    caseSensitive?: boolean;
    usedValues: string[];
    constructor(params: UniqueParams) {
        super(
            Object.assign(
                {
                    invalidMessage: "Name already taken",
                },
                params,
            ),
        );
        const textValidator = this.validator;
        this.validator = (val: string) => {
            return (
                textValidator(val)
                && (!this.usedValues
                    || (this.caseSensitive
                        ? this.usedValues.indexOf(val) === -1
                        : this.usedValues.every((v) => v.toLowerCase() !== val.toLowerCase())))
            );
        };
        this.caseSensitive = params.caseSensitive;
        this.usedValues = params.usedValues || [];
    }
}

export interface ValidatedComboBoxParams<T extends Base.Object>
    extends ValidatedParams,
        BaseComboBox.Params<T> {
    minLength?: number;
    maxLength?: number;
    shorterLengthText?: boolean;
}

/**
 * Supports length-validation of inputs. Defaults to min 1, max 254 characters.
 */
export class ValidatedBaseComboBox<T extends Base.Object> extends ValidatedInput {
    input: BaseComboBox<T>;
    minLength: number;
    maxLength: number;

    constructor(params: ValidatedComboBoxParams<T>) {
        super(params);
        this.minLength = params.minLength || 1;
        this.maxLength = params.maxLength || 254;
        const shorterLengthText = params.shorterLengthText;
        const previousInvalid = this.invalid;
        this.invalid = (v) => {
            if (this.getValue().length < this.minLength) {
                return shorterLengthText
                    ? `Min ${Util.countOf(this.maxLength, "character")}`
                    : `Must be at least ${Util.countOf(this.minLength, "character")}`;
            }
            if (this.getValue().length > this.maxLength) {
                return shorterLengthText
                    ? `Max ${Util.countOf(this.maxLength, "character")}`
                    : `Must be fewer than ${Util.countOf(this.maxLength + 1, "character")}`;
            }
            return previousInvalid(v);
        };

        this.input = new BaseComboBox(params);
        this.input.onChange = (elem: T | string, wasAdded: boolean, allSelected: T) => {
            this.validate();
            params.onChange?.(elem, wasAdded, allSelected);
            this.alertOnChange?.(this.getValue());
        };
        this.input.onTextBoxBlur = () => {
            this.validate();
            params.onTextBoxBlur?.();
            this.alertOnChange?.(this.getValue());
        };

        Dom.place(this.input, this.node, "first");
        params.inline && Dom.style(this.input.getNode(), "display", "inline-block");
        this.setErrorState(true);
    }

    override setErrorState(valid: boolean): void {
        super.setErrorState(valid);
        Dom.toggleClass(this.input.tb.getInput(), "error-outline", !valid);
    }

    override subscribeToChanges(subscription: () => void): void {
        this.alertOnChange = subscription;
    }

    override addToSubmit(submitLogic: (e?: any) => void): void {
        // Currently, adding to submit will not connect the added submit logic to anything
        // since at the time of writing there is no clear place to add the submit logic
    }

    getValue(): string {
        return this.input.getTextValue();
    }

    isDisabled(): boolean {
        return this.disabled;
    }

    isEmpty(): boolean {
        return this.getValue() === "";
    }

    isSubmitting(): boolean {
        return false;
    }

    isValid(): boolean {
        const value = this.getValue();
        const customValidated = this.userValidator && this.userValidator(value);
        const lengthValidated = value.length >= this.minLength && value.length <= this.maxLength;
        this.valid = !!(customValidated && lengthValidated);
        return this.valid;
    }

    setDisabled(disabled: boolean): void {
        this.disabled = disabled;
        this.input.setDisabled(disabled);
    }

    setValue(value: T): void {
        this.input.setValue(value);
    }
}

export interface ValidatedComboBoxAutocompleteParams
    extends ValidatedParams,
        ComboBox.AutocompleteParams {}

export class ValidatedComboBoxAutocomplete extends ValidatedInput {
    input: ComboBox.Autocomplete;

    constructor(params: ValidatedComboBoxAutocompleteParams) {
        super(params);
        this.disabled = false;
        this.input = new ComboBox.Autocomplete(params);

        this.input.onChange = (
            elem: string | ComboBox.AutocompleteData,
            wasAdded: boolean,
            allSelected: ComboBox.AutocompleteData,
        ) => {
            params.onChange && params.onChange(elem, wasAdded, allSelected);
            this.alertOnChange && this.alertOnChange(elem);
        };
        this.input.onTextBoxBlur = () => {
            this.validate();
            params.onTextBoxBlur && params.onTextBoxBlur();
            this.alertOnChange && this.alertOnChange(this.getValue());
        };
        this.input.onFocus = () => {
            this.input.forceUpdate();
            this.input.openPopup();
            params.onFocus && params.onFocus();
        };
        this.input.onSelect = (elem, isNew?, self?) => {
            this.submitting = true;
            this.input.minimize();
            if (this.isValid()) {
                params.onSelect && params.onSelect(elem, isNew, self);
            }
            this.submitting = false;
        };
        this.input.onFilter = (val: string) => {
            if (val) {
                val = val.trim ? val.trim() : val;
                if (val.length === 0) {
                    this.input.setValue(val);
                }
            }
            this.validate();

            if (!this.input.hasPopup()) {
                this.input.openPopup();
            }
        };
        // This is a not-so-great solution based off of BaseSelect#minimizePopupOnAll, so that we
        // are able to capture a user re-focusing the input field after the popup is minimized
        // after a selection
        this.input.connect(document.body, Input.tap, (evt) => {
            if (evt.target === this.input.tb.getInput()) {
                this.input.onFocus();
            }
        });

        // Display the input field
        Dom.place(this.input, this.node, "first");
        params.inline && Dom.style(this.input.getNode(), "display", "inline-block");
        this.setErrorState(true);
    }

    getValue(): any {
        return this.input.getTextValue();
    }

    setValue(value: any, silent?: boolean): void {
        // 0 is treated as false annoyingly enough.
        if (value || value === 0) {
            this.input.setValue(value, silent);
        } else {
            this.reset();
        }
    }

    isEmpty(): boolean {
        // NO_VALUE matches `TextValue#NULL_VALUE` on the backend. Used to cover the case for a
        // no value entry in ComboBox
        return this.getValue() === "" || this.getValue() === NO_VALUE;
    }

    setDisabled(disabled: boolean): void {
        this.input.setDisabled(disabled);
        this.disabled = disabled;
    }

    isDisabled(): boolean {
        return this.disabled;
    }

    override focus(): void {
        this.input.focus();
    }
    override blur(): void {
        this.input.blur();
    }

    isValid(): boolean {
        if (this.isRequired() && this.isEmpty()) {
            this.valid = false;
            return this.valid;
        }
        this.valid = !!(this.userValidator && this.userValidator(this.getValue()));
        return this.valid;
    }

    isSubmitting(): boolean {
        return this.submitting;
    }

    subscribeToChanges(subscription: () => void): void {
        this.alertOnChange = subscription;
    }

    // Currently, adding to submit will not connect the added submit logic to anything
    // since at the time of writing there is no clear place to add the submit logic
    addToSubmit(submitLogic: () => void): void {}

    override require(required: boolean, silent?: boolean): void {
        this.required = required;
        !silent && this.blur();
    }

    override reset(): void {
        this.input.clear();
        this.input.reset();
        super.reset();
    }
}

interface DurationParams extends ValidatedTextBoxParams {
    // How many characters to show to the left of the decimal, including ":", so "0:00:00" is 7
    minBeforeDecimal?: number;
    // How many digits to show to the right of the decimal
    afterDecimal?: number;
}

/**
 * A field which allows input in number of seconds (71.2) or clock-formatted string ("1:01.2")
 */
export class ValidatedDuration extends ValidatedTextBox {
    // Using regex instead of Is.number because Is.number will pass things like hexidecimal
    static readonly intRegex = new RegExp("^\\d+$");
    static readonly decimalRegex = new RegExp("^\\d*\\.?\\d+$");
    protected override min = 0;
    protected override max: number;
    // How many characters to show to the left of the decimal, including ":", so "0:00:00" is 7
    protected minBeforeDecimal = 7;
    // How many digits to show to the right of the decimal
    protected afterDecimal = 3;
    // Current value saved as a number of ms
    protected backingValue = -1;
    static readonly IS_VALID = "Valid";
    static readonly NEGATIVE = "Input should not be negative";
    static readonly HMS_ONLY = "Input should include only hours, minutes, and seconds";
    static readonly S_FORMAT = "Seconds should be a whole or decimal number";
    static readonly M_FORMAT = "Minutes should be a whole number";
    static readonly H_FORMAT = "Hours should be a whole number";
    static readonly S_EXCESS = "Seconds should be less than 60";
    static readonly M_EXCESS = "Minutes should be less than 60";

    // Possible responses from parsing input
    static readonly errorCodes = [
        ValidatedDuration.IS_VALID,
        ValidatedDuration.NEGATIVE,
        ValidatedDuration.HMS_ONLY,
        ValidatedDuration.S_FORMAT,
        ValidatedDuration.M_FORMAT,
        ValidatedDuration.H_FORMAT,
        ValidatedDuration.S_EXCESS,
        ValidatedDuration.M_EXCESS,
    ];

    constructor(params: DurationParams) {
        super(
            Object.assign(
                {
                    type: "text",
                    preventBrowserAutocomplete: true,
                },
                params,
            ),
        );
        const minBeforeDecimal = params.minBeforeDecimal;
        if (Is.num(minBeforeDecimal) && minBeforeDecimal in [0, 1, 2]) {
            this.minBeforeDecimal = minBeforeDecimal;
        }
        const afterDecimal = params.afterDecimal;
        if (Is.num(afterDecimal) && afterDecimal in [0, 1, 2]) {
            this.afterDecimal = afterDecimal;
        }
        this.validator = (v) => {
            const parsed = Is.number(v, true) ? v : ValidatedDuration.parseInput(v);
            return (
                parsed === null
                || (parsed >= 0 && this.minMaxCheck(parsed) === ValidatedDuration.IS_VALID)
            );
        };

        this.invalid = (v) => {
            const parsed = Is.number(v, true) ? v : ValidatedDuration.parseInput(v);
            if (this.preferredInvalid) {
                const result = this.preferredInvalid(v);
                if (result) {
                    return result;
                }
            }
            if (parsed < 0) {
                // Parsing will return a negative integer corresponding to the index of the error
                // in the static array of error messages
                return ValidatedDuration.errorCodes[-parsed];
            } else {
                return this.minMaxCheck(parsed);
            }
        };

        this.valid = !this.errorOnEmpty;
    }

    override getValue(): number | null {
        // Value shown in field my be less precise than programmatically set value
        // If currently shown string value is equivalent to a more precise saved value, return saved
        const fieldString = super.getValue();
        if (
            this.backingValue > -1
            && this.afterDecimal < 3
            && this.numberToTimeAmount(this.backingValue) === fieldString
        ) {
            return this.backingValue;
        }
        this.backingValue = -1;
        return ValidatedDuration.parseInput(fieldString);
    }

    override isEmpty(): boolean {
        // Note super.getValue is string currently in the field
        return super.getValue() === "";
    }

    override setValue(value: number | string, silent = true): void {
        if (Is.number(value, true)) {
            value = Number(value);
            this.backingValue = value; // Save exact numerical value here because...
            this.input.setValue(this.numberToTimeAmount(value), silent); // This can lose precision
        } else if (Is.string(value)) {
            this.backingValue = -1;
            // This is an unsafe cast added to avoid changing logic while fixing strict TS errors.
            const parsed = ValidatedDuration.parseInput(value) as number;
            if (parsed < 0) {
                // Error parsing input
                this.reset(silent);
            } else {
                this.input.setValue(this.numberToTimeAmount(parsed), silent);
            }
        } else {
            this.reset(silent);
        }
    }

    override setMin(min: number): void {
        this.min = min;
    }

    override getMin(): number {
        return this.min;
    }

    override resetMin(): void {
        this.min = 0;
    }

    override setMax(max: number): void {
        this.max = max;
    }

    override getMax(): number {
        return this.max;
    }

    minMaxCheck(value: number): string {
        const errorMsgPrefix = this.name ? `${this.name} must` : "Must";
        // this.min is 0 by default and negative input value is already checked by this.parseInput(),
        // so we only show this.min in error message if this.min > 0;
        const validationDisplay = (limitMs: number) => {
            // We ignore the default afterDecimal property because the user needs the actual value.
            // e.g. if afterDecimal is 0, and min is 2100 (2.1 sec), and they enter 2.01,
            // and we report "must be between 2 and..." they will be confused because they entered a
            // value greater than 2.
            return HR_MN_SC_MIL.trimmed(limitMs, this.minBeforeDecimal, 0);
        };
        if (this.min && Is.number(this.max) && (value < this.min || value > this.max)) {
            return `${errorMsgPrefix} be between ${validationDisplay(this.min)}
                 and ${validationDisplay(this.max)}`;
        } else if (this.min && value < this.min) {
            return `${errorMsgPrefix} be after ${validationDisplay(this.min)}`;
        } else if (Is.number(this.max) && value > this.max) {
            return `${errorMsgPrefix} be before ${validationDisplay(this.max)}`;
        } else {
            return ValidatedDuration.IS_VALID;
        }
    }

    /**
     * @param forceRecheck: Revalidate even if the input text has not changed
     */
    override validate(forceRecheck = false): boolean {
        this.setErrorState(this.isValid(forceRecheck));
        return this.valid;
    }

    /**
     * The super method don't work if the min and max can change because it returns early if the
     * input text has not changed. Also because just getting value from the current string input
     * can lose precision.
     * @param forceRecheck: Revalidate even if the input text has not changed
     */
    override isValid(forceRecheck = false): boolean {
        // This is an unsafe cast added to avoid changing logic while fixing strict TS errors.
        const value = this.getValue() as number;
        if (!forceRecheck && this.backingValue === value) {
            return this.valid;
        }
        if (!this.required && this.isEmpty()) {
            this.reset(true);
            this.valid = true;
            return this.valid;
        }
        if (value < 0) {
            // Error parsing current input
            this.valid = false;
            return false;
        }
        this.valid = this.validator(value) && (!this.userValidator || this.userValidator(value));
        this.currentValue = super.getValue();

        return this.valid;
    }

    private static errorToCode(error: string): number {
        return -ValidatedDuration.errorCodes.indexOf(error);
    }

    /**
     * Attempt to parse string description of duration to a number of milliseconds.
     * Note this is a static method and so does not check vs instance max or min.
     * @param value -
     *  null if blank,
     *  positive parsed number if successful,
     *  or negative integer corresponding to negative index of error code
     */
    private static parseInput(value: string): number | null {
        if (value === "" || value == null) {
            return null;
        }

        if (ValidatedDuration.decimalRegex.test(value)) {
            // Entered number of seconds, return ms
            return Number(value) * C.SEC;
        }

        if (value.indexOf("-") !== -1) {
            return this.errorToCode(ValidatedDuration.NEGATIVE);
        }

        // Get seconds, minutes, hours
        const parts = value.split(":").reverse();

        if (parts.length > 3) {
            return this.errorToCode(ValidatedDuration.HMS_ONLY);
        }

        let multiplier = C.SEC;
        let millis = 0;

        if (parts.length === 1) {
            if (!ValidatedDuration.decimalRegex.test(parts[0])) {
                return this.errorToCode(ValidatedDuration.S_FORMAT);
            }
            return Math.round(Number(parts[0]) * multiplier);
        }

        for (let i = 0; i < parts.length; i++) {
            if (i === 0 && !ValidatedDuration.decimalRegex.test(parts[i])) {
                // Seconds are NaN
                return this.errorToCode(ValidatedDuration.S_FORMAT);
            }
            if (i > 0 && !ValidatedDuration.intRegex.test(parts[i])) {
                // Minutes or hours are NaN
                return i === 1
                    ? this.errorToCode(ValidatedDuration.M_FORMAT)
                    : this.errorToCode(ValidatedDuration.H_FORMAT);
            }
            const asNum = Number(parts[i]);
            if (asNum >= 60 && i < parts.length - 1) {
                // 70:01.2 is okay, but 1:80.2 is not
                return i === 0
                    ? this.errorToCode(ValidatedDuration.S_EXCESS)
                    : this.errorToCode(ValidatedDuration.M_EXCESS);
            }
            millis += Math.round(asNum * multiplier);
            multiplier *= 60;
        }
        return millis;
    }

    /** Convert number of milliseconds to clock-formatted string like 66750 -> "1:06.750" */
    numberToTimeAmount(num: number): string {
        const time = HR_MN_SC_MIL.trimmed(num, this.minBeforeDecimal);
        return time.slice(
            0,
            this.afterDecimal === 0
                ? this.afterDecimal - 4 // If afterDecimal is 0, we need to trim "decimal point" as well.
                : this.afterDecimal - 3,
        );
    }
}

interface ValidatedIncrementalDurationParams extends DurationParams {
    increment?: number; // In milliseconds
}

/**
 * ValidatedDuration input box with buttons that allow users to increment/decrement the input
 * by a certain value.
 */
export class ValidatedIncrementalDuration extends ValidatedDuration {
    private readonly incUp: ActionNode;
    private readonly incDown: ActionNode;
    increment = ValidatedIncrementalDuration.DEFAULT_INCREMENT;

    // Default increment for duration inputs in milliseconds
    static DEFAULT_INCREMENT = C.SEC;

    constructor(params: ValidatedIncrementalDurationParams) {
        super(params);
        this.increment = params.increment ?? ValidatedIncrementalDuration.DEFAULT_INCREMENT;
        const inputWithIncrements = Dom.div({ class: "incremental-input" }, [
            Dom.node(this.input.getInput()),
            Dom.div({ class: "increment-buttons" }, [
                Dom.node((this.incUp = this.inputIncButton(this.increment))),
                Dom.node((this.incDown = this.inputIncButton(-this.increment))),
            ]),
        ]);
        Dom.place(inputWithIncrements, this.input);
    }

    private inputIncButton(inc: number): ActionNode {
        const button = new ActionNode(
            Dom.div(
                { class: inc > 0 ? "inc-button-up" : "inc-button-down" },
                Dom.node(
                    new Icon(inc > 0 ? "chevron-up-12" : "chevron-down-12", {
                        alt: inc > 0 ? "step up" : "step down",
                    }),
                ),
            ),
            {
                onClick: () => {
                    // This is an unsafe cast added to avoid changing logic while fixing strict TS errors.
                    const currValue = this.getValue() as number;
                    if (currValue < 0) {
                        // Has unparseable input
                        return;
                    }
                    let toSet;
                    if (currValue == null) {
                        // Not set yet
                        toSet = this.getMin();
                    } else {
                        toSet =
                            inc > 0
                                ? this.getMax()
                                    ? Math.min(this.getMax(), currValue + inc)
                                    : currValue + inc
                                : Math.max(this.getMin(), currValue + inc);
                    }
                    this.setValue(toSet, false);
                },
                makeFocusable: true,
                focusStyling: "focus-no-space-style",
            },
        );
        this.registerDestroyable(button);
        return button;
    }

    override setDisabled(state = true): void {
        super.setDisabled(state);
        this.incUp.setDisabled(state);
        this.incDown.setDisabled(state);
    }
}

interface ValidatedDurationStartEndInputsParams {
    // Increment in milliseconds.
    increment?: number;
    minBeforeDecimal?: number;
    afterDecimal?: number;
    placeholderMessage?: string;
    width?: string;
    inputClass?: string;
    onStartChange?: (val: string, self: ValidatedIncrementalDuration) => void;
    onEndChange?: (val: string, self: ValidatedIncrementalDuration) => void;
}

/**
 * A pair of ValidatedIncrementalDuration widgets that allows users to input start and end timestamps.
 * The start and end timestamp inputs will also be validated.
 */
export class ValidatedDurationStartEndInputs {
    readonly node: HTMLElement;
    readonly start: ValidatedIncrementalDuration;
    readonly end: ValidatedIncrementalDuration;

    private readonly toDestroy: Util.Destroyable[] = [];

    constructor(params: ValidatedDurationStartEndInputsParams) {
        this.start = new ValidatedIncrementalDuration(
            Object.assign(params, {
                name: "Start time",
                require: false,
                checkErrorOnKeydown: true,
                onChange: (value: any) => {
                    this.setEndMin();

                    // We need to validate this.end before this.start, since if they both have error,
                    // we want to show the error of the most recent input, which is this.start.
                    this.end.validate(true);
                    this.start.validate(true);

                    params.onStartChange?.(value, this.start);
                },
            }),
        );

        this.end = new ValidatedIncrementalDuration(
            Object.assign(params, {
                name: "End time",
                require: false,
                min: params.increment && ValidatedIncrementalDuration.DEFAULT_INCREMENT,
                checkErrorOnKeydown: true,
                onChange: (value: any) => {
                    this.setStartMax();

                    // We need to validate this.start before this.end, since if they both have error,
                    // we want to show the error of the most recent input, which is this.end.
                    this.start.validate(true);
                    this.end.validate(true);

                    params.onEndChange?.(value, this.end);
                },
            }),
        );
        // Inputs share error state and message space
        this.end.linkErrorMessage(this.start);

        this.toDestroy.push(this.start, this.end);

        this.node = Dom.div([this.start, this.end]);
    }

    isValid(): boolean {
        return this.start.getValid() && this.end.getValid();
    }

    validate(forceRecheck = false): boolean {
        return this.start.validate(forceRecheck) && this.end.validate(forceRecheck);
    }

    /**
     * Set total maxEndValue in milliseconds.
     * If maxEndValue is undefined or null, this.end.max is infinity.
     * Otherwise, this.end.max is maxEndValue.
     */
    setMaxEndValue(maxEndValue: number): void {
        this.end.setMax(maxEndValue);
        this.setStartMax();
        this.setEndMin();
        this.validate(true);
    }

    setEndMin(): void {
        // This is an unsafe cast added to avoid changing logic while fixing strict TS errors.
        const value = this.start.getValue() as number;
        !Is.number(value)
        || value < 0
        // This is an unsafe cast added to avoid changing logic while fixing strict TS errors.
        || (this.hasMaxEndValue() && value > (this.maxStart() as number))
            ? this.end.setMin(this.minEnd()) // Current value is blank or invalid
            : this.end.setMin(this.minEnd(value));
    }

    setStartMax(): void {
        // This is an unsafe cast added to avoid changing logic while fixing strict TS errors.
        const value = this.end.getValue() as number;
        !Is.number(value) || value < 0 || (this.hasMaxEndValue() && value > this.end.getMax())
            ? // This is an unsafe cast added to avoid changing logic while fixing strict TS errors.
              this.start.setMax(this.maxStart() as number) // Current value is blank or invalid
            : // This is an unsafe cast added to avoid changing logic while fixing strict TS errors.
              this.start.setMax(this.maxStart(value) as number);
    }

    private maxStart(maxValue: number = this.end.getMax()): number | null {
        return Is.number(maxValue) ? Math.max(0, maxValue - this.start.increment) : null;
    }

    private minEnd(minValue: number = 0): number {
        return this.hasMaxEndValue()
            ? Math.min(this.end.getMax(), minValue + this.end.increment)
            : minValue + this.end.increment;
    }

    private hasMaxEndValue(): boolean {
        return Is.number(this.end.getMax());
    }

    setStartEnd(start: number, end: number, silent = false): void {
        this.start.setValue(start, silent);
        this.end.setValue(end, silent);
        // When silent is on, we want to skip onChange call and validation, but we still want to
        // set correct min for end input and max for start input.
        if (silent) {
            this.setEndMin();
            this.setStartMax();
        }
    }

    setDisabled(state = true): void {
        this.start.setDisabled(state);
        this.end.setDisabled(state);
    }

    reset(silent?: boolean): void {
        this.start.reset(silent);
        this.end.reset(silent);
        this.setMaxEndValue(this.end.getMax());
        this.start.validate();
        this.end.validate();
    }

    destroy(): void {
        Util.destroy(this.toDestroy);
    }
}

/**
 * A message which will update content and display based on one or more input fields.
 * Behaves and updates similar to a {@link ValidatedTextBox}, but does not apply error styling,
 * can be connected to the updates of more than one input, and is independent of
 * the input's own validation.
 * @param invalid: A function which will return the content of a warning message. The return value
 *  should be truthy iff the message should show.
 * @param node: The node to show or hide
 * @param content: We will set the content of this to the Content returned by invalid.
 */
export class InputConnectedMessage {
    checkOnChange = false;

    constructor(
        private invalid: (value: string) => Dom.Content,
        public node: HTMLElement,
        private content: HTMLElement,
    ) {
        Dom.hide(this);
    }

    /**
     * Add an input field which should be connected to this message.
     */
    addInput(input: {
        getValue: () => string;
        onChange: (value: string) => void;
        onBlur: () => void;
    }): InputConnectedMessage {
        const oldOnChange = input.onChange;
        input.onChange = (value) => {
            oldOnChange?.(value);
            if (!this.checkOnChange) {
                return;
            }
            this.checkInvalid(value);
        };
        const oldOnBlur = input.onBlur;
        input.onBlur = () => {
            oldOnBlur?.();
            this.checkInvalid(input.getValue()).then((result) => {
                this.checkOnChange = result;
            });
        };
        return this;
    }

    private checkInvalid(value: string): Promise<boolean> {
        return Promise.resolve(this.invalid(value)).then((message) => {
            if (message) {
                Dom.setContent(this.content, message);
                Dom.show(this);
            } else {
                Dom.hide(this);
            }
            return !!message;
        });
    }
}

interface ValidatedTextAreaParams {
    name: string;
    textAreaId: string;
    height: TextAreaHeight;
    validator: (value: string) => boolean;
    label: string;
    hideLabel?: boolean;
    horizontal?: boolean;
    onBlur?: (i: React.FocusEvent<HTMLTextAreaElement>) => void;
    onFocus?: (i: React.FocusEvent<HTMLTextAreaElement>) => void;
    onChange?: (value: string) => void;
    containerClass?: string;
    placeholder?: string;
    invalid?: (v: any) => string;
}

export class ValidatedTextArea extends Validated {
    input: HTMLElement;
    connectToSubmit: (value?: string) => void;
    alertOnChange: (value?: string) => void;
    private textAreaContent: string;
    private setTextAreaValue: React.Dispatch<React.SetStateAction<string>>;
    private disabled: boolean;
    private readonly userOnChange?: (value: string) => void;
    private readonly validator: (value: string) => boolean;

    constructor(params: ValidatedTextAreaParams) {
        super(params);
        this.userOnChange = params.onChange;
        this.validator = params.validator;
        this.input = Dom.div({ class: params.containerClass ? params.containerClass : "" });
        const textArea = wrapReactComponent(
            (params: ValidatedTextAreaParams) => this.createTextArea(params),
            params,
        );
        this.registerDestroyable(textArea);
        Dom.place(textArea, this.input);
        Dom.place(this.input, this.node, "first");
    }

    private createTextArea(params: ValidatedTextAreaParams): JSX.Element {
        [this.textAreaContent, this.setTextAreaValue] = React.useState("");
        return (
            <TextArea
                id={params.textAreaId}
                value={this.textAreaContent}
                horizontal={params.horizontal}
                onFocus={params.onFocus}
                onBlur={params.onBlur}
                onChange={(i) => this.setValue(i.target.value)}
                label={params.label}
                hideLabel={params.hideLabel}
                height={params.height}
                placeholder={params.placeholder}
            />
        );
    }

    addToSubmit(submitLogic: (e?: any) => void): void {
        this.connectToSubmit = submitLogic;
    }

    subscribeToChanges(subscription: (e?: any) => void): void {
        this.alertOnChange = subscription;
    }

    getValue(): string {
        return this.textAreaContent;
    }

    isDisabled(): boolean {
        return this.disabled;
    }

    isEmpty(): boolean {
        return this.getValue() === "";
    }

    isValid(): boolean {
        this.valid = this.validator(this.getValue());
        return this.valid;
    }

    setDisabled(disabled: boolean): void {
        this.disabled = disabled;
    }

    setValue(value: string): void {
        this.setTextAreaValue(value);
        // If we don't set this, the previous input is validated rather than the
        // current input.
        this.textAreaContent = value;
        this.userOnChange?.(value);
        this.alertOnChange?.(value);
        this.validate();
    }
}
