import ActionNode = require("Everlaw/UI/ActionNode");
import Base = require("Everlaw/Base");
import { Compare as Cmp } from "core";
import { EverColor } from "design-system";
import Dom = require("Everlaw/Dom");
import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import { Is, Str } from "core";
import Project = require("Everlaw/Project");
import Perm = require("Everlaw/PermissionStrings");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import UI_Validated = require("Everlaw/UI/Validated");
import { ValidatedSubmit } from "Everlaw/UI/ValidatedSubmit";
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");
import Win = require("Everlaw/Win");
import RedactionStamp = require("Everlaw/Review/RedactionStamp");
import * as RedactionStampUtil from "Everlaw/Review/RedactionStampUtil";

interface StampCreatorParams {
    submitOnBlur: boolean;
    namePlaceholder: string;
    abbrPlaceholder: string;
    nameWidth: string;
    abbrWidth: string;
    // Change aria label parameters to required after all RedactionStamps have been migrated
    nameAriaLabel?: string;
    abbrAriaLabel?: string;
    // Note: If this is accompanied by a ValidatedSubmit button then an onSubmit parameter
    // shouldn't be provided since it will call the ValidatedSubmit submit logic and the
    // onSubmit logic one after the other.
    onSubmit?: (name: string, abbr: string) => void;
}

/**
 * A widget with two textboxes for creating redaction stamps. Automatically fills in abbreviations
 * based on the name provided.
 */
export class RedactionStampCreator {
    node: HTMLElement;
    private nameTb: UI_Validated.Text;
    private abbrTb: UI_Validated.Text;
    private abbrChanged: boolean;
    private focusContainer: FocusContainerWidget;
    constructor(params: StampCreatorParams) {
        this.node = Dom.div({ style: { display: "inline-block" } });
        const submit = () => {
            params.onSubmit && params.onSubmit(this.nameTb.getValue(), this.abbrTb.getValue());
        };
        if (params.submitOnBlur) {
            this.focusContainer = new FocusContainerWidget(this.node);
            this.focusContainer.onBlur = () => submit();
        }
        const nameWrapper = Dom.create(
            "div",
            {
                style: {
                    display: "inline-block",
                    marginRight: "5px",
                    width: params.nameWidth,
                    "vertical-align": "top",
                },
            },
            this.node,
        );

        const lengthTest = () => {
            const nameVal = this.nameTb?.getValue();
            const abbrVal = this.abbrTb?.getValue();
            return nameVal?.length > abbrVal?.length;
        };
        // For stamp creation, instead of displaying an error message that the stamp name is
        // already taken when a user tries to create an exact match of an existing stamp, we
        // allow users to submit a duplicate stamp. This allows us to silently fail on duplicate
        // stamp creation, since the backend will just return the matching stamp in that case.
        this.nameTb = new UI_Validated.Text({
            name: params.namePlaceholder,
            placeholderMessage: params.namePlaceholder,
            onChange: () => {
                this.updateAbbr();
            },
            validator: lengthTest,
            invalidMessage: "Must be longer than abbreviation",
            validateIfTextUnchanged: true,
            errorMessageClass: "custom-stamp-creator__error-message",
            onSubmit: () => submit(),
        });
        if (params.nameAriaLabel) {
            this.setNameAriaLabel(params.nameAriaLabel);
        }
        Dom.place(this.nameTb, nameWrapper);

        const abbrWrapper = Dom.create(
            "div",
            {
                style: { display: "inline-block", width: params.abbrWidth },
            },
            this.node,
        );
        this.abbrTb = new UI_Validated.Text({
            name: params.abbrPlaceholder,
            placeholderMessage: params.abbrPlaceholder,
            onChange: () => {
                this.abbrChanged = !!(this.nameTb.getValue() || this.abbrTb.getValue());
            },
            validator: lengthTest,
            invalidMessage: "Must be shorter than stamp name",
            validateIfTextUnchanged: true,
            onSubmit: () => submit(),
            required: false,
        });
        this.abbrTb.linkErrorMessage(this.nameTb);
        if (params.abbrAriaLabel) {
            this.setAbbrAriaLabel(params.abbrAriaLabel);
        }
        Dom.place(this.abbrTb, abbrWrapper);
    }
    focus(): void {
        this.nameTb.focus();
    }
    getAbbr(): string {
        return this.abbrTb.getValue();
    }
    setAbbrAriaLabel(ariaLabel: string): void {
        this.abbrTb.input.setTextBoxAriaLabel(ariaLabel);
    }
    getName(): string {
        return this.nameTb.getValue();
    }
    setNameAriaLabel(ariaLabel: string): void {
        this.nameTb.input.setTextBoxAriaLabel(ariaLabel);
    }
    getForms(): UI_Validated.Text[] {
        return [this.nameTb, this.abbrTb];
    }
    setValues(name: string, abbr: string): void {
        name ? this.nameTb.setValue(name) : this.nameTb.reset();
        abbr ? this.abbrTb.setValue(abbr) : this.abbrTb.reset();
        this.abbrChanged = !!(name || abbr);
    }
    // Suggests an abbreviation based on the current name if the user hasn't modified the
    // abbreviation yet.
    private updateAbbr() {
        const name = this.nameTb.getValue();
        const abbr = this.abbrTb.getValue();
        if (!name && !abbr) {
            this.abbrChanged = false;
            return;
        }

        if (!this.abbrChanged) {
            if (!Str.isNullOrWhitespace(name)) {
                const newAbbr = name.split(/\s+/).reduce((s: string, cur: string) => {
                    if (cur.length > 0) {
                        s += cur.charAt(0).toUpperCase();
                    }
                    return s;
                }, "");
                if (newAbbr.length > 0) {
                    this.abbrTb.setValue(newAbbr, true);
                }
            }
        }
    }
    destroy(): void {
        this.nameTb.destroy();
        this.abbrTb.destroy();
        if (this.focusContainer) {
            Util.destroy(this.focusContainer);
        }
    }
}

class StampSelectItem extends Base.DataPrimitive<RedactionStamp> {
    constructor(
        public isCustom: boolean,
        public isRecent: boolean,
        public override color: string,
        data: RedactionStamp,
        id: string | number,
        name?: string,
    ) {
        super(data, id, name);
    }
}

interface StampSelectorParams {
    // The stampable object to which to add the stamp.
    stampable?: RedactionStamp.Stampable;
    // Title displayed above the selector widget.
    title?: string;
    // If the link to the project's configuration page should be displayed.
    showSettings?: boolean;
    // Callback when a user has specified a stamp to use.
    onStampSelect?: (stamp: RedactionStamp) => void;
    // Should the redaction stamper be disabled
    isDisabled?: boolean;
    // Don't show title, settings, or remove. No abbr or add button. Custom stamp
    // input takes place of selector. All happens in space of one field
    isMinimal?: boolean;
    // Allows selection of no stamp
    allowsNoStamp?: boolean;
}

/**
 * A widget for specifying the text to use for a redaction stamp.  This widget takes into
 * account project settings for custom stamp creation, as well as custom stamps.
 */
export class RedactionStampSelector {
    node: HTMLElement;
    private stampable: RedactionStamp.Stampable;
    private title = "Redaction stamp";
    private isDisabled: boolean;
    private remover: HTMLElement;
    private settings: HTMLElement;
    private showSettings = true;
    private allowsNoStamp = true;
    private onStampSelect: (stamp: RedactionStamp) => void;
    private selectNode: HTMLElement;
    stampSelector: SingleSelect<StampSelectItem>;
    private customWrapper: HTMLElement;
    private isMinimal: boolean;
    /**
     * Widget for creating custom stamps. Only appears if customOption is selected or if there
     * are no stamps in stampSelector and the project allows custom stamps.
     */
    private customCreator: RedactionStampCreator;
    private toDestroy: Util.Destroyable[] = [];
    private noneOption = new StampSelectItem(
        false,
        false,
        EverColor.PARCHMENT_30,
        RedactionStamp.noStamp,
        "(No stamp)",
    );
    private customOption = new StampSelectItem(
        false,
        false,
        EverColor.GREEN_40,
        null,
        "(New custom stamp)",
    );
    constructor(params: StampSelectorParams) {
        Object.assign(this, params);
        this.remover = Dom.div(
            {
                class: "redaction-stamper-link redaction-stamper-remover",
            },
            "Remove",
        );
        this.settings = Dom.create("span", {
            class: "redaction-stamper-link redaction-stamper-settings",
            textContent: "Project stamp settings",
        });
        this.isDisabled = Is.defined(params.isDisabled)
            ? params.isDisabled
            : !User.me.can(Perm.CREATE_REDACTIONS, Project.CURRENT, User.Override.ELEVATED);
        let settingDiv;
        if (this.isMinimal) {
            settingDiv = null;
        } else {
            settingDiv = Dom.div(
                { class: "redaction-stamper-spacer" },
                Dom.span({ class: "notes-panel-title" }, this.title),
                this.settings,
                this.isDisabled ? null : this.remover,
            );
        }
        this.selectNode = Dom.div();
        this.node = Dom.div({ class: "redaction-stamper" }, settingDiv, this.selectNode);
        this.toDestroy.push(
            new ActionNode(this.remover, {
                onClick: () => {
                    this.stampSelector.select(this.noneOption);
                    RedactionStampUtil.updateRecentlyUsed(undefined);
                    this._onStampSelect(RedactionStamp.noStamp);
                },
                makeFocusable: true,
                focusStyling: "focus-text-style",
            }),
        );
        !this.allowsNoStamp && Dom.hide(this.remover);
        this.customWrapper = Dom.create("div", {}, this.node);
        Dom.hide(this.customWrapper);
        this.customCreator = new RedactionStampCreator({
            submitOnBlur: false,
            namePlaceholder: this.isMinimal ? "New stamp" : "Enter custom stamp",
            abbrPlaceholder: this.isMinimal ? "NS" : "Abbr.",
            nameWidth: this.isMinimal ? "88px" : "180px",
            abbrWidth: this.isMinimal ? "40px" : "60px",
        });
        if (!this.isMinimal) {
            Dom.style(this.customCreator, { marginTop: "8px", verticalAlign: "bottom" });
        }
        Dom.place(this.customCreator, this.customWrapper);

        this.toDestroy.push(
            new ActionNode(this.settings, {
                onClick: () => {
                    Win.openExternal(
                        Project.CURRENT.url("settings.do#tab=general"),
                        "projectSettings",
                    );
                },
            }),
        );
        Dom.show(this.settings, User.me.isProjectAdmin() && this.showSettings);
        this.toDestroy.push(this.customCreator);

        // The custom stamp creator submits on blur, so clicking this button doesn't explicitly do
        // anything.
        if (!this.isMinimal) {
            const customBtn = new ValidatedSubmit({
                buttonParams: {
                    parent: this.customWrapper,
                    class: "safe important skinny",
                    width: "50px",
                    style: { margin: "0 0 0 4px" },
                    label: "Add",
                    onClick: () => this.addCustom(),
                },
                forms: this.customCreator.getForms(),
                class: "custom-stamp-creator__add-button",
            });
            this.toDestroy.push(customBtn);
        }
    }
    private addCustom(): Promise<RedactionStamp> {
        const name = this.customCreator.getName();
        const abbr = this.customCreator.getAbbr();
        return RedactionStampUtil.addStamp(name, abbr, false).then((stamp) => {
            this.customCreator.setValues("", "");
            let stampItem: StampSelectItem;
            if (!stamp.isProjectStamp()) {
                stampItem = this.wrapCustomPrim(stamp);
            } else {
                stampItem = this.wrapProjectPrim(stamp);
            }
            this.stampSelector.add(stampItem);
            this.stampSelector.select(stampItem);
            this._onStampSelect(stamp);
            return stamp;
        });
    }
    private _onStampSelect(stamp: RedactionStamp) {
        Dom.hide(this.remover, stamp === RedactionStamp.noStamp);
        this.onStampSelect && this.onStampSelect(stamp);
    }
    private wrapCustomPrim(stamp: RedactionStamp) {
        return new StampSelectItem(true, false, EverColor.GREEN_40, stamp, stamp.display());
    }
    private wrapProjectPrim(stamp: RedactionStamp) {
        return new StampSelectItem(false, false, EverColor.EVERBLUE_40, stamp, stamp.display());
    }
    private wrapRecentlyUsedPrim(stamp: RedactionStamp, isCustom: boolean) {
        return new StampSelectItem(
            isCustom,
            true,
            isCustom ? EverColor.GREEN_40 : EverColor.EVERBLUE_40,
            stamp,
            stamp.display(),
        );
    }

    setStampSelectToNone(): void {
        this.updateStampSelect({ redactionStamp: this.noneOption.data, getStampSize: () => 1 });
    }

    updateStampSelect(stampable?: RedactionStamp.Stampable): void {
        if (stampable) {
            this.stampable = stampable;
        }
        if (this.stampSelector) {
            Util.destroy(this.stampSelector);
            delete this.stampSelector;
        }

        // Update the widgets to state of the current highlight's redaction stamp.
        //
        // Terminology:
        //      Project Stamp: The stamp matches a stamp on the project settings.
        //      Custom Stamp: The stamp doesn't match a stamp on the project settings.
        //
        // Some important interactions to note:
        // - "No Stamp" is always an option in the stamp dropdown.
        // - "(Custom Stamp)" is only an option if the project allows custom stamps.
        // - The custom stamp creator is only used when a project allows custom stamps.
        // - Custom stamps that a user has used in the past only
        //      appear in dropdown when project settings allow custom stamps.
        // - If the current stamp doesn't a match stamp in the dropdown and custom stamps are turned
        //      off, then it needs to be added to the dropdown and selected.
        const redactionHasStamp = !!this.stampable?.redactionStamp?.id;
        const curStamp =
            (this.stampable && this.stampable.redactionStamp)
            || RedactionStampUtil.getDefaultStamp();
        const projectStamps = RedactionStampUtil.getProjectStamps();
        const allowsCustom = RedactionStampUtil.getAllowCustom();
        const customStamps = RedactionStampUtil.getCustomStamps();

        Dom.hide(this.customWrapper);
        // If there are no project stamps, no custom stamps, and we don't allow custom stamps,
        // every redaction has no stamp meaning we don't need to show the selector.
        if (!(projectStamps.length || customStamps.length || redactionHasStamp || allowsCustom)) {
            Dom.hide(this.remover);
            Dom.hide(this);
            return;
        }

        // Build the list of items to show in the dropdown.
        const stamps = [];
        if (this.allowsNoStamp) {
            stamps.push(this.noneOption);
        }
        if (allowsCustom) {
            stamps.push(this.customOption);
        }
        stamps.push(...projectStamps.map((s) => this.wrapProjectPrim(s)));
        const getKey = (name: string, abbreviation: string) =>
            name + " - " + (abbreviation ? abbreviation : "");
        // Include custom stamps but only if they don't match an existing stamp in the dropdown.
        const seenStamps: { [display: string]: boolean } = {};
        stamps.forEach((s) => {
            if (s.data) {
                seenStamps[getKey(s.data.getContent(), s.data.getAbbreviation())] = true;
            }
        });
        stamps.push(
            ...customStamps
                .filter((s) => !(getKey(s.getContent(), s.getAbbreviation()) in seenStamps))
                .map((ps) => this.wrapCustomPrim(ps)),
        );

        const recentStamps = Base.get(RedactionStamp, RedactionStampUtil.getRecentStamps());
        // If there are at least 10 stamps in the project (project + custom), we show upto 3 recently used stamps.
        if (customStamps.length + projectStamps.length >= 10) {
            stamps.push(
                ...recentStamps.map((s) => this.wrapRecentlyUsedPrim(s, !s.isProjectStamp())),
            );
        }

        // There should ALWAYS be a match here. If we have redactions with stamps that don't
        // exist in our database, that's an issue we need to fix.
        const toSelect = stamps.filter(
            (s) =>
                s.data
                && s.data.getContent() === curStamp.getContent()
                && s.data.getAbbreviation() === curStamp.getAbbreviation(),
        );

        const projectStampHeader = "Project stamps";
        const customStampHeader = "Custom stamps";
        const recentlyUsedHeader = "Recently used";
        const noHeader = "";
        const stampIconClass = (e: StampSelectItem) => {
            if (!this.stampable || e === this.customOption || e === this.noneOption) {
                return null;
            }
            const size = this.stampable.getStampSize(e.data);
            return size ? null : "eye-off";
        };

        this.stampSelector = new SingleSelect<StampSelectItem>({
            initialSelected: toSelect[0],
            classOrder: [noHeader, recentlyUsedHeader, projectStampHeader, customStampHeader],
            getHeader: (item: StampSelectItem) => {
                if (!item.data || item.data === RedactionStamp.noStamp) {
                    return noHeader;
                }
                if (item.isRecent) {
                    return recentlyUsedHeader;
                }
                return item.data.isProjectStamp() ? projectStampHeader : customStampHeader;
            },
            pluralize: false,
            elements: stamps,
            ellipsifyText: true,
            mirrorTooltips: true,
            mirrorTooltipPosition: ["after"],
            popupClass: "redaction-stamper__popup",
            comparator: (a: StampSelectItem, b: StampSelectItem) => {
                // none option on top,
                // new custom option next
                // recently used stamps (ordered by most to least recent)
                // project stamps
                // custom stamps
                if (a === b) {
                    return 0;
                }
                if (a === this.noneOption) {
                    return -1;
                }
                if (b === this.noneOption) {
                    return 1;
                }
                if (a === this.customOption) {
                    return -1;
                }
                if (b === this.customOption) {
                    return 1;
                }
                if (a.isRecent && b.isRecent) {
                    const recentStamps: number[] = RedactionStampUtil.getRecentStamps();
                    return recentStamps.indexOf(a.data.id) - recentStamps.indexOf(b.data.id);
                }
                if (a.isRecent) {
                    return -1;
                }
                if (b.isRecent) {
                    return 1;
                }
                if (a.isCustom !== b.isCustom) {
                    return a.isCustom ? 1 : -1;
                }
                return Cmp.str(a.data.display(), b.data.display());
            },
            popup: "after",
            // Set a custom zIndex so the dropdown appears on top of any dialogs. Dijit dialogs use
            // z-indexes starting at 1000 and incrementing as dialogs are nested.
            zIndex: "2000",
            icon: stampIconClass,
            getClassForRow: (e: StampSelectItem) => {
                return stampIconClass(e) ? "stamp-select-option--with-icon" : "";
            },
            onSelect: (e: StampSelectItem) => {
                const isCustom = e === this.customOption;
                isCustom && this.isMinimal && Dom.hide(this.stampSelector);
                Dom.show(this.customWrapper, isCustom);
                if (!isCustom) {
                    RedactionStampUtil.updateRecentlyUsed(e.data.id);
                    this._onStampSelect(e.data);
                } else {
                    this.customCreator.focus();
                }
                this.stampSelector.minimize();
            },
        });
        this.stampSelector.hideHeader(noHeader);
        Dom.place(this.stampSelector, this.selectNode);
        this.stampSelector.setDisabled(this.isDisabled);
        Dom.show(this.remover, this.stampSelector.initialSelected !== this.noneOption);
    }

    /**
     * Gets the stamp from the selector or creates it if it doesn't exist yet (such as if the
     * user has input a new custom stamp).
     * Gets default stamp if no stamp has been selected.
     */
    getOrCreateStamp(): Promise<RedactionStamp> {
        const defaultStamp = Promise.resolve(RedactionStampUtil.getDefaultStamp());
        if (!this.stampSelector) {
            return defaultStamp;
        }
        const val = this.stampSelector.getSelected();
        if (!val) {
            return defaultStamp;
        }
        if (val === this.customOption) {
            const curName = this.customCreator.getName();
            if (!Str.isNullOrWhitespace(curName)) {
                return this.addCustom();
            } else {
                return defaultStamp;
            }
        } else {
            return Promise.resolve(val.data);
        }
    }
    destroy(): void {
        Util.destroy(this.toDestroy);
        Util.destroy(this.stampSelector);
    }
}
