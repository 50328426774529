/*
This file should import nothing because it may be used outside the platform.
 */

/**
 * {@link Element} attribute that can be used to uniquely identify Elements for purposes outside
 * of styling (e.g. analytics, e2e testing, etc.). There should only ever be one Element that
 * has any given EverId.
 */
const EVERID_ATTRIBUTE = "data-everid";

/**
 * Object containing all EverIds. EverIds are used to uniquely identify Elements for purposes
 * outside of styling (e.g. analytics, e2e testing, etc.). There should only ever be one Element
 * that has any given EverId.
 *
 * All properties/constants defined in this object should be in alphabetical order.
 */
export const EVERID = {
    ANALYTICS_PAGE: {
        PROJECT_SIZES: {
            HISTORICAL_SIZE_CHART: "analytics-historical-size-chart",
        },
        SIDEBAR: "analytics-sidebar",
        SIDEBAR_PROJECT_ANALYTICS_REVIEW_ACTIVITY: "analtyics-sidebar-project-analytics",
    },
    ASSIGNMENT_PAGE: {
        ASSIGN_WIZARD_SUBMIT_BUTTON: "assign-wizard-submit-button",
        CANCEL_ASSIGNMENT_BUTTON: "cancel-assignment-button",
        REASSIGN_WIZARD_SUBMIT_BUTTON: "reassign-wizard-submit-button",
        SELF_ASSIGN_BUTTON: "self-assign-button",
    },
    CLUSTERING: {
        NO_CLUSTERING_WRAPPER: "no-clustering-wrapper",
        SEARCH_CLUSTER_DIALOG: {
            SUBMIT: "search-cluster-dialog-submit",
        },
    },
    DATA: {
        NATIVE_UPLOADS_TAB: "native-uploads-tab",
        PRODUCTION_CARD_SHARE: "production-card-share",
        PRODUCTION_CARD_THREEDOT: "production-card-threedot",
        PRODUCTION_CARD_THREEDOT_MODIFY: "production-card-threedot-modify",
        PROTOCOLS_TAB: "protocols-tab",
    },
    DEPOSITION_PAGE: {
        CREATE_BUTTON: "deposition-wizard-create-button",
        TRANSCRIPT_ANALYZER_GENERATE_BUTTON: "transcript-analyzer-generate-button",
        TRANSCRIPT_FACT_EXTRACTION_GENERATE_BUTTON: "transcript-fact-extraction-generate-button",
    },
    DRAFT_PAGE: {
        CREATE_BUTTON: "draft-create-button",
    },
    HEADER: {
        ANALYTICS_ICON: "analytics-icon",
        ANALYTICS_POPOVER_DOCUMENT_CLUSTERING: "analytics-popover-document-clustering",
        ANALYTICS_POPOVER_PREDICTIVE_CODING: "analytics-popover-predictive-coding",
        CHRON_MENU_ITEM: "chron-menu-item",
        DATA_TRANSFER_ICON: "data-transfer-icon",
        DATA_TRANSFER_PRODUCTIONS: "data-transfer-productions",
        DATA_TRANSFER_UPLOADS: "data-transfer-uploads",
        HELP_ICON: "help-icon",
        HELP_ICON_SETTINGS: "help-icon-settings",
        HELP_ICON_SETTINGS_DONE: "project-management-project-settings-done",
        PROJECT_HOME: "project-home",
        PROJECT_MANAGEMENT_ANALYTICS_TAB: "project-management-analytics-tab",
        PROJECT_MANAGEMENT_ICON: "project-management-icon",
        PROJECT_MANAGEMENT_PROJECT_SETTINGS: "project-management-project-settings",
        SEARCH_ICON: "search-icon",
        SEARCH_TERM_REPORT_ICON: "search-term-report-icon",
        STORYBUILDER_ICON: "storybuilder-icon",
    },
    HOME_PAGE: {
        ADD_ASSIGNMENT_BUTTON: "add-assignment-button",
        ASSIGNMENT_CARD: "assignment-card",
        ASSIGNMENT_GROUP_CARD: "assignment-group-card",
        ASSIGNMENT_GROUP_SELF_ASSIGN: "assignment-group-self-assign",
        CLAIM_ASSIGNMENT_BUTTON: "claim-assignment-button",
        FOLDERS: "homepage-folders-tab",
        NEW_FOLDER_BUTTON: "homepage-new-folder-button",
        SHARE_BINDER: "share-binder",
        SHARE_FOLDER: "share-folder",
        TABS: "homepage-tabs",
    },
    LEGAL_HOLD_PAGE: {
        DATA_PRESERVATION_EXPORT_REPORT_BUTTON: "data-preservation-export-report-button",
        DATA_PRESERVATION_REACTIVATE_ALL_BUTTON: "data-preservation-reactivate-all-button",
        DATA_PRESERVATION_REACTIVATE_BUTTON: "data-preservation-reactivate-button",
        DATA_PRESERVATION_RELEASE_ALL_BUTTON: "data-preservation-release-all-button",
        DATA_PRESERVATION_RELEASE_BUTTON: "data-preservation-release-button",
        DIRECTORY_ADD_TO_EXISTING: "legal-hold-directory-add-to-existing",
        DIRECTORY_ADD_TO_NEW: "legal-hold-directory-add-to-new",
        DIRECTORY_EXPORT_REPORT_BUTTON: "legal-hold-directory-export-report-button",
        DIRECTORY_ISSUE_EXISTING: "legal-hold-directory-issue-existing",
        DIRECTORY_ISSUE_NEW: "legal-hold-directory-issue-new",
        DIRECTORY_RELEASE_FROM_ALL_BUTTON: "legal-hold-directory-release-from-all-button",
        ESCALATE_BUTTON: "legal-hold-escalate-button",
        REACTIVATE_ALL_BUTTON: "legal-hold-reactivate-all-button",
        REACTIVATE_BUTTON: "legal-hold-reactivate-button",
        REISSUE_BUTTON: "legal-hold-reissue-button",
        RELEASE_ALL_BUTTON: "legal-hold-release-all-button",
        RELEASE_BUTTON: "legal-hold-release-button",
        RENOTIFY_BUTTON: "legal-hold-renotify-button",
    },
    PRODUCTION_PAGE: {
        PRODUCTION_TAB: "production-tab",
    },
    PROJECT_SETTINGS: {
        AUTO_CODE_RULES_TAB: "auto-code-rules-tab",
        CODING_RULES_TAB: "coding-rules-tab",
        GROUPS_TAB_NEW_BUTTON: "groups-tab-new-button",
        LOCATION_WHITELIST_TAB: "location-whitelist-tab",
        NEW_GROUP_SUBMIT_BUTTON: "new-group-submit-button",
        SIDEBAR_CODES: "sidebar-codes",
        SIDEBAR_GENERAL: "sidebar-general",
        SIDEBAR_HIGHLIGHTS: "sidebar-highlights",
        SIDEBAR_PRODUCTION_TOOLS: "sidebar-production-tools",
        SIDEBAR_USERS: "sidebar-users",
    },
    RECOMMENDATION: {
        /**
         * This is either the popover or toast displayed for an active Recommendation step.
         */
        OVERLAY: "recommendation-overlay",
        /**
         * Dismiss button within every Recommendation overlay.
         */
        OVERLAY_DISMISS: "recommendation-overlay-dismiss",
        /**
         * Done button that appears in the Recommendation overlay for the last step of any multistep
         * Recommendation.
         */
        OVERLAY_DONE: "recommendation-overlay-done",
    },
    RESULTS_PAGE: {
        BATCH_ADD_REMOVE_FROM_PROJECT: "results-batch-add-remove-from-project",
        BATCH_APPLY: "results-batch-appy",
        BATCH_ASSIGN: "results-batch-assign",
        BATCH_COPY_WORK_PRODUCT: "results-batch-copy-work-product",
        BATCH_DEBUG: "results-batch-debug",
        BATCH_DELETE_FROM_DB: "results-batch-delete-from-db",
        BATCH_MODIFY: "results-batch-modify",
        BATCH_OTHER_BATES: "results-batch-other-bates",
        BATCH_REDACT: "results-batch-redact",
        BATCH_REPROCESS: "results-batch-reprocess",
    },
    REVIEW_PAGE: {
        ADD_STORY_BUTTON: "add-story-button",
        ANNOTATIONS_BULLPEN: "annotations-bullpen",
        BULLPEN: "bullpen",
        BULLPEN_TAB_SOURCE: "bullpen-tab-source",
        CODING_PRESETS_CLOSE_ICON: "coding-presets-close-icon",
        CONFIG_PANEL_CLOSE_DIV: "config-panel-close-div",
        EDIT_CODING_PRESETS: "edit-coding-presets",
        GROUPING_ASSISTANT_BUTTON: "review-grouping-assistant-button",
        GROUPING_ATTACHMENTS_BUTTON: "review-grouping-attachments-button",
        GROUPING_CHAT_CONVERSATION_BUTTON: "review-grouping-chat-conversation-button",
        GROUPING_CLUSTERING_BUTTON: "review-grouping-clustering-button",
        GROUPING_DOC_REFERENCES_BUTTON: "review-grouping-doc-references-button",
        GROUPING_DUPLICATES_BUTTON: "review-grouping-duplicates-button",
        GROUPING_THREADING_BUTTON: "review-grouping-threading-button",
        GROUPING_UNITIZATION_BUTTON: "review-grouping-unitization-button",
        GROUPING_VERSION_BUTTON: "review-grouping-version-button",
        MAKE_DEFAULT_CHECK: "make-default-check",
        NEW_LAYOUT: "new-layout",
        REDACTION_CREATOR: "redaction-creator",
        REVIEW_LAYOUT_EDIT: "review-layout-edit",
        SAVE_LAYOUT_NODE: "save-layout-node",
        SAVE_LAYOUT_SUBMIT: "save-layout-submit",
        STORY_ICON: "story-icon",
        TAB_ANNOTATIONS: "tab-annotations",
        TAB_CODING: "tab-coding",
        TAB_LAYOUT_EDIT: "tab-layout-edit",
        TAB_LAYOUT_EDIT_BETA: "tab-layout-edit-beta",
        TITLE_DIV: "title-div",
        UNITIZATION_BUTTON: "unitization-button",
    },
    SEARCH_PAGE: {
        MORE_OPTIONS_DIALOG: {
            DUPES_RADIO: "search-page-more-options-dupes-radio",
        },
        PROJECT_DOCUMENT_TERM: "project-document-term",
        PROJECT_EQL_TERM: "project-eql-term",
        PROJECT_METADATA_TERM: "project-metadata-term",
        SEARCH_BUILDER: "search-builder",
        SEARCH_FRAME: "search-frame",
        SEARCH_NAME_CONTAINER: "search-name-container",
        SEARCH_PAGE_RESULTS_ADD_COLUMN: "search-page-results-add-columns",
        SEARCH_PAGE_RESULTS_BATCH_BUTTON: "search-page-results-batch-button",
        SEARCH_PAGE_RESULTS_BATCH_TRANSFER_WORK_PRODUCT:
            "search-page-results-batch-transfer-work-product",
        SEARCH_PAGE_RESULTS_EDIT_COLUMNS_SAVE: "search-page-results-edit-columns-save",
        SEARCH_PAGE_RESULTS_SAVE_VIEW: "search-page-results-save-view",
        SEARCH_PAGE_RESULTS_SETTINGS_BUTTON: "search-page-results-settings-button",
        SEARCH_PAGE_RESULTS_VIEW_BUTTON: "search-page-results-view-button",
        SEARCH_PAGE_RESULTS_VIEW_BUTTON_BETA: "search-page-results-view-button-beta",
        SEARCH_PAGE_RESULTS_VISUALIZATION_BUTTON: "search-page-results-visualization-button",
    },
    SEARCH_TERM_REPORT_PAGE: {
        ADD_SEARCH_TERM: "SearchTermReport-add-term",
        SEARCH_TERM_REPORT_LIST: "search-term-report-list",
    },
    STORYBUILDER_PAGE: {
        DEPOSITION_BUTTON: "deposition-button",
        PEOPLE_AND_OUTLINES: "people-and-outlines",
        TIMELINE_TAB: "timeline-tab",
        TO_DO_LIST_ICON: "to-do-list-icon",
    },
    STYLE_PAGE: {
        EXAMPLE_REACT_FORM: "style-page-example-react-form",
        RECOMMENDATION_TEST_1: "recommendation-test-1",
    },
    SUPERUSER_PAGE: {
        SIDEBAR: "superuser-sidebar",
    },
    UPLOAD_PAGE: {
        CLOUD_ASANA_BUTTON: "cloud-upload-asana-button",
        CLOUD_BOX_BUTTON: "cloud-upload-box-button",
        CLOUD_DIRECT_LINK_BUTTON: "cloud-upload-direct-link-button",
        CLOUD_DROPBOX_BUTTON: "cloud-upload-dropbox-button",
        CLOUD_GOOGLE_DRIVE_BUTTON: "cloud-upload-google-drive-button",
        CLOUD_GOOGLE_VAULT_BUTTON: "cloud-upload-google-vault-button",
        CLOUD_JIRA_BUTTON: "cloud-upload-jira-button",
        CLOUD_OFFICE_365_BUTTON: "cloud-upload-office-365-button",
        CLOUD_ONEDRIVE_BUTTON: "cloud-upload-onedrive-button",
        CLOUD_SHAREFILE_BUTTON: "cloud-upload-sharefile-button",
        CLOUD_SHAREPOINT_BUTTON: "cloud-upload-sharepoint-button",
        CLOUD_SLACK_BUTTON: "cloud-upload-slack-button",
        CLOUD_ZENDESK_BUTTON: "cloud-upload-zendesk-button",
        CLOUD_ZOOM_BUTTON: "cloud-upload-zoom-button",
        FILE_TRANSFER_SUBMIT: "file-transfer-submit-button",
        NATIVE_UPLOAD_BUTTON: "native-upload-button",
        PDF_NO_LOAD_FILE_UPLOAD_BUTTON: "pdf-no-load-file-upload-button",
        PROCESSED_MISSING_FILES_CONTINUE_BUTTON: "processed-upload-missing-files-continue-button",
        PROCESSED_VERIFY_FILES_BUTTON: "processed-upload-verify-files-button",
    },
} as const;

type EverAttr = string | { [k: string]: EverAttr };
type RecursiveStringValuesOf<T extends EverAttr> =
    T extends Exclude<EverAttr, string> ? RecursiveStringValuesOf<T[keyof T]> : T;

/**
 * A type that includes all valid EverId values as defined in {@link EVERID}.
 */
export type EverId = RecursiveStringValuesOf<typeof EVERID>;

/**
 * Assigns the given EverId to the given {@link Element}. Throws an error if the given
 * {@link element} already has another EverId assigned to it.
 * @param element Element to add {@link everId} to.
 * @param everId EverClass to add to {@link element}.
 */
export function setEverId<E extends Element>(element: E, everId: EverId): E {
    if (element.hasAttribute(EVERID_ATTRIBUTE) && !element.matches(everIdSelector(everId))) {
        const existingId = element.getAttribute(EVERID_ATTRIBUTE);
        throw new Error(`Tried to assign id ${everId} to element with existing id ${existingId}`);
    }
    element.setAttribute(EVERID_ATTRIBUTE, everId);
    return element;
}

/**
 * Removes the current EverId (if any) from the given element.
 * @param element Element to remove {@link everId} from.
 */
export function removeEverId<E extends Element>(element: E): E {
    element.removeAttribute(EVERID_ATTRIBUTE);
    return element;
}

/**
 * Constructs a selector string for a given {@link everId}. This can be passed to anything
 * expecting a CSS selector.
 * @param everId EverId to construct a selector string for.
 */
export function everIdSelector(everId: EverId): string {
    return `[${EVERID_ATTRIBUTE}="${everId}"]`;
}

/**
 * Generates an object containing an EverId that can be spread (`...`) as props into a TSX/React
 * element or attribute object. If no {@link everId} is provided, returns an empty object.
 * @param everId EverId to assign as a prop.
 */
export function everIdProp(everId?: EverId): Record<string, EverId> {
    return everId === undefined ? {} : { [EVERID_ATTRIBUTE]: everId };
}
