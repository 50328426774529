import clsx from "clsx";
import * as Icon from "components/Icon";
import * as CommonIcon from "components/Icon/CommonIcon";
import { Tooltip, TooltipPlacement, TooltipProps } from "components/Tooltip";
import { useDetectOverflow } from "hooks/useDetectOverflow";
import React, {
    AriaAttributes,
    cloneElement,
    FC,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    Ref,
    useId,
    useRef,
} from "react";
import * as CSS from "csstype";
import "./InputWrapper.scss";
import { useCombinedRef } from "hooks/useCombinedRef";

export function describedBy(
    inputId: string,
    hasErrorMessage: boolean,
    hasHelper: boolean,
    ariaErrorMessage?: string,
    ariaDescribedBy?: string,
): string | undefined {
    const ids = clsx(ariaErrorMessage, ariaDescribedBy, {
        [`${inputId}__error-alert`]: hasErrorMessage && !ariaErrorMessage,
        [`${inputId}__helper`]: hasHelper,
    });
    return ids || undefined;
}

export function accessibilityProps(
    inputId: string,
    hasError: boolean,
    hasErrorMessage: boolean,
    required: boolean,
    ariaErrorMessage?: string,
): AriaAttributes {
    const result: AriaAttributes = {
        "aria-invalid": hasError,
        "aria-required": required,
    };
    // Note: aria-errormessage is not sufficient for accessibility for errors. Most screen
    // reader/browser combinations will not correctly fulfill the spec for aria-errormessage
    // (to read the error message aloud when it is added);
    // see https://a11ysupport.io/tech/aria/aria-errormessage_attribute
    // This being the case, you must also add the id to the describedby (handled in
    // describedBy above) and mark the input as aria-invalid (handled in this function above).
    if (ariaErrorMessage) {
        result["aria-errormessage"] = ariaErrorMessage;
    } else if (hasErrorMessage) {
        result["aria-errormessage"] = `${inputId}__error-alert`;
    }
    result["aria-labelledby"] = `${inputId}__label`;
    return result;
}

export interface InputWrapperProps {
    /**
     * The input element to wrap. May also include other elements. Any accessibility props must be
     * provided to the input by the caller.
     */
    children: ReactNode;
    /**
     * Additional class names to apply to the root element.
     */
    className?: string;
    /**
     * The error message to display below the input element.
     */
    errorMessage?: ReactNode;
    /**
     * A helper message to display below the input element.
     */
    helper?: ReactNode;
    /**
     * Whether to hide the label and sub-label. If true, the element will be visually hidden but
     * remain visible to the accessibility DOM. Defaults to false.
     */
    hideLabel?: boolean;
    /**
     * If present, a small info icon will be placed to the right of the label, and this tooltip
     * will be made to point to it.
     */
    info?: ReactElement<TooltipProps>;
    /**
     * The id of the input provided.
     */
    inputId: string;
    /**
     * The label to place above the input element, or next to it if horizontal is true.
     */
    label: ReactNode;
    /**
     * Whether a value is required for the input.
     */
    required?: boolean;
    /**
     * Additional styles to apply to the root element.
     */
    style?: CSS.Properties;
    /**
     * A "sub-label" to place to the right of the provided label prop. It will be placed within
     * the HTML label element of the wrapper.
     */
    subLabel?: ReactNode;
    /**
     * Whether the label should be placed to the left of the input. Defaults to false.
     */
    horizontal?: boolean;
    /**
     * A ref to forward to the input's <label> element.
     */
    labelRef?: Ref<HTMLLabelElement>;
    /**
     * The function to call when the input's label is clicked. Generally, this should be
     * unnecessary, since any clicks on the label will trigger a click on the input. If that
     * is not desired, preventDefault must be called in this handler.
     */
    onLabelClick?: MouseEventHandler<HTMLLabelElement>;
}

export const InputWrapper: FC<InputWrapperProps> = ({
    children,
    inputId,
    label,
    subLabel,
    info,
    errorMessage,
    helper,
    hideLabel,
    required,
    horizontal,
    labelRef: externalLabelRef,
    onLabelClick,
    className,
    style,
}) => {
    const infoIconRef = useRef<SVGSVGElement>(null);
    const infoTooltipId = useId();
    const rootClass = clsx("bb-input-wrapper", className, {
        "bb-input-wrapper--horizontal": horizontal,
        "bb-input-wrapper--error": !!errorMessage,
        "bb-input-wrapper--required": required,
        "bb-input-wrapper--hidden-label": hideLabel,
    });

    const internalLabelRef = useRef<HTMLLabelElement>(null);
    const labelRef = useCombinedRef(internalLabelRef, externalLabelRef);
    const [isMainLabelOverflowing, setMainLabelObserver] = useDetectOverflow();
    const [isSubLabelOverflowing, setSubLabelObserver] = useDetectOverflow();
    const isOverflowing = isMainLabelOverflowing || isSubLabelOverflowing;
    const tooltipId = useId();

    return (
        <div className={rootClass} style={style}>
            <div className={"bb-input-wrapper__header-wrapper"}>
                <label
                    id={`${inputId}__label`}
                    htmlFor={inputId}
                    className={"bb-input-wrapper__label"}
                    onClick={onLabelClick}
                    ref={labelRef}
                    tabIndex={isOverflowing ? 0 : undefined}
                >
                    <span className={"bb-input-wrapper__main-label"} ref={setMainLabelObserver}>
                        {label}
                    </span>
                    {required && (
                        <span className={"bb-input-wrapper__required-indicator"} aria-hidden={true}>
                            &nbsp;*
                        </span>
                    )}
                    {subLabel && (
                        <>
                            &nbsp;
                            <span
                                className={"bb-input-wrapper__sub-label"}
                                ref={setSubLabelObserver}
                            >
                                {subLabel}
                            </span>
                        </>
                    )}
                </label>
                {isOverflowing && (
                    <Tooltip
                        id={tooltipId}
                        target={internalLabelRef}
                        placement={TooltipPlacement.BOTTOM}
                        aria-hidden={true}
                    >
                        {label} {subLabel}
                    </Tooltip>
                )}
                {info && (
                    <>
                        <Icon.InfoCircle
                            ref={infoIconRef}
                            id={`${inputId}__info-icon`}
                            className={"bb-input-wrapper__info-icon"}
                            size={20}
                            aria-label={"More info"}
                            aria-describedby={infoTooltipId}
                            tabIndex={0}
                        />
                        {cloneElement(info, {
                            id: infoTooltipId,
                            target: infoIconRef,
                            className: "bb-input-wrapper__info-tooltip",
                        })}
                    </>
                )}
            </div>
            <div className={"bb-input-wrapper__footer-wrapper"}>
                {children}
                {errorMessage && (
                    <CommonIcon.ErrorTriangle
                        size={20}
                        className={"bb-input-wrapper__error"}
                        aria-hidden={true}
                    >
                        <span id={`${inputId}__error-alert`}>{errorMessage}</span>
                    </CommonIcon.ErrorTriangle>
                )}
                {helper && (
                    <div id={`${inputId}__helper`} className={"bb-input-wrapper__helper"}>
                        {helper}
                    </div>
                )}
            </div>
        </div>
    );
};
