/**
 * React hook for subscribing to URL hash updates. Re-renders whenever the hash changes.
 *
 * @param manager optional manager. Defaults to the STATIC, untyped manager (which is perfectly fine
 *                as you can type the return value however you want).
 * @returns the current URL hash as an object
 */
import { ObjectToQueryMap, UrlHash } from "core";
import { useEffect, useState } from "react";

export function useUrlHash<T extends ObjectToQueryMap>(
    manager = UrlHash.getStaticManager() as UrlHash.Manager<T>,
): T {
    const [urlHash, setUrlHash] = useState(manager.get());
    useEffect(() => {
        return manager.subscribe((hash: T) => setUrlHash(hash));
    }, [manager]);
    return urlHash;
}
