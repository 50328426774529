import clsx from "clsx";
import { targetHidden } from "components/util/BasePopover/BasePopoverUtil";
import { everIdProp } from "EverAttribute/EverId";
import { useFocusTrap } from "hooks/useFocusTrap";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useLatest } from "hooks/useLatest";
import { useReturnFocus } from "hooks/useReturnFocus";
import React, {
    ReactNode,
    RefObject,
    useEffect,
    CSSProperties,
    forwardRef,
    useRef,
    useState,
} from "react";
import { VirtualElement } from "@floating-ui/core";
import {
    useFloating,
    autoUpdate,
    shift,
    Middleware,
    hide,
    flip,
    offset as offsetFn,
    OffsetOptions,
} from "@floating-ui/react-dom";
import "./BasePopover.scss";
import { Portal } from "util/Portal";
import { AriaDescriptionProps, EverIdProp, FFC } from "util/type";
import { wrap } from "core";

/**
 * Valid placements for a popover. Takes the general format of <side>-<alignment> or just <side> if
 * there is no alignment.
 */
export enum PopoverPlacement {
    TOP = "top",
    TOP_START = "top-start",
    TOP_END = "top-end",
    BOTTOM = "bottom",
    BOTTOM_START = "bottom-start",
    BOTTOM_END = "bottom-end",
    LEFT = "left",
    LEFT_START = "left-start",
    LEFT_END = "left-end",
    RIGHT = "right",
    RIGHT_START = "right-start",
    RIGHT_END = "right-end",
}

// Side part of PopoverPlacement.
export enum PopoverSide {
    TOP = "top",
    BOTTOM = "bottom",
    LEFT = "left",
    RIGHT = "right",
}

// Alignment part of PopoverPlacement.
export enum PopoverAlignment {
    START = "start",
    END = "end",
}

export enum PopoverNesting {
    POPOVER = "popover",
    DIALOG = "dialog",
}

export const POPOVER_DEFAULT_ARROW_WIDTH = 16;
export const POPOVER_DEFAULT_ARROW_HEIGHT = 12;
export const POPOVER_DEFAULT_ARROW_MARGIN = 12;

export interface BasePopoverProps extends AriaDescriptionProps, EverIdProp {
    id?: string;
    className?: string;
    /**
     * Aria role for the popover. If {@link modal} is true, then this value will default to
     * "dialog" if not specified.
     */
    role?: string;
    /**
     * Style for the popover component. It may be better to apply custom styles through CSS or
     * through contentStyle/arrowStyle than through this prop.
     */
    style?: CSSProperties;
    /**
     * Style for the content container of the popover.
     */
    contentStyle?: CSSProperties;
    /**
     * Style for the arrow of the popover.
     */
    arrowStyle?: CSSProperties;
    /**
     * Ref of the target element that the popover is to be displayed next to.
     */
    target: RefObject<Element | VirtualElement | null>;
    /**
     * Contents of the popover.
     */
    children: ReactNode;
    /**
     * Whether to show the popover. Note that visibility can also depend on other factors, like
     * whether the target is visible or whether the popover has been positioned.
     */
    show: boolean;
    /**
     * The function to call when the popover becomes visible. Use this instead of {@link show}
     * for a more reliable indicator of when the popover is actually visible, as visibility
     * can depend on other factors besides the {@link show} prop.
     */
    onVisible?: () => void;
    /**
     * The function to call when the popover is hidden. Use this instead of {@link show}
     * for a more reliable indicator of when the popover is actually hidden, as visibility
     * can depend on other factors besides the {@link show} prop.
     */
    onHide?: () => void;
    /**
     * Placement(s) of the popover relative to the target. If an array of placements is given, the
     * first placement is treated as the preferred placement. If there is not enough room for the
     * preferred placement (i.e. there is overflow), the popover will automatically try to place
     * itself into the next given placement in the array. It will keep trying the placements in the
     * order which they are given until it finds the first placement that causes no overflow.
     * Defaults to PopoverPlacement.BOTTOM.
     */
    placement?: PopoverPlacement | PopoverPlacement[];
    /**
     * If true, automatically shifts the popover along the main axis of its placement to stay in
     * view when the viewport moves. Does not alter the popover's position if no shift is needed.
     */
    shift?: boolean;
    /**
     * If true, automatically hides the popover whenever its target element is hidden.
     * Defaults to true.
     */
    hideWhenTargetHidden?: boolean;
    /**
     * Whether an arrow pointing to the target should be shown. If false the arrow will be hidden,
     * but arrowHeight will still define how far the popover is from the target element.
     */
    arrow?: boolean;
    /**
     * Whether the arrow should be centered when the popover has an alignment. Defaults to false.
     *
     * There are two situations where this prop is ignored:
     * 1. If the target is shorter than (2 * arrowMargin) + arrowWidth, then the arrow will
     *    be automatically centered. This is because un-centered arrows on short targets have
     *    a wonky appearance.
     * 2. If the popover is too short for it to be aligned with the edge of the target and also
     *    have the arrow centered on the target, then the arrow will not be centered and will
     *    be {@link arrowMargin}px away from the edge of the popover.
     */
    centerArrow?: boolean;
    /**
     * Width of the arrow in px. This is the length of the edge where the arrow touches the popover.
     * Defaults to POPOVER_DEFAULT_ARROW_WIDTH.
     */
    arrowWidth?: number;
    /**
     * Height of the arrow in px. This is the distance between the popover and the target element.
     * Defaults to POPOVER_DEFAULT_ARROW_HEIGHT.
     */
    arrowHeight?: number;
    /**
     * The margin of the arrow in px. This is the distance between the arrow and the side of
     * the popover when the popover has an alignment and the arrow is not centered.
     * Defaults to POPOVER_DEFAULT_ARROW_MARGIN.
     */
    arrowMargin?: number;
    /**
     * This prop allows you to add space between the popover and the target or adjust placement
     * along other axes.
     *
     * See https://floating-ui.com/docs/offset#options for details.
     */
    offset?: OffsetOptions;
    /**
     * Whether the popover should be aria-hidden. This should be true iff the contents of the
     * popover should not be accessible to assistive technologies.
     */
    "aria-hidden"?: boolean;
    /**
     * Whether the popover should behave as a modal dialog. If true, focus will be trapped within
     * the popover. Defaults to false.
     */
    modal?: boolean;
    /**
     * Avoid using this prop if possible. Setting to false will cause the popover to display
     * improperly in many cases (inside tables, inside other popovers, inside dialogs) and it will
     * be clipped, or can cause issues with grid and flex layouts.
     *
     * The only exception is if there is interactable content within the popover when
     * {@link modal} is false / non-desirable.
     *
     * If this prop is set to true and has focusable/interactable content, {@link modal} should
     * also be set so as not to break keyboard navigation.
     *
     * Defaults to true.
     */
    renderOutsideParent?: boolean;
    /**
     * If the popover is nested inside a dialog or another popover, and renderOutsideParent is true
     * (default true for most popovers), you will need to provide this prop.
     *
     * If POPOVER is provided, will set the resulting popover's z-index to double the standard
     * z-index for popovers.
     *
     * If DIALOG is provided, will set the resulting popover's z-index to the sum of the z-index
     * for dialogs and popovers.
     */
    // TODO this is a temporary measure which will be replaced by a stacking-context implementation
    // remove this, and associated enum + css classes when done.
    nesting?: PopoverNesting;
}

/**
 * Bare-bones popover component that only handles the positioning logic required for
 * popover-like functionality. A popover is defined as an element that is rendered next to another
 * element and floats above other page elements (e.g. tooltips, popover menus).
 *
 * This component includes no styles aside from those strictly necessary for popover positioning.
 */
export const BasePopover: FFC<HTMLDivElement, BasePopoverProps> = forwardRef<
    HTMLDivElement,
    BasePopoverProps
>(
    (
        {
            target,
            children,
            show,
            onVisible,
            onHide,
            className,
            everId,
            style,
            contentStyle,
            arrowStyle,
            placement: placementProp = PopoverPlacement.BOTTOM,
            shift: shouldShift = false,
            hideWhenTargetHidden: shouldHide = true,
            arrow = true,
            centerArrow = false,
            arrowWidth = POPOVER_DEFAULT_ARROW_WIDTH,
            arrowHeight = POPOVER_DEFAULT_ARROW_HEIGHT,
            arrowMargin = POPOVER_DEFAULT_ARROW_MARGIN,
            offset,
            modal = false,
            renderOutsideParent = true,
            nesting,
            ...props
        },
        refProp,
    ) => {
        const placements: PopoverPlacement[] = wrap(placementProp);
        if (placements.length === 0) {
            throw new Error("No placements provided to Popover.");
        }

        const middleware: Middleware[] = [];
        if (shouldShift) {
            middleware.push(shift());
        }
        if (offset) {
            middleware.push(offsetFn(offset));
        }
        // Should be after shift middleware.
        middleware.push(flip({ fallbackPlacements: placements.slice(1) }));
        if (shouldHide) {
            middleware.push(hide());
        }

        const {
            x,
            y,
            strategy,
            refs: { setReference, setFloating, floating },
            elements,
            update,
            placement: finalPlacement,
            isPositioned,
            middlewareData,
        } = useFloating({
            open: show,
            placement: placements[0],
            middleware,
        });

        // Update popover position when viewport changes, target changes, popover changes, etc.
        useEffect(() => {
            if (show && elements.reference && elements.floating) {
                return autoUpdate(elements.reference, elements.floating, update);
            }
        }, [show, elements.reference, elements.floating, update]);

        // Merge target with the reference Ref that useFloating uses.
        useEffect(() => setReference(target.current), [setReference, target]);

        const [finalPosition, finalAlignment] = finalPlacement.split("-");
        const positionClass = `bb-popover__position-${finalPosition}`;
        const alignmentClass = finalAlignment ? `bb-popover__alignment-${finalAlignment}` : "";

        const popoverStyle = {
            position: strategy,
            top: "0",
            left: "0",
            transform: `translate(${Math.round(x)}px,${Math.round(y)}px)`,
            "--arrowWidth": arrowWidth + "px",
            "--arrowHeight": arrowHeight + "px",
            "--arrowMargin": arrowMargin + "px",
            ...style,
        };

        const side: PopoverSide = getSide(finalPlacement as PopoverPlacement);
        const alignment: PopoverAlignment | null = getAlignment(finalPlacement as PopoverPlacement);
        if (arrow && alignment !== null) {
            const leftOrRight = side === PopoverSide.LEFT || side === PopoverSide.RIGHT;
            const targetWidthOrHeight: number =
                target.current?.getBoundingClientRect()[leftOrRight ? "height" : "width"] ?? 0;
            // Style adjustment to center the popover's arrow on the target.
            if (centerArrow || 2 * arrowMargin + arrowWidth >= targetWidthOrHeight) {
                /*
                Calculate the popover offset.

                When the target is too short for the popover to be aligned with the edge of the
                target and also respect arrowMargin, the popover is shifted past the edge of the
                target so that arrowMargin is respected.

                The offset is the difference between two distances: the distance from popover edge
                to the center of arrow and the distance from target edge to target center.
                 */
                const offset = Math.max(arrowMargin + arrowWidth / 2 - targetWidthOrHeight / 2, 0);
                popoverStyle[leftOrRight ? "top" : "left"] =
                    alignment === PopoverAlignment.START ? -offset : offset;
                /*
                Calculate the arrow margin.

                If the popover is long enough that it can both be aligned with the edge of the
                target and the arrow can be centered on the target, then the arrow margin is
                calculated to center the arrow. Otherwise, arrowMargin is used.
                 */
                const popoverWidthOrHeight: number =
                    floating.current?.getBoundingClientRect()[leftOrRight ? "height" : "width"]
                    ?? 0;
                if (
                    popoverWidthOrHeight
                    && targetWidthOrHeight / 2
                        <= popoverWidthOrHeight - arrowMargin - arrowWidth / 2
                ) {
                    popoverStyle["--arrowMargin"] =
                        (targetWidthOrHeight - arrowWidth) / 2 + offset + "px";
                }
            }
        }

        const focusTrapRef = useRef<HTMLDivElement>(null);
        useFocusTrap(focusTrapRef, show && modal);
        useReturnFocus(show && modal);
        const combinedRef = useCombinedRef(refProp, setFloating, focusTrapRef);
        const [, triggerRerender] = useState<HTMLDivElement | null>(null);

        const onVisibleRef = useLatest(onVisible);
        const onHideRef = useLatest(onHide);
        const isVisible = show && isPositioned && !targetHidden(target, middlewareData);
        useEffect(() => {
            if (isVisible) {
                focusTrapRef.current?.focus();
                onVisibleRef.current?.();
            } else {
                onHideRef.current?.();
            }
        }, [onVisibleRef, onHideRef, isVisible]);

        const popover = (
            <div
                ref={combinedRef}
                // Use hidden instead of returning null so that screen readers can more easily
                // find the Popover.
                hidden={!isVisible}
                style={popoverStyle}
                className={clsx("bb-popover", positionClass, alignmentClass, className, {
                    [`bb-popover--${nesting}-nested`]: nesting,
                })}
                aria-modal={modal}
                tabIndex={modal ? -1 : undefined}
                role={modal ? props.role || "dialog" : props.role}
                // If this popover is rendered outside parent and is within an ancestor element
                // that also closes upon outside click, then clicking on this popover would
                // register as an outside click on the ancestor element, which would cause the
                // ancestor element to close. To prevent this, we stop event propagation.
                onClick={renderOutsideParent ? (e) => e.stopPropagation() : undefined}
                // If this popover is rendered outside parent and is within an ancestor element
                // that also closes upon hitting Esc, then hitting Esc on this popover would also
                // cause the ancestor element to close. To prevent this, we stop event propagation.
                onKeyDown={
                    renderOutsideParent
                        ? (e) => e.key === "Escape" && e.stopPropagation()
                        : undefined
                }
                {...everIdProp(everId)}
                {...props}
            >
                <div className={"bb-popover__content"} style={contentStyle}>
                    {children}
                </div>
                {show && arrow && (
                    // The ref below triggers a re-render on the popover after it's made
                    // visible so that the arrow margin calculation can correctly account
                    // for the size of the visible popover. Currently, triggerRerender is
                    // specifically applied to the arrow because a re-render is only needed
                    // when the popover has an arrow.
                    <div
                        ref={triggerRerender}
                        className={clsx("bb-popover__arrow", positionClass, alignmentClass)}
                        style={arrowStyle}
                    />
                )}
            </div>
        );
        return renderOutsideParent ? <Portal>{popover}</Portal> : popover;
    },
);
BasePopover.displayName = "BasePopover";

/**
 * Given a placement, returns the side of that placement.
 * @param placement PopoverPlacement to extract the side from.
 */
export function getSide(placement: PopoverPlacement): PopoverSide {
    const [side] = placement.split("-");
    return side as PopoverSide;
}

/**
 * Given a placement, returns the alignment of that placement if one exists.
 * @param placement PopoverPlacement to extract alignment from.
 */
export function getAlignment(placement: PopoverPlacement): PopoverAlignment | null {
    const [_, alignment] = placement.split("-");
    return alignment ? (alignment as PopoverAlignment) : null;
}

/**
 * Given an array of Dojo Popup `orient` strings, converts them into an array of corresponding
 * {@link PopoverPlacement} values  for use with Bluebook Popover components including Popover,
 * PopoverMenu, and Tooltip. Unrecognized `orient` values will be ignored.
 * @param orient Array of Dojo popup orientations.
 */
export function orientToPopoverPlacement(orient: string[]): PopoverPlacement[] {
    // Mapping of dojo popup orientations to PopoverPlacements.
    // Refer to https://dojotoolkit.org/reference-guide/1.9/dijit/popup.html for details on `orient`
    // values.
    const ORIENT_TO_PLACEMENT: Record<string, PopoverPlacement | PopoverPlacement[]> = {
        before: [PopoverPlacement.LEFT_START, PopoverPlacement.LEFT_END],
        after: [PopoverPlacement.RIGHT_START, PopoverPlacement.RIGHT_END],
        "before-centered": PopoverPlacement.LEFT,
        "after-centered": PopoverPlacement.RIGHT,
        "above-centered": PopoverPlacement.TOP,
        above: PopoverPlacement.TOP_START,
        "above-alt": PopoverPlacement.TOP_END,
        "below-centered": PopoverPlacement.BOTTOM,
        below: PopoverPlacement.BOTTOM_START,
        "below-alt": PopoverPlacement.BOTTOM_END,
    };
    return orient.flatMap((o) => ORIENT_TO_PLACEMENT[o]).filter((p) => !!p);
}
