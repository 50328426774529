/**
 * Utilities related to formatting durations.
 */
import { Constants as C, Str } from "core";

class IntervalDescription {
    constructor(
        public short: string,
        public medium: string,
        public long: string,
        public duration: number,
    ) {}
}
const YEAR = new IntervalDescription("y", "yr", "year", C.YR);
const MONTH = new IntervalDescription("mo", "mon", "month", C.MO);
const WEEK = new IntervalDescription("w", "wk", "week", C.WK);
const DAY = new IntervalDescription("d", "day", "day", C.DAY);
const HOUR = new IntervalDescription("h", "hr", "hour", C.HR);
const MINUTE = new IntervalDescription("m", "min", "minute", C.MIN);
const SECOND = new IntervalDescription("s", "sec", "second", C.SEC);
const MILLISECOND = new IntervalDescription("ms", "msec", "millisecond", 1);
const ORDERED_INTERVALS: IntervalDescription[] = [
    YEAR,
    MONTH,
    WEEK,
    DAY,
    HOUR,
    MINUTE,
    SECOND,
    MILLISECOND,
];

class DurationFormatter {
    constructor(protected intervals: IntervalDescription[]) {}
    format(duration: number): string {
        return this.extractQuantityPerInterval(duration)
            .map(([interval, quantity]) => this.display(interval, quantity))
            .join(" ");
    }
    asList(duration: number) {
        const quantitiesPerInterval = this.extractQuantityPerInterval(duration);
        return quantitiesPerInterval.map(([interval, quantity]) => quantity);
    }
    protected display(interval: IntervalDescription, quantity: number) {
        return `${quantity}${interval.short}`;
    }
    protected extractQuantityPerInterval(duration: number) {
        const durationsPerInterval: [IntervalDescription, number][] = [];
        for (const interval of this.intervals) {
            const quantity = Math.floor(duration / interval.duration);
            if (this.accept(interval, quantity)) {
                durationsPerInterval.push([interval, quantity]);
            }
            if (this.terminate(quantity)) {
                break;
            }
            duration %= interval.duration;
        }
        return durationsPerInterval;
    }
    protected accept(interval: IntervalDescription, quantity: number) {
        return true;
    }
    protected terminate(quantity: number) {
        return false;
    }
}

export const DAY_HR_MIN = new DurationFormatter([DAY, HOUR, MINUTE]);
export const HR_MIN = new DurationFormatter([HOUR, MINUTE]);
export const HR_MIN_SEC = new DurationFormatter([HOUR, MINUTE, SECOND]);
export const MIN_SEC = new DurationFormatter([MINUTE, SECOND]);
export const MIN = new DurationFormatter([MINUTE]);

/**
 * This formatter will return the first unit of time, in default short format, that a duration
 * exceeds.
 * For example, if a duration is 90 seconds, it will format to "1m".
 */
class FirstPositiveDurationFormatter extends DurationFormatter {
    protected override accept(interval: IntervalDescription, quantity: number) {
        return quantity !== 0 || interval === this.intervals[this.intervals.length - 1];
    }
    protected override terminate(quantity: number) {
        return quantity !== 0;
    }
}

/**
 * Formats a duration by returning the first positive unit of time, in short format, between days,
 * hours and minutes. For example, given a duration of 6 hours and 33 minutes, it will format to
 * "6h". A duration of 3 weeks, 5 days and 12 hours would format to "26d".
 */
export const FIRST_DAY_HR_MIN = new FirstPositiveDurationFormatter([DAY, HOUR, MINUTE]);

/**
 * This formatter will return all the units of time, in default short format, that a duration
 * exceeds.
 * For example, if a duration is 90 seconds, it will format to "1m 30s".
 */
class TrimZeroFormatter extends DurationFormatter {
    protected override extractQuantityPerInterval(duration: number) {
        const durationsPerInterval = super.extractQuantityPerInterval(duration);
        const firstPositiveIndex = durationsPerInterval.findIndex(
            ([unused, quantity]) => quantity !== 0,
        );
        const trimIndex =
            firstPositiveIndex === -1 ? durationsPerInterval.length - 1 : firstPositiveIndex;
        return durationsPerInterval.filter((unused, index) => index >= trimIndex);
    }
}

/**
 * Formats a duration by returning all units of time that a duration exceeds, in short format between hours,
 * minutes, and seconds. For example, given a duration of 6 hours and 33 minutes, it will format to
 * "6h 33m 0s". A duration of 5 days and 12 hours would format to "132h 0m 0s".
 */
export const TRIM_ZERO_HR_MIN_SEC = new TrimZeroFormatter([HOUR, MINUTE, SECOND]);

class Ago extends FirstPositiveDurationFormatter {
    protected override display(interval: IntervalDescription, quantity: number) {
        const num = quantity === 1 ? (interval === HOUR ? "an" : "a") : quantity;
        return `${num} ${Str.pluralForm(interval.long, quantity)}`;
    }
}

const LESS_THAN = "less than a ";
/** Ago formatter that says "less than a minute ago" instead of using seconds/milliseconds. */
const AGO_BY_MINUTE = new (class extends Ago {
    protected override display(interval: IntervalDescription, quantity: number) {
        return interval === SECOND || interval === MILLISECOND
            ? LESS_THAN + MINUTE.long
            : super.display(interval, quantity);
    }
})(ORDERED_INTERVALS);

/**
 * Accepts a UTC timestamp in milliseconds and returns the resulting "X ... ago" string.
 */
export function ago(from: number, abbreviate = false) {
    const agoStr = `${from === 0 ? "a while" : AGO_BY_MINUTE.format(Math.abs(from - Date.now()))} ago`;
    if (abbreviate && agoStr.indexOf(LESS_THAN) > -1) {
        return "just now";
    }
    return agoStr;
}

/**
 * Accepts a UTC timestamp in milliseconds and returns the resulting "X ... ago" string.
 * This is a more abbreviated version of {@link ago} where "minutes" is shortened to "m".
 */
export function agoShort(from: number): string {
    if (from === 0) {
        return "a while ago";
    }
    const duration = Math.abs(from - Date.now());
    return `${duration < C.MIN ? "<1m" : MIN.format(duration)} ago`;
}

/**
 * Process and format between milliseconds and digital clock form (e.g. 2:01:07.000)
 */
export class ClockDurationFormatter extends DurationFormatter {
    constructor() {
        super([HOUR, MINUTE, SECOND, MILLISECOND]);
    }

    override format(duration: number): string {
        return this.extractQuantityPerInterval(duration)
            .map(([interval, quantity]) => this.display(interval, quantity))
            .join("");
    }

    /**
     * Print part of the duration. Typically this will be called for each
     * part (hours, minutes, seconds, and milliseconds) and concatenated
     * @param interval - The type of thing we're printing (e.g. MINUTE)
     * @param quantity - The amount of this interval (e.g. 59)
     * @return a clock-formatted display of that amount (e.g. ":59")
     */
    protected override display(interval: IntervalDescription, quantity: number): string {
        if (interval.duration === MILLISECOND.duration) {
            // Milliseconds - quantity will be 0-999. Display like ".027"
            return "." + ("00" + quantity).slice(-3);
        } else if (interval.duration === HOUR.duration) {
            // Hours - quantity will be a positive integer. Display like "02" or "217"
            return quantity < 10 ? ("0" + quantity).slice(-2) : "" + quantity;
        } else {
            // Minutes or seconds - quantity will be 0-59. Display like ":02"
            return ":" + ("0" + quantity).slice(-2);
        }
    }
    nextWholeSecondFormat(duration: number, minBeforeDecimal = 4): string {
        return this.trimmed(Math.ceil(duration / C.SEC) * C.SEC, minBeforeDecimal, 0);
    }

    prevWholeSecondFormat(duration: number, minBeforeDecimal = 4): string {
        return this.trimmed(Math.floor(duration / C.SEC) * C.SEC, minBeforeDecimal, 0);
    }

    /**
     * Trim leading and trailing "0", ":", and "."
     * e.g.
     * trimmed(0, 1, 2) -> 0.00
     * trimmed(0, 3, 0) -> 00 //  Note the leading : is automatically omitted
     * trimmed(0, 10, 4) -> 0000:00:00.0000
     * @param duration - in milliseconds
     * @param minBeforeDecimal - min number of characters to keep before the decimal, including ":"
     * @param minAfterDecimal - min number of characters to keep after the decimal.
     */
    trimmed(duration: number, minBeforeDecimal = 8, minAfterDecimal = 3): string {
        let ret = this.format(duration);
        let iDec = ret.indexOf(".");
        const padLeft = minBeforeDecimal - iDec;
        const padRight = minAfterDecimal - (ret.length - 1 - iDec);
        for (let i = 0; i < padLeft; i++) {
            ret = "0" + ret;
            iDec++;
        }
        for (let i = 0; i < padRight; i++) {
            ret = ret + "0";
        }
        let si = 0;
        while (ret[si] === ":" || (si < iDec - minBeforeDecimal && ret[si] === "0")) {
            si++;
        }
        let ei = ret.length - 1;
        while (ret[ei] === "." || (ei > iDec + minAfterDecimal && ret[ei] === "0")) {
            ei--;
        }
        return ret.substring(si, ei + 1);
    }
}

export const HR_MN_SC_MIL = new ClockDurationFormatter();
