import { Constants as C, Is, Str } from "core";
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import dojo_cookie = require("dojo/cookie");
import domForm = require("dojo/dom-form");
import { objectToQuery } from "core";
import { Alert } from "Everlaw/RepeatedQueries/Alert";
import dojo_on = require("dojo/on");
import { AUTH_PROMPT_MESSAGES } from "Everlaw/Rest/RestAuthMessages";
import { DependencyList, useCallback, useEffect, useState } from "react";

export interface Callback {
    (data: any, msg?: string, stats?: any): void;
}

export type Fetcher<T = any> = (url: string, content?: any) => Promise<T>;

export const get: Fetcher = (url: string, content?: any) => {
    return new Promise<any>(function retry(resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url + "?" + objectToQuery(content));
        prepareXHR(xhr, url, resolve, reject, () => {
            retry(resolve, reject);
        });
        xhr.send();
    });
};

export const post: Fetcher = (url: string, content?: any) => {
    return new Promise<any>(function retry(resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        prepareXHR(xhr, url, resolve, reject, () => {
            retry(resolve, reject);
        });
        xhr.send(objectToQuery(Object.assign({ _csrf: dojo_cookie("XSRF-TOKEN") }, content)));
    });
};

interface UsePromiseProps<R, E> {
    /**
     * Function that returns a Promise that will be managed by {@link usePromise}.
     */
    action: () => Promise<R>;
    /**
     * Array of values that will trigger an {@link action} call when any of its contents change.
     * Checks for changes using {@link Object.is} on each value. The length of this array should
     * never change between renders.
     */
    dependencies: DependencyList;
    /**
     * Called when the Promise returned by {@link action} is resolved.
     */
    onResolve?: (result: R) => void;
    /**
     * Called when the Promise returned by {@link action} is rejected.
     */
    onReject?: (error: E) => void;
    /**
     * If true, disables the hook by discarding any pending Promise and preventing a new Promise
     * from being created. Defaults to false.
     */
    disabled?: boolean;
}

interface UsePromiseResult<R, E> {
    /**
     * Whether the Promise is still pending. This will be `true` until the Promise is resolved or
     * rejected.
     */
    isPending: boolean;
    /**
     * The result returned by the Promise. This will be `null` until the Promise is resolved.
     */
    result: R | null;
    /**
     * The error returned by the Promise. This will be `null` unless the Promise is rejected.
     */
    error: E | null;
    /**
     * Manually refreshes the action and creates a new Promise. Calling this is equivalent to
     * changing a value in the dependencies of `useRequest`. This will cause a new request to be
     * made, even if the dependencies are unchanged. This function will remain the same between
     * renders, so it is safe to include in the dependencies of hooks like `useEffect`.
     */
    refresh: () => void;
}

/**
 * React hook for kicking off actions that return {@link Promise}s. If a new Promise is created
 * while a previous one is still pending, the older Promise will be discarded and its output
 * ignored.
 */
export function usePromise<R, E = unknown>({
    action,
    dependencies,
    onResolve,
    onReject,
    disabled = false,
}: UsePromiseProps<R, E>): UsePromiseResult<R, E> {
    const [isPending, setIsPending] = useState(true);
    const [result, setResult] = useState<R | null>(null);
    const [error, setError] = useState<E | null>(null);
    const [refreshCount, setRefreshCount] = useState<number>(0);
    const refresh = useCallback(() => setRefreshCount((count) => count + 1), []);

    useEffect(() => {
        if (disabled) {
            return;
        }

        let cancelled = false;

        action().then(
            (result: R) => {
                if (!cancelled) {
                    setIsPending(false);
                    setResult(result);
                    onResolve?.(result);
                }
            },
            (err: E) => {
                if (!cancelled) {
                    setIsPending(false);
                    setError(err);
                    onReject?.(err);
                }
            },
        );

        return () => {
            // Reset state when we make a new request
            cancelled = true;
            setIsPending(true);
            setResult(null);
            setError(null);
        };
        // Disable exhaustive-deps because we cannot statically analyze dependency array contents.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, refreshCount, ...dependencies]);

    return { isPending, result, error, refresh };
}

interface UseRequestProps<D, E> extends Omit<UsePromiseProps<D, E>, "action"> {
    /**
     * Typically either {@link get Rest.get} or {@link post Rest.post}.
     */
    fetcher: Fetcher<D>;
    /**
     * URL to send the request to.
     */
    url: string;
    /**
     * Content to send with the request.
     */
    content?: Record<string, unknown>;
}

/**
 * React hook for making a network request. A new request will be made when `url` or any value in
 * `dependencies` changes. The response of any previous request is ignored. `dependencies` should be
 * added to signal when `content` has logically changed.
 */
export function useRequest<D, E = unknown>({
    fetcher,
    url,
    content,
    ...otherProps
}: UseRequestProps<D, E>): UsePromiseResult<D, E> {
    return usePromise<D, E>({
        ...otherProps,
        action: () => fetcher(url, content),
    });
}

/**
 * React hook for making a GET request. A new request will be made when `url` or any value in
 * `dependencies` changes. The response of any previous request is ignored. `dependencies` should be
 * added to signal when `content` has logically changed. Uses {@link useRequest} with
 * {@link get Rest.get} under the hood.
 */
export function useGetRequest<D>(
    props: Omit<UseRequestProps<D, Failed>, "fetcher">,
): UsePromiseResult<D, Failed> {
    return useRequest({ ...props, fetcher: get });
}

/**
 * React hook for making a POST request. A new request will be made when `url` or any value in
 * `dependencies` changes. The response of any previous request is ignored. `dependencies` should be
 * added to signal when `content` has logically changed. Uses {@link useRequest} with
 * {@link get Rest.post} under the hood.
 */
export function usePostRequest<D>(
    props: Omit<UseRequestProps<D, Failed>, "fetcher">,
): UsePromiseResult<D, Failed> {
    return useRequest({ ...props, fetcher: post });
}

export function formSubmit(form: HTMLFormElement) {
    const method = form.method.toLowerCase() === "get" ? get : post;
    return method(form.action, domForm.toObject(form));
}

export interface Response {
    // see RestResponse.java
    status: number;
    success: boolean;
    message?: string;
    data?: any;
    stats?: any;
    isUserFriendlyMessage: boolean;
}

let logoutPollTimeout: number;

export function parseResponse(xhr: XMLHttpRequest) {
    if (!Str.startsWith(xhr.getResponseHeader("Content-Type") || "", "application/json")) {
        // We got served an error page instead of a RestResponse. It's not JSON, so don't try to
        // parse it; just give an error message based on the HTTP status line.
        throw xhr.status
            ? Error("Status " + xhr.status + " " + xhr.statusText)
            : Error("Could not connect to Everlaw servers.");
    }
    const res: Response = JSON.parse(xhr.responseText); // throws SyntaxError on bad/truncated JSON
    res.status = xhr.status;
    res.success = xhr.status === 200;
    return res;
}

// beforeunload events are cancellable, so we may see multiple
// events without actually unloading the page.
let unloadCount = 0;
dojo_on(window, "beforeunload", () => {
    unloadCount++;
});
const navMessage = "_navigating away";

function handleResponse(xhr: XMLHttpRequest, url: string, initialUnloadCount: number): Response {
    // In-flight requests are terminated when navigating away from a page.
    // We should not treat this as an error.
    const isUnloading = initialUnloadCount < unloadCount;
    if (xhr.status === 0 && isUnloading) {
        return {
            status: 0,
            success: false,
            message: navMessage,
            isUserFriendlyMessage: false,
        };
    }
    try {
        return parseResponse(xhr);
    } catch (error) {
        return {
            status: xhr.status,
            success: false,
            data: error,
            // parseResponse can throw Errors or SyntaxErrors
            message: "Unable to load " + url + ". " + (error as Error).message,
            isUserFriendlyMessage: false,
        };
    }
}

// Hook for authentication prompting: must eventually either retry(), fail(), or return false.
// This will be set in RestAuthInit.ts.
export declare let promptIfNeeded: (res: Response, retry: () => void, fail: () => void) => boolean;

function prepareXHR(
    xhr: XMLHttpRequest,
    url: string,
    func: Callback,
    err: (e: Failed) => void,
    retry: () => void,
) {
    const initialUnloadCount = unloadCount;
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.onreadystatechange = function () {
        if (xhr.readyState !== XMLHttpRequest.DONE) {
            return;
        }
        const res = handleResponse(xhr, url, initialUnloadCount);
        if (res.success) {
            if (res.stats) {
                displayStats(res.stats, url);
            }
            func(res.data, res.message, res.stats);
        } else if (!promptIfNeeded(res, retry, fail)) {
            fail();
        }
        function fail() {
            err(new Failed(res.data, res.message, res.status, res.isUserFriendlyMessage));
        }
    };
    setLogoutPollTimeout();
}

export function displayStats(stats: any, name: string) {
    console.log("Rest request: " + name);
    console.log("Execute time: " + stats.executeTime + "ms");
    console.log(stats.message);
    handleRepeatedQuery(stats.repeatedQueries, stats.alertLevel);
}

export interface RepeatedQueryStats {
    endpoint: string;
    queries: { table: string; count: number }[];
}

/**
 * See {@link Alert} for the various actions could take when encountering a repeated query.
 */
export function handleRepeatedQuery(
    repeatedQueries?: RepeatedQueryStats,
    alertLevel: Alert = Alert.NONE,
) {
    if (!repeatedQueries || repeatedQueries.queries.length === 0 || alertLevel === Alert.NONE) {
        return;
    }
    window.dispatchEvent(new CustomEvent("repeatedQuery", { detail: repeatedQueries }));
    if (alertLevel !== Alert.DIALOG) {
        return;
    }
    Dialog.ok(
        "New N+1 Queries Detected",
        Dom.div(
            Dom.p("See RepeatedQueryParser.java."),
            Dom.p("Endpoint: " + repeatedQueries.endpoint),
            Dom.ul(repeatedQueries.queries.map((q) => Dom.li(q.count + ": " + q.table))),
        ),
    );
}

/**
 * Make a POST request on page unload, using the widely supported navigator.sendBeacon method.
 * If that functionality is not present (IE...) then we attempt to make a synchronous XHR request.
 * Note that in either case there is no success/error callback.
 */
export function postOnPageUnload(url: string, content?: any) {
    const data = objectToQuery(Object.assign({ _csrf: dojo_cookie("XSRF-TOKEN") }, content));
    if (navigator && navigator.sendBeacon) {
        // Sending this as a blob with a specified content type is the only way that seems to work
        // as of Chrome 73.
        navigator.sendBeacon(
            url,
            new Blob([data], { type: "application/x-www-form-urlencoded;charset=UTF-8" }),
        );
    } else {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, false);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        try {
            xhr.send(data);
        } catch (e) {
            if (
                !(e instanceof DOMException)
                || (e.name !== "NetworkError"
                    && e.name !== "AbortError"
                    && e.name !== "TimeoutError")
            ) {
                throw e;
            }
            // Otherwise, the server is most likely down or the user hit stop. There's not much we
            // can really do here.
            // TODO: Consider logging failed POSTs to a fallback, highly-available server.
            console.log(`${e.name} during postOnPageLoad to ${url}`, e);
        }
    }
}

let logoutPollEnabled = true;

function setLogoutPollTimeout() {
    if (!JSP_PARAMS.User) {
        return;
    }
    if (logoutPollTimeout) {
        clearTimeout(logoutPollTimeout);
    }
    if (!logoutPollEnabled) {
        return;
    }
    // Add an extra second to the timeout to ensure that this poll is after the timeout duration.
    logoutPollTimeout = window.setTimeout(
        () => {
            post("/users/logoutPoll.rest").catch(() => {});
        },
        (JSP_PARAMS.SessionTimeout ? JSP_PARAMS.SessionTimeout * C.SEC : C.MIN * 15) + C.SEC,
    );
}

export function enableLogoutPoll(enabled: boolean = true) {
    if (!enabled && logoutPollTimeout) {
        clearTimeout(logoutPollTimeout);
    }
    logoutPollEnabled = enabled;
    if (enabled) {
        setLogoutPollTimeout();
    }
}

export function startKeepAliveInterval(): number {
    get("/session/keepAlive.rest");
    return window.setInterval(
        () => {
            get("/session/keepAlive.rest");
        },
        // SessionTimeout should always be defined if the user is logged in.
        ((JSP_PARAMS.SessionTimeout as number) * C.SEC) / 3,
    );
}

export function isAuthFailure(e: any): boolean {
    return e instanceof Failed && e.isAuthFailure();
}

/**
 * The global unhandledrejection listener attached by UnhandledPromiseRejectionHandler.ts
 * will show this error b/c it has an attribute named "show" that is a function
 */
export class Failed {
    constructor(
        readonly data: any,
        readonly message: string | undefined,
        readonly status: number,
        readonly isUserFriendlyMessage = false,
    ) {}
    isAuthFailure(): boolean {
        return AUTH_PROMPT_MESSAGES.some((authMessage) => authMessage === this.message);
    }
    show() {
        // Don't show a popup for auth-related errors or for navigation-related in-flight termination
        if (!this.isAuthFailure() && this.message !== navMessage) {
            // We don't use SbFree#inContext here because it would introduce circular dependencies!
            const supportName = JSP_PARAMS.Server.isSbFree ? "Storybuilder" : "Everlaw";
            const body = this.isUserFriendlyMessage
                ? this.message
                : Dom.div(
                      { class: "error-dialog" },
                      Dom.p({ class: "error-instruct" }, "An unexpected error occurred:"),
                      Dom.p({ class: "error-detail" }, this.message),
                      Dom.p(
                          { class: "error-instruct" },
                          `Please contact ${supportName} support for assistance.`,
                      ),
                  );
            Dialog.ok("Error", body, undefined, "456px");
        }
    }
}

/// Temporary hack: In 39.0, we added 99 additional long poll domains: {1-99}.longpoll.everlaw....
/// In order to avoid breaking long-polling for clients using a whitelist-based firewall, requests
/// for those new domains that fail with a 0 status retry using longpoll.everlaw... instead. If the
/// retry succeeds, we use the fallback domain eagerly thereafter.
///
/// Since we report successful fallbacks to Bugsnag, we attempt to minimize false positives by only
/// calling the fallback a success if it succeeds immediately after an original long-poll fails. If
/// that fallback attempt fails, we go back to the original URL. Furthermore, once an original URL
/// has succeeded, we avoid fallbacks thereafter.
enum LongPollFallbackState {
    ORIGINAL_WITH_FALLBACK, // initial state: try original, immediately try fallback on failure
    USE_ORIGINAL, // original longpoll URL succeeded, or it matches fallback URL
    TRYING_FALLBACK, // original URL failed, trying fallback URL once
    USE_FALLBACK, // original URL failed but fallback succeeded
}

let longPollFallbackState = LongPollFallbackState.ORIGINAL_WITH_FALLBACK;

const LONGPOLL_RETRY = /\/\/[1-9][0-9]?\.longpoll\./;
const LONGPOLL_FALLBACK = "//longpoll.";

/**
 * Starts a long-polling Rest request.
 *
 * @param.url       the URL to connect to, typically ending in ".rest"
 * @param.content   the data to send, which the backend will receive as RequestParam parameters
 * @param.success   a function(data, msg) that is called upon receiving a success response;
 *                  typically the function should start the request again
 */
export function longPoll(params: {
    url: string;
    content: any;
    onResponse(r: Response): void;
    onError?(): void;
}) {
    // dojo/request works poorly for long-polling in two ways:
    // 1) It attaches a progress eventlistener to the XHR object, which makes Firefox show the
    //    page as still being loaded if the request is started before everything else has loaded
    // 2) It logs errors to the console, but a long-polling request will always fail when
    //    navigating away from the page; this is normal and does not warrant console spam
    const xhr = new XMLHttpRequest();
    function doRequest() {
        // Fallback logic; see explanation above.
        const url =
            longPollFallbackState === LongPollFallbackState.TRYING_FALLBACK
            || longPollFallbackState === LongPollFallbackState.USE_FALLBACK
                ? params.url.replace(LONGPOLL_RETRY, LONGPOLL_FALLBACK)
                : params.url;
        xhr.open("GET", url + "?" + objectToQuery(params.content));
        /* This header would force browsers to "pre-flight" the request with an OPTIONS request to
         * check cross-domain accessibility, which would take some work to handle in Spring.
         * Fortunately we don't actually need the header here (it's for RedirectUtil#restAwareRedirect,
         * and our only long-polling request, poll.rest, should never redirect) */
        // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send();
    }
    xhr.onreadystatechange = function () {
        if (xhr.readyState !== XMLHttpRequest.DONE) {
            return;
        }
        let res: Response;
        try {
            res = parseResponse(xhr);
        } catch (e) {
            if (xhr.status === 0) {
                // Fallback logic; see explanation above. When we have an eligible fallback to try,
                // we try it once immediately without delay. If it fails again, we instead fall
                // through to standard error handling.
                if (longPollFallbackState === LongPollFallbackState.ORIGINAL_WITH_FALLBACK) {
                    if (params.url.replace(LONGPOLL_RETRY, LONGPOLL_FALLBACK) === params.url) {
                        // original URL is the same as the fallback URL
                        longPollFallbackState = LongPollFallbackState.USE_ORIGINAL;
                    } else {
                        longPollFallbackState = LongPollFallbackState.TRYING_FALLBACK;
                        doRequest();
                        return;
                    }
                } else if (longPollFallbackState === LongPollFallbackState.TRYING_FALLBACK) {
                    // fallback failed too, bounce back to original state and retry after a delay
                    longPollFallbackState = LongPollFallbackState.ORIGINAL_WITH_FALLBACK;
                }
            }
            // Most failures are caused when the server is temporarily unreachable. Since this can
            // happen during a server restart, we wait about half as long as the typical restart
            // time before trying again.
            console.log("Request failed:", e);
            setTimeout(doRequest, 30 * C.SEC);
            params.onError && params.onError();
            return;
        }
        if (longPollFallbackState === LongPollFallbackState.ORIGINAL_WITH_FALLBACK) {
            // Original URL succeeded.
            longPollFallbackState = LongPollFallbackState.USE_ORIGINAL;
        } else if (longPollFallbackState === LongPollFallbackState.TRYING_FALLBACK) {
            // On fallback success we eagerly fall back thereafter.
            longPollFallbackState = LongPollFallbackState.USE_FALLBACK;
        }
        params.onResponse(res);
    };
    doRequest();
    return xhr;
}

export function longPollStop(xhr: XMLHttpRequest) {
    xhr.onreadystatechange = null;
    xhr.abort();
}

export function uploadFile(url: string, form: HTMLFormElement | FormData, content: any = {}) {
    return new Promise<any>(function retry(resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("X-XSRF-TOKEN", dojo_cookie("XSRF-TOKEN"));
        prepareXHR(xhr, url, resolve, reject, () => {
            retry(resolve, reject);
        });
        const formData = form instanceof HTMLFormElement ? new FormData(form) : form;
        Object.keys(content).forEach((k) => {
            const value = content[k];
            if (Is.defined(value)) {
                formData.append(k, value);
            }
        });
        xhr.send(formData);
    });
}

// Start the logout poll. This will check if the user is logged out.
setLogoutPollTimeout();
