/**
 * Misc UI-related functions and classes that are used in multiple Upload modules.
 */
import Bates = require("Everlaw/Bates");
import { Arr } from "core";
import DocType = require("Everlaw/DocType");
import Dom = require("Everlaw/Dom");
import { PrefixParsingLevel } from "Everlaw/PrefixParsingLevel";
import ActionNode = require("Everlaw/UI/ActionNode");
import Button = require("Everlaw/UI/Button");
import Checkbox = require("Everlaw/UI/Checkbox");
import Icon = require("Everlaw/UI/Icon");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Tooltip = require("Everlaw/UI/Tooltip");
import PdfnlfParsing = require("Everlaw/Model/Upload/Util/PdfnlfParsing");
import Util = require("Everlaw/Util");

export const SUCCESS_ICON = "icon_circle-check-filled-green-20";
export const WARNING_ICON = "icon_alert-circle-filled-yellow-20";
export const INVALID_ICON = "icon_x-circle-filled-red-20";
export const STATUS_ICON_CLASSES = [SUCCESS_ICON, WARNING_ICON, INVALID_ICON].join(" ");

/**
 * A width we often use for GridTable widgets in our upload UI.
 */
export const UPLOAD_GRIDTABLE_MINWIDTH = 696;

export class PrefixBox {
    readonly node: HTMLDivElement;
    protected preview: HTMLElement;
    protected dialog: QueryDialog;
    protected dialogBody: HTMLElement;
    private allowEmptyPrefixCheckbox: Checkbox;
    private box = Dom.textarea({ spellcheck: "false" });
    private editIcon: Button.IconButton;
    private prefixErrorMessage: HTMLElement;
    private prefixErrorSection: HTMLElement;

    protected currentPrefixes: string[] = [];
    protected currentPrefixParsingLevel: PrefixParsingLevel =
        PrefixParsingLevel.NO_EMPTY_OR_AMBIGUOUS_PREFIX;

    private toDestroy: Util.Destroyable[] = [];

    constructor(reanalyze: (prefixes: string[], prefixParsingLevel: PrefixParsingLevel) => void) {
        this.allowEmptyPrefixCheckbox = new Checkbox({
            label: "Allow Bates with empty prefix",
            onUserClick: () => this.onChange(),
        });
        this.toDestroy.push(this.allowEmptyPrefixCheckbox);
        this.dialogBody = Dom.div(
            { class: "v-spaced-16" },
            Dom.div(`To identify the correct Bates prefixes in your load file, edit or add prefixes
                    below and reanalyze.`),
            Dom.div(
                { class: "edit-prefixes-content-section" },
                Dom.div(
                    { class: "edit-prefixes-content-section-header" },
                    Dom.span({ class: "h7" }, "Bates prefixes in load file"),
                    Dom.span({ class: "bates-subtitle" }, "(case sensitive, enter one per line)"),
                ),
                this.box,
                (this.prefixErrorSection = Dom.div(
                    { class: "prefix-error-section hidden" },
                    new Icon("alert-triangle-red-20").node,
                    (this.prefixErrorMessage = Dom.div()),
                )),
                Dom.node(this.allowEmptyPrefixCheckbox),
            ),
        );

        this.dialog = QueryDialog.create(
            {
                title: "Edit Bates prefixes",
                classes: "upload-bates-prefixes-dialog",
                prompt: "",
                body: this.dialogBody,
                submitText: "Reanalyze",
                submitButtonParams: { width: null },
                onSubmit: () => {
                    this.dialog.hide();
                    this.dialog.destroy();
                    const prefixes = this.getReanalyzePrefixes();
                    const prefixParsingLevel = this.getPrefixParsingLevel();
                    reanalyze(prefixes, prefixParsingLevel);
                },
                destroyOnClose: false,
            },
            false,
        );
        this.toDestroy.push(this.dialog);

        const clearAndReanalyze = new Button({
            label: "Auto-detect prefixes",
            class: "upload-bates-prefixes__auto-button safe",
            width: null,
            onClick: () => {
                this.dialog.hide();
                this.dialog.destroy();
                reanalyze([], PrefixParsingLevel.NO_EMPTY_OR_AMBIGUOUS_PREFIX);
            },
        });
        this.toDestroy.push(clearAndReanalyze);
        Dom.place(clearAndReanalyze, this.dialog._submitButton, "before");

        this.editIcon = new Button.IconButton({
            iconClass: "pencil-20",
            tooltip: "Edit Bates prefixes",
            onClick: () => {
                this.dialog.show();
            },
        });
        this.toDestroy.push(this.editIcon);
        this.node = Dom.div(
            { class: "upload-bates-prefixes-preview" },
            Dom.div(
                { class: "h7" },
                "Bates prefixes",
                Dom.span({ class: "margin-left-4" }, this.editIcon.node),
            ),
            (this.preview = Dom.div()),
        );

        this.box.onkeyup = () => this.onChange();
    }

    buildExtraButton(): Button {
        const button = new Button({
            label: "Edit Bates prefixes",
            class: "skinny",
            width: null,
            onClick: () => this.dialog.show(),
        });
        this.toDestroy.push(button);
        return button;
    }

    getReanalyzePrefixes(): string[] {
        return this.box.value
            .trim()
            .split("\n")
            .map((s) => s.trim())
            .filter((s) => !!s);
    }

    getPrefixParsingLevel(): PrefixParsingLevel {
        let prefixParsingLevel: PrefixParsingLevel;
        if (this.allowEmptyPrefixCheckbox.isSet()) {
            prefixParsingLevel = PrefixParsingLevel.ALLOW_EMPTY_PREFIX;
        } else {
            prefixParsingLevel = PrefixParsingLevel.NO_EMPTY_PREFIX;
        }
        return prefixParsingLevel;
    }

    setReanalyzeView(prefixes?: string[], prefixParsingLevel?: PrefixParsingLevel): void {
        prefixes = prefixes?.filter((p) => p !== Bates.NO_PREFIX);
        this.box.value = prefixes?.join("\n") || "";
        this.allowEmptyPrefixCheckbox.set(
            prefixParsingLevel === PrefixParsingLevel.ALLOW_EMPTY_PREFIX,
        );
    }

    setPreviewPrefixes(prefixes?: string[]): void {
        Dom.setContent(
            this.preview,
            !!prefixes && prefixes.length > 0 ? prefixes.join(", ") : this.buildNoPrefixMessage(),
        );
        Dom.show(this.editIcon, !!prefixes && prefixes.length > 0);
    }

    protected buildNoPrefixMessage(): HTMLElement {
        const actionMessage = ActionNode.textAction("Add Bates prefixes manually", () =>
            this.dialog.show(),
        );
        const warningIcon = new Icon("alert-triangle-20");
        this.toDestroy.push(warningIcon);
        const warningMessage =
            "No Bates prefixes identified. Manually add prefixes and/or allow Bates with empty prefixes";
        return Dom.div(actionMessage.node, Icon.callout("alert-triangle-20", warningMessage));
    }

    get(): string[] {
        return this.currentPrefixes;
    }

    set(prefixes?: string[], prefixParsingLevel?: PrefixParsingLevel): void {
        this.currentPrefixParsingLevel =
            prefixParsingLevel || PrefixParsingLevel.NO_EMPTY_OR_AMBIGUOUS_PREFIX;

        prefixes = prefixes && Arr.unique(prefixes).sort(cmpPrefix);
        this.currentPrefixes = prefixes;
        this.setPreviewPrefixes(prefixes);
        this.setReanalyzeView(prefixes, prefixParsingLevel);
        this.onChange();
    }

    samePrefixes(): boolean {
        return Arr.equals(this.getReanalyzePrefixes(), this.get());
    }

    samePrefixParsingLevel(): boolean {
        return this.currentPrefixParsingLevel === this.getPrefixParsingLevel();
    }

    protected onChange(): void {
        const samePrefixes = this.samePrefixes();
        const samePrefixParsingLevel = this.samePrefixParsingLevel();

        const errorMessages = [];
        this.getReanalyzePrefixes().forEach((elem) => {
            const err = PdfnlfParsing.BatesFilenameParse.invalidPrefixReason(elem);
            if (err !== null) {
                errorMessages.push(Dom.div(err));
            }
        });

        if (errorMessages.length > 0) {
            Dom.setContent(this.prefixErrorMessage, errorMessages);
            Dom.show(this.prefixErrorSection);
        } else {
            Dom.hide(this.prefixErrorSection);
        }
        this.dialog.disableSubmit(
            (samePrefixes && samePrefixParsingLevel) || errorMessages.length > 0,
        );
    }

    destroy() {
        Dom.remove(this.node);
        Util.destroy(this.toDestroy);
    }
}

export function buildRandomSamples(d: Util.Destroyable[], iconClass?: string): HTMLElement {
    return buildSamples(d, "Random samples from load file", iconClass);
}

export function buildRandomDatabaseSamples(d: Util.Destroyable[], iconClass?: string): HTMLElement {
    return buildSamples(d, "Random samples from table", iconClass);
}

export function buildSamples(
    d: Util.Destroyable[],
    title: string,
    iconClass?: string,
): HTMLElement {
    const sampleInfo = new Icon(iconClass ? "info-circle-20 " + iconClass : "info-circle-20");
    const sampleTooltip = new Tooltip(
        sampleInfo,
        Dom.div(
            { class: "upload-config-random-samples-tooltip" },
            "Samples are random. Values in each column do not correspond to one particular record",
        ),
    );
    d.push(sampleInfo, sampleTooltip);
    return Dom.div({ class: "h-spaced-4" }, Dom.span(title), sampleInfo.node);
}

export function buildNoMoreValues(numValues: number): HTMLElement {
    return Dom.div({ class: "italic" }, "(No " + (numValues > 0 ? "other " : "") + "values)");
}

export function buildUnusualMappingIntro(headerCls: string, d: Util.Destroyable[]): HTMLElement[] {
    const warningIcon = new Icon("alert-triangle-red-20");
    d.push(warningIcon);
    return [
        Dom.div(Dom.span({ class: headerCls }, "Unusual mappings")),
        Dom.div(
            "Previous uploads mapped these load file columns to different Everlaw display names",
        ),
        Dom.div(
            { class: "h-spaced-8" },
            warningIcon.node,
            Dom.span(
                { class: "red-text" },
                `The values from the previous upload mapping will be deleted to allow for
                the new mapping in this upload`,
            ),
        ),
    ];
}

export function buildNoMoreArmFields(numExamples: number): HTMLElement {
    return Dom.div(
        { class: "arm-field-no-example-text" },
        "(No " + (numExamples > 0 ? "more" : "") + " values found in backup file)",
    );
}

export function cmpPrefix(a: string, b: string): number {
    if (a === Bates.NO_PREFIX) {
        return -1;
    } else if (b === Bates.NO_PREFIX) {
        return 1;
    } else {
        return a.localeCompare(b);
    }
}

// A mapping from extension to filetype for some special file types we might expect during an upload.
const customExtMap: { [ext: string]: string } = {
    vault: "google-vault-logo30",
};

// Return a filetype icon name appropriate for the given filename.
export function iconForFilename(filename: string) {
    const extIdx = filename.lastIndexOf(".");
    if (extIdx >= 0 && extIdx < filename.length) {
        const ext = filename.substring(extIdx + 1).toLowerCase();
        if (ext in customExtMap) {
            return customExtMap[ext];
        } else if (ext in DocType.byExtension) {
            const docType = DocType.byExtension[ext].id;
            return DocType.toIcon[docType];
        }
    }
    return "file";
}
