import Base = require("Everlaw/Base");
import Dom = require("Everlaw/Dom");
import { EverColor } from "design-system";
import Chronology = require("Everlaw/Chron/Chronology");
import ChronLabel = require("Everlaw/Chron/ChronologyLabel");
import ChronWorkEntry = require("Everlaw/Chron/ChronologyWorkEntry");
import { Str } from "core";
import { Color } from "Everlaw/ColorUtil";

class ChronologyProfile extends Base.Object {
    override id: ChronologyProfile.Id;
    chronLabelId: number;
    emails: string[] = [];
    socialMedia: string[] = [];
    notes: string;
    argumentIds: number[] = [];
    // The name of the label, stored for use after the associated label is deleted.
    cachedName?: string;
    // A base64 encoded string of the profile picture
    profilePic: string;
    static chronLabelIdToChronProfile: { [chronLabelId: number]: ChronologyProfile } = {};

    constructor(params: any) {
        super(params);
        this._mixin(params);
        params.workEntries && Base.set(ChronWorkEntry, params.workEntries);
        ChronologyProfile.chronLabelIdToChronProfile[this.chronLabelId] = this;
    }
    getColor() {
        const label = Base.get(ChronLabel, this.chronLabelId);
        return label ? label.getColor() : Color.fromEverColor(EverColor.EVERBLUE_40);
    }
    override display() {
        const label = Base.get(ChronLabel, this.chronLabelId);
        this.cachedName = label ? label.name : this.cachedName || "Unknown profile";
        return this.cachedName;
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    get className() {
        return "ChronologyProfile";
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module ChronologyProfile {
    export type Id = number & Base.Id<"ChronologyProfile">;

    export function getProfiles(chronId: Chronology.Id) {
        return Base.get(ChronologyProfile).filter((r) => {
            const l = Base.get(ChronLabel, r.chronLabelId);
            return l && l.category.chronologyId === chronId;
        });
    }

    export function getProfileForChronLabelId(chronLabelId: ChronLabel.Id) {
        return ChronologyProfile.chronLabelIdToChronProfile[chronLabelId];
    }

    export function getProfileImageContent(profile: ChronologyProfile) {
        if (profile.profilePic) {
            return Dom.img({
                class: "badge-profile-pic",
                src: profile.profilePic,
                alt: "",
            });
        }
        return Dom.div({
            class: "badge-initial-section",
            textContent: Str.getInitials(profile.display()),
            style: {
                backgroundColor: profile.getColor().toHexString(),
            },
        });
    }

    export interface RawUpdateParams {
        socialMedia?: string[];
        emails?: string[];
        notes?: string;
        argumentIds?: number[];
        profilePic?: string;
    }

    export interface UpdateParams {
        chronProfileId: Id;
        socialMedia?: string;
        emails?: string;
        notes?: string;
        argumentIds?: string;
        profilePic?: string;
    }
}

export = ChronologyProfile;
